import styles from './DynImage.module.scss';
// import { useState } from "react"
import { useEffect, useState } from 'react';
import usePrefStore from '../stores/prefs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAperture } from '@fortawesome/pro-light-svg-icons';

function DynImage(props) {
   const [myImg, setMyImg] = useState(false);
   const [fromByMessika, setFromByMessika] = useState(false);
   const [loading, setLoading] = useState(false);
   //
   // const imgSourcePrio = usePrefStore((state) => state.imgSourcePrio);
   // const showImageSource = usePrefStore((state) => state.showImageSource);
   const { imgSourcePrio, showImageSource } = usePrefStore();

   const checkImage = (path) => {
      // console.log('CHECK_IMAGE', path, props);
      return new Promise((resolve, reject) => {
         const img = new Image();
         img.onload = () => resolve(path);
         img.onerror = (err) => reject({ err, path });
         img.src = path;
      });
   };

   useEffect(() => {
      // console.log('DynImage', props.src, imgSourcePrio)
      if (Array.isArray(props.src) && props.src.length === 2) {
         let arr;
         if (!props.src[0] && props.src[1]) {
            arr = [
               `https://www.bymessika.com/media/catalog/product${props.src[1]}`,
               null
            ];
         }
         else if (imgSourcePrio === 'internal' || !props.src[1]) {
            arr = [props.src[0], props.src[1]];
         } else {
            arr = [
               `https://www.bymessika.com/media/catalog/product${props.src[1]}`,
               props.src[0]
            ];
         }
         // log('DynImage', arr)
         setLoading(true);
         checkImage(arr[0])
            .then((path) => {
               // log('DynImage', "OK1", path)
               setLoading(false);
               setMyImg(path);
               setFromByMessika(imgSourcePrio !== 'internal');
            })
            .catch((err) => {
               // log('DynImage', "KO1", err)
               if (arr[1]) {
                  checkImage(arr[1])
                     .then((path) => {
                        setLoading(false);
                        // log('DynImage', "OK2", path)
                        setMyImg(path);
                        setFromByMessika(imgSourcePrio === 'internal');
                     })
                     .catch((err) => {
                        setLoading(false);
                        // log('DynImage', "KO2", err)
                        // log('DynImage', "Pas d'image !")
                        // setMyImg('https://www.bymessika.com/media/catalog/product/b/a/bague-diamant-noir-titane-noir-move-titanium-06561.jpg')
                     });
               } else {
                  // Y'a qu'une seule image !
                  setLoading(false);
               }
            });
      }
   }, [props, imgSourcePrio]);

   // <img src={props.src} className={styles.dynimage} alt={props.alt} />
   return (
      <div
         className={`${styles.dynimage} ${props.className} ${loading ? styles.loading : ''
            }`}
      >
         {myImg && <img src={myImg} alt={props.alt} />}
         {myImg && fromByMessika && showImageSource && (
            <div className={styles.bymessika} />
         )}
         {!myImg && <FontAwesomeIcon icon={faAperture} />}
         {/* <div className={styles.bymessika} /> */}
      </div>
   );
}

export default DynImage;
