// import leftPaneImage from '../assets/login_left-pane.webp';
import InputField from '../components/InputField';
import Button from '../components/Button';
import { Checkbox, States as CheckboxStates } from '../components/Checkbox';
import styles from './Login.module.scss';
import styles_DropdownMenu from '../components/DropdownMenu.module.scss';
import Link from '../components/Link';
import usePrefStore from '../stores/prefs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppWriteContext } from '../Contexts/AppWriteContext';
import DropdownMenu from '../components/DropdownMenu';
import useTranslation from '../Hooks/useTranslation';
import useHelpers from '../Hooks/useHelpers';
// import useAppWrite from '../Hooks/useAppWrite';
// import { useLogger } from '../Contexts/Logger';
import glob_const from '../libs/glob_const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader, faWifi, faWifiExclamation, faArrowsRotate } from '@fortawesome/pro-solid-svg-icons'
import axios from 'axios'
import { Progress } from '@mantine/core';
import { ToastContainer, toast } from 'react-toastify'
import { GlobalContext } from '../Contexts/GlobalContext';
import useSalesStore from '../stores/sales';
import { db as dexie_db } from "../stores/db"
import { registerSW } from 'virtual:pwa-register'
import bcrypt from 'bcryptjs'

const SHOW_DEBUG = 5   // Nb de click sur l'image' pour montrer la partie DEBUG
const SPINNER_DURATION = 3000

const menu_lang = {
   french: 'fr',
   english: 'en',
}

function Login(props) {
   // 3 façons de faire :
   // 1ère)
   // const [prefRememberMe, setPrefRememberMe] = usePrefStore((state) => ([state.prefRememberMe, state.setPrefRememberMe]))
   // 2ème)
   // const { prefRememberMe, setPrefRememberMe } = usePrefStore((state) => ({ prefRememberMe: state.prefRememberMe, setPrefRememberMe: state.setPrefRememberMe }))
   // 3ème)
   // const prefRememberMe = usePrefStore((state) => state.rememberMe)
   // const setPrefRememberMe = usePrefStore((state) => state.setRememberMe)

   // const prefRememberMe = usePrefStore((state) => state.rememberMe);
   // const setPrefRememberMe = usePrefStore((state) => state.setRememberMe);
   // const appWriteEndpoint = usePrefStore((state) => state.appWriteEndpoint);
   // const setAppWriteEndpoint = usePrefStore((state) => state.setAppWriteEndpoint);
   // const appWriteProjectId = usePrefStore((state) => state.appWriteProjectId);
   // const setAppWriteProjectId = usePrefStore((state) => state.setAppWriteProjectId);
   // const currentLang = usePrefStore((state) => state.currentLang);
   // const setCurrentLang = usePrefStore((state) => state.setCurrentLang);
   // const prefLogin = usePrefStore((state) => state.login);
   // const setPrefLogin = usePrefStore((state) => state.setLogin);
   // const setIsLogged = usePrefStore((state) => state.setIsLogged);

   const allPrefs = usePrefStore(); // Pour l'envoi vers assistance IT si besoin
   const { initialState, rememberMe: prefRememberMe, setRememberMe: setPrefRememberMe, pwaApiEndpoint, setPwaApiEndpoint, currentLang, setCurrentLang, login: prefLogin, setLogin: setPrefLogin, setIsLogged, setHashedPassword, hashedPassword, setHashedLastLogin, hashedLastLogin, bcryptSalt, udid, pwaApiKey, recover, reset: resetPrefs, appWriteEndpoint, setAppWriteEndpoint, setRecentCustomers, setRecentOrders, showCessDelay } = usePrefStore();
   const { getVersion, getVersionDate, getCopyright, getDevRefShort, cleanCache } = useHelpers()
   //
   const [login, setLogin] = useState(prefRememberMe ? prefLogin : '');
   const [password, setPassword] = useState('');
   const [otpToken, setOtpToken] = useState('');
   const [langMenu, setLangMenu] = useState(null);
   const [isLostPasswordVisible, setIsLostPasswordVisible] = useState(false)
   const [isMenuLangVisible, setIsMenuLangVisible] = useState(false)
   const [clickCount, setClickCount] = useState(0);   // A 5 on démasque le champ server
   const [sendingPrefs, setSendingPrefs] = useState(false);
   const [importingPrefs, setImportingPrefs] = useState(false);
   const [retrievedPrefs, setRetrievedPrefs] = useState();
   const [isFetching, setIsFetching] = useState(false);
   const [errorsFetching, setErrorsFetching] = useState();
   const [fetchTotal, setFetchTotal] = useState(0);
   const [fetchDone, setFetchDone] = useState(0);
   const [fetchPourc, setFetchPourc] = useState(0);
   const [fetchSubProgress, setFetchSubProgress] = useState(0);
   const [canCleanDb, setCanCleanDb] = useState(false);
   const [canCleanOrders, setCanCleanOrders] = useState(false);
   const [showProgressBecauseTooLong, setShowProgressBecauseTooLong] = useState(false);
   const [initState, setInitState] = useState();
   //
   const { isWebSocketConnected } = { ...GlobalContext() };
   //
   const { setLastUpdatedBiOrders, setLastUpdatedOrders, setLastUpdatedCustomers } = useSalesStore()
   //
   const { doLogin, doLogout, initialFetch, awPushWaitingOrders, awPushUdid, fetchOtp, resetPasswordOtp } = { ...AppWriteContext() };
   //
   const navigate = useNavigate();
   const passwordRef = useRef(null);
   // const { log } = useLogger();
   const { trans } = useTranslation();

   // console.log('ALL PREFS', initialState());

   // console.log('DO_LOGIN===', doLogin);
   // console.log('AW_CTXT===', AppWriteContext);

   // console.log(import.meta.env)  <== OK ça permet de récupérér les varenvs de prefixe = "VITE_" + qq autres de vite

   // Permet de refresh le SW (skip waiting) uniquement sur la page Login
   useEffect(() => {
      const updateSW = registerSW({
         onRegistered(r) {
            // console.log('**SW++ 2 onRegistered', r);
            if (r) {
               r.update()     // On check immédiatement à l'affichage du login
            }
         },
         onNeedRefresh() {
            updateSW()
         },
      })
   }, [])

   const chooseLang = useCallback((lang) => {
      setCurrentLang(lang)
      setIsMenuLangVisible(false)
   }, [setCurrentLang])

   useEffect(() => {
      setInitState(initialState())
   }, [initialState])

   useEffect(() => {
      setFetchPourc((fetchTotal > 0) ? (fetchDone * 100 / fetchTotal) : 0)
   }, [fetchTotal, fetchDone])

   useEffect(() => {
      setLangMenu(
         <DropdownMenu noscrollbars left={-100} top={-20} onSelect={chooseLang}>
            <li className={`${(currentLang !== menu_lang.english) ? styles_DropdownMenu.selected : ''}`} id={menu_lang.french}>
               {trans('?Français', 1, { lng: 'fr' })}
            </li>
            <li className={`${(currentLang === menu_lang.english) ? styles_DropdownMenu.selected : ''}`} id={menu_lang.english}>
               {trans('?Anglais', 1, { lng: 'en' })}
            </li>
         </DropdownMenu>
      );
   }, [chooseLang, currentLang, setCurrentLang, trans]);

   function onRememberMeHandler(status) {
      let newStatus = status === CheckboxStates.Checked;
      setPrefRememberMe(newStatus);
      if (!newStatus) {
         setPrefLogin('');
      } else {
         setPrefLogin(login);
      }
      // return true; // Chnagement tjs OK
   }

   function onLoginChangeHandler(val) {
      val = val?.toLowerCase()
      setLogin(val);
      // if (prefRememberMe) {
      setPrefLogin(val);
      // } else {
      //    setPrefLogin('');
      // }
   }

   function onPasswordChangeHandler(val) {
      setPassword(val);
   }

   function onOtpTokenChangeHandler(val) {
      setOtpToken(val);
   }

   const startFetches = useCallback((newLogin) => {
      setFetchDone(0)
      setErrorsFetching(null)
      setShowProgressBecauseTooLong(false)
      console.log('Start fetching');
      initialFetch(newLogin, () => {
         console.log('Fetching finished');
         console.log('setIsLogged(true)');
         setIsLogged(true)
         awPushWaitingOrders()
         navigate('/catalog', { replace: true });
      }, (err) => {
         console.log('Error fetching');
         console.log('setIsLogged(false)');
         setIsLogged(false)
         setErrorsFetching(err)
         setTimeout(() => {   // On réactive le bouton un peu plus tard, le temps d'attirer le regard sur l'erreur et éviter des envois trop rapides
            setIsFetching(false)
         }, 2000)
      }, setFetchTotal, () => { setFetchDone(cur => cur + 1) }, (txt) => { setFetchSubProgress(txt) })

      setTimeout(() => {   // On n'affiche la barre de chargement que si c'est trop long (> 2sec). Ca evite une barre trop furtive
         setShowProgressBecauseTooLong(true)
      }, 500)

   }, [initialFetch, navigate, setIsLogged, awPushWaitingOrders])

   // console.log('PREFS', usePrefStore());
   // console.log('Remain', remainFetching, totalFetching, (totalFetching - remainFetching) * 100);
   // console.log(errorsFetching);

   const cleanDb = useCallback(async () => {
      setClickCount(0)
      if (canCleanDb) {
         console.log('CleanDB');
         setLastUpdatedBiOrders(null)
         setLastUpdatedCustomers(null)
         await dexie_db.customers.clear()
         await dexie_db.bi_orders.clear()
         setCanCleanDb(false)
      }
      if (canCleanOrders) {
         console.log('CleanOrders');
         setLastUpdatedOrders(null)
         await dexie_db.orders.clear()
         setCanCleanOrders(false)
      }
      // await dexie_db.orders.clear()
      const remember = prefRememberMe
      const login = prefLogin
      resetPrefs()
      if (remember) {
         setPrefRememberMe(remember)
         setPrefLogin(login)
      }
   }, [canCleanDb, canCleanOrders, prefLogin, prefRememberMe, resetPrefs, setLastUpdatedBiOrders, setLastUpdatedCustomers, setLastUpdatedOrders, setPrefLogin, setPrefRememberMe])

   const onLogin = useCallback(async () => {
      if (!isFetching) {
         try {
            setIsFetching(true)  // Pour désactiver le bouton, car on va fetcher très prochainement
            // console.log(password, bcryptSalt);
            const hash = bcrypt.hashSync(password, bcryptSalt)
            const hash_login = bcrypt.hashSync(login, bcryptSalt)
            console.log('hash', hash);
            const ret = await doLogin(login, password)
            console.log(ret);
            if (ret.status) { // Vérifié online, ok, loggué. On passe ensuite à l'étape de fetch des data
               // Login/Pwd ok
               console.log('Vérification en online réussie. Loggué');
               // setIsLogged(true)
               setHashedPassword(hash)
               const same_login = (hash_login === hashedLastLogin)
               console.log('HASH LOGIN', hash_login, hashedLastLogin);
               if (!same_login) {  // Ca signifie qu'on a changé d'utilisateur. On purge la base des clients et des commande
                  console.log('HASH LOGIN>>>', hash_login, hashedLastLogin);
                  setRecentCustomers(null)
                  setRecentOrders(null)
                  await cleanDb()
                  setHashedLastLogin(hash_login)
               }
               awPushUdid(login)
               fetchOtp()
               console.log('Start fetches');
               startFetches(!same_login) // C'est dans cette fonction qu'on passera en isLoggued(true)
            } else {    // Pas vérifié online, 2 possibilités : pas de réseau ou refusé par le serveur
               console.log(JSON.stringify(ret.data));
               if (ret.data.code === glob_const.http_codes.UNAUTHORIZED) {    // Refusé, on ne peut plus se connecter (même en offline) tant qu'on n'aura pas réussi une nv connexion online
                  console.log('Vérification en online refusée par le serveur. Pas/plus loggué');
                  await doLogout()
                  console.log('setIsLogged(false)');
                  setIsLogged(false)
                  setIsFetching(false)
                  setHashedPassword(null)
                  // setPassword('')
                  toast.error(trans('?Accès refusé'), {
                     position: "top-right",
                     autoClose: 2500,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     theme: "colored",
                  });
               } else if (ret.data.code === glob_const.http_codes.BAD_REQUEST) {    // Autre erreur, on affiche le message mais on ne peut plus se connecter (même en offline) tant qu'on n'aura pas réussi une nv connexion online
                  console.log('Pb de vérification en online par le serveur (ex : mdp top court). Pas/plus loggué');
                  console.log('setIsLogged(false)');
                  setIsLogged(false)
                  setIsFetching(false)
                  setHashedPassword(null)
                  toast.error(ret.data.message ?? trans('?Erreur inconnue'), {
                     position: "top-right",
                     autoClose: 2500,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     theme: "colored",
                  });
               } else {    // Erreur mais pas refusé par serveur. Certainement un pb réseau. On vérifie le hash du mdp de la dernière conexion online réussie
                  // console.log('Compare', password, hashedPassword)
                  const ret = bcrypt.compareSync(password, hashedPassword)
                  console.log('Ret', JSON.stringify(ret))
                  if (ret) {
                     console.log('Vérification en online impossible, mais mdp vérifié offline. Loggué');
                     console.log('setIsLogged(true)');
                     setIsLogged(true)
                     navigate('/catalog', { replace: true });
                  } else {
                     setIsFetching(false)
                     console.log('Vérification en online impossible, et mdp impossible à vérifier offline. Pas/plus loggué');
                  }
               }
            }
         } catch (err) {
            console.log('Pb inattendu au login. Pas/plus loggué', err);
            toast.error(trans('?Erreur inconnue'), {
               position: "top-right",
               autoClose: 2500,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               theme: "colored",
            });
            setIsLogged(false)
            setIsFetching(false)
         }
      }
   }, [awPushUdid, bcryptSalt, cleanDb, doLogin, doLogout, fetchOtp, hashedLastLogin, hashedPassword, isFetching, login, navigate, password, setHashedLastLogin, setHashedPassword, setIsLogged, setRecentCustomers, setRecentOrders, startFetches, trans])

   // Tout est prêt pour du login avec des services OAuth2 tiers. Exemple fonctionnel pour Amazon
   // function onLoginWithAmazon() {
   //    doLoginWithAmazon()
   // }

   // COMMENTÉ POUR TEST V0.52 MESSIKA - A REMETTRE QUAND OK APPWRITE !
   // useEffect(() => {
   //    setIsLogged(false)   // On part du principe qu'on a un fake "success"
   //    if (props.status === 'success') {
   //       getAccount().then((res) => {
   //          console.log('ICI!')
   //          if (res) {
   //             setIsLogged(true)
   //             navigate("/", { replace: true })
   //          } else {
   //             console.log('Fake login!')
   //          }
   //       }).catch(err => {
   //          console.log('Fake login!', err)
   //       })
   //    }
   // }, [getAccount, navigate, props.status, setIsLogged])

   // console.log(setRememberMe)
   // console.log('rm', remember_me)
   // console.log("login", login)
   // console.log('login', props);

   const sendPrefs = useCallback(() => {
      setSendingPrefs(true)
      axios.post(`${pwaApiEndpoint}/device_prefs`, {
         udid,
         prefs: allPrefs
      }, {
         headers: { 'X-API-Key': pwaApiKey },
      }).then((data) => {
         console.log('DATA', data);
      }).catch(err => {
         console.log('ERR', err);
      }).finally(() => {
         // On laisse 1sec pour avoir le temps de lire
         setTimeout(() => {
            setClickCount(0)  // On raffiche la page de login
            setSendingPrefs(false)
         }, SPINNER_DURATION)
      })
   }, [allPrefs, pwaApiEndpoint, pwaApiKey, udid])

   const doRetrievePrefs = useCallback(() => {
      console.log('RETR...LA', pwaApiKey);
      axios.get(`${pwaApiEndpoint}/device_prefs/${udid}`, {
         headers: { 'X-API-Key': pwaApiKey },
      }).then((data) => {
         console.log('DATA', data);
         setRetrievedPrefs(data.data)
      }).catch(err => {
         setRetrievedPrefs(null)
      })
   }, [pwaApiEndpoint, pwaApiKey, udid])

   const importPrefs = useCallback(() => {
      setImportingPrefs(true)
      setTimeout(() => {
         setClickCount(0)  // On raffiche la page de login
         setImportingPrefs(false)
         console.log(retrievedPrefs);
         try {
            recover(JSON.parse(retrievedPrefs))
            // On close le sendback
            axios.patch(`${pwaApiEndpoint}/device_prefs/${udid}`, {}, {
               headers: { 'X-API-Key': pwaApiKey },
            })
            navigate('/', { replace: true });
         } catch (err) {
            console.log('Erreur de recover', err)
         }
      }, SPINNER_DURATION)
   }, [navigate, pwaApiEndpoint, pwaApiKey, recover, retrievedPrefs, udid])

   useEffect(() => {
      console.log('RETR...');
      if (!retrievedPrefs && (clickCount >= SHOW_DEBUG)) {
         console.log('RETR...ICI');
         doRetrievePrefs()
      } else if (retrievedPrefs && (clickCount < SHOW_DEBUG)) {
         setRetrievedPrefs(null)
      }
   }, [clickCount, doRetrievePrefs, retrievedPrefs])

   function onKeyDown(evt) {
      // console.log(evt.key, evt.target, passwordRef, passwordRef.current);
      if (evt.keyCode === 13) {
         evt.preventDefault()
         if (evt.target.name === 'login') {
            passwordRef.current.focus();
         } else {
            onLogin()
         }
      }
   }

   // function refresh() {
   //    console.log('**SW++ ICI');
   //    const reg = registerSW({
   //       // onRegistered(r) {
   //       //    console.log('**SW++ onRegistered', r);
   //       //    r && setInterval(() => {
   //       //       console.log('**SW++ update?');
   //       //       r.update()
   //       //    }, intervalMS)
   //       // },
   //       // onNeedRefresh() {
   //       //    console.log('**SW++ onNeedRefresh');
   //       //    console.log('**SW++ onNeedRefresh updateSW !!!');
   //       //    // updateSW()
   //       //    setNeedRefresh(true)
   //       // },
   //       // onOfflineReady() {
   //       //    console.log('**SW++ onOfflineReady');
   //       // },
   //    })
   //    console.log('**SW++ REG', reg);
   //    reg()
   // }

   // console.log(sendingPrefs);

   function lostPassword() {
      setIsLostPasswordVisible(val => !val)
   }

   async function onChangePassword() {
      const ret = await resetPasswordOtp(otpToken, password)
      if (ret) {
         toast.success(trans('?resetpwd_ok'), {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            // progress: ,
            theme: "colored",
         });
         setIsLostPasswordVisible(false)
         setOtpToken(null)
      } else {
         toast.error(trans('?resetpwd_nok'), {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
         });
      }
   }

   const purgeCacheAndLogin = () => {
      cleanCache()
      if (!isFetching) {
         onLogin()
      }
   }

   return (
      <div className={styles.login} onKeyDown={(evt) => onKeyDown(evt)}>
         <div className={styles.left}>
            {/* <img src={leftPaneImage} alt="Messika modèle" onClick={() => setClickCount(click => click + 1)} /> */}
            <img src="/assets/login_left-pane.webp" alt="Messika modèle" onClick={() => setClickCount(click => click + 1)} />
         </div>
         <div className={styles.right}>
            <div className={styles.top}>
               <FontAwesomeIcon icon={isWebSocketConnected ? faWifi : faWifiExclamation} className={isWebSocketConnected ? styles.green : styles.red} />
            </div>
            <div className={styles.lang}>
               <Button onClick={() => { setIsMenuLangVisible(val => !val) }}>{(currentLang === 'en') ? 'EN' : 'FR'}</Button>
               {isMenuLangVisible && langMenu}
            </div>
            {(clickCount >= SHOW_DEBUG) &&
               <>
                  <h1>{trans("?Page d'assistance")}</h1>
                  <div className={styles.assist_block}>
                     <span>{trans("?assist_wrong")}</span>
                     <Button onClick={() => { setClickCount(0) }}>{trans("?Quitter cette page")}</Button>
                  </div>
                  <div className={styles.assist_block}>
                     <span>{trans("?clean_db")}</span>
                     <div className={styles.clean_db}>
                        <Checkbox caption={trans("?Vider la base")} checked={canCleanDb ? CheckboxStates.Checked : CheckboxStates.Unchecked} onChange={() => setCanCleanDb(val => !val)} />
                        <Checkbox caption={trans("?Vider les commandes (Danger !)")} checked={canCleanOrders ? CheckboxStates.Checked : CheckboxStates.Unchecked} onChange={() => setCanCleanOrders(val => !val)} />
                     </div>
                     <Button onClick={cleanDb} disabled={!canCleanDb && !canCleanOrders}>{trans("?Vider")}</Button>
                  </div>
                  <div className={styles.assist_block}>
                     <div className={styles.serv_reload}>
                        <InputField title={`${trans("?Serveur")} "DB"`} placeholder="domaine.com" name="server" value={appWriteEndpoint} onChange={(val) => setAppWriteEndpoint(val?.toLowerCase())} button={<Button onClick={() => { setAppWriteEndpoint(initState.appWriteEndpoint) }}><FontAwesomeIcon icon={faArrowsRotate} /></Button>} />
                     </div>
                     <div className={styles.serv_reload}>
                        <InputField title={`${trans("?Serveur")} "ADM"`} placeholder="domaine.com" name="server" value={pwaApiEndpoint} onChange={(val) => setPwaApiEndpoint(val?.toLowerCase())} button={<Button onClick={() => { setPwaApiEndpoint(initState.pwaApiEndpoint) }}><FontAwesomeIcon icon={faArrowsRotate} /></Button>} />
                     </div>
                     {!retrievedPrefs && <>
                        <span>{trans("?assist_send_prefs")}</span>
                        {!sendingPrefs && <Button onClick={sendPrefs}>{trans("?Envoyer")}</Button>}
                        {sendingPrefs && <Button inactive><FontAwesomeIcon icon={faLoader} className={styles.rotate} />{trans("?Envoi en cours")}</Button>}
                     </>
                     }
                     {retrievedPrefs && <>
                        <span>{trans("?assist_got_prefs")}</span>
                        {!importingPrefs && <Button green onClick={importPrefs}>{trans("?Importer")}</Button>}
                        {importingPrefs && <Button green inactive><FontAwesomeIcon icon={faLoader} className={styles.rotate} />{trans("?Importer")}</Button>}
                     </>
                     }
                  </div>
               </>
            }
            {(clickCount < SHOW_DEBUG) &&
               <>
                  <h1>{trans("?Veuillez vous identifier")}</h1>
                  <form>
                     <InputField title={trans("?Identifiant")} placeholder="exemple@domaine.com" name="login" value={login} onChange={onLoginChangeHandler} disabled={isFetching} />
                     <InputField title={trans("?Mot de passe")} placeholder={trans("?Votre mot de passe")} password name="password" value={password} ref={passwordRef} onChange={onPasswordChangeHandler} disabled={isFetching} />
                     {isLostPasswordVisible && <InputField title={trans("?Code OTP")} placeholder={trans("?6 chiffres")} name="otp_token" numeric value={otpToken} onChange={onOtpTokenChangeHandler} disabled={isFetching} />}
                     {/* <table>
                        <tr>
                           <td>{bcrypt.hashSync(login, bcryptSalt)}</td>
                        </tr>
                        <tr>
                           <td>{hashedLastLogin}</td>
                        </tr>
                     </table> */}
                  </form>
                  <div className={styles.toolz}>
                     <Checkbox caption={trans("?Se souvenir de moi")} checked={`${prefRememberMe ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={onRememberMeHandler} />
                     {!isLostPasswordVisible && <Link disabled={isFetching} onClick={lostPassword}>{trans("?Mot de passe oublié")}</Link>}
                     {isLostPasswordVisible && <Link disabled={isFetching} onClick={lostPassword}>{trans("?Masquer")}</Link>}
                  </div>
                  {!isLostPasswordVisible &&
                     <Button big onClick={onLogin} onLongPress={purgeCacheAndLogin} longPressDuration={showCessDelay} disabled={isFetching}>
                        {trans("?S'identifier")}
                     </Button>
                  }
                  {isLostPasswordVisible &&
                     <Button big onClick={onChangePassword} disabled={isFetching}>
                        {trans("?Changer le mot de passe")}
                     </Button>
                  }
               </>
            }
            {isFetching && showProgressBecauseTooLong &&
               <>
                  <Progress color="red" radius="xl" size="xl" value={fetchPourc} animate={true} className={styles.progress} />
                  {fetchSubProgress}
               </>
            }
            {errorsFetching && <>
               <span className={styles.fetch_error}>{errorsFetching}</span>
            </>}
            <div className={styles.bottom}>
               <span>UDID : {udid.substring(0, 4)}...{udid.slice(-4)}</span>
               <span>{getVersion()} - {getCopyright()} - {getVersionDate()}</span>
               <span>{getDevRefShort()}</span>
            </div>
         </div>
         <ToastContainer />
      </div>
   );
}

export default Login;
