import styles from './App.module.scss';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Main from './pages/Main';
import Catalog from './pages/Catalog';
import Login from './pages/Login';
import Customers from './pages/Customers';
import CustomerDetail from './pages/CustomerDetail';
import Orders from './pages/Orders';
import Params from './pages/Params';
import Tarifs from './pages/Tarifs';
import CatalogDetail from './pages/CatalogDetail';
// import useModelsStore from './stores/models'
import { useEffect/*, useRef, useState*/ } from 'react';
// import useTarifs from './Hooks/useTarifs';
import Tests from './pages/Tests';
// import useCollections from './Hooks/useCollections';
// import useSales from './Hooks/useSales';
// import useEventListener from './Hooks/useEventListener';
// import useScreenOrientation from 'react-hook-screen-orientation'
import { useMobileOrientation } from 'react-device-detect';
import usePrefStore from './stores/prefs';
import OrderDetail from './pages/OrderDetail';
// import LoginCallback from './pages/LoginCallback';
import { AppWriteContext } from './Contexts/AppWriteContext';
import DarkLayer from './components/DarkLayer';
import SyncLayer from './components/SyncLayer';
import OtpLayer from './components/OtpLayer';
import MissingTarifLayer from './components/MissingTarifLayer';
import DebugInfo from './components/DebugInfo';
import WebSocket from './components/WebSocket';
import { GlobalContext } from './Contexts/GlobalContext';
import { CatalogFilterContext } from './Contexts/CatalogFilterContext';
// import Fix from './pages/Fix';
import useTranslation from './Hooks/useTranslation';
import { ErrorBoundary } from 'react-error-boundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckMedical } from '@fortawesome/pro-solid-svg-icons'
import axios from 'axios'
import moment from 'moment'
import useHelpers from './Hooks/useHelpers';
// import useWebSocket, { ReadyState } from 'react-use-websocket'

function PrivateRoute({ children }) {
   const appWriteContext = { ...AppWriteContext() }
   const { isLogged } = usePrefStore();

   if (appWriteContext) {
      // const { isLogged } = appWriteContext;
      // const { isLogged } = useAppWrite()

      // console.log('isLogged ?', isLogged, children)
      return isLogged ? children : <Navigate to="/login" />;
   } else {
      return children
   }
}

// const old_log = console.log
// console.warn('OLD LOG!!!', old_log);

// function catchLogs() {
//    if (old_log === console.log) {
//       console.warn('CATCH LOG!!!');
//       console.log = (...args) => {
//          const error = new Error()
//          let str = error.stack.split('\n')[2]
//          const parts = str.match(/.*\/([^\\/\\?]+)[^:]*:([0-9]+):([0-9]+)[^0-9]*$/)

//          if (Array.isArray(parts) && (parts.length >= 4)) {
//             str = `${parts[1]}:${parts[2]}:${parts[3]}`
//             // } else {
//             //    str = '???' + (Array.isArray(parts) ? 'Y' : 'N') + str
//          }
//          old_log(`LOG: ${str}`, ...args)
//       }
//       console.warn('CATCH LOG!!! SET', old_log);
//    }
// }

// function releaseLogs() {
//    console.warn('RELEASE LOG!!! AVANT', console.log);
//    console.warn('RELEASE LOG!!! OLD', old_log);
//    console.log = old_log
//    console.warn('RELEASE LOG!!! APRÈS', console.log);
// }

const oldLogFunc = console.log

function App() {
   // const collections = useModelsStore((state) => state.collections)
   // const setCollections = useModelsStore((state) => state.setCollections)
   // const setModels = useModelsStore((state) => state.setModels)
   // const setPortes = useModelsStore((state) => state.setPortes)
   //
   // const [oldLogFunc, setOldLogFunc] = useState(null)
   //
   const { trans } = useTranslation();
   // const { fetchTarifRegions } = useTarifs()
   const location = useLocation();
   // const { fetchCollections, fetchModels, fetchFamilles, fetchPortes, fetchCycles, fetchEtapes, fetchGenres, fetchCouleurs } = useCollections()
   const { isPortrait } = useMobileOrientation()
   //
   // const [wsURL, setWsURL] = useState(null)
   // const noScrollbars = usePrefStore((state) => state.noScrollbars)
   // const setRecentOrders = usePrefStore((state) => state.setRecentOrders)
   // const debug = usePrefStore((state) => state.debug);
   const { noScrollbars, debug, pwaApiEndpoint, pwaApiKey, udid, sessionDuration, setCurTimeout, curTimeout, appVerForLastCachePurge, setAppVerForLastCachePurge } = usePrefStore();
   const { isDarkLayerVisible, isSyncLayerVisible, isOtpLayerVisible, isMissingTarifLayerVisible, allLogs, lastStackDump, setLastStackDump } = { ...GlobalContext() } // Le {...} pour éviter un pb tant que GlobalContext() retourne undefined
   //
   const { setSuspendRecalc } = { ...CatalogFilterContext() }
   const navigate = useNavigate();
   const { doLogout } = { ...AppWriteContext() };
   const { cleanCache, getVersion } = useHelpers();

   // /*
   // ██     ██ ███████ ██████  ███████  ██████   ██████ ██   ██ ███████ ████████
   // ██     ██ ██      ██   ██ ██      ██    ██ ██      ██  ██  ██         ██
   // ██  █  ██ █████   ██████  ███████ ██    ██ ██      █████   █████      ██
   // ██ ███ ██ ██      ██   ██      ██ ██    ██ ██      ██  ██  ██         ██
   //  ███ ███  ███████ ██████  ███████  ██████   ██████ ██   ██ ███████    ██
   // */

   // // https://www.npmjs.com/package/react-use-websocket
   // const { sendMessage, sendJsonMessage, lastMessage, readyState } = useWebSocket(wsURL, {
   //    shouldReconnect: (closeEvent) => true,
   //    reconnectAttempts: 99999999,  // 190 ans suivant le motif suivant :
   //    reconnectInterval: (attemptNumber) => {
   //       let pause
   //       if (attemptNumber < 12) {  // Toutes les 5sec durant 1min (12 essais)
   //          pause = 5000
   //       } else if (attemptNumber < 32) {    // Toutes les 15 sec durant 5min (20 essais)
   //          pause = 15000
   //       } else {    // Toutes les minutes ensuite (sans fin)
   //          pause = 60000
   //       }
   //       console.log(`WebSocket, Reconnexion ${attemptNumber} - Prochain essai dans ${Math.round(pause / 1000)} sec`);
   //       return pause
   //    },
   //    onError: (evt) => {
   //       console.log('On Error', evt)
   //    }
   // });

   // useEffect(() => {
   //    setWsURL(pwaApiEndpoint.replace('http', 'ws'))
   // }, [pwaApiEndpoint])

   // useEffect(() => {
   //    console.log('SetInterval');
   //    const timer = setInterval(() => {
   //       if (readyState === ReadyState.OPEN) {
   //          console.log('PING...');
   //          // setLastPing(moment())
   //          sendJsonMessage({ msg: 'PING', udid })
   //          // On attend le retour 2 sec, sinon on considère déconnecté
   //          // setTimeout(() => {
   //          //    if (pingPongState === pingPongStates.wait) {
   //          //       console.log('PingPong KO');
   //          //       setPingPongState(pingPongState.ko)
   //          //    } else {
   //          //       console.log('PingPong left...', pingPongState);
   //          //    }
   //          // }, 2000)
   //       }
   //    }, 10000)
   //    return () => {
   //       console.log('clearInterval');
   //       clearInterval(timer)
   //    }
   // }, [readyState, sendJsonMessage, udid])

   // useEffect(() => {
   //    try {
   //       const obj = JSON.parse(lastMessage.data)
   //       console.log('LAST', obj);
   //       if (obj.msg === 'PONG') {
   //          console.log('PingPong OK...');
   //          // setLastPing(null)
   //       }
   //    } catch (err) {
   //       // console.log('ERR', err, lastMessage);
   //       // Pb JSON...
   //    }
   // }, [lastMessage])

   // // log(setCollections)

   // // console.log('ICI3!!!', oldLogFunc, console.log);

   // const connectionStatus = {
   //    [ReadyState.CONNECTING]: 'Connecting',
   //    [ReadyState.OPEN]: 'Open',
   //    [ReadyState.CLOSING]: 'Closing',
   //    [ReadyState.CLOSED]: 'Closed',
   //    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
   // }[readyState];

   // useEffect(() => {
   //    if (!isWebSocketConnected) {
   //       console.log('ReadyState', readyState, connectionStatus);
   //       if (readyState === ReadyState.OPEN) {
   //          sendMessage(JSON.stringify({ msg: 'HELLO', udid }))
   //       }
   //    }
   //    setIsWebSocketConnected(readyState === ReadyState.OPEN)
   // }, [connectionStatus, isWebSocketConnected, readyState, sendMessage, setIsWebSocketConnected, udid])

   // useEffect(() => {
   //    console.log('READY?', readyState);
   // }, [readyState])

   /*
    █████  ██████  ██████
   ██   ██ ██   ██ ██   ██
   ███████ ██████  ██████
   ██   ██ ██      ██
   ██   ██ ██      ██
   */

   // Mis en commentaire car un lien mailto: déclenchait cet event et l'appel au doLogout()
   // useEventListener('beforeunload', (event) => {
   //    // console.log('beforeunload', event);
   //    console.log('beforeunload', navigator.standalone, window.matchMedia('(display-mode: standalone)'));

   //    // let displayMode = 'browser';
   //    if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
   //       //   displayMode = 'standalone';
   //       doLogout()
   //    }
   // })
   // console.log('NAVI', navigator)

   useEffect(() => {
      const curVer = getVersion()
      if (curVer !== appVerForLastCachePurge) {
         console.log(('CLEAN CACHE !!'));
         cleanCache();
         setAppVerForLastCachePurge(curVer)
      }
   }, [appVerForLastCachePurge, cleanCache, getVersion, setAppVerForLastCachePurge])

   useEffect(() => {
      // console.log('ICI!!!', oldLogFunc);
      // console.log('ICI2!!!', oldLogFunc, console.log);
      // if (!oldLogFunc) {
      //    console.log('ICI4!!!', oldLogFunc, console.log);
      //    if (console.log) {   // Au départ console.log est undefined ou null qq instants
      //       console.log('ICI5!!!', oldLogFunc, console.log);
      //       // setOldLogFunc(console.log)
      //    }
      // } else { // On ne fait rien tant qu'on n'a pas mémorisé oldFunc...
      if (allLogs) {
         // catchLogs()
         // if (!oldLogFunc) {
         // console.warn('CATCH LOG!!!');
         console.log = (...args) => {
            const error = new Error()
            let str = error.stack.split('\n')[2]
            const parts = str.match(/.*\/([^\\/\\?]+)[^:]*:([0-9]+):([0-9]+)[^0-9]*$/)

            if (Array.isArray(parts) && (parts.length >= 4)) {
               str = `${parts[1]}:${parts[2]}:${parts[3]}`
               // } else {
               //    str = '???' + (Array.isArray(parts) ? 'Y' : 'N') + str
            }
            if (oldLogFunc) {
               oldLogFunc(`${str}: `, ...args)
               // oldLogFunc('LOG2:', JSON.stringify({ source: str }))
            }
            if (allLogs) {
               allLogs.push(JSON.stringify({ source: str, log: [...args] }))
            }
         }
         // }
      } else {
         // if (oldLogFunc) {
         // releaseLogs()
         // console.warn('RELEASE LOG!!! AVANT.', console.log);
         // console.warn('RELEASE LOG!!! OLD', oldLogFunc);
         console.log = oldLogFunc
         // console.warn('RELEASE LOG!!! APRÈS', console.log);
         // setOldLogFunc(null)
         // }
      }
      // }
   }, [allLogs])

   // useEffect(() => {
   // fetchTarifRegions() // Les prix seront chargés auto après les currencies dans le hook useTarifs
   // fetchTarifTrads()
   // fetchCollections()
   // fetchCouleurs()
   // fetchCycles()
   // fetchDevises()
   // fetchEtapes()
   // fetchFamilles()
   // fetchGenres()
   // fetchPortes()
   // fetchModels()
   // fetchCountries()
   // fetchCustomers()
   // fetchOrders()
   // fetchTarrifs()
   // }, [fetchTarifRegions])

   // useEffect(() => {
   // console.log('Check appWriteInitDone', appWriteInitDone);
   // if (appWriteInitDone === true) {
   //    fetchCustomers()
   // } else if (appWriteInitDone === false) {  // Pas de session
   //    navigate('/login', { replace: true })
   // }
   // sinon (appWriteInitDone === undefined) => on attend que ça change !
   // }, [fetchCustomers, appWriteInitDone, navigate])
   // useEffect(() => {
   //    if (isLogged) {
   //       fetchCustomers()
   //    } else {  // Pas de session
   //       console.log('navigate(/login)');
   //       navigate('/login', { replace: true })
   //    }
   // }, [fetchCustomers, isLogged, navigate])

   useEffect(() => {
      if (noScrollbars) {
         document.body.classList.add('noscrollbars')
      } else {
         document.body.classList.remove('noscrollbars')
      }
   }, [noScrollbars])

   useEffect(() => {
      let state = true

      // console.log('***>>>***', setSuspendRecalc, location)
      if (setSuspendRecalc) {
         if (location.pathname.includes('/catalog')) {
            state = false
         }
         setSuspendRecalc(state)
      }
   }, [setSuspendRecalc, location])

   // Empêche scroll de la fenêtre si popup affichée
   useEffect(() => {
      if (isDarkLayerVisible || isSyncLayerVisible || isOtpLayerVisible) {
         document.body.classList.add('noscroll')
      } else {
         document.body.classList.remove('noscroll')
      }
   }, [isDarkLayerVisible, isSyncLayerVisible, isOtpLayerVisible])


   function toggleShow() {
      const obj = document.getElementById('stack')
      obj.classList.toggle(styles.hide)
   }

   // console.log(styles);
   // console.log(styles.error);

   function fallbackRender({ error, resetErrorBoundary }) {
      const now = moment().unix()
      // Call resetErrorBoundary() to reset the error boundary and retry the render.

      // console.log('ERROR>>>>', typeof error.stack, error.stack.split('at')[1])
      let stack_lines = error.stack.split('\n')
      let str = stack_lines[1]
      let stack = JSON.stringify(stack_lines, null, 3)
      // console.log('ERROR>>>>3', str);
      // at http://localhost:3000/src/components/Catalog/ListItem.jsx?t=1684947495474:84:34
      // at (http://localhost:3000/src/components/Catalog/ListItem.jsx?t=1684947495474:84:34)
      const parts = str.match(/.*\/([^\\/\\?]+)[^:]*:([0-9]+):([0-9]+)[^0-9]*$/)

      if (Array.isArray(parts) && (parts.length >= 4)) {
         str = `${parts[1]}:${parts[2]}:${parts[3]}`
      }
      // console.log('ERROR>>>>4', parts);

      if (!lastStackDump || (lastStackDump < (now - 60))) { // Si 1er ou > 1min
         setLastStackDump(now)
         console.log(pwaApiEndpoint);
         axios.post(`${pwaApiEndpoint}/crash`, {
            udid,
            stack: stack_lines
         }, {
            headers: { 'X-API-Key': pwaApiKey },
         }).then((data) => {
            console.log('DATA', data);
         }).catch(err => {
            console.log('ERR', err);
         })
      }

      return (
         <div className={styles.error}>
            <div className={styles.card}>
               <h1 onClick={() => { toggleShow() }}><FontAwesomeIcon icon={faTruckMedical} />Something went wrong</h1>
               <div className={styles.detail}>
                  <span style={{ color: "red" }}>
                     {error.message} - Source: {str}
                  </span>
                  <p>Please contact technical support and provide this full error message and the exact date+time the error occured:</p>
                  <p style={{ color: "red" }}>{moment().format()}</p>
                  <p>You can also take a screenshot...</p>
                  <div id="stack" className={styles.hide}>
                     <pre>
                        {stack}
                     </pre>
                  </div>
                  <a href="/">Get back to home page</a>
               </div>
            </div>
         </div >
      )
   }

   useEffect(() => {
      // console.log('**************===>', curTimeout, moment().unix());
      if (curTimeout && (curTimeout < moment().unix())) {
         doLogout()
      } else {
         if (location.pathname !== '/login') {
            // console.log('************ SetTimer', location, timeout)
            const timer = setTimeout(() => {
               // console.log('************ Timer', location, navigate)
               doLogout()
            }, sessionDuration * 1000)
            // console.log('************', timer);
            setCurTimeout(moment().add(sessionDuration, 'seconds').unix())
            return (() => {
               // console.log('************ ClearTimer')
               clearTimeout(timer)
               setCurTimeout(null)
            })
         }
      }
   }, [doLogout, location, navigate, setCurTimeout, sessionDuration, curTimeout])

   return (
      <ErrorBoundary
         fallbackRender={fallbackRender}
      // onReset={(details) => {
      //    // Reset the state of your app so the error doesn't happen again
      // }}
      >
         <div /*className={styles.app}*/>
            {isDarkLayerVisible && <DarkLayer />}
            {isSyncLayerVisible && <SyncLayer />}
            {isOtpLayerVisible && <OtpLayer />}
            {isMissingTarifLayerVisible && <MissingTarifLayer />}
            {<WebSocket />}
            {debug && <DebugInfo />}
            {/* __{isPortrait ? "Y" : "N"}___ */}
            {isPortrait && (
               <p style={{ display: 'flex', fontSize: '3rem', fontWeight: '700', textAlign: 'center', height: '100vh', alignItems: 'center', margin: 0, padding: '5%', lineHeight: '180%' }}>{trans('?turn_landscape')}</p>
            )}
            {!isPortrait && (
               <Routes>
                  <Route path="/" element={
                     <PrivateRoute>
                        <Main />
                     </PrivateRoute>
                  } />
                  <Route path="/index.html" element={
                     <PrivateRoute>
                        <Main />
                     </PrivateRoute>
                  } />
                  {/* <Route path="/fix" element={<Fix />} /> */}
                  <Route path="/login" element={<Login />} />
                  {/* <Route path="/login-success" element={<LoginCallback status="success" />} />
                  <Route path="/login-failure" element={<LoginCallback status="failure" />} /> */}
                  <Route path="/catalog" element={
                     <PrivateRoute>
                        <Catalog />
                     </PrivateRoute>
                  } />
                  <Route path="/catalog-detail" element={
                     <PrivateRoute>
                        <CatalogDetail />
                     </PrivateRoute>
                  } />
                  <Route path="/customers" element={
                     <PrivateRoute>
                        <Customers />
                     </PrivateRoute>
                  } />
                  <Route path="/customer-detail" element={
                     <PrivateRoute>
                        <CustomerDetail />
                     </PrivateRoute>
                  } />
                  <Route path="/orders" element={
                     <PrivateRoute>
                        <Orders />
                     </PrivateRoute>
                  } />
                  <Route path="/order-detail" element={
                     <PrivateRoute>
                        <OrderDetail />
                     </PrivateRoute>
                  } />
                  <Route path="/params" element={
                     <PrivateRoute>
                        <Params />
                     </PrivateRoute>
                  } />
                  <Route path="/tarifs" element={
                     <PrivateRoute>
                        <Tarifs />
                     </PrivateRoute>
                  } />
                  <Route path="/tests" element={
                     <PrivateRoute>
                        <Tests />
                     </PrivateRoute>
                  } />
               </Routes>
            )}
         </div>
      </ErrorBoundary>
   )
}

export default App;
