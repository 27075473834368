import styles from "./MissingTarifLayer.module.scss"
import { GlobalContext } from '../Contexts/GlobalContext';
import { useEffect, useState } from "react";
// import useCollections from "../Hooks/useCollections";
import Button from './Button';
import useTranslation from '../Hooks/useTranslation';
import { AppWriteContext } from "../Contexts/AppWriteContext";
import usePrefStore from "../stores/prefs";
import useModelsStore from "../stores/models";

// Layer semi opaque utilisé quand on affiche une popup.

function MissingTarifLayer(props) {
   const [strError, setStrError] = useState()
   const [isLoading, setIsLoading] = useState(false)
   const [finishedLoading, setFinishedLoading] = useState(false)
   const [localizedRegionName, setLocalizedRegionName] = useState()
   //
   const { trans } = useTranslation();
   const { currentLang: curLang, missingTarifRegionName, setMissingTarifRegionName, setCurTarifRegionName, setCurCustomer, setCurOrder } = usePrefStore();
   //
   const { tarif_regions, tarif_trads } = useModelsStore()
   // const [, setPrepCount] = useState(0);
   // const [controller, setController] = useState();
   const { setIsMissingTarifLayerVisible } = { ...GlobalContext() };
   const { fetchTarifsForSingleRegion } = { ...AppWriteContext() };

   useEffect(() => {
      console.log(tarif_regions, missingTarifRegionName);

      if ((curLang === 'en') && tarif_trads) {
         setLocalizedRegionName(tarif_trads[missingTarifRegionName]?.label.en)
      } else {
         setLocalizedRegionName(missingTarifRegionName)
      }

      if (!tarif_regions) {
         setStrError(trans("?Le tarif du client est inconnu !"))
         // } else if (!tarif_regions[missingTarifRegionName]?.in_list) {
         //    setStrError(trans("?Le tarif du client n'est pas dans votre liste !"))
      }
   }, [curLang, missingTarifRegionName, tarif_regions, tarif_trads, trans])

   function doLoad() {
      // const a_controller = new AbortController()
      // setController(a_controller);
      setIsLoading(true)
      fetchTarifsForSingleRegion(missingTarifRegionName, false, () => {
         // setInListOnTarifRegion(missingTarifRegionName, true)
         setCurTarifRegionName(missingTarifRegionName);
         setMissingTarifRegionName(null);
         setIsMissingTarifLayerVisible(false)
         setFinishedLoading(true)
      }, (err) => {
         setFinishedLoading(true)
         setStrError(err)
      });
   }

   function doCancel() {
      // controller.abort()
      setIsLoading(false)
      setMissingTarifRegionName(null);
      setCurCustomer(null);
      setCurOrder(null);
      setIsMissingTarifLayerVisible(false)
   }

   return (
      <div className={styles.missing_tarif_layer}>
         <div className={styles.missing}>
            {trans(isLoading ? "?Téléchargement du tarif" : "?Tarif à télécharger")} : {localizedRegionName}
         </div>
         {strError &&
            <div className={styles.error}>
               {strError}
            </div>
         }
         <div className={styles.buttons}>
            {!isLoading && !finishedLoading && !strError && <Button primary onClick={doLoad}>
               {trans('?Télécharger le tarif')}
            </Button>
            }
            <Button secondary red onClick={doCancel}>
               {trans('?Annuler')}
            </Button>
         </div>
      </div>
   )
}

export default MissingTarifLayer
