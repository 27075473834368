import usePrefStore from "../../stores/prefs";
import styles from "./MenuTopList.module.scss"

function MenuTopList(props) {
   // const noScrollbars = usePrefStore((state) => state.noScrollbars);
   const { noScrollbars } = usePrefStore();

   return (
      <div className={`${styles.menulist} ${props.visible ? styles.visible : ""}`}>
         <ul className={`${noScrollbars || props.noscrollbars ? styles.noscrollbars : ''}`}>
            {props.children}
         </ul>
      </div>
   )
}

export function MenuTopListItem(props) {
   function onClick(evt) {
      if (props.onClick) {
         props.onClick(evt)
      }
   }

   return (
      <li className={`${styles.menulistitem} ${props.selected ? styles.selected : ""}`} onClick={onClick}>
         {props.children}
      </li>
   )
}

export default MenuTopList
