// import imgLogo from '../../assets/menu_logo.png';
// import imgClose from '../../assets/menu_close.png';
import styles from './Menu.module.scss';
import MenuTopItem from './MenuTopItem';
import MenuTopList, { MenuTopListItem } from './MenuTopList';
import MenuDashboardItem from './MenuDashboardItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faGem, faPeopleGroup, faFileLines, faGear, faRightFromBracket, faGlobe, faBitcoinSign, faFlaskVial, faTriangleExclamation, faPersonDigging, faTruckMedical, faBook, faKey } from '@fortawesome/free-solid-svg-icons';
import { faUserTieHairLong, faCloudArrowDown, faBookmark, faWifi, faWifiExclamation, faCloudSlash, faLightEmergencyOn } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../Hooks/useTranslation';
// import useTest from "../Hooks/useTest"
import usePrefStore from '../../stores/prefs';
import { useCallback, useEffect, useState } from 'react';
import useModelsStore from '../../stores/models';
import useHelpers from "../../Hooks/useHelpers";
import useSales from '../../Hooks/useSales';
// import { useLogger } from '../Contexts/Logger';
import { AppWriteContext } from '../../Contexts/AppWriteContext';
import { useLiveQuery } from 'dexie-react-hooks';
import { GlobalContext } from '../../Contexts/GlobalContext';
import Button from '../Button';
import { useLongPress } from 'use-long-press';
import useTarifs from '../../Hooks/useTarifs';
import moment from 'moment';
import 'moment/dist/locale/fr';

const mailSupport = "Support Messika<supportome@messikagroup.com>"

const Menu = (props) => {
   const [isLangMenuVisible, setIsLangMenuVisible] = useState(false);
   const [isTarifMenuVisible, setIsTarifMenuVisible] = useState(false);
   const [menuListTarifs, setMenuListTarifs] = useState(null);
   const [menuListRecentCustomers, setMenuListRecentCustomers] = useState(null);
   const [menuListRecentOrders, setMenuListRecentOrders] = useState(null);
   const [tarifIsValid, setTarifIsValid] = useState(true);
   const [strButtonMenuTarif, setStrButtonMenuTarif] = useState(null);
   // const [newVersionReady, setNewVersionReady] = useState(false);
   const [strTimeout, setStrTimeout] = useState();
   //
   // //const lang = usePrefStore((state) => state.currentLang)
   // const setLang = usePrefStore((state) => state.setCurrentLang);
   // const curLang = usePrefStore((state) => state.currentLang)
   // const curTarifRegionName = usePrefStore((state) => state.curTarifRegionName);
   // const setCurTarifRegionName = usePrefStore((state) => state.setCurTarifRegionName);
   // const curTarifCustomer = usePrefStore((state) => state.curTarifCustomer);
   // const curTarifRegion = usePrefStore((state) => state.curTarifRegion);
   // const setCurCustomer = usePrefStore((state) => state.setCurCustomer);
   // const setCurOrder = usePrefStore((state) => state.setCurOrder);
   // const curCustomer = usePrefStore((state) => state.curCustomer);
   // const curOrder = usePrefStore((state) => state.curOrder);
   // const recentCustomers = usePrefStore((state) => state.recentCustomers);
   // const recentOrders = usePrefStore((state) => state.recentOrders);
   // const keepHistoryOpen = usePrefStore((state) => state.keepHistoryOpen);
   // const debug = usePrefStore((state) => state.debug);
   // const setDebug = usePrefStore((state) => state.setDebug);
   const { setCurrentLang: setLang, currentLang: curLang, curTarifRegionName, setCurTarifRegionName, curTarifCustomer, curTarifRegion, setCurCustomer, setCurOrder, curCustomer, curOrder, recentCustomers, recentOrders, keepHistoryOpen, debug, setDebug, curTimeout/*, login*/, setMissingTarifRegionName, appWriteEndpoint } = usePrefStore();
   //
   // const tarifNames = useModelsStore((state) => state.tarif_regions);
   // const tarifs = useModelsStore((state) => state.tarifs);
   // const tarif_trads = useModelsStore((state) => state.tarif_trads);
   const { tarif_regions: tarifNames, tarifs, tarif_trads } = useModelsStore();
   //
   const { trans, getLangName } = useTranslation();
   const { getVersion, getVersionDate, formatCESPrice, formatNumber } = useHelpers();
   const { isTarifValid, isCustomerTarifSameAsRegion, isTarifLoadedForRegion } = useTarifs()
   const navigate = useNavigate();
   const { getCustomerById, getOrdersByIds, calcTotalForOrder, getItemsCountForOrder, updateRecentCustomers, updateRecentOrders, setCustomerIdAsCurrent, setOrderIdAsCurrent } = useSales();
   // const { test } = useTest()
   const { doLogout, otp } = { ...AppWriteContext() };
   const { isListRecentCustomersVisible, setIsListRecentCustomersVisible, isListRecentOrdersVisible, setIsListRecentOrdersVisible, setIsSyncLayerVisible, setIsOtpLayerVisible, isWebSocketConnected, setIsMissingTarifLayerVisible } = { ...GlobalContext() };
   // const { log } = useLogger();
   const live_orders = useLiveQuery(async () => await getOrdersByIds(recentOrders), [recentOrders])

   useEffect(() => {
      moment.locale(curLang)
      setStrTimeout(moment.unix(curTimeout).fromNow(true))

      const timer = setInterval(() => {
         // console.log('************', timer);
         setStrTimeout(moment.unix(curTimeout).fromNow(true))
      }, 6000)   // Chaque minute

      return (() => {
         // console.log('************ ClearInterval')
         clearInterval(timer)
      })

   }, [curLang, curTimeout])

   const switchTarif = useCallback(
      (new_tarif) => {
         if (isTarifLoadedForRegion(new_tarif)) {
            setCurTarifRegionName(new_tarif);
         } else {
            setIsMissingTarifLayerVisible(true)
            setMissingTarifRegionName(new_tarif)
         }
         setIsTarifMenuVisible(false);
         setCurCustomer(null)
         setCurOrder(null)
      },
      [isTarifLoadedForRegion, setCurCustomer, setCurOrder, setCurTarifRegionName, setIsMissingTarifLayerVisible, setMissingTarifRegionName]
   );

   useEffect(() => {
      if (!keepHistoryOpen) {
         setIsListRecentCustomersVisible(false)
         setIsListRecentOrdersVisible(false)
      }
   }, [keepHistoryOpen, setIsListRecentCustomersVisible, setIsListRecentOrdersVisible])

   useEffect(() => {
      let is_valid

      if (curCustomer) {
         is_valid = isTarifValid(curTarifRegion) && isTarifValid(curTarifCustomer)
      } else {
         is_valid = isTarifValid(curTarifRegion)
      }
      setTarifIsValid(is_valid)
   }, [isTarifValid, curCustomer, curTarifCustomer, curTarifRegion])

   useEffect(() => {
      let str = null

      // console.log('TAR', tarifNames, curTarifRegion, curTarifRegionName, tarif_trads);

      if (tarifIsValid) {
         let localizedRegionName

         if (curLang === 'en') {
            localizedRegionName = tarif_trads[curTarifRegionName]?.label.en ?? '???'
         } else {
            localizedRegionName = curTarifRegionName ?? '???'
         }
         // if (curTarifCustomer && curTarifRegion &&
         //    ((curTarifCustomer.other.ces.currency !== curTarifRegion.other.ces.currency) ||
         //       (curTarifCustomer.other.ppu.currency !== curTarifRegion.other.ppu.currency))
         if (curTarifCustomer && curTarifRegion && !isCustomerTarifSameAsRegion(curTarifCustomer, curTarifRegion)) {
            str = `${localizedRegionName} (${curTarifCustomer.other.ppu.currency} + ${curTarifCustomer.other.ces.currency})`
         } else {
            str = localizedRegionName
         }
      }
      setStrButtonMenuTarif(str)
   }, [curLang, curTarifCustomer, curTarifRegion, curTarifRegionName, isCustomerTarifSameAsRegion, tarifIsValid, tarif_trads])

   // useEffect(() => {
   //    console.log('***+++CHANGE RECENT', recentOrders)
   // }, [recentOrders])

   // useEffect(() => {
   //    console.log('***+++CHANGE LIVE', live_orders)
   // }, [live_orders])

   useEffect(() => {
      const arr = [];
      // console.log(tarifNames);

      for (const name in tarifNames) {
         let label
         const lang = (curLang === 'en') ? 'en' : 'fr'
         const element = tarifNames[name];

         // console.log(tarif_trads, name, lang);
         if (lang !== 'en') {
            label = name
         } else {
            label = tarif_trads[name]?.label.en ?? name
         }

         if (element.in_list) {
            arr.push(
               <MenuTopListItem selected={name === curTarifRegionName} key={`tarif_${name}`} onClick={() => switchTarif(name)}>
                  {label}
                  {/*element.preload &&*/
                     tarifs[element.currencies.hjo.ces.tarif] &&
                     tarifs[element.currencies.hjo.ppu.tarif] &&
                     tarifs[element.currencies.other.ces.tarif] &&
                     tarifs[element.currencies.other.ppu.tarif] &&
                     <FontAwesomeIcon style={{ color: 'var(--color-primary-green)' }} icon={faCloudArrowDown} />
                  }
                  {/*element.preload &&*/
                     (
                        !tarifs[element.currencies.hjo.ces.tarif] ||
                        !tarifs[element.currencies.hjo.ppu.tarif] ||
                        !tarifs[element.currencies.other.ces.tarif] ||
                        !tarifs[element.currencies.other.ppu.tarif]
                     ) &&
                     <FontAwesomeIcon style={{ color: 'var(--color-primary-red)' }} icon={faCloudArrowDown} />
                  }
               </MenuTopListItem>
            )
         }
      }
      setMenuListTarifs(arr);
   }, [curLang, curTarifRegionName, switchTarif, tarifNames, tarif_trads, tarifs]);

   const toggleShowDebug = () => {
      setDebug(!debug)
   }

   const longPressOnShowCess = useLongPress(toggleShowDebug, { threshold: 5000 })

   const chooseCustomer = useCallback((customer_id) => {
      setCustomerIdAsCurrent(customer_id)
      updateRecentCustomers(customer_id)
      if (!keepHistoryOpen) {
         setIsListRecentCustomersVisible(false)
      }
      navigate(`/customer-detail?id=${customer_id}`, { replace: false })
   }, [keepHistoryOpen, navigate, setCustomerIdAsCurrent, setIsListRecentCustomersVisible, updateRecentCustomers])

   const chooseOrder = useCallback((order_id) => {
      setOrderIdAsCurrent(order_id)
      updateRecentOrders(order_id)
      if (!keepHistoryOpen) {
         setIsListRecentOrdersVisible(false)
      }
      navigate(`/order-detail?id=${order_id}`, { replace: false })
   }, [keepHistoryOpen, navigate, setIsListRecentOrdersVisible, setOrderIdAsCurrent, updateRecentOrders])

   useEffect(() => {
      const arr = recentCustomers.map(id => {
         const customer_id = id
         const customer = getCustomerById(id)

         return (
            <MenuTopListItem key={`customer_${customer_id}`} onClick={() => chooseCustomer(customer_id)}>
               <div className={`${styles.recent_item} ${(curCustomer === customer_id) ? styles.red : ''}`}>
                  <FontAwesomeIcon icon={faUserTieHairLong} />
                  <div>
                     <span># {customer_id}</span>
                     <span>{customer?.name ?? '???'}</span>
                  </div>
               </div>
            </MenuTopListItem>
         )
      })
      setMenuListRecentCustomers(arr)
   }, [chooseCustomer, curCustomer, getCustomerById, recentCustomers])

   useEffect(() => {
      // console.log('***+++REBUILD RECENT', recentOrders, live_orders);
      if (live_orders) {
         const arr = recentOrders.map(async id => {
            const order_id = id
            const order = live_orders.find(an_order => an_order.id === id)
            // const customer = await getCustomerById(order?.customer_id)
            const customer = getCustomerById(order?.customer_id)
            const currency = customer?.tarifs.ces.currency  // Dans le menu on n'est pas forcément sur le client ou la commande sélectionné(e), on doit donc cibler explicitement la bonne devise
            const totalForOrder = calcTotalForOrder(order)
            const itemsCountForOrder = getItemsCountForOrder(order)

            return (
               <MenuTopListItem key={`order_${order_id}`} onClick={() => chooseOrder(order_id)}>
                  <div className={`${styles.recent_item} ${(curOrder === order_id) ? styles.red : ''}`}>
                     <FontAwesomeIcon icon={faFileLines} />
                     <div>
                        <span># {order_id}</span>
                        <span>{customer?.name ?? '???'}</span>
                        {order?.mark && <span><FontAwesomeIcon icon={faBookmark} /> {order.mark}</span>}
                        <span>{formatCESPrice(totalForOrder, currency)}, {formatNumber(itemsCountForOrder)} {trans("?article", itemsCountForOrder)}</span>
                     </div>
                  </div>
               </MenuTopListItem>
            )
         })
         // console.log('***+++REBUILD RECENT2', arr);
         Promise.all(arr).then(arr => {
            // console.log('***+++REBUILD RECENT3', arr);
            setMenuListRecentOrders(arr)
         })
      }
   }, [calcTotalForOrder, chooseOrder, curOrder, formatNumber, formatCESPrice, getCustomerById, getItemsCountForOrder, live_orders, recentOrders, trans])

   function goMain() {
      navigate('/', { replace: true });
   }

   function goCatalog() {
      navigate('/catalog', { replace: false });
   }

   function goCustomers() {
      setIsListRecentCustomersVisible(false)
      navigate('/customers', { replace: false });
   }

   function goOrders() {
      setIsListRecentOrdersVisible(false)
      navigate('/orders', { replace: true });
   }

   function goParams() {
      navigate('/params', { replace: false });
   }

   // function goTarifs() {
   //    navigate('/tarifs', { replace: true });
   // }

   function goTests() {
      if (process.env.NODE_ENV === 'development') {
         navigate('/tests', { replace: true });
      }
   }

   function goLogout() {
      // navigate("/login", { replace: true })
      doLogout();
   }

   function switchLang(new_lang) {
      setLang(new_lang);
      setIsLangMenuVisible(false)
   }

   function toggleLangVisible() {
      setIsLangMenuVisible(cur => !cur);
      setIsTarifMenuVisible(false)
      if (!keepHistoryOpen) {
         setIsListRecentCustomersVisible(false)
         setIsListRecentOrdersVisible(false)
      }
   }

   function toggleTarifVisible() {
      setIsTarifMenuVisible(cur => !cur);
      setIsLangMenuVisible(false)
      if (!keepHistoryOpen) {
         setIsListRecentCustomersVisible(false)
         setIsListRecentOrdersVisible(false)
      }
   }

   function toogleRecentCustomers() {
      console.log(isListRecentCustomersVisible);
      setIsListRecentCustomersVisible(cur => !cur)
      setIsLangMenuVisible(false)
      setIsTarifMenuVisible(false)
      setIsListRecentOrdersVisible(false)
   }

   function toogleRecentOrders() {
      setIsListRecentOrdersVisible(cur => !cur)
      setIsLangMenuVisible(false)
      setIsTarifMenuVisible(false)
      setIsListRecentCustomersVisible(false)
   }

   function onConfigTariffs() {
      navigate('/tarifs', { replace: true });
   }

   // function updateApp() {
   //    navigator.serviceWorker.ready.then((registration) => {
   //       registration.update();
   //    });
   // }

   function prepareOfflineMode() {
      console.log('prepareOfflineMode');
      setIsSyncLayerVisible(true)
   }

   const contactSupport = useCallback(() => {
      const supportReqSubjet = trans('?oMe_subject_support')
      // console.log('prepareOfflineMode');
      window.location = `mailto:${mailSupport}?subject=${supportReqSubjet.replace(' ', '%20')}`
   }, [trans])

   const showDoc = useCallback(() => {
      window.open(`https://rbv3-docs.messikagroup.com/OME_User_Guide_${(curLang === 'en') ? 'GB' : 'FR'}.pdf`, '_blank')
   }, [curLang])

   const showOtp = useCallback(() => {
      setIsOtpLayerVisible(true)
   }, [setIsOtpLayerVisible])

   return (
      <div className={styles.menu} {...longPressOnShowCess()}>
         <div className={styles.header}>
            {/* <img src={imgLogo} alt="Logo Messika" onClick={goMain} />
            <button>
               <img src={imgClose} alt="Close menu" />
            </button> */}
            {(appWriteEndpoint.includes('-dev') || appWriteEndpoint.includes('http://')) &&
               <FontAwesomeIcon icon={faPersonDigging} />
            }
            <img src="/assets/menu_logo.png" alt="Logo Messika" onClick={goMain} />
            {/* <button>
               <img src="/assets/menu_close.png" alt="Close menu" />
            </button> */}
         </div>
         <div className={styles.top}>
            <MenuTopItem label={trans('?Langue')} icon={<FontAwesomeIcon icon={faGlobe} />} value={getLangName()} hasDropdown dropdownVisible={isLangMenuVisible} onClick={toggleLangVisible} />
            {/* <MenuTopList visible={isLangMenuVisible}> */}
            {isLangMenuVisible &&
               <MenuTopList visible>
                  <MenuTopListItem onClick={() => switchLang('fr')}>{trans('?Français', 1, { lng: 'fr' })}</MenuTopListItem>
                  <MenuTopListItem onClick={() => switchLang('en')}>{trans('?Anglais', 1, { lng: 'en' })}</MenuTopListItem>
               </MenuTopList>
            }
            <MenuTopItem label={trans('?Tarif')} icon={<FontAwesomeIcon icon={tarifIsValid ? faBitcoinSign : faTriangleExclamation} />} value={strButtonMenuTarif} hasDropdown dropdownVisible={isTarifMenuVisible} onClick={toggleTarifVisible} />
            {/* <MenuTopList visible={isTarifMenuVisible}>{menuListTarifs}</MenuTopList> */}
            {isTarifMenuVisible &&
               <MenuTopList visible>
                  {menuListTarifs}
                  <Button primary lefticon={<FontAwesomeIcon icon={faGear} />} onClick={onConfigTariffs}>
                     {trans('?Paramétrer')}
                  </Button>
               </MenuTopList>
            }
         </div>
         <div className={styles.dashboard}>
            <h1>{trans('?Dashboard')}</h1>
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faHouse} />} selected={props.page === 'main' ? true : false} onClick={goMain}>
               {trans('?Accueil')}
            </MenuDashboardItem>
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faGem} />} selected={props.page === 'catalog' ? true : false} onClick={goCatalog}>
               {trans('?Catalogue')}
            </MenuDashboardItem>
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faPeopleGroup} />} hasDropdown dropdownVisible={isListRecentCustomersVisible} selected={props.page === 'customers' ? true : false} onClick={goCustomers} onDropdown={toogleRecentCustomers}>
               {trans('?Client', true)}
            </MenuDashboardItem>
            {isListRecentCustomersVisible &&
               <MenuTopList visible>{menuListRecentCustomers}</MenuTopList>
            }
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faFileLines} />} hasDropdown dropdownVisible={isListRecentOrdersVisible} selected={props.page === 'orders' ? true : false} onClick={goOrders} onDropdown={toogleRecentOrders}>
               {trans('?Commande', true)}
            </MenuDashboardItem>
            {isListRecentOrdersVisible &&
               <MenuTopList visible>{menuListRecentOrders}</MenuTopList>
            }
         </div>
         <div className={styles.dashboard}>
            <h1>{trans('?Autre', true)}</h1>
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faGear} />} disabled selected={props.page === 'params' ? true : false} onClick={goParams}>
               {trans('?Paramètres')}
            </MenuDashboardItem>
            {/* <MenuDashboardItem icon={<FontAwesomeIcon icon={faCloudArrowDown} />} selected={props.page === 'tarifs' ? true : false} onClick={goTarifs}>
               {trans('?Tarifs')}
            </MenuDashboardItem> */}
            {(process.env.NODE_ENV === 'development') && (
               <MenuDashboardItem icon={<FontAwesomeIcon icon={faFlaskVial} />} selected={props.page === 'tests' ? true : false} onClick={goTests}>
                  Tests
               </MenuDashboardItem>
            )}
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faCloudSlash} />} onClick={prepareOfflineMode}>
               {trans('?Préparation offline')}
            </MenuDashboardItem>
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faTruckMedical} />} onClick={contactSupport}>
               {trans('?Support')}
            </MenuDashboardItem>
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faBook} />} onClick={showDoc}>
               {trans('?Documentation')}
            </MenuDashboardItem>
            {otp && (
               <MenuDashboardItem icon={<FontAwesomeIcon icon={faKey} />} onClick={showOtp}>
                  {trans('?Mot de passe unique')}
                  <FontAwesomeIcon icon={faLightEmergencyOn} className={styles.redicon} />
               </MenuDashboardItem>
            )}
         </div>
         <div className={styles.footer}>
            {/* {newVersionReady &&
               <span>Nouvelle version... Quitter</span>
            } */}
            <MenuDashboardItem icon={<FontAwesomeIcon icon={faRightFromBracket} />} onClick={goLogout}>
               {trans('?Se déconnecter')}
            </MenuDashboardItem>
         </div>
         {/* <div className={styles.login_info}>
            <span>[ {login} ]</span>
         </div> */}
         <div className={styles.session}>
            <span>{trans("?Fin de session dans")} {strTimeout}</span>
         </div>
         <div className={styles.version}>
            <div className={styles.websocket}>
               <FontAwesomeIcon icon={isWebSocketConnected ? faWifi : faWifiExclamation} className={isWebSocketConnected ? styles.green : styles.red} />
            </div>
            <span>Ver. : {getVersion()}</span>
            <span>{getVersionDate()}</span>
            {/* <span>SW : {getSWVersion()}</span> */}
         </div>
      </div>
   );
};

export default Menu;
