import { useState, createContext, useContext, useEffect, useCallback } from "react";
import useCollections from "../Hooks/useCollections";
import useTarifs from "../Hooks/useTarifs";

const theContext = createContext()

const CatalogFilterContext = (() => useContext(theContext))

const CatalogFilterContextProvider = ({ children }) => {
   const { getGammesAsSortedArray, getCollectionsAsSortedArray, getGammeForCollectionId, getFamillesAsSortedArray, getPortesAsSortedArray, getCouleursAsSortedArray, getAllModelsAsArray } = useCollections()
   const { getMinMaxPriceForEachModelInArray } = useTarifs()
   const [paramCollectionId, setParamCollectionId] = useState(null)   // Collection ID si entrée dans catalogue par une collection
   const [paramGammeId, setParamGammeId] = useState(null)   // Gamme ID si entrée dans catalogue par une collection
   const [minPrice, setMinPrice] = useState(null)           /* Prix mini tous modèles filtrés confondus
                                                               (sans filtrer sur prix évidemment !) */
   const [maxPrice, setMaxPrice] = useState(null)           /* Prix max tous modèles filtrés confondus
                                                               (sans filtrer sur prix évidemment !) */
   const [gammes, setGammes] = useState(null)               // Toutes les gammes + état coché ou pas
   const [collections, setCollections] = useState(null)     // Toutes les collections + état coché ou pas
   const [familles, setFamilles] = useState(null)           // Toutes les familles + état coché ou pas
   const [portes, setPortes] = useState(null)               // Tous les portés + état coché ou pas
   const [couleurs, setCouleurs] = useState(null)           // Toutes les couleurs + état coché ou pas
   const [doFilter, setDoFilter] = useState(false)          // OK pour filtrer ?
   const [minManualPrice, setMinManualPrice] = useState(null) // Prix mini choisi manuellement pour le filtrage
   const [maxManualPrice, setMaxManualPrice] = useState(null) // Prix maxi choisi manuellement pour le filtrage
   const [allMinMax, setAllMinMax] = useState(null)         // Les prix min/max de tous les modèles
   const [searchText, setSearchText] = useState('')         // Le texte de recherche
   const [suspendRecalc, setSuspendRecalc] = useState(true) // Le calcul des min/max ne se fait que dans une page catalogue

   useEffect(() => {
      const gamme_id = paramGammeId ?? (paramCollectionId ? getGammeForCollectionId(paramCollectionId)?.id : null)

      console.log('+++GAMMES', gamme_id, paramCollectionId);
      setGammes(
         getGammesAsSortedArray().map((gamme) => {
            // console.log('SET GAMMES', id, gamme_id, gamme.id, gamme)
            return {
               id: gamme.id,
               label: gamme.label,
               visible: true,
               checked: (gamme_id === gamme.id)      // Checké si on a ciblé directement une collection, donc une gamme
            }
         })
      )
   }, [getGammeForCollectionId, getGammesAsSortedArray, paramCollectionId, paramGammeId])

   useEffect(() => {
      setCollections(
         getCollectionsAsSortedArray().map((collection) => {
            return {
               id: collection.id,
               label: collection.label,
               visible: true,
               checked: (paramCollectionId === collection.id)     // Checké si on a ciblé directement une collection (même si pas affiché)
            }
         })
      )
   }, [getCollectionsAsSortedArray, paramCollectionId])

   useEffect(() => {
      setFamilles(
         getFamillesAsSortedArray().map((famille) => {
            return {
               id: famille.id,
               label: famille.label,
               visible: true,
               checked: false   // Pas checké au départ mais considéré visible
            }
         })
      )
   }, [getFamillesAsSortedArray])

   useEffect(() => {
      setPortes(
         getPortesAsSortedArray().map((porte) => {
            return {
               id: porte.id,
               label: porte.label,
               visible: true,
               checked: false   // Pas checké au départ
            }
         })
      )
   }, [getPortesAsSortedArray])

   useEffect(() => {
      setCouleurs(
         getCouleursAsSortedArray().map((couleur) => {
            return {
               id: couleur.id,
               label: couleur.label,
               visible: true,
               checked: false   // Pas checké au départ
            }
         })
      )
   }, [getCouleursAsSortedArray, setCouleurs])

   /*******
    ******* RÉCUPÉRATION DES ITEMS SÉLECTIONNÉS (GAMMES, COLLECTIONS, FAMILLES, PORTES, COULEURS)
    *******/

   // Retourne la liste des gammes sélectionnées, sinon null
   const getSelectedGammes = useCallback(() => {
      return gammes?.filter((gamme) => gamme.checked)
   }, [gammes])

   // Retourne un tableau des ids des gammes sélectionnées, sinon []
   const getSelectedGammesIds = useCallback(() => {
      const gammes = getSelectedGammes()

      return gammes?.map(gamme => gamme.id) ?? []
   }, [getSelectedGammes])

   // Retourne la liste des collections sélectionnées, sinon []
   const getSelectedCollections = useCallback(() => {
      return collections?.filter((collection) => collection.checked && collection.visible)
   }, [collections])

   // Retourne un tableau des ids des collections sélectionnées, sinon []
   const getSelectedCollectionsIds = useCallback(() => {
      const collections = getSelectedCollections()

      return collections?.map((collection) => collection.id) ?? []
   }, [getSelectedCollections])

   // Retourne la liste des familles sélectionnées, sinon []
   const getSelectedFamilles = useCallback(() => {
      return familles?.filter((famille) => famille.checked && famille.visible)
   }, [familles])

   // Retourne un tableau des ids des familles sélectionnées, sinon []
   const getSelectedFamillesIds = useCallback(() => {
      const familles = getSelectedFamilles()

      return familles?.map((famille) => famille.id) ?? []
   }, [getSelectedFamilles])

   // Retourne la liste des portés sélectionnés, sinon []
   const getSelectedPortes = useCallback(() => {
      return portes?.filter((porte) => porte.checked && porte.visible)
   }, [portes])

   // Retourne un tableau des ids des portés sélectionnés, sinon []
   const getSelectedPortesIds = useCallback(() => {
      const portes = getSelectedPortes()

      return portes?.map((porte) => porte.id) ?? []
   }, [getSelectedPortes])

   // Retourne la liste des couleurs sélectionnées, sinon []
   const getSelectedCouleurs = useCallback(() => {
      return couleurs?.filter((couleur) => couleur.checked && couleur.visible)
   }, [couleurs])

   // Retourne un tableau des ids des couleurs sélectionnées, sinon []
   const getSelectedCouleursIds = useCallback(() => {
      const couleurs = getSelectedCouleurs()

      return couleurs?.map((couleur) => couleur.id) ?? []
   }, [getSelectedCouleurs])

   const clearGammes = useCallback(() => {
      console.log('ON GAMME CLEAR')
      const arr = gammes.map((gamme) => {
         return { ...gamme, checked: false }
      })
      setGammes(arr)
   }, [gammes, setGammes])

   const clearCollections = useCallback(() => {
      const arr = collections.map((collection) => {
         return { ...collection, checked: false }
      })
      setCollections(arr)
   }, [collections, setCollections])

   const clearFamilles = useCallback(() => {
      const arr = familles.map((famille) => {
         return { ...famille, checked: false }
      })
      setFamilles(arr)
   }, [familles, setFamilles])

   const clearPortes = useCallback(() => {
      const arr = portes.map((porte) => {
         return { ...porte, checked: false }
      })
      setPortes(arr)
   }, [portes, setPortes])

   const clearCouleurs = useCallback(() => {
      const arr = couleurs.map((couleur) => {
         return { ...couleur, checked: false }
      })
      setCouleurs(arr)
   }, [couleurs, setCouleurs])

   const clearAllFilters = useCallback(() => {
      clearGammes()
      clearCollections()
      clearFamilles()
      clearPortes()
      clearCouleurs()
      setMinManualPrice(null)
      setMaxManualPrice(null)
   }, [clearCollections, clearCouleurs, clearFamilles, clearGammes, clearPortes])

   useEffect(() => {
      if (!suspendRecalc) {
         /* On récupère les prix min-max de tous les modèles.
            Ca permet plus d'efficacité que de calculer les min-max globaux puis de refaire une récupération du min-max individuel.
            */
         const ret = getMinMaxPriceForEachModelInArray(getAllModelsAsArray())
         // console.log('ALL MIN MAX', ret)
         setAllMinMax(ret)
      }
   }, [getAllModelsAsArray, getMinMaxPriceForEachModelInArray, suspendRecalc])

   // useEffect(() => {
   //    console.log('::MINMAX - CatalogFilterContext - getAllModelsAsArray changed');
   // }, [getAllModelsAsArray])

   // useEffect(() => {
   //    console.log('::MINMAX - CatalogFilterContext - getMinMaxPriceForEachModelInArray changed');
   // }, [getMinMaxPriceForEachModelInArray])

   // useEffect(() => {
   //    console.log('::MINMAX - CatalogFilterContext - suspendRecalc changed');
   // }, [suspendRecalc])

   return (
      <theContext.Provider value={{ context: theContext, doFilter, setDoFilter, searchText, setSearchText, paramCollectionId, setParamCollectionId, paramGammeId, setParamGammeId, allMinMax, setAllMinMax, minPrice, maxPrice, setMaxPrice, setMinPrice, gammes, setGammes, collections, setCollections, familles, setFamilles, portes, setPortes, couleurs, setCouleurs, minManualPrice, setMinManualPrice, maxManualPrice, setMaxManualPrice, getSelectedGammes, getSelectedGammesIds, getSelectedCollections, getSelectedCollectionsIds, getSelectedFamilles, getSelectedFamillesIds, getSelectedPortes, getSelectedPortesIds, getSelectedCouleurs, getSelectedCouleursIds, clearGammes, clearCollections, clearFamilles, clearPortes, clearCouleurs, clearAllFilters, suspendRecalc, setSuspendRecalc }}>
         {children}
      </theContext.Provider>
   )
}

export { CatalogFilterContext, CatalogFilterContextProvider }