import { useEffect, useState } from "react"
import styles from "./ColorBullet.module.scss"

function ColorBullet(props) {
   const [addClasses, setAddClasses] = useState("")

   useEffect(() => {
      let newClass = ""
      if (props.small) {
         newClass = newClass + " " + styles.small
      }
      if (props.big) {
         newClass = newClass + " " + styles.big
      }
      // if (props.wg) {
      //    newClass = newClass + " " + styles.wg
      // }
      // if (props.yg) {
      //    newClass = newClass + " " + styles.yg
      // }
      // if (props.pg) {
      //    newClass = newClass + " " + styles.pg
      // }
      setAddClasses(newClass)
   }, [props])

   return (
      <div className={`${styles.colorbullet}${addClasses}`}>
      </div>
   )
}

export default ColorBullet
