import { Fragment, useCallback, useEffect, useState } from 'react'
import Menu from '../components/Menu/Menu'
import Stats from '../components/Stats'
import Searchbar from '../components/Searchbar'
import styles from './Main.module.scss'
// import useTranslation from '../Hooks/useTranslation'

function Main() {
   // States mémo pour historique
   // States
   const [searchText, setSearchText] = useState('')
   const [searchBar, setSearchBar] = useState(null)
   const [stats, setStats] = useState(null)
   //
   // const { trans } = useTranslation()

   const onSearchChange = useCallback((text) => {
      setSearchText(text);
   }, [])

   useEffect(() => {
      setSearchBar(<Searchbar onChange={onSearchChange} text={searchText} />)
   }, [onSearchChange, searchText])

   useEffect(() => {
      setStats(<Stats />)
   }, [])

   return (
      <Fragment>
         <Menu page="Main" />
         <div className={styles.main}>
            {searchBar}
            <div className={styles.content}>
               <div className={styles.header}>
                  <h1>
                     Bienvenue
                  </h1>
               </div>
               {stats}
            </div>
         </div>
      </Fragment>
   )
}

export default Main
