import Dexie from 'dexie'

export const db = new Dexie('sales')

// https://dexie.org/docs/

/**
 * https://dexie.org/docs/Version/Version.stores() :
 * - Unlike SQL, you don’t need to specify all properties but only the one you wish to index.
 *   ++     Auto-incremented primary key
 *   &      Unique index
 *   *      Multi-entry index (Means that if key is an array, each array value will be regarded as a key to the object)
 *   [A+B]  Compound index or primary key
 * 
 * Only properties of certain types can be indexed. This includes string, number, Date and Array
 * but NOT boolean, null or undefined. Indexing a property path that turns out to hold a non-indexable
 * type will have no effect. And using orderBy() with that property will NOT list that object. 
*/
db.version(18).stores({
   bi_orders: '&id, customer_id, status, date',     // 1st = Primary key (leave empty for no prim key), others = indexed props
   // orders: '[short_udid+id], id, customer_id, status, date',     // 1st = Primary key (leave empty for no prim key), others = indexed props
   orders: '&id, customer_id, status, date',     // 1st = Primary key (leave empty for no prim key), others = indexed props
   customers: '&id',     // 1st = Primary key (leave empty for no prim key), others = indexed props
   ventes_ral: '&id',     // 1st = Primary key (leave empty for no prim key), others = indexed props
   confies: '&id',     // 1st = Primary key (leave empty for no prim key), others = indexed props
   confies_ral: '&id',     // 1st = Primary key (leave empty for no prim key), others = indexed props
})
