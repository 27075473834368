import styles from "./MenuTopItem.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown } from "@fortawesome/free-solid-svg-icons"

function MenuTopItem(props) {
   function onClick(evt) {
      if (props.onClick) {
         props.onClick(evt)
      }
   }

   return (
      <button className={styles.menuitem} onClick={onClick}>
         <div className={styles.iconbox}>{props.icon}</div>
         <div className={styles.innerbox}>
            <span>{props.label}</span>
            <span>{props.value}</span>
         </div>
         {props.hasDropdown &&
            <span className={styles.dropdown_arrow}>
               <FontAwesomeIcon icon={faSortDown} style={{ transformOrigin: 'center', transform: `rotate(${props.dropdownVisible ? "0" : "-90deg"})` }} />
            </span>
         }
      </button>
   )
}

export default MenuTopItem
