// import React, { StrictMode } from "react"
import React from "react"
import ReactDOM from "react-dom/client"
import 'normalize.css'
import "./scss/vars.scss"
import "./scss/index.scss"
import "./i18n"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { AppWriteContextProvider } from './Contexts/AppWriteContext';
import { CatalogFilterContextProvider } from './Contexts/CatalogFilterContext';
import { GlobalContextProvider } from './Contexts/GlobalContext';

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
   //  <React.StrictMode>
   <GlobalContextProvider>
      <CatalogFilterContextProvider>
         <AppWriteContextProvider>
            <BrowserRouter>
               <App />
            </BrowserRouter>
         </AppWriteContextProvider>
      </CatalogFilterContextProvider>
   </GlobalContextProvider>
   //  </React.StrictMode>
)