// import styles from "./WebSocket.module.scss"
import { useEffect, useState } from "react"
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { GlobalContext } from '../Contexts/GlobalContext';
import usePrefStore from "../stores/prefs";

function WebSocket(props) {
   const [wsURL, setWsURL] = useState(null)
   //
   const { pwaApiEndpoint, udid } = usePrefStore();
   const { isWebSocketConnected, setIsWebSocketConnected } = { ...GlobalContext() } // Le {...} pour éviter un pb tant que GlobalContext() retourne undefined

   // https://www.npmjs.com/package/react-use-websocket
   const { sendMessage, sendJsonMessage, readyState } = useWebSocket(wsURL, {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 99999999,  // 190 ans suivant le motif suivant :
      reconnectInterval: (attemptNumber) => {
         let pause
         if (attemptNumber < 12) {  // Toutes les 5sec durant 1min (12 essais)
            pause = 5000
         } else if (attemptNumber < 32) {    // Toutes les 15 sec durant 5min (20 essais)
            pause = 15000
         } else {    // Toutes les minutes ensuite (sans fin)
            pause = 60000
         }
         console.log(`WebSocket, Reconnexion ${attemptNumber} - Prochain essai dans ${Math.round(pause / 1000)} sec`);
         return pause
      },
      onError: (evt) => {
         console.log('On Error', evt)
      }
   }); // TODO : changer adresse

   useEffect(() => {
      setWsURL(pwaApiEndpoint.replace('http', 'ws'))
   }, [pwaApiEndpoint])

   useEffect(() => {
      // console.log('SetInterval');
      const timer = setInterval(() => {
         if (readyState === ReadyState.OPEN) {
            // console.log('PING...');
            // setLastPing(moment())
            sendJsonMessage({ msg: 'PING', udid })
            // On attend le retour 2 sec, sinon on considère déconnecté
            // setTimeout(() => {
            //    if (pingPongState === pingPongStates.wait) {
            //       console.log('PingPong KO');
            //       setPingPongState(pingPongState.ko)
            //    } else {
            //       console.log('PingPong left...', pingPongState);
            //    }
            // }, 2000)
         }
      }, 10000)
      return () => {
         // console.log('clearInterval');
         clearInterval(timer)
      }
   }, [readyState, sendJsonMessage, udid])

   // useEffect(() => {
   //    try {
   //       const obj = JSON.parse(lastMessage.data)
   //       console.log('LAST', obj);
   //       if (obj.msg === 'PONG') {
   //          console.log('PingPong OK...');
   //          // setLastPing(null)
   //       }
   //    } catch (err) {
   //       // console.log('ERR', err, lastMessage);
   //       // Pb JSON...
   //    }
   // }, [lastMessage])

   // log(setCollections)

   // console.log('ICI3!!!', oldLogFunc, console.log);

   const connectionStatus = {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
   }[readyState];

   useEffect(() => {
      if (!isWebSocketConnected) {
         console.log('ReadyState', readyState, connectionStatus);
         if (readyState === ReadyState.OPEN) {
            sendMessage(JSON.stringify({ msg: 'HELLO', udid }))
         }
      }
      setIsWebSocketConnected(readyState === ReadyState.OPEN)
   }, [connectionStatus, isWebSocketConnected, readyState, sendMessage, setIsWebSocketConnected, udid])

   useEffect(() => {
      console.log('READY?', readyState);
   }, [readyState])

   return (
      <div />
   )
}

export default WebSocket
