import styles from './MailAddressPicker.module.scss';
import useTranslation from '../Hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBuilding, faBox, faStore } from '@fortawesome/pro-solid-svg-icons'
import { faAt } from '@fortawesome/pro-regular-svg-icons';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import usePrefStore from '../stores/prefs';
import { useCallback, useEffect, useState } from 'react';
import useHelpers from '../Hooks/useHelpers';
import useSales from '../Hooks/useSales';
import Button from './Button';
// import { useCallback, useEffect, useRef, useState } from 'react';

const tabs = {
   adresses: 'adresses',
   autres: 'autres',
   message: 'message'
}

function MailAddressPicker(props) {
   const { trans } = useTranslation();
   const { inlineStyleFromProps, mailRegex } = useHelpers();
   const { getContactsFromCustomerAsArray, getCustomerById } = useSales();
   // States
   const [curTab, setCurTab] = useState(tabs.adresses)
   const [addStyle, setAddStyle] = useState({});
   const [addressesLines, setAddressesLines] = useState(null);
   const [allMails, setAllMails] = useState(null);
   const [otherMails, setOtherMails] = useState('')
   const [text, setText] = useState('')
   // const [others, setOthers] = useState(''); // Autres adresses manuelles
   const [nbSelected, setNbSelected] = useState(0);
   const [nbOthers, setNbOthers] = useState(0);
   //
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups);
   const { globalClosePopups } = usePrefStore();

   useEffect(() => {
      const style = inlineStyleFromProps(props); // On reconstruit un style avec les propriétés trouvées dans props
      setAddStyle(style);
   }, [props, inlineStyleFromProps]);

   // const selectAddress = useCallback((addr_id) => {
   // let new_addr
   // if (curAddrType === tabs.jewels) {
   //    new_addr = {
   //       ...props.order.addresses,
   //       jewels: (props.order.addresses?.jewels === addr_id) ? null : addr_id
   //    }
   // } else {
   //    new_addr = {
   //       ...props.order.addresses,
   //       ecrins: (props.order.addresses?.ecrins === addr_id) ? null : addr_id
   //    }
   // }
   // console.log('UPDATE', addr_id, new_addr);
   // updateOrder(props.order.id, {
   //    "addresses": { ...new_addr }
   // })

   // }, [])

   useEffect(() => {
      const customer = getCustomerById(props.customer)
      const contacts = getContactsFromCustomerAsArray(customer)
      console.log(props, customer, contacts);

      setAllMails(contacts?.filter(a_contact => a_contact.email?.includes('@')).map(a_contact => {
         return {
            selected: false,
            name: `${a_contact.name} (${a_contact.type})`,
            email: a_contact.email
         }
      }))
   }, [getContactsFromCustomerAsArray, getCustomerById, props])

   const switchAddress = useCallback((num) => {
      setAllMails(arr => arr.map((a_contact, idx) => {
         return {
            selected: (idx === num) ? !a_contact.selected : a_contact.selected,
            name: a_contact.name,
            email: a_contact.email
         }
      }))
   }, [])

   function onOtherMailsChange(evt) {
      const new_text = evt.target.value
      setOtherMails(new_text)
   }

   const extractGoodMailAddr = useCallback((text) => {
      const parts = text?.split(/[ ,\n]/) ?? []
      return parts.filter(a_part => a_part.match(mailRegex))  // Check email
   }, [mailRegex])

   useEffect(() => {
      const res = extractGoodMailAddr(otherMails)
      setNbOthers(res.length)
   }, [extractGoodMailAddr, otherMails])

   const onTextChange = useCallback((evt) => {
      const new_text = evt.target.value
      // console.log('Check', new_text, props);
      if (!props.maxLength || (new_text.length <= props.maxLength) || (new_text.length <= text.length)) {   // (new_text.length <= text.length) permet d'autoriser la réduction d'un texte déjà trop long
         setText(new_text)
      }
   }, [props, text.length])

   useEffect(() => {
      setNbSelected(allMails?.filter(a_contact => a_contact.selected).length)
   }, [allMails])

   useEffect(() => {
      if (allMails) {
         const lines = allMails.map((an_address, idx) => {
            return (
               <li key={`addresses_line_${idx}`} className={an_address.selected ? styles.checked : ''} onClick={() => switchAddress(idx)}>
                  <div className={styles.address}>
                     <span>{an_address.name}</span>
                     <span>{an_address.email}</span>
                  </div>
               </li>
            )
         })
         setAddressesLines(lines)
      } else {
         setAddressesLines(null)
      }
   }, [allMails, switchAddress])

   function doNothing(evt) {
      console.log('doNothing');
      evt.stopPropagation();
   }

   function doClose() {
      if (props.onCancel) {
         props.onCancel()
      }
   }

   function doOK() {
      if (props.onValidate) {
         const res = extractGoodMailAddr(otherMails)
         // On renvoie un tableau d'adresses email
         props.onValidate(
            [...allMails
               .filter(an_address => an_address.selected)
               .map(an_address => an_address.email),
            ...res],
            text)
      }
   }

   function onClickBackground() {
      console.log('onClickBackground');
      if (props.onClose) {
         if (globalClosePopups !== 'button') {
            props.onClose();
         }
      }
   }

   return (
      <div className={styles.background} onClick={onClickBackground}>
         <div className={styles.address_picker} onClick={doNothing} style={addStyle}>
            <div className={styles.top}>
               <FontAwesomeIcon icon={faAt} />
               <span>{trans('?Adresse mail', true)}</span>
               <button onClick={doClose}>
                  <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
            <div className={styles.detail}>

               <div className={styles.header}>
                  <div className={styles.addr_types}>
                     <span className={(curTab === tabs.adresses) ? styles.selected : ''} onClick={() => setCurTab(tabs.adresses)}>
                        {/* <FontAwesomeIcon icon={faBagShopping} /> */}
                        {trans('?Email', true)}{nbSelected ? ` (${nbSelected})` : ''}
                        <div className={styles.bot_border} />
                     </span>
                     <span className={(curTab === tabs.autres) ? styles.selected : ''} onClick={() => setCurTab(tabs.autres)}>
                        {/* <FontAwesomeIcon icon={faCloudSlash} /> */}
                        {trans('?Autre', true)}{nbOthers ? ` (${nbOthers})` : ''}
                        <div className={styles.bot_border} />
                     </span>
                     <span className={(curTab === tabs.message) ? styles.selected : ''} onClick={() => setCurTab(tabs.message)}>
                        {/* <FontAwesomeIcon icon={faCloudSlash} /> */}
                        {trans('?Message')}
                        <div className={styles.bot_border} />
                     </span>
                  </div>
               </div>
               {(curTab === tabs.adresses) &&
                  <ul className={styles.detail_addresses}>
                     {addressesLines}
                  </ul>
               }
               {(curTab === tabs.autres) &&
                  <textarea autoFocus rows={props.lines} value={otherMails} onChange={onOtherMailsChange} />
               }
               {(curTab === tabs.message) &&
                  <textarea autoFocus rows={props.lines} value={text} onChange={onTextChange} />
               }
            </div>
            <div className={styles.footer}>
               <Button tertiary lefticon={<FontAwesomeIcon icon={faXmark} />} onClick={doClose}>
                  {trans('?Annuler')}
               </Button>
               <Button lefticon={<FontAwesomeIcon icon={faCheck} />} onClick={doOK} disabled={((nbSelected + nbOthers) <= 0)}>
                  {trans('?Envoyer mail')}
               </Button>
            </div>
         </div>
      </div>
   )
}

export default MailAddressPicker;
