import styles from "./SegmentedButtons.module.scss"
import { useCallback, useEffect, useState } from "react"

function SegButton(props) {
   const [isChecked, setIsChecked] = useState(false)

   useEffect(() => {
      setIsChecked(props.checked)
   }, [props])

   function onClick() {
      if (!isChecked && props.onClick) {
         props.onClick(props.value)
      }
   }

   return (
      <span value={props.value} id={props.id} className={isChecked ? styles.checked : ""} onClick={onClick}>{props.label}</span>
   )
}

function SegmentedButtons(props) {
   const [curChecked, setCurChecked] = useState(null)
   const [segments, setSegments] = useState(null)
   const [addClasses, setAddClasses] = useState('')

   useEffect(() => {
      if (props.default) {
         setCurChecked(props.default)
      }
   }, [props, curChecked])

   const onClick = useCallback((val) => {

      if (!props.noselection) {
         setCurChecked(val)
      }
      if (props.onChange) {
         props.onChange(val)
      }
   }, [props])

   useEffect(() => {
      let addStyles = ''

      setSegments(
         props.segments.map((seg, idx) => (
            <SegButton key={`${props.name}-${idx}`} id={`${props.name}-${idx}`} name={props.name} value={seg.value} checked={seg.value === curChecked} label={seg.label} onClick={onClick} />)
         )
      )
      if (props.small) {
         addStyles = `${addStyles} ${styles.small}`
      }
      if (props.medium) {
         addStyles = `${addStyles} ${styles.medium}`
      }
      if (props.large) {
         addStyles = `${addStyles} ${styles.large}`
      }
      if (props.medium_padding) {
         addStyles = `${addStyles} ${styles.medium_padding}`
      }
      if (props.large_padding) {
         addStyles = `${addStyles} ${styles.large_padding}`
      }
      if (props.round) {
         addStyles = `${addStyles} ${styles.round}`
      }
      if (props.shadow) {
         addStyles = `${addStyles} ${styles.shadow}`
      }
      setAddClasses(addStyles)
   }, [curChecked, onClick, props])

   return (
      <div className={`${styles.segment} ${props.className ?? ""} ${addClasses} ${props.opaque ? styles.opaque : ""}`}>
         {segments}
      </div>
   )
}

export default SegmentedButtons
