import styles from "./ButtonLink.module.scss"

function ButtonLink(props) {
   function onClick(evt) {
      if (props.onClick) {
         props.onClick(evt)
      }
   }

   return (
      <button className={styles.buttonlink} onClick={onClick}>
         {props.lefticon && <span className={styles.lefticon}>{props.lefticon}</span>}
         {props.children}
         {props.righticon && <span className={styles.righticon}>{props.righticon}</span>}
      </button>
   )
}

export default ButtonLink
