import { useCallback } from "react"
import useTarifs from "../Hooks/useTarifs"
// import useModelsStore from "../stores/models"
import useCollections from "./useCollections"
// import usePrefStore from "../stores/prefs"

const useFilters = () => {
   const { getColorsForModel } = useTarifs()
   const { getAllModelsAsArray } = useCollections()
   // const models = useModelsStore((state) => state.models)
   // const minFilterLength = usePrefStore((state) => state.minFilterLength)
   // const { minFilterLength } = usePrefStore()

   /**
    * @param {array} arr_source, Tableau des modèles à filtrer
    * @param {string} text, Chaîne de caractère pour filtrage. Elle sera découpée en morceaux avec séparateur espace. Chaque morceau servant de critère de recherche (ReExp "contient")
    * @returns {array}, Tableau des modèles filtrés
    */
   const filterOnText = useCallback((arr_source, text) => {
      const str = text?.trim().toUpperCase()

      if (!str) {
         // log('filterOnText - RET1', typeof arr_source)
         return arr_source
      } else {
         let arr_final = []
         const parts_or = str.split(" ")  // Liste des blocs de texte à chercher (on applique un "ou" entre chaque bloc)
         for (const or_part of parts_or) {
            /* NON, ce test est effectué avant l'appel à filterOnText
               if (or_part.length >= minFilterLength) {
            */
            const parts_and = or_part.split("+")  // Liste des parties "et" dans un bloc de texte à chercher (on applique un "et" entre chaque partie)
            let arr_tempo = arr_source   // Au départ on considère que tout matche. On travaille sur les objets
            for (const and_part of parts_and) {
               /* NON, ce test est effectué avant l'appel à filterOnText
                  if (and_part.length >= minFilterLength) {
               */
               arr_tempo = arr_tempo.filter( // On remplace par un sous-ensemble => "ET" (i.e. intersection)
                  (item) => {
                     // log('::::', item)
                     const item_id = item.id.toUpperCase()
                     const item_label = item.label.toUpperCase()
                     const item_desig = item.desig.toUpperCase()
                     const item_argu = item.argu.toUpperCase()
                     return (
                        ((and_part.length < 4) && item_id.startsWith(and_part)) ||  // De 1 à 3 car => "ID débute par"
                        ((and_part.length >= 4) && (
                           item_id.includes(and_part) ||   // 4 car et + => "ID contient"
                           item_desig.includes(and_part) ||
                           item_argu.includes(and_part)
                        )) ||
                        item_label.includes(and_part)
                     )
                     // return (regex_start.test(coll.id) || regex.test(coll.label) || regex.test(coll.desig))
                  }
               )
               //}      // Fin if (and_part.length >= minFilterLength) {
            }
            // On ajoute ("OU", i.e. union) seulement les items inexistants (=>uniques)
            for (const item of arr_tempo) {
               if (arr_final.indexOf(item) === -1) {
                  arr_final.push(item);
               }
            }
            //}   // Fin if (or_part.length >= minFilterLength) {
         }
         return arr_final
      }
   }, [])

   const filterOnCriteriaExceptPrice = useCallback((arr_source, crits) => {
      let arr = arr_source
      // log("doFilterOnCriteria", crits, arr_source, typeof arr_source)

      // Si on a sélectionné des GAMMES
      if (crits.gammes?.length) {
         // log('Filter gammes start')
         // Tableau des collections des gammes sélectionnées
         // const collections_for_selected_gammes = getCollectionsInGammesAsArray(crits.gammes)
         arr = arr.filter((model) => {
            return crits.gammes.includes(model.gamme)
         })
         // log('Filter gammes end')
      }

      // Si on a sélectionné des COLLECTIONS
      if (crits.collections?.length) {
         // log('Filter collections start')
         arr = arr.filter((model) => {
            // return crits.collections.reduce((prev, cur) => {
            //    return prev || (item.collec === cur)
            // }, false)
            return crits.collections.includes(model.collec)
         })
         // log('Filter collections end')
      }

      // Si on a sélectionné des FAMILLES
      if (crits.familles?.length) {
         // log('Filter familles start')
         arr = arr.filter((model) => {
            // return crits.familles.reduce((prev, cur) => {
            //    return prev || (item.fam === cur)
            // }, false)
            return crits.familles.includes(model.fam)
         })
         // log('Filter familles end')
      }

      // Si on a sélectionné des PORTES
      if (crits.portes?.length) {
         // log('Filter portes start')
         arr = arr.filter((model) => {
            // return crits.portes.reduce((prev, cur) => {
            //    return prev || (item.porte === cur)
            // }, false)
            return crits.portes.includes(model.porte)
         })
         // log('Filter portes end')
      }

      // Si on a sélectionné des COULEURS
      if (crits.couleurs?.length) {
         // log('Filter colors start')
         arr = arr.filter((item) => {
            const allColors = getColorsForModel(item.id)
            return crits.couleurs.reduce((prev, cur) => {
               return prev || allColors.colors?.includes(cur)
            }, false)
         })
         // log('Filter colors end')
      }
      return arr
      // return arr_source
   }, [getColorsForModel])

   const filterOnPrice = useCallback((arr_source, crits, all_minmax) => {
      if (!arr_source || ((crits?.price_min === null) && (crits?.price_max === null))) {   // Si null ou undefined, on ne va pas plus loin...
         return arr_source
      }
      let arr = arr_source
      // if ((crits.price_min !== null) && (crits.price_max !== null)) {
      // log('Filter price start')
      arr = arr.filter((item) => {
         // const minMaxPrice = getMinMaxPriceForModel(item.id)
         const minMaxPrice = all_minmax[item.id]
         // log('CRIT', minMaxPrice, crits.price_min, crits.price_max)
         // return ((minMaxPrice.min >= crits.price_min) && (minMaxPrice.min <= crits.price_max))
         if (crits.price_min !== null) {
            if (crits.price_max !== null) {
               return ((minMaxPrice.min >= crits.price_min) && (minMaxPrice.max <= crits.price_max))
            } else {
               return (minMaxPrice.min >= crits.price_min)
            }
         } else {
            return (minMaxPrice.max < crits.price_max)
         }
      })
      // log('Filter price start')
      // }
      return arr
      // return arr_source
   }, [])

   // const filterOnCriteria = useCallback((crits) => {
   //    return filterOnCriteriaExceptPrice(Object.values(models), crits)
   // }, [filterOnCriteriaExceptPrice, models])

   const filterOnCriteria = useCallback((crits) => {
      return filterOnCriteriaExceptPrice(getAllModelsAsArray(), crits)
   }, [filterOnCriteriaExceptPrice, getAllModelsAsArray])

   return { filterOnText, filterOnCriteriaExceptPrice, filterOnPrice, filterOnCriteria }
}

export default useFilters