
import styles from "./PopupOrder.module.scss"
import { faFileLines, faPenToSquare, faEye, faBuilding, faBox, faStore, faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { faTruck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from "react"
import { useLiveQuery } from "dexie-react-hooks"
import usePrefStore from "../../stores/prefs";
import useSales from "../../Hooks/useSales";
import useTranslation from '../../Hooks/useTranslation'
import useHelpers from '../../Hooks/useHelpers'
import { useNavigate } from "react-router-dom";

const tab_names = {
   unsent: "unsent",
   delivery: "delivery",
}

function PopupOrder(props) {
   // const curOrder = usePrefStore((state) => state.curOrder);
   // const curCustomer = usePrefStore((state) => state.curCustomer);
   const { curOrder, curCustomer } = usePrefStore();
   //
   const { calcTotalForOrder, getItemsCountForOrder, getOrderById, getUnsyncOrdersForCustomerId, type_address, getAddressesFromCustomer, getCustomerById } = useSales()
   const live_order = useLiveQuery(async () => await getOrderById(curOrder), [curOrder])
   const live_unsent_orders = useLiveQuery(async () => await getUnsyncOrdersForCustomerId(curCustomer), [curCustomer])
   //
   // const [addClasses, setAddClasses] = useState("")
   const [totalForOrder, setTotalForOrder] = useState(null);
   const [itemsCountForOrder, setItemsCountForOrder] = useState(null);
   const [curTab, setCurTab] = useState()
   const [addresses, setAddresses] = useState(null)
   const [customer, setCustomer] = useState(null)
   // const { trans } = useTranslation()
   //
   const navigate = useNavigate();
   const { trans } = useTranslation()
   const { formatCESPrice, formatNumber, buildAddress } = useHelpers();

   useEffect(() => {
      setCurTab(tab_names.unsent)
   }, [])

   useEffect(() => {
      if (live_order) {
         setCustomer(getCustomerById(live_order.customer_id))
      }
   }, [getCustomerById, live_order])

   useEffect(() => {
      if (customer) {
         let obj = {}
         const all = getAddressesFromCustomer(customer)

         console.log(all);
         if (live_order.addresses?.jewels) {
            obj[live_order.addresses.jewels] = all[live_order.addresses.jewels]
         }
         if (live_order.addresses?.ecrins) {
            obj[live_order.addresses.ecrins] = all[live_order.addresses.ecrins]
         }
         setAddresses(Object.keys(obj).length ? obj : null)
         // console.log(obj, Object.keys(obj).length);
      }
      // const arr = 
   }, [customer, getAddressesFromCustomer, live_order])

   useEffect(() => {
      setTotalForOrder(calcTotalForOrder(live_order))
      setItemsCountForOrder(getItemsCountForOrder(live_order))
   }, [calcTotalForOrder, getItemsCountForOrder, live_order])

   function onClick(evt) {
      evt.stopPropagation()
   }

   function onClose(evt) {
      evt.stopPropagation()
      if (props.onClose) {
         props.onClose()
      }
   }

   function switchTab(tab) {
      setCurTab(tab)
   }

   useEffect(() => {
      console.log('LIVE_OTHER_ORDERS', live_unsent_orders);
   }, [live_unsent_orders])

   function makeActive(order_id) {
      if (props.onOrderChange) {
         props.onOrderChange(order_id)
      }
   }

   function showOrder(order_id) {
      navigate(`/order-detail?id=${order_id}`, { replace: false })
      if (props.onClose) {
         props.onClose()
      }
   }

   // console.log('LIVE CUST', live_order)

   return (
      // <div className={`${styles.popup_order}${addClasses}`} onClick={onClick}>
      <div className={styles.popup_order} onClick={onClick}>
         <div className={styles.header} onClick={onClose}>
            <div className={styles.header_content}>
               <FontAwesomeIcon icon={faFileLines} />
               <div className={styles.order_info}>
                  <span># {live_order?.id}</span>
                  <span>{formatCESPrice(totalForOrder)}</span>
                  <span>{formatNumber(itemsCountForOrder)} {trans("?article", itemsCountForOrder)}</span>
               </div>
               <button onClick={onClose}>
                  <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
         </div>
         <div className={styles.detail}>
            <div className={styles.filters}>
               <span className={(curTab === tab_names.unsent) ? styles.selected : ''} onClick={() => switchTab(tab_names.unsent)}>
                  <FontAwesomeIcon icon={faFileLines} />
                  {trans('?Commandes en cours')}
                  <div className={styles.bot_border} />
               </span>
               <span className={(curTab === tab_names.delivery) ? styles.selected : ''} onClick={() => switchTab(tab_names.delivery)}>
                  <FontAwesomeIcon icon={faTruck} />
                  {trans('?Livraison', true)}
                  <div className={styles.bot_border} />
               </span>
            </div>
            {(curTab === tab_names.delivery) && <div className={styles.delivery}>
               <ul>
                  {
                     addresses &&
                     Object.keys(addresses).map((key) => {
                        const addr = addresses[key]
                        // console.log(addr);
                        return (
                           <li key={`addr_${key}`}>
                              <FontAwesomeIcon icon={(key === type_address.siege) ? faBuilding : (key.startsWith(type_address.ecrins) ? faBox : faStore)} />
                              <div className={styles.detail_lines}>
                                 {buildAddress(addr).map(line => <span>{line}</span>)}
                              </div>
                           </li>
                        )
                     })
                  }
               </ul>
            </div>
            }
            {(curTab === tab_names.unsent) && <div className={styles.unsent}>
               <ul>
                  {
                     live_unsent_orders?.sort(
                        (order1, order2) => {
                           if (curOrder === order1.id) {
                              return -1
                           } else if (curOrder === order2.id) {
                              return 1
                           } else {
                              return order2.id - order1.id
                           }
                        }
                     ).map((order) => {
                        const order_id = order.id
                        const total = calcTotalForOrder(order)
                        const count_items = getItemsCountForOrder(order)

                        return (
                           <li className={`${(curOrder === order_id) ? styles.selected : ''}`} key={`unsent_${order_id}`}>
                              <FontAwesomeIcon icon={faFileLines} />
                              <div className={styles.detail_lines}>
                                 <span># {order_id}</span>
                                 {order?.mark && <span><FontAwesomeIcon icon={faBookmark} /> {order.mark}</span>}
                                 <span>{formatCESPrice(total)} - {formatNumber(count_items)} {trans("?article", count_items)}</span>
                              </div>
                              <div className={styles.buttons}>
                                 {(curOrder !== order_id) && !order?.readonly &&
                                    <button onClick={() => makeActive(order_id)}>
                                       <FontAwesomeIcon icon={faPenToSquare} />
                                    </button>
                                 }
                                 <button onClick={() => showOrder(order_id)}>
                                    <FontAwesomeIcon icon={faEye} />
                                 </button>
                              </div>
                           </li>
                        )
                     })
                  }
               </ul>
            </div>
            }
         </div>
      </div>
   )
}

export default PopupOrder
