import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CardCollection from '../components/CardCollection';
import Menu from '../components/Menu/Menu';
// import Searchbar from '../components/Searchbar';
// import useModelsStore from "../stores/models"
import styles from './Catalog.module.scss';
import SegmentedButtons from '../components/SegmentedButtons';
import usePrefStore from '../stores/prefs';
import useCollections from '../Hooks/useCollections';
// import { useLogger } from '../Contexts/Logger.jsx';
import CatalogSearchBar from '../components/Catalog/CatalogSearchBar'
import { CatalogFilterContext } from '../Contexts/CatalogFilterContext';

function Catalog() {
   const navigate = useNavigate()
   // const catalogMainAlign = usePrefStore((state) => state.catalogMainAlign)
   const { catalogMainAlign } = usePrefStore()
   const { getGammesAsSortedArray, getCollectionsInGammesAsSortedArray } = useCollections()
   const [allGammes, setAllGammes] = useState([])
   const [gammeSegItems, setGammeSegItems] = useState(null)
   const [gammeCollectionItems, setGammeCollectionItems] = useState(null)
   // const { log, warn, debug, error } = useLogger()
   const { setSearchText } = { ...CatalogFilterContext() }         // Texte de recherche

   useEffect(() => {
      setSearchText('')
   }, [setSearchText])

   useEffect(() => {
      setAllGammes(getGammesAsSortedArray())
   }, [getGammesAsSortedArray])

   const goDetail = useCallback(
      (gamme_id, coll_id) => {
         // log('goDetail', name)
         navigate(`/catalog-detail?gamme=${gamme_id}&collec=${coll_id}`, { replace: true })
      },
      [navigate]
   )

   function onSearch(text) {
      // console.log(`/catalog-detail?search=${encodeURIComponent(text)}`)
      // navigate(`/catalog-detail?search=${encodeURIComponent(text)}`, { replace: true })
      navigate('/catalog-detail', { replace: true })
   }

   function onFilter() {
      console.log('ON FILTER!!!')
      navigate('/catalog-detail', { replace: true })
   }

   function onQuickJumpChange(val) {
      // TODO
      // log(`onQuickJumpChange(${val})`)
      // log(`navigate(#gamme-${val}`)
      // navigate(`#gamme-${val}`)
      // navigate("/catalog/#gamme-JOI")
      // navigate("/toto")
      // ref.current.scrollIntoView()
      const element = document.getElementById(`gamme-${val}`)
      element.scrollIntoView({ behavior: 'smooth', alignToTop: true })
   }

   useEffect(() => {
      const segments = allGammes.map((gamme) => {
         return { value: gamme.id, label: gamme.label };
      })
      setGammeSegItems(
         <SegmentedButtons className={styles.quickjump} round shadow opaque segments={segments} noselection medium medium_padding name="quickjump" onChange={onQuickJumpChange} />
      )
   }, [allGammes])

   useEffect(() => {
      setGammeCollectionItems(
         allGammes.map((gamme) => {
            return (
               <Fragment key={`frag-${gamme.id}`}>
                  <h1 id={`gamme-${gamme.id}`}>{gamme.label}</h1>
                  <div className={styles.innerbox}>
                     {getCollectionsInGammesAsSortedArray(
                        gamme.id,
                        'label'
                     ).map((collection) => {
                        return (
                           <CardCollection key={`card-${gamme.id}-${collection.id}`} label={collection.label} image={`../catalogue/collections/${collection.id}.webp`} onClick={() => {
                              goDetail(gamme.id, collection.id)
                           }}></CardCollection>
                        )
                     })}
                  </div>
               </Fragment>
            )
         })
      )
   }, [allGammes, getCollectionsInGammesAsSortedArray, goDetail])

   // useEffect(() => {
   //    log('getGammeKeys', getGammeKeys())
   //    log('getCollectionKeysForGamme: jewelry', getCollectionKeysForGamme("jewelry"))
   // }, [getGammeKeys, getCollectionKeysForGamme])

   return (
      <Fragment>
         <Menu page="catalog" />
         <div className={`${styles.catalog} ${catalogMainAlign === 'center' ? styles.catacenter : ''}`}>
            {/* <Searchbar onSearch={onSearch} /> */}
            <CatalogSearchBar onSearch={onSearch} onFilter={onFilter} />
            {gammeSegItems}
            {gammeCollectionItems}
         </div>
      </Fragment>
   )
}

export default Catalog;
