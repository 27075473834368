import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import styles from './Checkbox.module.scss';

export const States = {
   Checked: 'checked',
   Unchecked: 'unchecked',
   Undefined: null
};

export function Checkbox(props) {
   const [state, setState] = useState(false);
   // const { log } = useLogger()

   useEffect(() => {
      setState(props.checked);
   }, [props]);

   function toggleCheckbox(evt) {
      if (!props.disabled) {
         let newState = state;

         evt.stopPropagation();
         if (props.three_states) {
            if (state === States.Checked) {
               newState = States.Undefined;
            } else if (state === States.Undefined) {
               newState = States.Unchecked;
            } else {
               newState = States.Checked;
            }
         } else {
            newState =
               state === States.Checked ? States.Unchecked : States.Checked;
         }

         // On interroge le créateur de la checkbox si OK de changer
         if (props.canChange) {
            if (props.canChange(newState)) {
               // OK de changer
               // setState(newState);
               if (props.onChange) {
                  props.onChange(newState);
               }
            }
         } else {
            if (props.onChange) {
               props.onChange(newState);
            }
         }
      }
   }

   // log('PROPS>>>', props)

   return (
      <div
         className={`${styles.container} ${props.small ? styles.small : ''} ${props.light ? styles.light : ''
            } ${state !== States.Unchecked ? styles.selected : ''} ${props.disabled ? styles.disabled : ''}`}
         onClick={toggleCheckbox}
      >
         {/* <input type="checkbox" defaultChecked={state === State.Checked} /> */}
         {state === States.Checked && (
            <button className={styles.button} onClick={toggleCheckbox}>
               <FontAwesomeIcon icon={faCheck} />
            </button>
         )}
         {state === States.Unchecked && (
            <button className={styles.button} onClick={toggleCheckbox}></button>
         )}
         {state === States.Undefined && (
            <button className={styles.button} onClick={toggleCheckbox}>
               <FontAwesomeIcon icon={faMinus} />
            </button>
         )}
         {props.icon}
         <label>{props.caption}</label>
      </div>
   );
}

export default Checkbox;
