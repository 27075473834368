// import { useLogger } from '../Contexts/Logger';
import styles from './RadioButtons.module.scss';
import { useEffect, useState, useCallback } from 'react';

export function SingleRadioButton(props) {
   const onClick = useCallback(() => {
      if (props.onClick) {
         props.onClick();
      }
   }, [props]);

   // console.log('SingleRadioButton', props);

   return (
      <button
         // id={props.key}
         className={`${styles.single_radio_button} ${
            props.selected ? styles.selected : ''
         }`}
         onClick={onClick}
      >
         {props.children}
      </button>
   );
}

function RadioButtons(props) {
   const [addClasses, setAddClasses] = useState('');
   const [buttons, setButtons] = useState(null);
   // const { log } = useLogger();

   useEffect(() => {
      let newClass = '';
      if (props.small) {
         newClass = newClass + ' ' + styles.small;
      }
      if (props.normal) {
         newClass = newClass + ' ' + styles.normal;
      }
      if (props.medium) {
         newClass = newClass + ' ' + styles.medium;
      }
      if (props.large) {
         newClass = newClass + ' ' + styles.large;
      }
      setAddClasses(newClass);
   }, [props]);

   const onClick = useCallback(
      (key) => {
         console.log(key);
         if (props.onWillChange) {
            props.onWillChange(key);
         }
      },
      [props]
   );

   useEffect(() => {
      const arr = props.children?.map((child) => {
         // console.log('CHILD', child);
         return (
            <SingleRadioButton
               key={child.key}
               selected={props.selected === child.key}
               onClick={() => onClick(child.key)}
            >
               {child}
            </SingleRadioButton>
         );
      });

      setButtons(arr);
   }, [props, onClick]);

   return (
      <div className={`${styles.radio_buttons}${addClasses}`}>{buttons}</div>
   );
}

export default RadioButtons;
