import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import usePrefStore from '../stores/prefs';

/*
 * https://www.i18next.com/translation-function/plurals
 */

export default function useTranslation2() {
   // const lang = usePrefStore((state) => state.currentLang)
   const { currentLang: lang } = usePrefStore()
   // const setLang = usePrefStore((state) => state.setCurrentLang)
   // const defLang = usePrefStore((state) => state.defaultLang)
   // const setDefLang = usePrefStore((state) => state.setDefaultLang)
   const { t: translate, i18n } = useTranslation()

   const getLangName = () => {
      if (lang === 'fr') {
         return translate("Français")
      } else if (lang === 'en') {
         return translate("English")
      } else {
         return "?"
      }
   }

   useEffect(() => {
      i18n.changeLanguage(lang)
   }, [lang, i18n])

   // qty = val ou true pour forcer le pluriel
   const trans = useCallback((text, qty = null, options = null) => {
      let val = translate(text, options).split('|')
      if ((val.length !== 2) || ((qty !== true) && (qty <= 1))) {
         return val[0]
      } else {
         return val[1]
      }
   }, [translate])

   /**
    * Affichage paramétré (avec des placeholders)
    * placeholders : %1, %2...
    * params : une valeur (si un placeholder) ou un tableau (si plusieurs placeholders)
    */
   const transWithParams = useCallback((text, params, qty = null, options = null) => {
      const val = translate(text, options).split('|')
      const arr = (Array.isArray(params) ? params : [params])
      let str

      if ((val.length !== 2) || ((qty !== true) && (qty <= 1))) {
         str = val[0]
      } else {
         str = val[1]
      }

      return arr.reduce((prev, a_param, index) => {
         return prev.replace(`%${index + 1}`, a_param)
      }, str)
   }, [translate])

   // qty = val ou true pour forcer le pluriel
   const lowerCaseTranslate = useCallback((text, qty = null) => {
      return trans(text, qty).toLowerCase()
   }, [trans])

   return {
      // lang,
      // setLang,
      // defLang,
      // setDefLang,
      trans,
      transWithParams,
      lowtrans: lowerCaseTranslate,
      getLangName
   }
}