import { useCallback, useEffect, useState } from 'react';
import styles from './Button.module.scss';
// import { useLogger } from '../Contexts/Logger';
import { useLongPress } from 'use-long-press';

function Button(props) {
   const [addClasses, setAddClasses] = useState('');
   // const { log } = useLogger();

   function onClick(evt) {
      // console.log("onClick")
      if (!props.disabled && props.onClick) {
         console.log('onClick>>');
         props.onClick(evt);
      }
   }

   const onLongPress = useCallback((evt) => {
      if (!props.disabled && props.onLongPress) {
         console.log('onLongPress');
         props.onLongPress(evt);
      }
   }, [props])

   const longPress = useLongPress(onLongPress, { threshold: props.longPressDuration ?? 9999999 })

   useEffect(() => {
      let newClass = '';
      if (props.small) {
         newClass = newClass + ' ' + styles.small;
      }
      if (props.big) {
         newClass = newClass + ' ' + styles.big;
      }
      if (props.red) {
         newClass = newClass + ' ' + styles.red;
      }
      if (props.green) {
         newClass = newClass + ' ' + styles.green;
      }
      if (props.secondary) {
         newClass = newClass + ' ' + styles.secondary;
      }
      if (props.tertiary) {
         newClass = newClass + ' ' + styles.tertiary;
      }
      if (props.nobackground) {
         newClass = newClass + ' ' + styles.nobackground;
      }
      if (props.disabled) {
         newClass = newClass + ' ' + styles.disabled;
      }
      if (props.inactive) {
         newClass = newClass + ' ' + styles.inactive;
      }
      setAddClasses(newClass);
   }, [props]);

   // console.log('Button.props', props);

   return (
      <button
         className={`${styles.button} ${addClasses} ${props.className}`}
         onClick={onClick}
         {...longPress()}
      >
         {props.lefticon && (
            <span className={styles.lefticon}>{props.lefticon}</span>
         )}
         {props.children}
         {props.righticon && (
            <span className={`${styles.righticon} ${props.className}`}>
               {props.righticon}
            </span>
         )}
      </button>
   );
}

export default Button;
