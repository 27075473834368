import useTranslation from '../Hooks/useTranslation';
import styles from './Signature.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck, faEraser } from '@fortawesome/free-solid-svg-icons';
// import { faCommentQuestion } from '@fortawesome/pro-regular-svg-icons';
import Button from './Button';
import { useEffect, useRef, useState } from 'react';
import useHelpers from '../Hooks/useHelpers';
// import { useCallback, useEffect, useRef, useState } from 'react';
import useScrollBlock from "../Hooks/useScrollBlock";

// https://www.npmjs.com/package/react-signature-canvas
// https://ozkaptanhakan.medium.com/how-to-add-react-signature-canvas-on-react-hook-form-f1e93929959e
// https://github.com/szimek/signature_pad
import SignatureCanvas from 'react-signature-canvas'

function Signature(props) {
   const { trans } = useTranslation();
   // States
   const [addStyle, setAddStyle] = useState({})
   const [curSig, setCurSig] = useState()
   //
   const { inlineStyleFromProps } = useHelpers()
   const [blockScroll, allowScroll] = useScrollBlock();
   //
   let refSig = useRef({})

   useEffect(() => {
      blockScroll();
      return () => {
         allowScroll()
      }
   }, [allowScroll, blockScroll])

   useEffect(() => {
      if (refSig && props.sig) {
         const the_sig = props.sig.map(arr => {
            return arr.map(point => { return { x: point.x, y: point.y, color: "black", time: point.time } })
         })
         setCurSig(the_sig)
         refSig.fromData(the_sig)
      }
   }, [props.sig, refSig])

   useEffect(() => {
      const style = inlineStyleFromProps(props) // On reconstruit un style avec les propriétés trouvées dans props
      setAddStyle(style)
   }, [inlineStyleFromProps, props])

   const onEndSig = () => {
      // setCurSig(refSig.toData())
      const new_arr = refSig.toData().map(arr => {
         const start = arr[0].time
         return arr.map(point => { return { x: Math.round(point.x), y: Math.round(point.y), time: point.time - start } })
         // return arr.map(point => { return { x: Math.round(point.x), y: point.y } })
         // return arr
      })
      setCurSig(new_arr)
      console.log('onEndSig', refSig.toData(), new_arr, new_arr.length)
   }

   const clearSig = () => {
      setCurSig(null)
      refSig.clear()
   }

   function doNothing(evt) {
      console.log('doNothing');
      evt.stopPropagation();
   }

   function doCancel() {
      if (props.onCancel) {
         props.onCancel()
      }
   }

   function doOK() {
      if (props.onOK) {
         console.log(curSig);
         if (curSig && ((curSig.length > 1) || (curSig[0].length > 1))) {
            props.onOK(curSig)
         } else {
            props.onOK(null)
         }
      }
   }

   return (
      <div className={styles.background}>
         <div className={styles.signature} onClick={doNothing} style={addStyle}>
            <SignatureCanvas penColor='black' canvasProps={{ width: 800, height: 600, className: 'signature' }} onEnd={onEndSig} ref={(ref) => { refSig = ref }} />
            <div className={styles.buttons}>
               <Button secondary red lefticon={<FontAwesomeIcon icon={faEraser} />} onClick={clearSig}>
                  {trans('?Effacer')}
               </Button>
               <Button primary lefticon={<FontAwesomeIcon icon={faXmark} />} onClick={doCancel}>
                  {trans('?Annuler')}
               </Button>
               <Button green disabled={props.enableOK === false} lefticon={<FontAwesomeIcon icon={faCheck} />} onClick={doOK}>
                  {trans('?OK')}
               </Button>
            </div>
         </div>
      </div>
   )
}

export default Signature;
