import { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '../components/Button';
import CardCollection from '../components/CardCollection';
import { Checkbox, States as CheckboxStates } from '../components/Checkbox';
import ColorBullet from '../components/ColorBullet';
import DropdownCB from '../components/DropdownCB';
import DropdownMenu from '../components/DropdownMenu';
import InputField from '../components/InputField';
import Menu from '../components/Menu/Menu';
import useCollections from '../Hooks/useCollections';
import useTarifs from '../Hooks/useTarifs';
import useSales from '../Hooks/useSales';
import usePrefStore from '../stores/prefs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck, faFilePdf, faClone, faCloudArrowUp, faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { faGrid2, faBars } from '@fortawesome/pro-solid-svg-icons';
// import useCollections from "../Hooks/useCollections"
// import useHelpers from "../Hooks/useHelpers"
// import useModelsStore from "../stores/models"
import styles from './Tests.module.scss';
import Keypad from '../components/Keypad';
import { Link } from 'react-router-dom';
import PagesButtons from '../components/PagesButtons';
import Slider from '../components/Slider';
import RadioButtons from '../components/RadioButtons';
import DropdownField from '../components/DropdownField';
import SegmentedButtons from '../components/SegmentedButtons';
import Confirm from '../components/Confirm';
// import { AppWriteContext } from '../Contexts/AppWriteContext';
// import { useLogger } from '../Contexts/Logger';
// import { Client, Account } from "appwrite";


function Tests() {
   // // const tarif_regions = useModelsStore((state) => state.tarif_regions)
   // // const setTarifNames = useModelsStore((state) => state.setTarifNames)
   // // const setPreloadOnTarifRegion = useModelsStore((state) => state.setPreloadOnTarifRegion)
   // const curTarifRegionName = usePrefStore((state) => state.curTarifRegionName)
   const { curTarifRegionName } = usePrefStore()
   // const curCurrency = usePrefStore((state) => state.curCurrency)
   //
   // const { getCollectionModels } = useCollections()
   // const { /*getPrice,*/ testFetchTarifNames } = useTarifs()
   // const { formatNumber, formatPrice, findStepFromValue } = useHelpers()
   // const prices = useModelsStore((state) => state.prices)
   // const { emptyTarifs, fetchTarifsForSelectedRegions } = useTarifs()
   const { getGammes, getGammesAsArray, getGammesAsSortedArray, getGammeForCollectionId, getCollections, getCollectionsAsArray, getCollectionsAsSortedArray, getFamilles, getFamillesAsArray, getFamillesAsSortedArray, getPortes, getPortesAsArray, getPortesAsSortedArray, getCollectionsInGammes, getCollectionById } = useCollections()
   const { getMinMaxPriceForModel, getPriceForModelColorSize, getCessPriceForModelColorSize, getColorsForModel, getSizesForModelColor, getAllSizesForModel, findRegionFromCessionTarifNames, findRegionFromPublicTarifNames } = useTarifs()
   const { getCustomerById, getOrderById, getOrderIdsForCustomerId, getOrdersForCustomerId, getOrders, getOrdersAsArray } = useSales()
   //
   const [currency, setCurrency] = useState(null)
   const [showGetMinMaxPrice, setShowGetMinMaxPrice] = useState(false)
   const [showGetPrice, setShowGetPrice] = useState(false)
   const [showGetColors, setShowGetColors] = useState(false)
   const [showGetSizes, setShowGetSizes] = useState(false)
   const [showGetAllSizes, setShowGetAllSizes] = useState(false)
   const [showGetGammes, setShowGetGammes] = useState(false)
   const [showGetCollections, setShowGetCollections] = useState(false)
   const [showGetFamilles, setShowGetFamilles] = useState(false)
   const [showGetPortes, setShowGetPortes] = useState(false)
   const [showGetGammeForCollectionId, setShowGetGammeForCollectionId] = useState(false)
   const [showGetCustomer, setShowGetCustomer] = useState(false)
   const [showGetOrder, setShowGetOrder] = useState(false)
   const [showGetOrderIdsForCustomerId, setShowGetOrderIdsForCustomerId] = useState(false)
   const [showAppWrite, setShowAppWrite] = useState(false)
   const [showConfirm, setShowConfirm] = useState(false)
   const [showConfirmSimple, setShowConfirmSimple] = useState(false)
   const [showUIItems, setShowUIItems] = useState(false)
   const [customerId, setCustomerId] = useState(null)
   const [customer, setCustomer] = useState(null)
   const [orderId, setOrderId] = useState(null)
   const [order, setOrder] = useState(null)
   const [orders, setOrders] = useState(null)
   const [modelId, setModelId] = useState(null)
   const [collectionId, setCollectionId] = useState(null)
   const [tarifId, setTarifId] = useState(null)
   const [currencyId, setCurrencyId] = useState(null)
   const [minMaxValue, setMinMaxValue] = useState(null)
   const [priceValue, setPriceValue] = useState(null)
   const [cessPriceValue, setCessPriceValue] = useState(null)
   const [colorValues, setColorValues] = useState(null)
   const [sizeValues, setSizeValues] = useState(null)
   const [allSizeValues, setAllSizeValues] = useState(null)
   const [gamme, setGamme] = useState(null)
   const [gammes, setGammes] = useState(null)
   const [collections, setCollections] = useState(null)
   const [familles, setFamilles] = useState(null)
   const [portes, setPortes] = useState(null)
   const [color, setColor] = useState(null)
   const [size, setSize] = useState(null)
   const [confirmCheckboxState, setConfirmCheckboxState] = useState(CheckboxStates.Unchecked)
   const [testSelectedRadioButton1, setTestSelectedRadioButton1] = useState('radbtns_list1')
   const [testSelectedRadioButton2, setTestSelectedRadioButton2] = useState('radbtns_list2')
   const [testSelectedRadioButton3, setTestSelectedRadioButton3] = useState('radbtns_list3')
   const [testSelectedRadioButton4, setTestSelectedRadioButton4] = useState('radbtns_list4')
   const [testMultipageFrom, setTestMultipageFrom] = useState(3)
   const [testMultipageTo /*, setTestMultipageTo*/] = useState(100)
   const [testMultipageSelectedPage, setTestMultipageSelectedPage] = useState(5)
   const [testChecked, setTestChecked] = useState(true)
   const [testDropdownFieldValue1, setTestDropdownFieldValue1] = useState('item2')
   const [testDropdownFieldValue2, setTestDropdownFieldValue2] = useState('item4')
   const [testIsDropdownFieldOpen1, setTestIsDropdownFieldOpen1] = useState(false)
   const [testIsDropdownFieldOpen2, setTestIsDropdownFieldOpen2] = useState(false)
   const [testSelectedSegButton1, setTestSelectedSegButton1] = useState('JOA')
   const [testSelectedSegButton2, setTestSelectedSegButton2] = useState('Y')
   // const { log } = useLogger()
   // const { subscribeToSessionEvents } = { ...AppWriteContext() };

   useEffect(() => {
      setCurrency(curTarifRegionName.other_ces) // BUG pas ok, c'est un nom (string) pas un objet !
   }, [curTarifRegionName])

   // useEffect(() => {
   //    const arr = [1, 2, 3]
   //    console.log('AVANT_arr', arr)
   //    let val = arr[0]
   //    val++
   //    console.log('APRÈS_arr', arr)

   //    const arr2 = [{ val: 1 }, { val: 2 }, { val: 3 }]
   //    console.log('AVANT_arr2', { ...arr2 })
   //    let val2 = arr2[0]
   //    // val2.val++
   //    console.log('APRÈS_arr2', arr2)
   // }, [])

   // const test = { a: 1, b: 2 }
   // const test = undefined
   // const test = null
   // const { a, b } = { ...test }
   // // const { a, b } = test
   // console.log('>>>', a, b);

   // useEffect(() => {
   //    const client = new Client()
   //       .setEndpoint('http://localhost/v1')
   //       .setProject('644ff20fd4f45a14ed27');
   //    const account = new Account(client);
   //    const promise = account.createEmailSession(
   //       'gildas@quiniou.eu',
   //       'Balibalo22'
   //    );
   //    let unsub = null

   //    promise.then(function (response) {
   //       console.log('***SESS', response);
   //       // Subscribe to files channel

   //       unsub = client.subscribe('documents', response => {
   //          // if (response.events.includes('buckets.*.files.*.create')) {
   //          // Log when a new file is uploaded
   //          console.log('***EVT', response.payload);
   //          console.log('***WEBSOCK', client.realtime.socket);
   //          client.realtime.socket.addEventListener('open', event => {
   //             console.log('***OPEN');
   //          });
   //          client.realtime.socket.addEventListener('close', event => {
   //             console.log('***CLOSE');
   //          });
   //       });

   //       console.log('***CLI_SUB', unsub);

   //       return () => {
   //          console.log('***UNSUB ?');
   //          if (unsub) {
   //             console.log('***UNSUB...');
   //             unsub()
   //          }
   //       }
   //    }, function (error) {
   //       console.log('***ERR', error);
   //    });

   // }, [])

   function doActionTest() {
      console.log('getGammes: ', getGammes())
      // console.log("getGammesAsArray: ", getGammesAsArray())
      // console.log("getGammesAsSortedArray: ", getGammesAsSortedArray())
      // console.log("getGammesAsSortedArray: ", getGammesAsSortedArray("label"))
      // console.log("getGammesAsSortedArray: ", getGammesAsSortedArray("key"))
      // console.log("getGammesAsSortedArray: ", getGammesAsSortedArray("order"))
      // console.log("getCollectionsInGammes: ", getCollectionsInGammes('JOI'))
      // console.log("getCollectionsInGammes: ", getCollectionsInGammes(['JOI','HJO']))
      // console.log("getCollectionsInGammesAsArray: ", getCollectionsInGammesAsArray('JOI'))
      // console.log("getCollectionsInGammesAsSortedArray: ", getCollectionsInGammesAsSortedArray('JOI', 'label'))
      // console.log("getCollectionsInGammesAsSortedArray: ", getCollectionsInGammesAsSortedArray(['JOI','HJO'], 'label'))
      // console.log("getModelsInCollection: ", getModelsInCollection('000021'))
      // console.log("getModelsInCollectionAsArray: ", getModelsInCollectionAsArray('000021'))
      // console.log("getModelsInCollectionAsSortedArray: ", getModelsInCollectionAsSortedArray('000021', 'label'))
      console.log('getCollectionById: ', getCollectionById('000021'))
   }

   console.log('GetCollectionsInGammes("HJO")', getCollectionsInGammes('HJO'))
   console.log('GetCollectionsInGammes(["HJO","JOI"])', getCollectionsInGammes(['HJO', 'JOI']))
   console.log('findRegionFromCessionTarifNames("CES017", "EUR")', findRegionFromCessionTarifNames('CES017', 'EUR'))
   console.log('findRegionFromPublicTarifNames("CES017", "EUR")', findRegionFromPublicTarifNames('CES017', 'EUR'))

   // function doActionTest() {
   //    let num = "123456.78"
   //    console.log(num.toLocaleString("fr", { minimumFractionDigits: 0, maximumFractionDigits: 2 }))
   //    console.log(formatNumber(num, 2))
   //    console.log(formatPrice(num, 2))
   // }

   // function doActionTest() {
   //    console.log("doActionTest")
   //    console.log(`getPrice(CES001)`, getPrice("CES001"))
   // }

   // function doActionTest() {
   //    console.log("doActionTest")
   //    console.log(`getCollectionModels(00021)`, getCollectionModels("00021"))
   // }

   // function doActionTest() {
   //    console.log("doActionTest")
   //    setPreloadOnTarifRegion("Allemagne", true)
   // }

   // function doActionTest2() {
   //    console.log("doActionTest2")
   //    setPreloadOnTarifRegion("Allemagne", false)
   // }

   /* Permet de simuler la synchro d'un nv "set" de tarifs
   function doActionTest() {
      console.log("doActionTest")
      setTarifNames({
         "CES001": [
            "EUR", "BROUZOUFS"
         ],
         "CES002": [
            "YOLO", "YALA"
         ],
         "CES999": [
            "EUR"
         ]
      })
   }
   
   // Permet de simuler la modif du lastused et du preload d'un tarif
   function doActionTest2() {
      console.log("doActionTest2")
      let newCur = currencies
      newCur["CES001"].lastused = Date.now()
      newCur["CES001"].preload = true
      setTarifNames(
         newCur
      )
   }
   **/

   function toggleShowMinMaxPrice() {
      setShowGetMinMaxPrice(!showGetMinMaxPrice)
   }

   function toggleShowPrice() {
      setShowGetPrice(!showGetPrice)
   }

   function toggleShowColors() {
      setShowGetColors(!showGetColors)
   }

   function toggleShowSizes() {
      setShowGetSizes(!showGetSizes)
   }

   function toggleShowAllSizes() {
      setShowGetAllSizes(!showGetAllSizes)
   }

   function toggleShowGetCustomer() {
      setShowGetCustomer(!showGetCustomer)
   }

   function toggleShowGetOrder() {
      setShowGetOrder(!showGetOrder)
   }

   function toggleShowGetOrderIdsForCustomerId() {
      setShowGetOrderIdsForCustomerId(!showGetOrderIdsForCustomerId)
   }

   function toggleShowGetGammeForCollectionId() {
      setShowGetGammeForCollectionId(!showGetGammeForCollectionId)
   }

   function toggleShowGetGammes() {
      setShowGetGammes(!showGetGammes)
   }

   function toggleShowGetCollections() {
      setShowGetCollections(!showGetCollections)
   }

   function toggleShowGetFamilles() {
      setShowGetFamilles(!showGetFamilles)
   }

   function toggleShowGetPortes() {
      setShowGetPortes(!showGetPortes)
   }

   function toggleShowAppWrite() {
      setShowAppWrite(!showAppWrite)
   }

   function toggleShowUIItems() {
      setShowUIItems(!showUIItems)
   }

   function changeCustomerId(val) {
      setCustomerId(val)
   }

   function changeOrderId(val) {
      setOrderId(val)
   }

   function changeModelId(val) {
      setModelId(val)
   }

   function changeTarifId(val) {
      setTarifId(val.length ? val : null)
   }

   function changeCurrencyId(val) {
      setCurrencyId(val.length ? val : null)
   }

   function changeColor(val) {
      setColor(val.length ? val : null)
   }

   function changeSize(val) {
      setSize(val.length ? val : null)
   }

   function changeGammeForCollectionId(val) {
      setCollectionId(val.length ? val : null)
   }

   function _getMinMaxPriceForModel() {
      const val = getMinMaxPriceForModel(modelId, tarifId, currencyId)
      console.log(modelId, tarifId, currencyId, val)
      setMinMaxValue(val)
   }

   function _getPriceForModelColorSize() {
      let val = getPriceForModelColorSize(modelId, color, size, tarifId, currencyId)
      console.log(modelId, color, size, tarifId, currencyId, val)
      setPriceValue(val)
      val = getCessPriceForModelColorSize(modelId, color, size, tarifId, currencyId)
      console.log(modelId, color, size, tarifId, currencyId, val)
      setCessPriceValue(val)
   }

   function _getColorsForModel() {
      const val = getColorsForModel(modelId, tarifId, currencyId)
      console.log(modelId, tarifId, currencyId, val)
      setColorValues(val)
   }

   function _getSizesForModelColor() {
      const val = getSizesForModelColor(modelId, color, tarifId, currencyId)
      console.log(modelId, color, tarifId, currencyId, val)
      setSizeValues(val)
   }

   function _getAllSizesForModel() {
      const val = getAllSizesForModel(modelId, tarifId, currencyId)
      console.log(modelId, tarifId, currencyId, val)
      setAllSizeValues(val)
   }

   function _getGammeForCollectionId() {
      const gamme = getGammeForCollectionId(collectionId)
      console.log(collectionId, gamme)
      setGamme(JSON.stringify(gamme))
   }

   function _getGammes() {
      const gammes = getGammes()
      console.log(gammes)
      setGammes(JSON.stringify(gammes, null, 3))
   }

   function _getGammesAsArray() {
      const gammes = getGammesAsArray()
      console.log(gammes)
      setGammes(JSON.stringify(gammes, null, 3))
   }

   function _getGammesAsSortedArray() {
      const gammes = getGammesAsSortedArray()
      console.log(gammes)
      setGammes(JSON.stringify(gammes, null, 3))
   }

   function _getCollections() {
      const collections = getCollections()
      console.log(collections)
      setCollections(JSON.stringify(collections, null, 3))
   }

   function _getCollectionsAsArray() {
      const collections = getCollectionsAsArray()
      console.log(collections)
      setCollections(JSON.stringify(collections, null, 3))
   }

   function _getCollectionsAsSortedArray(field) {
      const collections = getCollectionsAsSortedArray(field)
      console.log(collections)
      setCollections(JSON.stringify(collections, null, 3))
   }

   function _getFamilles() {
      const familles = getFamilles()
      console.log(familles)
      setFamilles(JSON.stringify(familles, null, 3))
   }

   function _getFamillesAsArray() {
      const familles = getFamillesAsArray()
      console.log(familles)
      setFamilles(JSON.stringify(familles, null, 3))
   }

   function _getFamillesAsSortedArray(field) {
      const familles = getFamillesAsSortedArray(field)
      console.log(familles)
      setFamilles(JSON.stringify(familles, null, 3))
   }

   function _getPortes() {
      const porte = getPortes()
      console.log(porte)
      setPortes(JSON.stringify(porte, null, 3))
   }

   function _getPortesAsArray() {
      const porte = getPortesAsArray()
      console.log(porte)
      setPortes(JSON.stringify(porte, null, 3))
   }

   function _getPortesAsSortedArray(field) {
      const porte = getPortesAsSortedArray(field)
      console.log(porte)
      setPortes(JSON.stringify(porte, null, 3))
   }

   function _getCustomer() {
      const obj = getCustomerById(customerId)
      console.log(customerId, obj)
      setCustomer(JSON.stringify(obj, null, 3))
   }

   async function _getOrder() {
      console.log('getOrders', getOrders())
      console.log('getOrders(unsent)', getOrders(true))
      console.log('getOrdersAsArray', getOrdersAsArray(), await getOrdersAsArray())
      console.log('getOrdersAsArray(unsent)', getOrdersAsArray(true), await getOrdersAsArray(true))
      console.log(`getOrderById(${orderId})`, await getOrderById(orderId))

      console.log(`getOrderIdsForCustomerId(${customerId})`, getOrderIdsForCustomerId(customerId), await getOrderIdsForCustomerId(customerId))
      console.log(`getOrdersForCustomerId(${customerId})`, getOrdersForCustomerId(customerId))

      const obj = await getOrderById(orderId)
      console.log(orderId, obj)
      setOrder(JSON.stringify(obj, null, 3))
   }

   function _getOrderIdsForCustomerId() {
      getOrderIdsForCustomerId(customerId).then((obj) => {
         console.log(customerId, obj)
         setOrders(obj.join(', '))
      })
   }

   function onRadioWillChange1(which) {
      setTestSelectedRadioButton1(which)
   }

   function onRadioWillChange2(which) {
      setTestSelectedRadioButton2(which)
   }

   function onRadioWillChange3(which) {
      setTestSelectedRadioButton3(which)
   }

   function onRadioWillChange4(which) {
      setTestSelectedRadioButton4(which)
   }

   const onTestFirstPageChange = useCallback((val) => {
      setTestMultipageFrom(val)
   }, [])

   const onTestPageChange = useCallback((val) => {
      console.log('onPageChange', val)
      setTestMultipageSelectedPage(val)
   }, [])

   function testCheckedChanged(status) {
      return true; // Changement accepté
   }

   function testCheckedChangedFail(status) {
      return false; // Changement refusé
   }

   function doCheckedChanged(status) {
      setTestChecked(status === CheckboxStates.Checked)
   }

   function onTestDropdownFieldSelect1(id) {
      if (id) {
         setTestDropdownFieldValue1(id)
      }
      setTestIsDropdownFieldOpen1(false)
   }

   function onTestDropdownFieldSelect2(id) {
      if (id) {
         setTestDropdownFieldValue2(id)
      }
      setTestIsDropdownFieldOpen2(false)
   }

   function testToggleDropdownField1() {
      console.log(testIsDropdownFieldOpen1)
      setTestIsDropdownFieldOpen1(!testIsDropdownFieldOpen1)
   }

   function testToggleDropdownField2() {
      setTestIsDropdownFieldOpen2(!testIsDropdownFieldOpen2)
   }

   function onSegButtonsChange1(seg) {
      setTestSelectedSegButton1(seg)
   }

   function onSegButtonsChange2(seg) {
      setTestSelectedSegButton2(seg)
   }

   function onClick() {
      setTestIsDropdownFieldOpen1(false)
      setTestIsDropdownFieldOpen2(false)
   }

   function _getTarifNames() {
      // testFetchTarifNames()
   }

   // console.log(tarifId, currencyId, modelId)
   // console.log(colorValues)
   // console.log('selectedTestRadioButton1', selectedTestRadioButton1)

   // console.log('findStepFromValue', findStepFromValue([1000, 2000, 3000], 3001))

   // useEffect(() => {
   //    subscribeToSessionEvents()
   // }, [subscribeToSessionEvents])

   return (
      <Fragment>
         <Menu page="tests" />
         <div className={styles.tests} onClick={onClick}>
            <div className={styles.innerbox}>
               <h1>Tests (ne pas utiliser)</h1>
               <section>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                     <Button small onClick={doActionTest}>Get Prices</Button>
                     {/* <Button small onClick={doActionTest}>Get Models</Button> */}
                     {/* <Button small onClick={doActionTest2}>Unset Preload</Button> */}
                     {/* <Button small onClick={doActionTest}>Add currencies</Button>
                     <Button small onClick={doActionTest2}>Change currencies</Button> */}
                     {/* Action 1 : <input type="text" value="Go" onChange={doActionTest} />
                     Action 2 : <input type="text" value="Go 2" onChange={doActionTest2} /> */}
                  </div>
               </section>
               <Button small onClick={toggleShowGetGammes}>
                  Show GetGammes
               </Button>
               {showGetGammes && (
                  <>
                     <section className={styles.display_row}>
                        <Button small onClick={_getGammes}>GetGammes</Button>
                        <Button small onClick={_getGammesAsArray}>GetGammesAsArray</Button>
                        <Button small onClick={_getGammesAsSortedArray}>GetGammesAsSortedArray</Button>
                     </section>
                     {gammes && (
                        <section>
                           <pre>
                              Gammes = {gammes}
                           </pre>
                        </section>
                     )}
                  </>
               )}
               <Button small onClick={toggleShowGetCollections}>
                  Show GetCollections
               </Button>
               {showGetCollections && (
                  <>
                     <section className={styles.display_row}>
                        <Button small onClick={_getCollections}>GetCollections</Button>
                        <Button small onClick={_getCollectionsAsArray}>GetCollectionsAsArray</Button>
                        <Button small onClick={() => _getCollectionsAsSortedArray("label")}>GetCollectionsAsSortedArray (label)</Button>
                        <Button small onClick={() => _getCollectionsAsSortedArray("id")}>GetCollectionsAsSortedArray (id)</Button>
                     </section>
                     {collections && (
                        <section>
                           <pre>
                              Collections = {collections}
                           </pre>
                        </section>
                     )}
                  </>
               )}
               <Button small onClick={toggleShowGetFamilles}>
                  Show GetFamilles
               </Button>
               {showGetFamilles && (
                  <>
                     <section className={styles.display_row}>
                        <Button small onClick={_getFamilles}>GetFamilles</Button>
                        <Button small onClick={_getFamillesAsArray}>GetFamillesAsArray</Button>
                        <Button small onClick={() => _getFamillesAsSortedArray("label")}>GetFamillesAsSortedArray (label)</Button>
                        <Button small onClick={() => _getFamillesAsSortedArray("id")}>GetFamillesAsSortedArray (id)</Button>
                     </section>
                     {familles && (
                        <section>
                           <pre>
                              Familles = {familles}
                           </pre>
                        </section>
                     )}
                  </>
               )}
               <Button small onClick={toggleShowGetPortes}>
                  Show GetPortes
               </Button>
               {showGetPortes && (
                  <>
                     <section className={styles.display_row}>
                        <Button small onClick={_getPortes}>GetPortes</Button>
                        <Button small onClick={_getPortesAsArray}>GetPortesAsArray</Button>
                        <Button small onClick={() => _getPortesAsSortedArray("label")}>GetPortesAsSortedArray (label)</Button>
                        <Button small onClick={() => _getPortesAsSortedArray("id")}>GetPortesAsSortedArray (id)</Button>
                     </section>
                     {portes && (
                        <section>
                           <pre>
                              Portes = {portes}
                           </pre>
                        </section>
                     )}
                  </>
               )}
               <Button small onClick={toggleShowGetGammeForCollectionId}>
                  Show GetGammeForCollectionId
               </Button>
               {showGetGammeForCollectionId && (
                  <section className={styles.display_row}>
                     <InputField className={styles.small_width} title="Collection ID" placeholder={'000021'} value={collectionId} onChange={changeGammeForCollectionId}
                     ></InputField>
                     <Button small onClick={_getGammeForCollectionId}>
                        GetGammeForCollectionId
                     </Button>
                     <span>Gamme = {gamme}</span>
                  </section>
               )}
               <Button small onClick={toggleShowMinMaxPrice}>
                  Show GetMiniMaxPrice
               </Button>
               {showGetMinMaxPrice && (
                  <section className={styles.display_row}>
                     <InputField className={styles.small_width} title="Tarif" placeholder={curTarifRegionName} value={tarifId} onChange={changeTarifId}
                     ></InputField>
                     <InputField className={styles.small_width} title="Devise" placeholder={currency} value={currencyId} onChange={changeCurrencyId}
                     ></InputField>
                     <InputField className={styles.small_width} title="Modèle" placeholder="10001" value={modelId} onChange={changeModelId}
                     ></InputField>
                     <Button small onClick={_getMinMaxPriceForModel}>
                        GetMiniMaxPrice
                     </Button>
                     {!minMaxValue?.reason && (
                        <span>
                           Min = {minMaxValue?.min ?? '??'}, Max = {minMaxValue?.max ?? '??'}
                        </span>
                     )}
                     {minMaxValue?.reason && (
                        <span>Erreur = {minMaxValue?.reason.text ?? null}</span>
                     )}
                  </section>
               )}
               <Button small onClick={toggleShowPrice}>
                  Show GetPrice
               </Button>
               {showGetPrice && (
                  <section className={styles.display_row}>
                     <InputField className={styles.small_width} title="Tarif" placeholder={curTarifRegionName} value={tarifId} onChange={changeTarifId} ></InputField>
                     <InputField className={styles.small_width} title="Devise" placeholder={currency} value={currencyId} onChange={changeCurrencyId} ></InputField>
                     <InputField className={styles.small_width} title="Modèle" placeholder="10001" value={modelId} onChange={changeModelId} ></InputField>
                     <InputField className={styles.small_width} title="Couleur" placeholder="WG" value={color} onChange={changeColor} ></InputField>
                     <InputField className={styles.small_width} title="Taille" placeholder="50" value={size} onChange={changeSize} ></InputField>
                     <Button small onClick={_getPriceForModelColorSize}>
                        GetPrice
                     </Button>
                     {!priceValue?.reason && (
                        <span>PPU = {priceValue?.price ?? '??'}</span>
                     )}
                     {priceValue?.reason && (
                        <span>Erreur = {priceValue?.reason.text ?? null}</span>
                     )}
                     {!cessPriceValue?.reason && (
                        <span>P. Cess. = {cessPriceValue?.price ?? '??'}</span>
                     )}
                     {cessPriceValue?.reason && (
                        <span>
                           Erreur = {cessPriceValue?.reason.text ?? null}
                        </span>
                     )}
                  </section>
               )}
               <Button small onClick={toggleShowColors}>
                  Show GetColors
               </Button>
               {showGetColors && (
                  <section className={styles.display_row}>
                     <InputField className={styles.small_width} title="Tarif" placeholder={curTarifRegionName} value={tarifId} onChange={changeTarifId} ></InputField>
                     <InputField className={styles.small_width} title="Devise" placeholder={currency} value={currencyId} onChange={changeCurrencyId} ></InputField>
                     <InputField className={styles.small_width} title="Modèle" placeholder="10001" value={modelId} onChange={changeModelId} ></InputField>
                     <Button small onClick={_getColorsForModel}>
                        GetColors
                     </Button>
                     {!colorValues?.reason && (
                        <span>
                           Couleurs = [{colorValues?.colors?.join(', ') ?? '??'}]
                        </span>
                     )}
                     {colorValues?.reason && (
                        <span>Erreur = {colorValues?.reason.text ?? null}</span>
                     )}
                  </section>
               )}
               <Button small onClick={toggleShowSizes}>
                  Show GetSizes
               </Button>
               {showGetSizes && (
                  <section className={styles.display_row}>
                     <InputField className={styles.small_width} title="Tarif" placeholder={curTarifRegionName} value={tarifId} onChange={changeTarifId} ></InputField>
                     <InputField className={styles.small_width} title="Devise" placeholder={currency} value={currencyId} onChange={changeCurrencyId} ></InputField>
                     <InputField className={styles.small_width} title="Modèle" placeholder="10001" value={modelId} onChange={changeModelId} ></InputField>
                     <InputField className={styles.small_width} title="Couleur" placeholder="WG" value={color} onChange={changeColor} ></InputField>
                     <Button small onClick={_getSizesForModelColor}>
                        GetSizes
                     </Button>
                     {!sizeValues?.reason && (
                        <span>
                           Tailles = [{sizeValues?.sizes?.join(', ') ?? '??'}]
                        </span>
                     )}
                     {sizeValues?.reason && (
                        <span>Erreur = {sizeValues?.reason.text ?? null}</span>
                     )}
                  </section>
               )}
               <Button small onClick={toggleShowAllSizes}>
                  Show GetAllSizes
               </Button>
               {showGetAllSizes && (
                  <section className={styles.display_row}>
                     <InputField className={styles.small_width} title="Tarif" placeholder={curTarifRegionName} value={tarifId} onChange={changeTarifId} ></InputField>
                     <InputField className={styles.small_width} title="Devise" placeholder={currency} value={currencyId} onChange={changeCurrencyId} ></InputField>
                     <InputField className={styles.small_width} title="Modèle" placeholder="10001" value={modelId} onChange={changeModelId} ></InputField>
                     <Button small onClick={_getAllSizesForModel}>
                        GetAllSizes
                     </Button>
                     {!allSizeValues?.reason && (
                        <span>
                           Tailles = [{allSizeValues?.sizes?.join(', ') ?? '??'}]
                        </span>
                     )}
                     {allSizeValues?.reason && (
                        <span>
                           Erreur = {allSizeValues?.reason.text ?? null}
                        </span>
                     )}
                  </section>
               )}
               <Button small onClick={toggleShowGetCustomer}>
                  Show GetCustomer
               </Button>
               {showGetCustomer && (
                  <>
                     <section className={styles.display_row}>
                        <InputField className={styles.small_width} title="ID" placeholder="999999" value={customerId} onChange={changeCustomerId} ></InputField>
                        <Button small onClick={_getCustomer}>
                           GetCustomer
                        </Button>
                     </section>
                     {customer && (
                        <section>
                           <pre>
                              Customer = {customer}
                           </pre>
                        </section>
                     )}
                  </>
               )}
               <Button small onClick={toggleShowGetOrderIdsForCustomerId}>
                  Show GetOrderIdsForCustomerId
               </Button>
               {showGetOrderIdsForCustomerId && (
                  <section className={styles.display_row}>
                     <InputField className={styles.small_width} title="ID" placeholder="999999" value={customerId} onChange={changeCustomerId} ></InputField>
                     <Button small onClick={_getOrderIdsForCustomerId}>
                        GetOrderIdsForCustomerId
                     </Button>
                     {orders && (
                        <span>
                           Order Ids = {orders}
                        </span>
                     )}
                  </section>
               )}
               <Button small onClick={toggleShowGetOrder}>
                  Show GetOrder
               </Button>
               {showGetOrder && (
                  <>
                     <section className={styles.display_row}>
                        <InputField className={styles.small_width} title="ID" placeholder="999999" value={orderId} onChange={changeOrderId} ></InputField>
                        <Button small onClick={_getOrder}>
                           GetOrder
                        </Button>
                     </section>
                     {order && (
                        <section>
                           <pre>
                              Order = {order}
                           </pre>
                        </section>
                     )}
                  </>
               )}
               <Button small onClick={toggleShowAppWrite}>
                  Show AppWrite
               </Button>
               {showAppWrite && (
                  <section className={styles.display_row}>
                     <Button small onClick={_getTarifNames}>
                        Get Tarif Names (Bucket)
                     </Button>
                  </section>
               )}
               <Button small onClick={() => setShowConfirmSimple(val => !val)}>
                  Show Confirm (simple)
               </Button>
               {showConfirmSimple && (
                  <Confirm width={'50%'} onCancel={() => { setShowConfirmSimple(false); alert('Cancel') }} onOK={() => { setShowConfirmSimple(false);; alert('OK') }}>
                     Bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
                  </Confirm>
               )}
               <Button small onClick={() => setShowConfirm(val => !val)}>
                  Show Confirm (checkbox)
               </Button>
               {showConfirm && (
                  <Confirm width={'50%'} onCancel={() => { setShowConfirm(false); alert('Cancel') }} onOK={() => { setShowConfirm(false);; alert('OK') }} enableOK={confirmCheckboxState === CheckboxStates.Checked}>
                     Bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
                     <Checkbox caption="Refus" checked={confirmCheckboxState} onChange={(state) => { setConfirmCheckboxState(state) }} />
                  </Confirm>
               )}
               <Button small onClick={toggleShowUIItems}>
                  Show UI Items
               </Button>
               {showUIItems && (
                  <Fragment>
                     <section className={styles.display_row}>
                        <InputField className={styles.small_width} title="Label" placeholder={123} value={123} /*onChange={}*/ ></InputField>
                        <InputField className={styles.small_width} title="Label" placeholder={123} ></InputField>
                     </section>
                     <section className={styles.display_row}>
                        <Button small /*onClick={}*/>Small</Button>
                        <Button medium /*onClick={}*/>Medium</Button>
                        <Button big /*onClick={}*/>Big</Button>
                        <Button medium red /*onClick={}*/>Red</Button>
                        <Button medium green /*onClick={}*/>Green</Button>
                        <Button medium secondary /*onClick={}*/>Secondary</Button>
                        <Button medium tertiary /*onClick={}*/>Tertiary</Button>
                        <Button medium nobackground /*onClick={}*/>No background</Button>
                        <Button medium disabled /*onClick={}*/>Disabled</Button>
                     </section>
                     <section className={styles.display_row}>
                        <Checkbox caption="Normal" checked={`${testChecked ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} canChange={testCheckedChanged} onChange={doCheckedChanged} />
                        <Checkbox caption="Refus" checked={CheckboxStates.Checked} canChange={testCheckedChangedFail} onChange={doCheckedChanged} />
                        <Checkbox caption="Refus" checked={CheckboxStates.Unchecked} canChange={testCheckedChangedFail} onChange={doCheckedChanged} />
                     </section>
                     <section className={styles.display_row}>
                        <ColorBullet yg />YG
                        <ColorBullet wg />WG
                        <ColorBullet pg />PG
                        <ColorBullet tn />TN
                     </section>
                     <section className={styles.display_row}>
                        <DropdownCB
                           /*className={styles.dropdown_gammes}*/ values={[
                              { id: 1, caption: 'allowNoneChecked', short: '001', checked: false },
                              { id: 2, caption: 'Label2', short: '002', checked: true },
                              { id: 3, caption: 'Label3', short: '003', checked: false },
                              { id: 4, caption: 'Label4', short: '004', checked: false }
                           ]}
                           /*onChange={}*/ open={false}
                           /*onOpenClose={}*/ allowNoneChecked
                        />
                        <DropdownCB
                           /*className={styles.dropdown_gammes}*/ values={[
                              { id: 1, caption: 'Not allowNoneChecked', short: '001', checked: false },
                              { id: 2, caption: 'Label2', short: '002', checked: true },
                              { id: 3, caption: 'Label3', short: '003', checked: false },
                              { id: 4, caption: 'Label4', short: '004', checked: false }
                           ]}
                           /*onChange={}*/ open={false} /*onOpenClose={}*/
                        />
                        <DropdownCB
                           /*className={styles.dropdown_gammes}*/ noscrollbars
                           values={[
                              { id: 1, caption: 'noScrollbars', short: '001', checked: false },
                              { id: 2, caption: 'Label2', short: '002', checked: true },
                              { id: 3, caption: 'Label3', short: '003', checked: false },
                              { id: 4, caption: 'Label4', short: '004', checked: false }
                           ]}
                           /*onChange={}*/ open={true}
                           /*onOpenClose={}*/ allowNoneChecked
                        />
                     </section>
                     <section className={styles.display_row}>
                        <div style={{ width: 200 }}>
                           <Keypad /*ref={refKeypad}*/ /*onValidate={}*/ visible={true} disabled={false} value={12} min={0} max={999} /*onChange={onKeypadChange}*/ /*onCancel={cancelKeypad}*/></Keypad>
                        </div>
                     </section>
                     <section className={styles.display_row}>
                        <div
                           style={{ position: 'relative', marginLeft: 650, width: 80, height: 20, border: '3px dotted red' }}>
                           <DropdownMenu noscrollbars right={-20} top={20} /* leftt={20} bottom={40} */>
                              <li /*onClick={}*/>
                                 <FontAwesomeIcon icon={faPenToSquare} />Editer
                              </li>
                              <li>
                                 <FontAwesomeIcon icon={faBadgeCheck} />Commande active
                              </li>
                              <li>
                                 <FontAwesomeIcon icon={faFilePdf} />Envoyer en PDF à...
                              </li>
                              <li>
                                 <FontAwesomeIcon icon={faClone} />Dupliquer
                              </li>
                              <li>
                                 <FontAwesomeIcon icon={faCloudArrowUp} />Envoyer
                              </li>
                           </DropdownMenu>
                        </div>
                     </section>
                     <section className={styles.display_row}>
                        <Link /*onClick={}*/>Purger filtres</Link>
                     </section>
                     <section className={styles.display_row}>
                        <PagesButtons from={testMultipageFrom} max={testMultipageTo} selected={testMultipageSelectedPage} onFirstPageChange={onTestFirstPageChange} onPageChange={onTestPageChange}></PagesButtons>
                     </section>
                     <section className={styles.display_row}>
                        <div style={{ width: 400 }}>
                           <Slider steps={20} step_pix_size={10} from={3} to={8} /*onChange={onSliderChange}*/ />
                        </div>
                     </section>
                     <section className={styles.display_row}>
                        <CardCollection label="Label" image="https://placebear.com/640/360" /* onClick={}*/></CardCollection>
                     </section>
                     <section className={styles.display_row} style={{ alignItems: 'center', gap: 30 }} >
                        <span>Small</span>
                        <RadioButtons small selected={testSelectedRadioButton1} onWillChange={onRadioWillChange1} >
                           <FontAwesomeIcon key="radbtns_list1" icon={faBars} />
                           <FontAwesomeIcon key="radbtns_grid1" icon={faGrid2} />
                        </RadioButtons>
                        <span>Normal (défaut)</span>
                        <RadioButtons /* normal */ selected={testSelectedRadioButton2} onWillChange={onRadioWillChange2}>
                           <FontAwesomeIcon key="radbtns_list2" icon={faBars} />
                           <FontAwesomeIcon key="radbtns_grid2" icon={faGrid2} />
                        </RadioButtons>
                        <span>Medium</span>
                        <RadioButtons medium selected={testSelectedRadioButton3} onWillChange={onRadioWillChange3} >
                           <FontAwesomeIcon key="radbtns_list3" icon={faBars} />
                           <FontAwesomeIcon key="radbtns_grid3" icon={faGrid2} />
                        </RadioButtons>
                        <span>Large</span>
                        <RadioButtons large selected={testSelectedRadioButton4} onWillChange={onRadioWillChange4} >
                           <FontAwesomeIcon key="radbtns_list4" icon={faBars} />
                           <FontAwesomeIcon key="radbtns_grid4" icon={faGrid2} />
                        </RadioButtons>
                     </section>
                     <section className={styles.display_row}>
                        <DropdownField selectedValue={testDropdownFieldValue1} height={40} width={250} onSelect={onTestDropdownFieldSelect1} isOpen={testIsDropdownFieldOpen1} onToggle={testToggleDropdownField1} >
                           <Fragment key="item1">Editer</Fragment>
                           <Fragment key="item2">Commande active</Fragment>
                           <Fragment key="item3">
                              Bla Bla Bla Bla Bla Bla Bla Bla Bla Bla
                           </Fragment>
                           <Fragment key="item4">Envoyer en PDF à...</Fragment>
                           <Fragment key="item5">Dupliquer</Fragment>
                           <Fragment key="item6">Envoyer</Fragment>
                        </DropdownField>
                        <DropdownField selectedValue={testDropdownFieldValue2} showAbove width={250} height={40} onSelect={onTestDropdownFieldSelect2} isOpen={testIsDropdownFieldOpen2} onToggle={testToggleDropdownField2} >
                           <Fragment key="item1">
                              <FontAwesomeIcon icon={faPenToSquare} />Editer
                           </Fragment>
                           <Fragment key="item2">
                              <FontAwesomeIcon icon={faBadgeCheck} />Commande active
                           </Fragment>
                           <Fragment key="item3">
                              <FontAwesomeIcon icon={faFilePdf} />Envoyer en PDF à...
                           </Fragment>
                           <Fragment key="item4">
                              <FontAwesomeIcon icon={faClone} />Dupliquer
                           </Fragment>
                           <Fragment key="item5">
                              <FontAwesomeIcon icon={faCloudArrowUp} />Envoyer
                           </Fragment>
                        </DropdownField>
                     </section>
                     <section className={styles.display_row} style={{ alignItems: 'center', gap: 20 }} >
                        <SegmentedButtons
                           /*className={styles.quickjump}*/ opaque segments={[
                              { value: 'JOA', label: 'Joaillerie' },
                              { value: 'ACC', label: 'Accessoires' },
                              { value: 'HJO', label: 'Haute-Joaillerie' }
                           ]}
                           default={testSelectedSegButton1} name="quickjump" onChange={onSegButtonsChange1} />
                        <SegmentedButtons
                           /*className={styles.quickjump}*/ opaque segments={[
                              { value: 'X', label: 'XXX' },
                              { value: 'Y', label: 'YYY' },
                              { value: 'Z', label: 'ZZZ' }
                           ]} noselection name="quickjump" onChange={onSegButtonsChange2} />{' '}
                        Clické : <span>{testSelectedSegButton2}</span>
                     </section>
                     <section className={styles.display_row} style={{ alignItems: 'center', gap: 20 }} >
                        Size, Small :
                        <SegmentedButtons small opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                        Medium :
                        <SegmentedButtons medium opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                        Large :
                        <SegmentedButtons large opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                     </section>
                     <section className={styles.display_row} style={{ alignItems: 'center', gap: 20 }} >
                        Padding, Normal :
                        <SegmentedButtons medium opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                        Medium :
                        <SegmentedButtons medium medium_padding opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                        Large :
                        <SegmentedButtons medium large_padding opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                     </section>
                     <section className={styles.display_row} style={{ alignItems: 'center', gap: 20 }} >
                        Medium Round :
                        <SegmentedButtons medium medium_padding round opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                        Medium Round Shadow :
                        <SegmentedButtons medium medium_padding round shadow opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                     </section>
                     <section className={styles.display_row} style={{ alignItems: 'center', gap: 20 }} >
                        Large Round :
                        <SegmentedButtons large large_padding round opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                        Large Round Shadow :
                        <SegmentedButtons large large_padding round shadow opaque segments={[
                           { value: 'X', label: 'XXX' },
                           { value: 'Y', label: 'YYY' },
                           { value: 'Z', label: 'ZZZ' }
                        ]} noselection name="quickjump" />
                     </section>
                  </Fragment>
               )}
               {/* </section> */}
            </div>
         </div >
      </Fragment >
   )
}

export default Tests;
