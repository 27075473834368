import styles from './AddressPicker.module.scss';
import useTranslation from '../Hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faBox, faStore } from '@fortawesome/pro-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import usePrefStore from '../stores/prefs';
import { useCallback, useEffect, useState } from 'react';
import useHelpers from '../Hooks/useHelpers';
import useSales from '../Hooks/useSales';
// import { useCallback, useEffect, useRef, useState } from 'react';

const tabs = {
   jewels: 'jewels',
   ecrins: 'ecrins'
}

function AddressPicker(props) {
   const { trans } = useTranslation();
   const { inlineStyleFromProps, buildAddress } = useHelpers();
   const { getAddressesFromCustomerAsSortedArray, getCustomerById, type_address, tag_address, updateOrder } = useSales();
   // States
   const [curAddrType, setCurAddrType] = useState(tabs.jewels)
   const [addStyle, setAddStyle] = useState({});
   const [addressesLines, setAddressesLines] = useState(null);
   //
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups);
   const { globalClosePopups, currentLang } = usePrefStore();

   useEffect(() => {
      const style = inlineStyleFromProps(props); // On reconstruit un style avec les propriétés trouvées dans props
      setAddStyle(style);
   }, [props, inlineStyleFromProps]);

   const selectAddress = useCallback((addr_id) => {
      let new_addr
      if (curAddrType === tabs.jewels) {
         new_addr = {
            ...props.order.addresses,
            jewels: (props.order.addresses?.jewels === addr_id) ? null : addr_id
         }
      } else {
         new_addr = {
            ...props.order.addresses,
            ecrins: (props.order.addresses?.ecrins === addr_id) ? null : addr_id
         }
      }
      console.log('UPDATE', addr_id, new_addr);
      updateOrder(props.order.id, {
         "addresses": { ...new_addr }
      })

   }, [curAddrType, props, updateOrder])

   useEffect(() => {
      const customer = getCustomerById(props.order?.customer_id)
      const addresses = getAddressesFromCustomerAsSortedArray(customer)

      console.log(props, props.order, props.order?.customer_id, addresses);
      if (addresses) {
         let arr = null
         if (curAddrType === tabs.jewels) {
            arr = addresses.filter(
               (an_address) => ((an_address.id === type_address.siege) || (
                  !an_address.id.startsWith(type_address.ecrins) &&
                  !an_address.id.startsWith(type_address.pos)
               ))
            )
         } else {
            arr = addresses.filter(
               (an_address) => ((an_address.id === type_address.siege) || an_address.id.startsWith(type_address.ecrins))
            )
         }
         const lines = arr.map((an_address, idx) => {
            let icon, text, checked

            if (curAddrType === tabs.jewels) {
               if (an_address.id === type_address.siege) {
                  icon = faBuilding
                  text = tag_address.siege[currentLang ?? 'fr']
               } else {
                  icon = faStore
                  text = tag_address.other[currentLang ?? 'fr']
               }
               checked = (an_address.id === props.order.addresses?.jewels)
            } else {
               if (an_address.id === type_address.siege) {
                  icon = faBuilding
                  text = tag_address.siege[currentLang ?? 'fr']
               } else {
                  icon = faBox
                  text = tag_address.ecrins[currentLang ?? 'fr']
               }
               checked = (an_address.id === props.order.addresses?.ecrins)
            }
            const addr_id = an_address.id
            return (
               <li key={`addresses_line_${idx}`} className={checked ? styles.checked : ''} onClick={() => selectAddress(addr_id)}>
                  <div className={styles.type}>
                     <FontAwesomeIcon icon={icon} />
                     <span>{text}</span>
                  </div>
                  <div className={styles.address}>
                     {buildAddress(an_address).map(line => <span>{line}</span>)}
                  </div>
               </li>
            )
         })
         setAddressesLines(lines)
      } else {
         setAddressesLines(null)
      }
   }, [buildAddress, curAddrType, currentLang, getAddressesFromCustomerAsSortedArray, getCustomerById, props, selectAddress, tag_address, trans, type_address])

   function doNothing(evt) {
      console.log('doNothing');
      evt.stopPropagation();
   }

   function doClose() {
      if (props.onClose) {
         props.onClose()
      }
   }

   function onClickBackground() {
      console.log('onClickBackground');
      if (props.onClose) {
         if (globalClosePopups !== 'button') {
            props.onClose();
         }
      }
   }

   return (
      <div className={styles.background} onClick={onClickBackground}>
         <div className={styles.address_picker} onClick={doNothing} style={addStyle}>
            <div className={styles.top}>
               {props.icon}
               <span>{props.title}</span>
               <button onClick={doClose}>
                  <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
            <div className={styles.detail}>
               <div className={styles.header}>
                  <div className={styles.addr_types}>
                     <span className={(curAddrType === tabs.jewels) ? styles.selected : ''} onClick={() => setCurAddrType(tabs.jewels)}>
                        {/* <FontAwesomeIcon icon={faBagShopping} /> */}
                        {trans('?Bijou', true)}
                        <div className={styles.bot_border} />
                     </span>
                     <span className={(curAddrType === tabs.ecrins) ? styles.selected : ''} onClick={() => setCurAddrType(tabs.ecrins)}>
                        {/* <FontAwesomeIcon icon={faCloudSlash} /> */}
                        {trans('?Ecrin', true)}
                        <div className={styles.bot_border} />
                     </span>
                  </div>
               </div>

               <ul className={styles.detail_addresses}>
                  {addressesLines}
               </ul>
            </div>
         </div>
      </div>
   )
}

export default AddressPicker;
