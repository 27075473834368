import { useCallback } from "react"
import useModelsStore from "../stores/models"
import usePrefStore from "../stores/prefs"
// import { useLogger } from "../Contexts/Logger"
// import { AppWriteContext } from '../Contexts/AppWriteContext';

// const fetch_names = {
//    tarifRegions: 'tarifRegions',
//    tarifTrads: 'tarifTrads',
//    models: 'models',
//    customers: 'customers',
//    countries: 'countries',
//    orders: 'orders',
//    collections: 'collections',
//    couleurs: 'couleurs',
//    cycles: 'cycles',
//    devises: 'devises',
//    etapes: 'etapes',
//    familles: 'familles',
//    genres: 'genres',
//    modeles: 'modeles',
//    portes: 'portes',
// }

const useCollections = () => {
   const { collections, familles, portes, cycles, etapes, genres, couleurs, models } = useModelsStore()
   const { currentLang: curLang, defaultLang: defLang } = usePrefStore()
   //
   // const { log, error } = useLogger()
   // const { getListItem } = { ...AppWriteContext() };

   // console.log('getListItem', getListItem);

   const HJO = "HJO"

   // const fetchModels = useCallback(() => {
   //    console.log('Fetch modèles')
   //    fetch('/catalogue/modeles.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch modèles')
   //       })
   //       .then(data => {
   //          setModels(data)
   //          console.log('Fetch modèles OK')
   //       })
   // }, [setModels])

   // const fetchCollections = useCallback(() => {
   //    console.log('Fetch collections')
   //    fetch('/catalogue/collections.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch collections')
   //       })
   //       .then(data => {
   //          setCollections(data)
   //          console.log('Fetch collections OK')
   //       })
   // }, [setCollections])
   // const fetchCollections = useCallback(() => {
   //    return new Promise((success, failure) => {
   //       console.log('Fetch collections')

   //       getListItem(fetch_names.collections, checksum_collections ?? '').then(res => {
   //          if (res.status) {
   //             const doc = res.data

   //             if (doc) {  // On a fetché un doc plus récent
   //                console.log('fetchCollections > OK');
   //                console.log(doc.data);
   //                console.log(JSON.parse(doc.data));
   //                console.log(JSON.parse(doc.data));
   //                try { // Permet de s'assurer que le JSON est OK
   //                   setCollections(JSON.parse(doc.data))
   //                   setChecksumCollections(doc.checksum)
   //                   success()
   //                } catch (err) {
   //                   console.log('fetchCollections > ERR');
   //                   failure(err)
   //                }
   //             } else {    // On n'a pas fetché de doc, donc pas trouvé de plus récent
   //                if (!checksum_collections) {  // Oui, mais on n'a rien actuellement dans la base, donc ça signifie qu'il y a un pb !
   //                   console.log('fetchCollections > ERR');
   //                   failure('Not found and no local data!')
   //                } else {    // On garde les data locales récupérées d'un fetch précédent
   //                   success()
   //                }
   //             }
   //          } else {
   //             console.log('fetchCollections > ERR');
   //             failure(res.err)
   //          }
   //       }).catch(err => {
   //          console.log('fetchCollections > ERR');
   //          failure(err)
   //       })
   //    })
   // }, [checksum_collections, getListItem, setChecksumCollections, setCollections])

   // const fetchCouleurs = useCallback(() => {
   //    console.log('Fetch couleurs')
   //    fetch('/catalogue/couleurs.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch couleurs')
   //       })
   //       .then(data => {
   //          setCouleurs(data)
   //          console.log('Fetch couleurs OK')
   //       })
   // }, [setCouleurs])

   // const fetchCouleurs = useCallback(() => {
   //    return new Promise((success, failure) => {
   //       console.log('Fetch couleurs')

   //       getListItem(fetch_names.couleurs, checksum_couleurs ?? '').then(res => {
   //          if (res.status) {
   //             const doc = res.data

   //             if (doc) {  // On a fetché un doc plus récent
   //                console.log('fetchCollections > OK');
   //                console.log(doc.data);
   //                console.log(JSON.parse(doc.data));
   //                console.log(JSON.parse(doc.data));
   //                try { // Permet de s'assurer que le JSON est OK
   //                   setCouleurs(JSON.parse(doc.data))
   //                   setChecksumCouleurs(doc.checksum)
   //                   success()
   //                } catch (err) {
   //                   console.log('fetchCouleurs > ERR');
   //                   failure(err)
   //                }
   //             } else {    // On n'a pas fetché de doc, donc pas trouvé de plus récent
   //                if (!checksum_couleurs) {  // Oui, mais on n'a rien actuellement dans la base, donc ça signifie qu'il y a un pb !
   //                   console.log('fetchCouleurs > ERR');
   //                   failure('Not found and no local data!')
   //                } else {    // On garde les data locales récupérées d'un fetch précédent
   //                   success()
   //                }
   //             }
   //          } else {
   //             console.log('fetchCouleurs > ERR');
   //             failure(res.err)
   //          }
   //       }).catch(err => {
   //          console.log('fetchCouleurs > ERR');
   //          failure(err)
   //       })
   //    })
   // }, [checksum_couleurs, getListItem, setChecksumCouleurs, setCouleurs])

   // // Fetch générique pour collections, couleurs, cycles, etapes, familles, genres, portes
   // const fetchList = useCallback((name, setList, checksum, setChecksum) => {
   //    return new Promise((resolve, reject) => {
   //       console.log(`Fetch ${name}`)

   //       getListItem(name, 'checksum' ?? '').then(res => {
   //          if (res.status) {
   //             const doc = res.data

   //             if (doc) {  // On a fetché un doc plus récent
   //                console.log(`fetch ${name} > OK`);
   //                // console.log(doc.data);
   //                // console.log(JSON.parse(doc.data));
   //                // console.log(JSON.parse(doc.data));
   //                try { // Permet de s'assurer que le JSON est OK
   //                   setList(JSON.parse(doc.data))
   //                   setChecksum(doc.checksum)
   //                   resolve()
   //                } catch (err) {
   //                   console.log(`fetch ${name} > ERR`, err);
   //                   reject(new Error(err))
   //                }
   //             } else {    // On n'a pas fetché de doc, donc pas trouvé de plus récent
   //                console.log(`fetch ${name} > Doc null`);
   //                if (!checksum) {  // Oui, mais on n'a rien actuellement dans la base, donc ça signifie qu'il y a un pb !
   //                   console.log(`fetch ${name} > ERR`);
   //                   reject(new Error(`${name}: Not found and no local data!`))
   //                } else {    // On garde les data locales récupérées d'un fetch précédent
   //                   resolve()
   //                }
   //             }
   //          } else {
   //             console.log(`fetch ${name} > ERR`, res);
   //             reject(new Error(res?.data?.message ?? 'Erreur inconnue'))
   //          }
   //       }).catch(err => {
   //          console.log(`fetch ${name} > ERR`, err);
   //          reject(new Error('Erreur inconnue'))
   //       })
   //    })
   // }, [getListItem])

   // const fetchFamilles = useCallback(() => {
   //    console.log('Fetch familles')
   //    fetch('/catalogue/familles.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch familles')
   //       })
   //       .then(data => {
   //          setFamilles(data)
   //          console.log('Fetch familles OK')
   //       })
   // }, [setFamilles])

   // const fetchPortes = useCallback(() => {
   //    console.log('Fetch portés')
   //    fetch('/catalogue/portes.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch portés')
   //       })
   //       .then(data => {
   //          setPortes(data)
   //          console.log('Fetch portés OK')
   //       })
   // }, [setPortes])

   // const fetchCycles = useCallback(() => {
   //    console.log('Fetch cycles')
   //    fetch('/catalogue/cycles.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch cycles')
   //       })
   //       .then(data => {
   //          setCycles(data)
   //          console.log('Fetch cycles OK')
   //       })
   // }, [setCycles])

   // const fetchEtapes = useCallback(() => {
   //    console.log('Fetch etapes')
   //    fetch('/catalogue/etapes.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch etapes')
   //       })
   //       .then(data => {
   //          setEtapes(data)
   //          console.log('Fetch etapes OK')
   //       })
   // }, [setEtapes])

   // const fetchGenres = useCallback(() => {
   //    console.log('Fetch genres')
   //    fetch('/catalogue/genres.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch genres')
   //       })
   //       .then(data => {
   //          setGenres(data)
   //          console.log('Fetch genres OK')
   //       })
   // }, [setGenres])

   /**
    * Construit un SKU à partir du model, couleur, taille avec couleur et taille qui peuvent être null
    */
   const makeSKU = useCallback((model, color, size) => {
      console.log('makeSKU', model, color, size, [model, color, size].filter(item => (item && item !== '-')).join('-'));
      return [model, color, size].filter(item => (item && item !== '-')).join('-')  // filter pour virer les valeur vides/null/undefined
   }, [])

   /**
    * Déstructure un SKU à en model, couleur, taille avec couleur et taille qui peuvent être null
    */
   const splitSKU = useCallback((sku) => {
      const parts = sku?.split('-')

      console.log('>>', sku, parts);
      if (parts) {
         return { model: parts[0], color: parts[1] ?? null, size: parts[2] ?? null }
      } else {
         return null
      }
   }, [])

   const gammeSort = useCallback((a, b) => {
      const specialGammes = {
         // Astuce pour classer avant classement ordre alpha si pas trouvé
         JOI: " A",
         ACC: " B",
         EMO: " C",
         HJO: " D"
      }
      let valA, valB

      valA = specialGammes[a.id] ?? String(a.id)
      valB = specialGammes[b.id] ?? String(b.id)
      // console.log('COMP', valA, typeof valA, valB, typeof valB, valA - valB, valA.localeCompare(valB))
      return valA.localeCompare(valB)
   }, [])

   const sortOnLabel = useCallback((a, b) => {
      return a.label.localeCompare(b.label)
   }, [])

   const sortOnIdString = useCallback((a, b) => {
      return a.id.localeCompare(b.id)
   }, [])

   // const sortOnIdInt = useCallback((a, b) => {
   //    return a.id - b.id
   // }, [])

   /**
    * Extrait un champ de texte dans la langue demandée
    * obj = l'objet
    * field = le nom du champ de cet objet qui contient un objet {fr:"..."", en:"..."}
    */
   const getTextInLang = useCallback((obj, field, lang) => {
      const obj_field = obj[field]
      let text

      if (!obj_field) {
         text = "?1"
      } else {
         if (typeof obj_field === "object") {
            if (!lang) {
               text = obj_field[curLang] || "?2"
            } else {
               text = obj_field[lang] || obj_field[defLang] || "?3"
            }
         } else {
            text = "?4"
         }
      }
      return text
   }, [curLang, defLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "HJO": {
    *       id: "HJO",
    *       order: 1,
    *       label: "Haute-Joaillerie"
    *    },
    *    ...
    * }
    */
   const getGammes = useCallback((lang = null) => {
      return Object.keys(collections).reduce((prev, id) => {
         const a_gamme = collections[id]
         let label = getTextInLang(a_gamme, "label", lang)

         return {
            ...prev, [id]: { id, order: a_gamme.order, label }
         }
      }, {})
   }, [collections, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "HJO",
    *       order: 1,
    *       label: "Haute-Joaillerie"
    *    },
    *    ...
    * ]
    */
   const getGammesAsArray = useCallback((lang = null) => {
      const obj = getGammes(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getGammes])

   // Idem, trié par la fonction spéciale aux gammes : gammeSort
   const getGammesAsSortedArray = useCallback((lang = null) => {
      return getGammesAsArray(lang).sort(gammeSort)
   }, [getGammesAsArray, gammeSort])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "HJO",
    *    order: 1,
    *    label: "Haute-Joaillerie"
    * }
    */
   const getGammeById = useCallback((id, lang = null) => {
      // On doit passer par un getGammesAsArray() car les gammes sont issues des collections !!!
      const arr = getGammesAsArray(lang).filter(gamme => {
         // console.log(`>> ${collection.id} vs ${id}`);
         // console.log(collection)
         return gamme.id === id
      })
      if (arr.length === 1) {
         return { ...arr[0] }
      } else {
         console.error(`getGammeById(${id}) - Pas trouvé !`);
         return {}
      }
   }, [getGammesAsArray])

   /**
    * Retourne toutes les collections.
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "000021": {
    *       id: "000021",
    *       label: "GATSBY"
    *    },
    *    ...
    * }
    * ...
    */
   const getCollections = useCallback((lang = null) => {
      const gammes = getGammes()

      // Parcours des gammes et récup des objets collections de chaque gamme
      const colls = Object.keys(gammes).reduce((prev, key) => {
         return { ...prev, ...(collections[key].collections) }
      }, {})

      // Reconstruction des objets en ne gardant que le label dans la langue ciblée
      return Object.keys(colls).reduce((prev, id) => {
         const a_coll = colls[id]
         const label = getTextInLang(a_coll, "label", lang)

         return { ...prev, [id]: { id, label } }
      }, {})
   }, [collections, getGammes, getTextInLang])

   /**
   * Exemple de retour (label dans la langue par défaut) :
   * [
   *    {
   *       id: "000021",
   *       label: "GATSBY"
   *    },
   *    ...
   * ]
   */
   const getCollectionsAsArray = useCallback((lang = null) => {
      const obj = getCollections(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getCollections])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getCollectionsAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getCollectionsAsArray(lang).sort(cmpFunc)
   }, [getCollectionsAsArray, sortOnIdString, sortOnLabel])

   /**
    * Récupère les collections d'une ou plusieurs gammes.
    * gammes : string | string[]. Un tableau de string permet de cibler plusieurs gammes en même temps. Revoie des valeurs uniques
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "000021": {
    *       id: "000021",
    *       label: "GATSBY"
    *    },
    *    ...
    * }
    */
   const getCollectionsInGammes = useCallback((/* string || string[] : */gammes, lang = null) => {
      let arr_gammes

      if (Array.isArray(gammes)) {
         arr_gammes = gammes
      } else {
         arr_gammes = [gammes]
      }

      const ret = arr_gammes.reduce((prev, gamme) => {
         if (typeof collections[gamme] !== "object") {
            console.error(`getCollectionsInGammes(${gamme}, ${lang}) - Pas un objet !`);
            return { ...prev }
         }
         const colls = collections[gamme].collections
         if (typeof colls !== "object") {
            console.error(`getCollectionsInGammes(${gamme}, ${lang}) - collections: Pas un objet !`);
            return { ...prev }
         }
         // console.log('OBJ_KEYS', Object.keys(colls))
         return {
            ...prev,
            ...Object.keys(colls).reduce((prev, id) => {
               const a_coll = colls[id]
               let label = getTextInLang(a_coll, "label", lang)

               return {
                  ...prev, [id]: { id, label }
               }
            }, {})
         }
      }, {})
      return ret
   }, [collections, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "HJO",
    *    order: 1,
    *    label: "Haute-Joaillerie"
    * }
    */
   const getGammeForCollectionId = useCallback((id, lang = null) => {

      return getGammesAsArray().find((gamme) => {
         return (getCollectionsInGammes(gamme.id)[id] !== undefined)
      })
   }, [getCollectionsInGammes, getGammesAsArray])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "000021": 
    *       label: "GATSBY"
    *    },
    *    ...
    * ]
    */
   const getCollectionsInGammesAsArray = useCallback((/* string || string[] : */gammes, lang = null) => {
      const obj = getCollectionsInGammes(gammes, lang)
      return Object.keys(obj).map((id) => {
         return { id, ...obj[id] }
      })
   }, [getCollectionsInGammes])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getCollectionsInGammesAsSortedArray = useCallback((/* string || string[] : */gammes, field = "id", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getCollectionsInGammesAsArray(gammes, lang).sort(cmpFunc)
   }, [getCollectionsInGammesAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "000021",
    *    label: "GATSBY"
    * }
    */
   const getCollectionById = useCallback((id, lang = null) => {
      // const all = getGammesAsArray(lang).reduce((prev, gamme) => {
      //    // console.log(">>Gamme ", gamme)
      //    // console.log(">>suite ", getCollectionsInGammesAsArray(gamme.id))
      //    return [...prev, ...getCollectionsInGammesAsArray(gamme.id)]
      // }, [])
      const all = getCollectionsInGammesAsArray(Object.keys(getGammes()))
      // console.log('ALL', all);
      const arr = all.filter(collection => {
         // console.log(`>> ${collection.id} vs ${id}`);
         // console.log(collection)
         return collection.id === id
      })
      if (arr.length === 1) {
         return { ...arr[0] }
      } else {
         console.error(`getCollectionById(${id}) - Pas trouvé !`);
         return {}
      }
   }, [getCollectionsInGammesAsArray, getGammes])

   const destructModel = useCallback((model, id, lang = null) => {
      let desig = getTextInLang(model, "desig", lang)
      let label = getTextInLang(model, "label", lang)
      let argu = getTextInLang(model, "argu", lang)

      return { ...model, id, desig, label, argu }
   }, [getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "04186": {
    *       id: "04186",
    *       label: "Gatsby Meli Melo large R"
    *       ...
    *    },
    *    ...
    * }
    */
   const getAllModels = useCallback((lang = null) => {
      return Object.keys(models).reduce((prev, id) => {
         const a_model = models[id]

         return { ...prev, [id]: destructModel(a_model, id, lang) }
      }, {})
   }, [models, destructModel])
   // const _getAllModels = useMemo(() => {
   //    return Object.keys(models).reduce((prev, id) => {
   //       const a_model = models[id]

   //       return { ...prev, [id]: destructModel(a_model, id, null) }
   //    }, {})
   // }, [models, destructModel])

   // const getAllModels = useCallback(() => _getAllModels, [_getAllModels])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "04186",
    *       label: "Gatsby Meli Melo large R"
    *       ...
    *    },
    *    ...
    * ]
    */
   const getAllModelsAsArray = useCallback((lang = null) => {
      const obj = getAllModels(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getAllModels])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, design, argu, id]
   const getAllModelsAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else if (field === 'design') { // PAS TESTE
         cmpFunc = (a, b) => a.design?.localeCompare(b.design)
      } else if (field === 'argu') { // PAS TESTE
         cmpFunc = (a, b) => a.argu?.localeCompare(b.argu)
      } else {
         cmpFunc = sortOnLabel
      }
      return getAllModelsAsArray(lang).sort(cmpFunc)
   }, [getAllModelsAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "04186": {
    *       id: "04186",
    *       label: "Gatsby Meli Melo large R"
    *       ...
    *    },
    *    ...
    * }
    */
   const getModelsInCollection = useCallback((collection, lang = null) => {
      return Object.keys(models).reduce((prev, id) => {
         const a_model = models[id]

         if (a_model.collec === collection) {
            // let desig = getTextInLang(a_model, "desig", lang)
            // let label = getTextInLang(a_model, "label", lang)

            // return { ...prev, [id]: { ...a_model, id, desig, label } }
            return { ...prev, [id]: destructModel(a_model, id, lang) }
         } else {
            return prev
         }
      }, {})
   }, [models, destructModel])

   /**
    * Exemple de retour (desig et label dans la langue par défaut) :
    * [
    *    {
    *       id: "04186",
    *       label: "Gatsby Meli Melo large R"
    *       ...
    *    },
    *    ...
    * ]
    */
   const getModelsInCollectionAsArray = useCallback((collection, lang = null) => {
      const obj = getModelsInCollection(collection, lang)
      return Object.keys(obj).map((id) => {
         return { ...obj[id] }
      })
   }, [getModelsInCollection])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getModelsInCollectionAsSortedArray = useCallback((collection, field = "id", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getModelsInCollectionAsArray(collection, lang).sort(cmpFunc)
   }, [getModelsInCollectionAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "04186",
    *    label: "Gatsby Meli Melo large R"
    *    ...
    * }
    * ou null si pas trouvé
    */
   const getModelById = useCallback((id, lang = null) => {
      const a_model = models[id]
      if (a_model) {
         // let desig = getTextInLang(a_model, "desig", lang)
         // let label = getTextInLang(a_model, "label", lang)

         // return { ...a_model, id, desig, label }
         return destructModel(a_model, id, lang)
      } else {
         return null
      }
   }, [destructModel, models])

   const isHJOModel = useCallback((id) => {
      const a_model = models[id]
      // console.log(id, a_model)
      return (a_model?.gamme === HJO)
   }, [models])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "01": {
    *       id: "01",
    *       label: "Bague"
    *    },
    *    ...
    * }
    */

   const getFamilles = useCallback((lang = null) => {
      return Object.keys(familles).reduce((prev, id) => {
         const a_famille = familles[id]
         let label = getTextInLang(a_famille, "label", lang)

         return {
            ...prev, [id]: { id, label }
         }
      }, {})
   }, [familles, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "101001",
    *       label: "Broche"
    *    },
    *    ...
    * ]
    */
   const getFamillesAsArray = useCallback((lang = null) => {
      const obj = getFamilles(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getFamilles])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getFamillesAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getFamillesAsArray(lang).sort(cmpFunc)
   }, [getFamillesAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "101001",
    *    label: "Broche"
    *    ...
    * }
    */
   const getFamilleById = useCallback((id, lang = null) => {
      const a_famille = familles[id]
      if (a_famille) {
         let label = getTextInLang(a_famille, "label", lang)

         // console.log(`getPorteById(${id})`, { ...a_porte, id, label })
         return { ...a_famille, label }
      } else {
         return []
      }
   }, [familles, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "101001": {
    *       id: "101001",
    *       label: "Broche"
    *    },
    *    ...
    * }
    */
   const getPortes = useCallback((lang = null) => {
      return Object.keys(portes).reduce((prev, id) => {
         const a_porte = portes[id]
         let label = getTextInLang(a_porte, "label", lang)

         return {
            ...prev, [id]: { id, label }
         }
      }, {})
   }, [portes, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "101001",
    *       label: "Broche"
    *    },
    *    ...
    * ]
    */
   const getPortesAsArray = useCallback((lang = null) => {
      const obj = getPortes(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getPortes])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getPortesAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getPortesAsArray(lang).sort(cmpFunc)
   }, [getPortesAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "101001",
    *    label: "Broche"
    *    ...
    * }
    */
   const getPorteById = useCallback((id, lang = null) => {
      const a_porte = portes[id]
      if (a_porte) {
         let label = getTextInLang(a_porte, "label", lang)

         // console.log(`getPorteById(${id})`, { ...a_porte, id, label })
         return { ...a_porte, label }
      } else {
         return []
      }
   }, [portes, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "A": {
    *       id: "A",
    *       label: "Best seller"
    *    },
    *    ...
    * }
    */

   const getCycles = useCallback((lang = null) => {
      return Object.keys(cycles).reduce((prev, id) => {
         const a_cycle = cycles[id]
         let label = getTextInLang(a_cycle, "label", lang)

         return {
            ...prev, [id]: { id, label }
         }
      }, {})
   }, [cycles, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "A",
    *       label: "Best seller"
    *    },
    *    ...
    * ]
    */
   const getCyclesAsArray = useCallback((lang = null) => {
      const obj = getCycles(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getCycles])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getCyclesAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getCyclesAsArray(lang).sort(cmpFunc)
   }, [getCyclesAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "A",
    *    label: "Best seller"
    *    ...
    * }
    */
   const getCycleById = useCallback((id, lang = null) => {
      const a_cycle = cycles[id]
      if (a_cycle) {
         let label = getTextInLang(a_cycle, "label", lang)

         // console.log(`getCycleById(${id})`, { ...a_cycle, id, label })
         return { ...a_cycle, label }
      } else {
         return []
      }
   }, [cycles, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "F": {
    *       id: "F",
    *       label: "Modèle validé"
    *    },
    *    ...
    * }
    */

   const getEtapes = useCallback((lang = null) => {
      return Object.keys(etapes).reduce((prev, id) => {
         const a_etape = etapes[id]
         let label = getTextInLang(a_etape, "label", lang)

         return {
            ...prev, [id]: { id, label }
         }
      }, {})
   }, [etapes, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "F",
    *       label: "Modèle validé"
    *    },
    *    ...
    * ]
    */
   const getEtapesAsArray = useCallback((lang = null) => {
      const obj = getEtapes(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getEtapes])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getEtapesAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getEtapesAsArray(lang).sort(cmpFunc)
   }, [getEtapesAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "F",
    *    label: "Modèle validé"
    *    ...
    * }
    */
   const getEtapeById = useCallback((id, lang = null) => {
      const a_etape = etapes[id]
      if (a_etape) {
         let label = getTextInLang(a_etape, "label", lang)

         // console.log(`getCycleById(${id})`, { ...a_cycle, id, label })
         return { ...a_etape, label }
      } else {
         return []
      }
   }, [etapes, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "FEMME": {
    *       id: "FEMME",
    *       label: "Femme"
    *    },
    *    ...
    * }
    */

   const getGenres = useCallback((lang = null) => {
      return Object.keys(genres).reduce((prev, id) => {
         const a_genre = genres[id]
         let label = getTextInLang(a_genre, "label", lang)

         return {
            ...prev, [id]: { id, label }
         }
      }, {})
   }, [genres, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "FEMME",
    *       label: "Femme"
    *    },
    *    ...
    * ]
    */
   const getGenresAsArray = useCallback((lang = null) => {
      const obj = getGenres(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getGenres])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getGenresAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getGenresAsArray(lang).sort(cmpFunc)
   }, [getGenresAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "F",
    *    label: "Modèle validé"
    *    ...
    * }
    */
   const getGenreById = useCallback((id, lang = null) => {
      const a_genre = genres[id]
      if (a_genre) {
         let label = getTextInLang(a_genre, "label", lang)

         // console.log(`getCycleById(${id})`, { ...a_cycle, id, label })
         return { ...a_genre, label }
      } else {
         return []
      }
   }, [genres, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    "YG": {
    *       "label": {
    *          "fr": "Or jaune",
    *          "en": "Yellow gold"
    *       }
    *    },
    *    ...
    * }
    */

   const getCouleurs = useCallback((lang = null) => {
      return Object.keys(couleurs).reduce((prev, id) => {
         const a_couleur = couleurs[id]
         let label = getTextInLang(a_couleur, "label", lang)

         return {
            ...prev, [id]: { id, label }
         }
      }, {})
   }, [couleurs, getTextInLang])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * [
    *    {
    *       id: "YG",
    *       label: "Or jaune"
    *    },
    *    ...
    * ]
    */
   const getCouleursAsArray = useCallback((lang = null) => {
      const obj = getCouleurs(lang)
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getCouleurs])

   // Idem, trié sur le champ dont le nom est passé en 1er paramètre. Possibilités : [label, id]
   const getCouleursAsSortedArray = useCallback((field = "label", lang = null) => {
      let cmpFunc

      if (field === 'id') {
         cmpFunc = sortOnIdString
      } else {
         cmpFunc = sortOnLabel
      }
      return getCouleursAsArray(lang).sort(cmpFunc)
   }, [getCouleursAsArray, sortOnIdString, sortOnLabel])

   /**
    * Exemple de retour (label dans la langue par défaut) :
    * {
    *    id: "YG",
    *    label: "Or jaune"
    *    ...
    * }
    */

   const getCouleurById = useCallback((id, lang = null) => {
      const a_couleur = couleurs[id]
      if (a_couleur) {
         let label = getTextInLang(a_couleur, "label", lang)

         return { ...a_couleur, label }
      } else {
         return []
      }
   }, [couleurs, getTextInLang])

   return {
      // fetch_names,
      // fetchCollections,
      // fetchCouleurs,
      // fetchCycles,
      // fetchEtapes,
      // fetchFamilles,
      // fetchGenres,
      // fetchPortes,
      // fetchModels,
      // fetchList,
      makeSKU,
      splitSKU,
      gammeSort,
      getGammes,
      getGammesAsArray,
      getGammesAsSortedArray,
      getGammeById,
      getGammeForCollectionId,
      getCollections,
      getCollectionsAsArray,
      getCollectionsAsSortedArray,
      getCollectionsInGammes,
      getCollectionsInGammesAsArray,
      getCollectionsInGammesAsSortedArray,
      getAllModels,
      getAllModelsAsArray,
      getAllModelsAsSortedArray,
      getModelsInCollection,
      getModelsInCollectionAsArray,
      getModelsInCollectionAsSortedArray,
      getCollectionById,
      getModelById,
      isHJOModel,
      getFamilles,
      getFamillesAsArray,
      getFamillesAsSortedArray,
      getFamilleById,
      getPortes,
      getPortesAsArray,
      getPortesAsSortedArray,
      getPorteById,
      getCycles,
      getCyclesAsArray,
      getCyclesAsSortedArray,
      getCycleById,
      getEtapes,
      getEtapesAsArray,
      getEtapesAsSortedArray,
      getEtapeById,
      getGenres,
      getGenresAsArray,
      getGenresAsSortedArray,
      getGenreById,
      getCouleurs,
      getCouleursAsArray,
      getCouleursAsSortedArray,
      getCouleurById
   }
}

export default useCollections