import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { v4 as uuidv4 } from 'uuid';
import bcrypt from 'bcryptjs'

let _pwaApiEndpoint, _appWriteEndpoint, _appWriteProjectId, _appWriteDatabaseId, _appWriteCollCustomersId, _appWriteCollBiOrdersId, _appWriteCollOrdersId, _appWriteCollPDFId, _appWriteCollUdidId, _appWriteCollListsId, _appWriteCollStockId, _appWriteCollOtpId, _appWriteCollTarifsId, _appWriteCollVentesRALId, _appWriteCollConfiesId, _appWriteCollConfiesRALId

// Compile in production environment only
// https://github.com/KeJunMao/vite-plugin-conditional-compile
// #v-ifdef VITE_MODE=prod
_appWriteEndpoint = "https://rbv3-api.messikagroup.com/v1"
_pwaApiEndpoint = "https://rbv3-webhooks.messikagroup.com"
_appWriteProjectId = "ome"
_appWriteDatabaseId = "production"
_appWriteCollCustomersId = "customers"
_appWriteCollBiOrdersId = "orders_bi"
_appWriteCollOrdersId = "orders"
_appWriteCollPDFId = "pdf"
_appWriteCollUdidId = "udids"
_appWriteCollListsId = "lists"
_appWriteCollStockId = "stocks"
_appWriteCollOtpId = "otp"
_appWriteCollTarifsId = "tarifs"
_appWriteCollVentesRALId = "ventes_ral"
_appWriteCollConfiesId = "confies"
_appWriteCollConfiesRALId = "confies_ral"
// #v-endif
// #v-ifdef VITE_MODE=beta
_appWriteEndpoint = "https://rbv3-dev-api.messikagroup.com/v1"
_pwaApiEndpoint = "https://rbv3-dev-webhooks.messikagroup.com"
_appWriteProjectId = "ome"
_appWriteDatabaseId = "production"
_appWriteCollCustomersId = "customers"
_appWriteCollBiOrdersId = "orders_bi"
_appWriteCollOrdersId = "orders"
_appWriteCollPDFId = "pdf"
_appWriteCollUdidId = "udids"
_appWriteCollListsId = "lists"
_appWriteCollStockId = "stocks"
_appWriteCollOtpId = "otp"
_appWriteCollTarifsId = "tarifs"
_appWriteCollVentesRALId = "ventes_ral"
_appWriteCollConfiesId = "confies"
_appWriteCollConfiesRALId = "confies_ral"
// #v-endif
// #v-ifdef VITE_MODE=dev
_appWriteEndpoint = "http://localhost/v1"
_pwaApiEndpoint = "http://localhost:3300"
_appWriteProjectId = "ome"
_appWriteDatabaseId = "production"
_appWriteCollCustomersId = "customers"
_appWriteCollBiOrdersId = "orders_bi"
_appWriteCollOrdersId = "orders"
_appWriteCollPDFId = "pdf"
_appWriteCollUdidId = "udids"
_appWriteCollListsId = "lists"
_appWriteCollStockId = "stocks"
_appWriteCollOtpId = "otp"
_appWriteCollTarifsId = "tarifs"
_appWriteCollVentesRALId = "ventes_ral"
_appWriteCollConfiesId = "confies"
_appWriteCollConfiesRALId = "confies_ral"
// _appWriteProjectId = "644ff20fd4f45a14ed27"
// _appWriteDatabaseId = "644ff28ea6c7b48f05e5"
// _appWriteCollCustomersId = "64516f930d0fe5b14ae7"
// _appWriteCollBiOrdersId = "647f0e33e27154620eb8"
// _appWriteCollOrdersId = "64b49518aed39efabfaf"
// _appWriteCollPDFId = "649789413cabb9b2c0d9"
// _appWriteCollUdidId = "649e0af54adcdbae5007"
// _appWriteCollListsId = "649ef43ece51e4579328"
// _appWriteCollStockId = "649868218df758e91bf7"
// _appWriteCollOtpId = "otp"
// _appWriteCollTarifsId = "649cc8cc388ac8c022de"
// _appWriteCollVentesRALId = "ventes_ral"
// _appWriteCollConfiesId = "confies"
// _appWriteCollConfiesRALId = "confies_ral"
// #v-endif

const initialState = {
   udid: uuidv4(),
   // appWriteEndpoint: "http://kikiserver.quiniou.eu:8080/v1",
   // pwaApiEndpoint: "http://localhost:3300",

   pwaApiEndpoint: _pwaApiEndpoint,
   appWriteEndpoint: _appWriteEndpoint,
   pwaApiKey: "fe33c7c5787e7db6fc3b08a874061ff935addfd2d5ebd806806eb41963308e63c58ed62c12e1163e0eb7eb02fbdf1b1d7fe19ee7f13dfb6536c4f55be8691c71",
   appWriteProjectId: _appWriteProjectId,
   appWriteDatabaseId: _appWriteDatabaseId,
   appWriteCollCustomersId: _appWriteCollCustomersId,
   appWriteCollBiOrdersId: _appWriteCollBiOrdersId,
   appWriteCollOrdersId: _appWriteCollOrdersId,
   appWriteCollPDFId: _appWriteCollPDFId,
   appWriteCollUdidId: _appWriteCollUdidId,
   appWriteCollListsId: _appWriteCollListsId,
   appWriteCollStockId: _appWriteCollStockId,
   appWriteCollOtpId: _appWriteCollOtpId,
   appWriteCollTarifsId: _appWriteCollTarifsId,
   appWriteCollVentesRALId: _appWriteCollVentesRALId,
   appWriteCollConfiesId: _appWriteCollConfiesId,
   appWriteCollConfiesRALId: _appWriteCollConfiesRALId,
   isLogged: false,
   sessionDuration: 3600,           // 1H
   curTimeout: null,                // Heure de timeout
   rememberMe: false,
   login: "",
   bcryptSalt: bcrypt.genSaltSync(),
   hashedLastLogin: null,           // Mémorisation du hash du dernier login utilisé avec succès. Permet de purger les clients et commandes (BI) et de refetcher de zéro, sinon il manquera des infos à cause du last_updated
   hashedPassword: null,            // Mémorisation du hash password. Permet de se logguer ensuite en mode offline
   localOrderId: "1",               // ID local de la prochaine commande (préfixé des 4 prem chiffres de UDID)
   catalogMainAlign: "left",        // Vignettes du catalogue à gauche (left) ou centrées (center)
   globalClosePopups: "button",     // "button" = uniquement la croix
   currentLang: "fr",
   defaultLang: "fr",
   showWeeksNum: true,              // Affichage des numéros des semaine dans le date picker
   qtyMaxOrder: 999,                // Qté maxi pour une ligne de commande
   maxTarifsOffline: 6,             // Nb maxi de tarifs préchargeables pour le mode Offline
   curTarifRegionName: null,        // Nom du tarif (région) courant
   missingTarifRegionName: null,    // Nom du tarif (région) choisi mais absent. Doit être chargé.
   curTarifRegion: null,            // Tarif (région) courant (nom et devise CES/PPU en HJO/Autres)
   curTarifCustomer: null,          // Tarif (client) courant (nom et devise CES/PPU en HJO/Autres)
   curCustomer: null,               // Client actif
   curOrder: null,                  // Commande active
   debug: false,                    // Debug (affichage des erreurs)
   minFilterLength: 3,              // Longueur de texte mini pour lancer un filtrage
   noScrollbars: false,             // Toujours masquer les ascenseurs
   imgSourcePrio: 'bymessika',      // Priorité de la source des images (internal | bymessika)
   timeFormat: '24',                // Format heure : [12, 24]
   dateFormat: 'dmy',               // Format date : [dmy, ymd, mdy, ydm]
   listPageLength: "10",            // Taille des listes (commandes, clients) en nb de lignes / page
   maxPageButtonsOnLists: 5,        // Nb maxi de boutons de pages en pied des listes
   showImageSource: false,          // Affichage puce grise pour byMessika ?
   formatPhone: true,               // Formatage des numéros de téléphone au format International ?
   searchModeInOrder: null,         // Mode de recherche depuis un Bdc : dans bdc, dans catalog, créa manuelle avec un SKU
   searchModeInCustomer: null,      // Mode de recherche depuis un détail client : autre client, une commande (dans tab "commande")
   recentCustomers: [],             // Les derniers clients consultés/travaillés dans l'appli
   recentOrders: [],                // Les dernières commandes consultées/travaillées dans l'appli
   maxRecentItems: 5,               // Nb maxi de "derniers items" (commandes, clients...)
   showCess: true,                  // Affichage des prix de Cession ?
   showCessDelay: 2000,             // Delai d'appui pour rétablir l'affichage des prix de Cession
   keepHistoryOpen: true,           // Garder historique ouvert même en changeant de page (1 seul à la fois)
   appVerForLastCachePurge: null,   // Version de l'appli pour laquelle on a purgé le cache la dernière fois
}

const prefStore = (set) => ({
   ...initialState,
   fix: (val) =>
      set((state) => {
         console.log('>>>', val, { ...val });
         return {
            ...val
         }
      }),
   initialState: () => {      // Pour réinitialiser l'état par défaut
      return {
         ...initialState    // On conserve si possible le UDID
      }
   },
   reset: () =>       // Pour réinitialiser l'état par défaut
      set((state) => ({
         ...initialState,
         udid: state.udid ?? uuidv4(),                            // On conserve si possible le UDID
         rememberMe: state.rememberMe ?? true,                    // le rememberMe
         bcryptSalt: state.bcryptSalt ?? bcrypt.genSaltSync(),    // la clé de hashage
         pwaApiEndpoint: state.pwaApiEndpoint,                    // les endPoints
         appWriteEndpoint: state.appWriteEndpoint,
      })),
   recover: (new_state) =>       // Pour réinitialiser l'état par injection depuis le serveur
      set((state) => ({
         ...initialState, ...new_state      // On conserve si possible le UDID
      })),
   setIsLogged: (val) =>
      set((state) => ({
         isLogged: val,
      })),
   setSessionDuration: (val) =>
      set((state) => ({
         sessionDuration: val,
      })),
   setCurTimeout: (val) =>
      set((state) => ({
         curTimeout: val,
      })),
   setRememberMe: (val) =>
      set((state) => ({
         rememberMe: val,
      })),
   setPwaApiEndpoint: (val) =>
      set((state) => ({
         pwaApiEndpoint: val,
      })),
   setAppWriteEndpoint: (val) =>
      set((state) => ({
         appWriteEndpoint: val,
      })),
   setAppWriteDatabaseId: (val) =>
      set((state) => ({
         appWriteDatabaseId: val,
      })),
   setAppWriteProjectId: (val) =>
      set((state) => ({
         appWriteProjectId: val,
      })),
   setLogin: (val) =>
      set((state) => ({
         login: val,
      })),
   setHashedPassword: (val) =>
      set((state) => ({
         hashedPassword: val,
      })),
   setHashedLastLogin: (val) =>
      set((state) => ({
         hashedLastLogin: val,
      })),
   setLocalOrderId: (val) =>
      set((state) => ({
         localOrderId: String(val),
      })),
   setCatalogMainAlign: (val) =>
      set((state) => ({
         catalogMainAlign: val,
      })),
   setGlobalClosePopups: (val) =>
      set((state) => ({
         globalClosePopups: val,
      })),
   setDefaultLang: (val) =>
      set((state) => ({
         defaultLang: val,
      })),
   setQtyMaxOrder: (val) =>
      set((state) => ({
         qtyMaxOrder: Number(val),
      })),
   setMaxTarifsOffline: (val) =>
      set((state) => ({
         maxTarifsOffline: Number(val),
      })),
   setShowWeeksNum: (val) =>
      set((state) => ({
         showWeeksNum: val,
      })),
   setCurrentLang: (val) => {
      return set((state) => ({
         currentLang: val,
      }))
   },
   setMissingTarifRegionName: (val) =>
      set((state) => ({
         missingTarifRegionName: val,
      })),
   setCurTarifRegionName: (val) =>
      set((state) => ({
         curTarifRegionName: val,
      })),
   setCurTarifRegion: (val) =>
      set((state) => ({
         curTarifRegion: val,
      })),
   setCurTarifCustomer: (val) =>
      set((state) => ({
         curTarifCustomer: val,
      })),
   setCurCustomer: (val) =>
      set((state) => ({
         curCustomer: val,
      })),
   setCurOrder: (val) =>
      set((state) => ({
         curOrder: val,
      })),
   setDebug: (val) =>
      set((state) => ({
         debug: val,
      })),
   // setLogServerUrl: (val) =>
   //    set((state) => ({
   //       logServerUrl: val,
   //    })),
   // setLogServerPort: (val) =>
   //    set((state) => ({
   //       logServerPort: val,
   //    })),
   // setLogToServer: (val) =>
   //    set((state) => ({
   //       logToServer: val,
   //    })),
   // setLogToConsole: (val) =>
   //    set((state) => ({
   //       logToConsole: val,
   //    })),
   setMinFilterLength: (val) => {
      const len = Number(val)

      return set((state) => ({
         minFilterLength: (len <= 0) ? 2 : len,
      }))
   },
   setNoScrollbars: (val) =>
      set((state) => ({
         noScrollbars: val,
      })),
   setImgSourcePrio: (val) =>
      set((state) => ({
         imgSourcePrio: val,
      })),
   setTimeFormat: (val) =>
      set((state) => ({
         timeFormat: val,
      })),
   setDateFormat: (val) =>
      set((state) => ({
         dateFormat: val,
      })),
   setListPageLength: (val) => {
      const len = Number(val)

      return set((state) => ({
         listPageLength: (len <= 0) ? 10 : len,
      }))
   },
   setMaxPageButtonsOnLists: (val) => {
      const num = Number(val)

      return set((state) => ({
         maxPageButtonsOnLists: (num <= 0) ? 5 : num,
      }))
   },
   setShowImageSource: (val) =>
      set((state) => ({
         showImageSource: val,
      })),
   setFormatPhone: (val) =>
      set((state) => ({
         formatPhone: val,
      })),
   setSearchModeInOrder: (val) =>
      set((state) => ({
         searchModeInOrder: val,
      })),
   setSearchModeInCustomer: (val) =>
      set((state) => ({
         searchModeInCustomer: val,
      })),
   setRecentCustomers: (val) =>
      set((state) => ({
         recentCustomers: val ?? [],      // Pour éviter de stocker des erreurs
      })),
   setRecentOrders: (val) => {
      console.log('setRecentOrders', val);
      set((state) => ({
         recentOrders: val ?? [],      // Pour éviter de stocker des erreurs
      }))
   },
   setMaxRecentItems: (val) => {
      const num = Number(val)

      return set((state) => ({
         maxRecentItems: (num <= 0) ? 5 : num,
      }))
   },
   setShowCess: (val) =>
      set((state) => ({
         showCess: val,
      })),
   setShowCessDelay: (val) =>
      set((state) => ({
         showCessDelay: Number(val),
      })),
   setKeepHistoryOpen: (val) =>
      set((state) => ({
         keepHistoryOpen: val,
      })),
   setAppVerForLastCachePurge: (val) =>
      set((state) => ({
         appVerForLastCachePurge: val,
      })),
})

const usePrefStore = create(devtools(persist(prefStore, { name: "prefs" })))

// console.log('usePrefStore', prefStore);

export default usePrefStore