import { useState, createContext, useContext, useEffect } from "react";
import usePrefStore from "../stores/prefs"
import useTarifs from '../Hooks/useTarifs';
// import Confirm from "../components/Confirm";
// import { Checkbox, States as CheckboxStates } from '../components/Checkbox';
// import useTranslation from '../Hooks/useTranslation';
// import useSales from "../Hooks/useSales";
import { registerSW } from 'virtual:pwa-register'
import glob_const from "../libs/glob_const";

const theContext = createContext()

const GlobalContext = (() => useContext(theContext))

const GlobalContextProvider = ({ children }) => {
   const [isDarkLayerVisible, setIsDarkLayerVisible] = useState(false)
   const [isSyncLayerVisible, setIsSyncLayerVisible] = useState(false)
   const [isOtpLayerVisible, setIsOtpLayerVisible] = useState(false)
   const [isMissingTarifLayerVisible, setIsMissingTarifLayerVisible] = useState(false)
   const [isListRecentCustomersVisible, setIsListRecentCustomersVisible] = useState(false)
   const [isListRecentOrdersVisible, setIsListRecentOrdersVisible] = useState(false)
   const [isWebSocketConnected, setIsWebSocketConnected] = useState(false)
   const [keepSearchBarAccessible, setKeepSearchBarAccessible] = useState(false)
   const [curTarif, setCurTarif] = useState(null)
   // const [showConfirm, setShowConfirm] = useState(false)
   // const [confirmCheckboxState, setConfirmCheckboxState] = useState(CheckboxStates.Unchecked)
   const [allLogs, setAllLogs] = useState(null)    // Si null, on ne garde pas les logs
   const [lastStackDump, setLastStackDump] = useState(null)    // Pour éviter de faire des stack dumps (vers serveur) trop fréquents
   // const [updateSW, setUpdateSW] = useState()
   //
   // const curCustomer = usePrefStore((state) => state.curCustomer);
   // const setCurCustomer = usePrefStore((state) => state.setCurCustomer);
   // const curTarifRegionName = usePrefStore((state) => state.curTarifRegionName);
   const { curTarifRegionName } = usePrefStore();
   //
   const { getTarifsForRegion } = useTarifs();
   // const { getCustomerById } = useSales();
   // const { trans } = useTranslation();

   useEffect(() => {
      setCurTarif(getTarifsForRegion(curTarifRegionName))
   }, [curTarifRegionName, getTarifsForRegion])

   useEffect(() => {
      let timer

      registerSW({
         onRegistered(r) {
            console.log('**SW++ 1 onRegistered', r);
            if (r) {
               r.update()  // Un check immédiat
               timer = setInterval(() => {
                  // console.log('**SW++ 1 update?');
                  r.update()
               }, glob_const.check_sw_update_delay)      // Un check toutes les 15min
               console.log('**SW++ 1 setInterval', timer);
            }
         },
         // onNeedRefresh() {
         //    console.log('**SW++ 1 setNeedRefresh');
         //    setNeedRefresh(true)
         // },
         onOfflineReady() {
            console.log('**SW++ 1 onOfflineReady');
         },
      })

      return () => {
         console.log('**SW++ 1 clearInterval', timer);
         clearInterval(timer)
      }
   }, [])

   return (
      <theContext.Provider value={{ context: theContext, isDarkLayerVisible, setIsDarkLayerVisible, isSyncLayerVisible, setIsSyncLayerVisible, isOtpLayerVisible, setIsOtpLayerVisible, keepSearchBarAccessible, setKeepSearchBarAccessible, isListRecentCustomersVisible, setIsListRecentCustomersVisible, isListRecentOrdersVisible, setIsListRecentOrdersVisible, curTarif, allLogs, setAllLogs, lastStackDump, setLastStackDump, isWebSocketConnected, setIsWebSocketConnected, isMissingTarifLayerVisible, setIsMissingTarifLayerVisible }}>
         {children}
         {/* {showConfirm &&
            <Confirm width={'50%'} onCancel={() => { setShowConfirm(false); alert('Cancel') }} onOK={() => { setShowConfirm(false); alert('OK') }} enableOK={confirmCheckboxState === CheckboxStates.Checked}>
               {trans('?Bla...')}
               <Checkbox caption="Refus" checked={confirmCheckboxState} onChange={(state) => { setConfirmCheckboxState(state) }} />
            </Confirm>
         } */}
      </theContext.Provider>
   )
}

export { GlobalContext, GlobalContextProvider }