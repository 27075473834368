import styles from "./OtpLayer.module.scss"
import { GlobalContext } from '../Contexts/GlobalContext';
import Button from './Button';
import useTranslation from '../Hooks/useTranslation';
// import useTarifs from "../Hooks/useTarifs";
import { AppWriteContext } from "../Contexts/AppWriteContext";
import QRCode from "react-qr-code";
// import usePrefStore from "../stores/prefs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useState } from "react";
import { ToastContainer, toast } from 'react-toastify'

// Layer semi opaque utilisé quand on affiche une popup.

function OtpLayer(props) {
   const [verifCode, setVerifCode] = useState(null);
   const { trans } = useTranslation();
   const { setIsOtpLayerVisible } = { ...GlobalContext() };
   const { otp, setOtp, verifOtp } = { ...AppWriteContext() };
   // const { login } = usePrefStore();

   async function doValidate() {
      const ret = await verifOtp(verifCode)
      if (ret) {
         setIsOtpLayerVisible(false)
         setOtp(null)
      } else {
         toast.error(trans('?Mauvais code'), {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
         });
      }
   }

   const copyToClipboard = useCallback(() => {
      navigator.clipboard.writeText(otp.secret);
   }, [otp])

   function onChange(evt) {
      setVerifCode(evt.target.value.substring(0, 6));
   }

   function doCancel() {
      setIsOtpLayerVisible(false)
   }

   // console.log(`OTP = ${otp}`)

   return (
      <div className={styles.otp_layer}>
         <div className={styles.text}>
            <span>
               {trans('?OTP_scan1')}
            </span>
            <Button primary red onClick={copyToClipboard}>
               <FontAwesomeIcon className={styles.clearfield} icon={faClipboard} />
            </Button>
         </div>
         <div className={styles.qrcode}>
            <QRCode
               size={256}
               style={{ height: "auto", maxWidth: "100%", width: "100%" }}
               //https://docs.yubico.com/yesdk/users-manual/application-oath/uri-string-format.html
               // value={
               //    `otpauth://totp/Messika:${login}?secret=${otp.secret}&issuer=Messika&digits=6&algorithm=SHA1&period=30`
               // }
               value={otp.url}
               viewBox={`0 0 256 256`}
            />
         </div>
         <span className={styles.secret}>{otp.secret}</span>
         <div className={styles.text}>
            {trans('?OTP_scan2')}
         </div>
         <div className={styles.buttons}>
            <input inputmode="numeric" pattern="[0-9]*" type="text" onChange={onChange} value={verifCode} />
            <Button primary green onClick={doValidate}>
               {trans('?Valider')}
            </Button>
            <Button primary onClick={doCancel}>
               {trans('?Annuler')}
            </Button>
         </div>
         <ToastContainer />
      </div>
   )
}

export default OtpLayer
