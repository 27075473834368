import styles from './CommentEditor.module.scss';
import useTranslation from '../Hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import Button from './Button';
import usePrefStore from '../stores/prefs';
import { useCallback, useEffect, useState } from 'react';
import useHelpers from '../Hooks/useHelpers';
// import { useCallback, useEffect, useRef, useState } from 'react';

function CommentEditor(props) {
   const { trans, transWithParams } = useTranslation();
   const { inlineStyleFromProps } = useHelpers();
   // States
   const [text, setText] = useState('')
   const [reste, setReste] = useState(null)
   const [addStyle, setAddStyle] = useState({});
   //
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups);
   const { globalClosePopups } = usePrefStore();

   useEffect(() => {
      const val = props.value ?? ''
      const style = inlineStyleFromProps(props); // On reconstruit un style avec les propriétés trouvées dans props
      setAddStyle(style);
      setText(val)
      if (props.maxLength) {
         setReste(props.maxLength - (val.length ?? 0))
      }
   }, [props, inlineStyleFromProps]);

   function doNothing(evt) {
      console.log('doNothing');
      evt.stopPropagation();
   }

   function doCancel() {
      if (props.onCancel) {
         props.onCancel()
      }
   }

   function doOK() {
      if (props.onValidate) {
         props.onValidate(text)
      }
   }

   function onSubmit() {
      doOK()
   }

   function onClickBackground() {
      console.log('onClickBackground');
      if (props.onCancel) {
         if (globalClosePopups !== 'button') {
            props.onCancel();
         }
      }
   }

   const changeText = useCallback((newText) => {
      setText(newText)
      if (props.maxLength) {
         setReste(props.maxLength - newText.length)
      }
   }, [props.maxLength])

   const onTextChange = useCallback((evt) => {
      const new_text = evt.target.value
      console.log('Check', new_text, props);
      if (!props.maxLength || (new_text.length <= props.maxLength) || (new_text.length <= text.length)) {   // (new_text.length <= text.length) permet d'autoriser la réduction d'un texte déjà trop long
         changeText(new_text)
      }
   }, [changeText, props, text.length])

   function doClean(evt) {
      evt.stopPropagation();
      changeText('')
   }

   return (
      <div className={styles.background} onClick={onClickBackground}>
         <div className={styles.comment_editor} onClick={doNothing} style={addStyle}>
            <div className={styles.top}>
               {props.icon}
               <span>{props.title}</span>
               <button onClick={doCancel}>
                  <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
            <div className={styles.detail}>
               <form onSubmit={onSubmit}>
                  {(props.lines > 1) && <textarea autoFocus rows={props.lines} value={text} onChange={onTextChange} />}
                  {(props.lines === 1) && <input autoFocus type="text" value={text} onChange={onTextChange} />}
               </form>
               {props.maxLength && <div className={styles.length}>{transWithParams("?reste x_sur_y cars", [reste, props.maxLength], reste)}</div>}
            </div>
            <div className={styles.footer}>
               <Button tertiary lefticon={<FontAwesomeIcon icon={faTrashCan} />} onClick={doClean} />
               <Button tertiary lefticon={<FontAwesomeIcon icon={faXmark} />} onClick={doCancel}>
                  {trans('?Annuler')}
               </Button>
               <Button lefticon={<FontAwesomeIcon icon={faCheck} />} onClick={doOK}>
                  {trans('?Valider')}
               </Button>
            </div>
         </div>
      </div>
   )
}

export default CommentEditor;
