import useTranslation from '../Hooks/useTranslation';
import styles from './Confirm.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCommentQuestion } from '@fortawesome/pro-regular-svg-icons';
import Button from './Button';
import { useEffect, useState } from 'react';
import useHelpers from '../Hooks/useHelpers';
// import { useCallback, useEffect, useRef, useState } from 'react';

function Confirm(props) {
   const { trans } = useTranslation();
   // States
   const [addStyle, setAddStyle] = useState({})
   //
   const { inlineStyleFromProps } = useHelpers()

   useEffect(() => {
      const style = inlineStyleFromProps(props) // On reconstruit un style avec les propriétés trouvées dans props
      setAddStyle(style)
   }, [inlineStyleFromProps, props])


   function doNothing(evt) {
      console.log('doNothing');
      evt.stopPropagation();
   }

   function doCancel() {
      if (props.onCancel) {
         props.onCancel()
      }
   }

   function doOK() {
      if (props.onOK) {
         props.onOK()
      }
   }

   return (
      <div className={styles.background}>
         <div className={styles.confirm_card} onClick={doNothing} style={addStyle}>
            <div className={styles.top}>
               <FontAwesomeIcon icon={faCommentQuestion} />
               <span>{props.title}</span>
               <button onClick={doCancel}>
                  <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
            <div className={styles.detail}>
               {props.text}
               {props.children}
            </div>
            <div className={styles.footer}>
               <Button primary lefticon={<FontAwesomeIcon icon={faXmark} />} onClick={doCancel}>
                  {trans(props.yesno ? '?Non' : '?Annuler')}
               </Button>
               <Button green disabled={props.enableOK === false} lefticon={<FontAwesomeIcon icon={faCheck} />} onClick={doOK}>
                  {trans(props.yesno ? '?Oui' : '?OK')}
               </Button>
            </div>
         </div>
      </div>
   )
}

export default Confirm;
