import { useEffect, useMemo, useState } from "react"
// import useModelsStore from "../../stores/models"
import styles from "./ListItem.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBagShopping, faMemoCircleInfo } from "@fortawesome/pro-regular-svg-icons"
import { faStars } from "@fortawesome/pro-solid-svg-icons"
import useTranslation from "../../Hooks/useTranslation"
import useHelpers from "../../Hooks/useHelpers"
import useTarifs from "../../Hooks/useTarifs"
import useCollections from "../../Hooks/useCollections"
import DynImage from "../DynImage"
import usePrefStore from "../../stores/prefs"
// import useTest from "../../Hooks/useTest"

function ListItem(props) {
   // const showCess = usePrefStore((state) => state.showCess)
   const { showCess } = usePrefStore()
   //
   // const models = useModelsStore((state) => state.models)
   const [model, setModel] = useState(null)
   const { trans } = useTranslation()
   // const { test } = useTest()
   const { formatWeightWithNC, formatDiamWeightWithNC, formatNumberWithNC, formatPPUPrice, formatCESPrice } = useHelpers()
   const { getMinMaxPriceForModel, getMinMaxCessPriceForModel, getColorsForModel } = useTarifs()
   const [minMax, setMinMax] = useState({ min: 0, max: 0 })
   const [minMaxCession, setMinMaxCession] = useState({ min: 0, max: 0 })
   const [porte, setPorte] = useState('')
   const [cycle, setCycle] = useState('')
   const [argu, setArgu] = useState(null)
   const [/*gamme*/, setGamme] = useState('')
   const [collection, setCollection] = useState('')
   const { getModelById, getPorteById, getGammeById, getCollectionById, getCycleById, isHJOModel } = useCollections()
   const [strColors, setStrColors] = useState('')
   const [modelImage, setModelImage] = useState(null)
   const [isHJO, setIsHJO] = useState(false)

   useEffect(() => {
      const a_model = getModelById(props.id)
      const ret_colors = getColorsForModel(a_model.id)

      setModel(a_model)
      setGamme(getGammeById(a_model.gamme).label)
      setCollection(getCollectionById(a_model.collec).label)
      setPorte(getPorteById(a_model.porte).label)
      setCycle(getCycleById(a_model.cycle).label)
      // console.log('+++MODEL', a_model);
      // console.log('+++MODEL2', a_model.argu);
      if (a_model.argu.includes(trans('?substr_exclu_bout'))) {
         // console.log('+++MODEL3');
         // setArgu(trans('?Exclu boutique'))
         setArgu(<FontAwesomeIcon icon={faStars} />)
      } else {
         // console.log('+++MODEL4');
         setArgu(null)
      }
      setStrColors(ret_colors?.colors?.join(", "))
      setIsHJO(isHJOModel(props.id))
   }, [getModelById, props, setPorte, getGammeById, getCollectionById, getPorteById, getColorsForModel, getCycleById, isHJOModel, trans])

   // useEffect(() => {
   //    console.log('MM** ICI2');
   //    let minmax = getMinMaxPriceForModel(model?.id)
   //    if (!minmax.reason) {   // reason contient un texte d'erreur si pb
   //       setMinMax(minmax)
   //    } else {
   //       setMinMax({ min: 0, max: 0 })
   //    }
   //    minmax = getMinMaxCessPriceForModel(model?.id)
   //    if (!minmax.reason) {   // reason contient un texte d'erreur si pb
   //       setMinMaxCession(minmax)
   //    } else {
   //       setMinMaxCession({ min: 0, max: 0 })
   //    }
   // }, [setMinMax, setMinMaxCession, getMinMaxPriceForModel, getMinMaxCessPriceForModel, model])

   const min_max = useMemo(() => {
      if (model) {
         const minmax = getMinMaxPriceForModel(model?.id)
         if (!minmax.reason) {   // reason contient un texte d'erreur si pb
            return minmax
         } else {
            return { min: 0, max: 0 }
         }
      } else {
         return { min: 0, max: 0 }
      }
   }, [getMinMaxPriceForModel, model])

   // console.log('MM** ICI');

   const min_max_cess = useMemo(() => {
      if (model) {
         const minmax = getMinMaxCessPriceForModel(model?.id)
         if (!minmax.reason) {   // reason contient un texte d'erreur si pb
            return minmax
         } else {
            return { min: 0, max: 0 }
         }
      } else {
         return { min: 0, max: 0 }
      }
   }, [getMinMaxCessPriceForModel, model])

   useEffect(() => {
      setMinMax(min_max)
      setMinMaxCession(min_max_cess)
   }, [min_max, min_max_cess])

   function showQtyPicker() {
      if (props.onShowQtyPicker) {
         props.onShowQtyPicker(props.id)
      }
   }

   function showDetail() {
      if (props.onShowDetail) {
         props.onShowDetail(props.id)
      }
   }

   useEffect(() => {
      if (model && model.id) {
         setModelImage(<DynImage className={styles.imgmodel} src={[`catalogue/modeles/${model.id}@2x.png`, model.bymessika ? model.bymessika[0] : null]} alt={`Model ${model.id}`} />)
      }
   }, [model])

   return (model &&
      <div className={styles.listitem}>
         {/* <img src={`catalogue/modeles/${model.id}@2x.png`} alt={`Model ${model.id}`} /> */}
         {modelImage}
         <div className={styles.infobox}>
            {/* <span className={styles.gamme_family}>{gamme}/{collection}</span> */}
            <span className={styles.gamme_family}>{collection}</span>
            <span className={styles.label}>
               {props.id} - {model.label || ''}
               <span className={styles.argu}>{argu}</span>
            </span>
            <span className={styles.cycle}>{cycle}</span>
            <span className={styles.subfamily}>
               {model.desig || ''}
            </span>
            <span className={styles.porte}>{porte}</span>
            <table className={styles.infotable}>
               <thead>
                  <tr>
                     <th>{trans("?Couleur")}</th>
                     <th>{trans("?Poids")}</th>
                     <th>{trans("?Poids diamants")}</th>
                     <th>{trans("?Diamants")}</th>
                     <th>{trans("?Ecrin")}</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>{strColors}</td>
                     <td>{`${formatWeightWithNC(model.pds)}`}</td>
                     <td>{`${formatDiamWeightWithNC(model.pdsdts)}`}</td>
                     <td>{`${formatNumberWithNC(model.dts)}`}</td>
                     <td>{model.ecrin}</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div className={styles.pricebox}>
            <span className={styles.price}>{formatPPUPrice(minMax.min)}</span>
            {showCess && <span className={styles.cess}>{trans("?Cess.")} : {formatCESPrice(minMaxCession.min)}</span>}
            <div className={styles.buttons}>
               <button onClick={showDetail}><FontAwesomeIcon icon={faMemoCircleInfo} /></button>
               {isHJO &&
                  <button className={styles.disabled}><FontAwesomeIcon icon={faBagShopping} /></button>
               }
               {!isHJO &&
                  <button onClick={showQtyPicker}><FontAwesomeIcon icon={faBagShopping} /></button>
               }
            </div>
         </div>
      </div>
   )
}

export default ListItem
