import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

const salesStore = (set) => {
   return {
      customers: [],
      countries: [],

      // Checksum du dernier fetch valide (collection, modèles, familles, tarifs etc.)
      checksum_countries: null,

      // Date+heure de dernière modification dans AppWrite
      last_updated_customers: null,
      last_updated_ventes_ral: null,
      last_updated_confies: null,
      last_updated_confies_ral: null,
      last_updated_bi_orders: null,
      last_updated_orders: null,

      setCustomers: (list) =>
         set((state) => ({
            customers: list,
         })),
      setCountries: (list) =>
         set((state) => ({
            countries: list,
         })),
      setChecksumCountries: (val) =>
         set((state) => ({
            checksum_collections: val,
         })),
      setLastUpdatedCustomers: (val) =>
         set((state) => ({
            last_updated_customers: val,
         })),
      setLastUpdatedVentesRAL: (val) =>
         set((state) => ({
            last_updated_ventes_ral: val,
         })),
      setLastUpdatedConfies: (val) =>
         set((state) => ({
            last_updated_confies: val,
         })),
      setLastUpdatedConfiesRAL: (val) =>
         set((state) => ({
            last_updated_confies_ral: val,
         })),
      setLastUpdatedBiOrders: (val) =>
         set((state) => ({
            last_updated_bi_orders: val,
         })),
      setLastUpdatedOrders: (val) =>
         set((state) => ({
            last_updated_orders: val,
         })),
   }
}

const useSalesStore = create(devtools(persist(salesStore, { name: "sales" })))

export default useSalesStore