import { useCallback, useEffect, useState } from "react"
// import { Client, Account, Storage } from "appwrite"
import useModelsStore from "../stores/models"
import usePrefStore from "../stores/prefs"
import useErrors from "../common/useErrors"
// import { useLogger } from "../Contexts/Logger"
import useCollection from "./useCollections"
import { AppWriteContext } from "../Contexts/AppWriteContext"

const useTarifs = () => {
   const { emptyTarifs: _emptyTarifs, emptyTarifsChecksums: _emptyTarifsChecksums, tarifs, tarif_regions, devises } = useModelsStore()
   // Prefs store
   const { curTarifRegionName, curTarifCustomer, curTarifRegion, currentLang: curLang } = usePrefStore()
   //
   const { errCodes, buildError } = useErrors()
   // const compressedPrices = usePrefStore((state) => state.compressedPrices)
   //
   const { isHJOModel, splitSKU } = useCollection()
   //
   const [allMinMax, setAllMinMax] = useState({})
   // const [inPurge, setInPurge] = useState(false)
   //
   const { fetchTarifsForSingleRegion } = { ...AppWriteContext() };

   // Fonction de tri spéciale pour les tailles. Les tailles non trouvées sont mises après par ordre alpha
   const sizeSort = useCallback((a, b) => {
      const specialSizes = {
         // Astuce pour classer avant classement ordre alpha si pas trouvé
         XXXS: " A",
         XXS: " B",
         XS: " C",
         S: " D",
         SM: " E",
         M: " F",
         ML: " G",
         L: " H",
         XL: " I",
         XXL: " J",
         XXXL: " K"
      }
      let valA, valB

      // Tout sera comparé en mode String, y compris les tailles numériques (qui sont sur 2 digits de toute façon)
      valA = specialSizes[a] ?? String(a)
      valB = specialSizes[b] ?? String(b)
      return valA.localeCompare(valB)
   }, [])

   // Fonction de tri spéciale pour les couleurs. Les couleurs non trouvées sont mises après, par ordre alpha
   const colorSort = useCallback((a, b) => {
      const specialColors = {
         // Astuce pour classer avant classement ordre alpha si pas trouvé
         WG: " A",
         YG: " B",
         PG: " C",
         BG: " D"
         // Florence me dit de ne pas trier le reste
      }
      let valA, valB

      // Tout sera comparé en mode String, ce qui est déjà le cas en principe
      valA = specialColors[a] ?? String(a)
      valB = specialColors[b] ?? String(b)
      return valA.localeCompare(valB)
   }, [])

   useEffect(() => {
      setAllMinMax({})
   }, [curTarifRegionName])

   // https://discord.com/channels/564160730845151244/564160731327758347/1082325156761960460
   //
   // const testFetchTarifNames = useCallback(async () => {
   // console.log('Fetch noms tarifs [TEST]')

   //    const client = new Client()
   // console.log('[TEST] 1', client)
   //    const storage = new Storage(client)
   // console.log('[TEST] 2', storage)

   //    try {
   //       client
   //          .setEndpoint('http://localhost/v1') // Your API Endpoint
   //          .setProject('640ed8fa2de589a34435') // Your project ID
   //       // .setSelfSigned() // Use only on dev mode with a self-signed SSL cert

   //       console.log('[TEST] 3')
   //       const account = new Account(client);
   //       console.log('[TEST] 3bis', account)

   //       const sess = await account.createEmailSession(
   //          'toto@toto.com',
   //          'totototo'
   //       )
   //       console.log('[TEST] 3ter', sess)


   //       // console.log('getSess', await account.getSession(sess['$id']));

   //       console.log('[TEST] 4')
   //       const result = storage.getFileDownload('ref_data', 'tarif_regions');
   //       console.log(result) // Resource URL

   //       // https://discord.com/channels/564160730845151244/564161955963731980/751462114740142160
   //       // const jwt = await account.createJWT()
   //       // console.log('[TEST] 3bisbis', jwt)
   //       const cookieFallback = localStorage.getItem("cookieFallback") || false;
   //       fetch(result, {
   //          method: "GET",
   //          credentials: "include",
   //          mode: "cors",
   //          headers: {
   //             ...(cookieFallback && { "X-Fallback-Cookies": cookieFallback })
   //             // "x-appwrite-jwt": jwt.jwt
   //          }
   //       })
   //          .then(response => response.json())
   //          .catch(err => {
   //             console.error('Pb de fetch noms tarifs', err)
   //          }).then(data => {
   //             console.log('Fetch noms tarifs OK')
   //             console.log(data)
   //          })
   //       // const ret = await account.deleteSession(sess['$id'])
   //       // console.log('[TEST] 4bis', ret)

   //    } catch (err) {
   //       console.log('[TEST] 5')
   //       console.log('ERR', err)
   //    }
   // console.log('[TEST] 6')
   // console.log('FINI')

   // }, [])

   // const fetchDevises = useCallback(() => {
   //    console.log('Fetch devises')
   //    fetch('/tarifs/devises.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb de fetch devises')
   //       })
   //       .then(data => {
   //          setDevises(data)
   //          console.log('Fetch devises OK')
   //       })
   // }, [setDevises])

   /**
   {
      "Allemagne": {
         "hjo": {
            "ces": { "currency": "EUR", "tarif": "CES017" },
            "ppu": { "currency": "EUR", "tarif": "PPU017" }
         },
         "other": {
            "ces": { "currency": "EUR", "tarif": "CES017" },
            "ppu": { "currency": "EUR", "tarif": "PPU017" }
         }
      },
      ...
   */
   // const fetchTarifRegions = useCallback(() => {
   //    console.log('Fetch noms tarifs')
   //    fetch('/tarifs/tarif-regions.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb Fetch noms tarifs', err)
   //       })
   //       .then(data => {
   //          setTarifNames(data)
   //          console.log('fetch noms tarifs OK')
   //       })
   // }, [setTarifNames])

   /**
   {
      "Allemagne": {
         "hjo": {
            "ces": { "currency": "EUR", "tarif": "CES017" },
            "ppu": { "currency": "EUR", "tarif": "PPU017" }
         },
         "other": {
            "ces": { "currency": "EUR", "tarif": "CES017" },
            "ppu": { "currency": "EUR", "tarif": "PPU017" }
         }
      },
      ...
   */
   // const fetchTarifTrads = useCallback(() => {
   //    console.log('Fetch trads tarifs')
   //    fetch('/tarifs/tarif-trads.json')
   //       .then(response => response.json())
   //       .catch(err => {
   //          console.error('Pb Fetch trads tarifs', err)
   //       })
   //       .then(data => {
   //          setTarifTrads(data)
   //          console.log('fetch trads tarifs OK')
   //       })
   // }, [setTarifTrads])

   /**
    * Retourne l'objet `currencies` d'une région, ou null si pas trouvée :
    * {
    *    hjo: {
    *       ces: {
    *          tarif: "CES001",
    *          currency: "EUR",
    *       }
    *       ppu: {...}
    *    },
    *    other: {...}
    * }
    */
   const getTarifsForRegion = useCallback((region_name) => {
      if (!tarif_regions || !tarif_regions[region_name]) {
         return null
      } else {
         return tarif_regions[region_name].currencies
      }
   }, [tarif_regions])

   /**
    * Retrouve un nom de région à partir d'un nom de tarif OTHER_PPU et de sa devise. Exemple : ("PPU001", "EUR")
    * ou null si pas trouvé
    */
   // const findRegionFromTarifNames = useCallback((other_ppu_tarif, other_ppu_currency) => {
   const _findRegionFromTarifNames = useCallback((from_cess, other_tarif, other_currency) => {
      // console.log('findRegionFromCessionTarifNames', from_cess, other_tarif, other_currency, tarif_regions);
      if (!tarif_regions) {
         // console.log('NULL');
         return null
      } else {
         // console.log('SUITE');
         const found = Object.keys(tarif_regions).find((a_name) => {
            const a_tarif = tarif_regions[a_name]
            // return ((a_tarif.currencies.other.ppu.tarif === other_ppu_tarif) && (a_tarif.currencies.other.ppu.currency === other_ppu_currency))
            if (from_cess) {
               // console.log('CMP CESS', a_tarif.currencies.other.ces.tarif, other_tarif, a_tarif.currencies.other.ces.currency, other_currency, ((a_tarif.currencies.other.ces.tarif === other_tarif) && (a_tarif.currencies.other.ces.currency === other_currency)))
               return ((a_tarif.currencies.other.ces.tarif === other_tarif) && (a_tarif.currencies.other.ces.currency === other_currency))
            } else {
               // console.log('CMP PPU', a_tarif.currencies.other.ppu.tarif, other_tarif, a_tarif.currencies.other.ppu.currency, other_currency, ((a_tarif.currencies.other.ppu.tarif === other_tarif) && (a_tarif.currencies.other.ppu.currency === other_currency)))
               return ((a_tarif.currencies.other.ppu.tarif === other_tarif) && (a_tarif.currencies.other.ppu.currency === other_currency))
            }
         })
         return found ?? null
      }
   }, [tarif_regions])

   const findRegionFromCessionTarifNames = useCallback((other_cess_tarif, other_cess_currency) => {
      return _findRegionFromTarifNames(true, other_cess_tarif, other_cess_currency)
   }, [_findRegionFromTarifNames])

   const findRegionFromPublicTarifNames = useCallback((other_ppu_tarif, other_ppu_currency) => {
      return _findRegionFromTarifNames(false, other_ppu_tarif, other_ppu_currency)
   }, [_findRegionFromTarifNames])

   /**
    * Construit et retourne un tarif complet (HJO et Autres) à partir d'un objet client qui ne contient que la partie "Autres""
    * {
    *    other: {
    *       ppu: {
    *          tarif: "PPU001",
    *          currency: "EUR",
    *       }
    *       ces: {...}
    *    },
    *    hjo: {...}
    */
   // const buildFullTarifFromCustomer = useCallback((a_customer) => {
   //    // console.log('***>>>***1', a_customer);
   //    const region = findRegionFromTarifNames(a_customer.tariff.other.ppu, a_customer.tariff.other.ces)
   //    // console.log('***>>>***2', region);
   //    const tarif = tarif_regions[region]
   //    // console.log('***>>>***3', tarif);

   //    return tarif?.currencies ?? null
   // }, [findRegionFromTarifNames, tarif_regions])

   // useCallback() pour prévenir un rerender à cause du setTarifs()
   // const fetchTarifsForSelectedRegions = useCallback(() => {
   //    console.log('Fetch tarifs', tarif_regions)

   //    /* On utilise un Set pour éviter les doublons */
   //    let to_fetch = new Set()
   //    for (const key in tarif_regions) {
   //       const a_tarif = tarif_regions[key]
   //       // console.log(name)
   //       if (a_tarif.preload) {
   //          const names = [
   //             a_tarif.currencies.hjo.ces.tarif,
   //             a_tarif.currencies.hjo.ppu.tarif,
   //             a_tarif.currencies.other.ces.tarif,
   //             a_tarif.currencies.other.ppu.tarif
   //          ]
   //          console.log(`Tarif names "${key}" Add`, [...names])
   //          /* Tweak d'ajout d'un array de valeurs à un Set : on recréer un nv Set */
   //          to_fetch = new Set([...to_fetch, ...names])
   //       }
   //    }
   //    console.log('To fetch', to_fetch)
   //    for (const key of to_fetch) {
   //       // console.log(`Fetching prices /tarifs/tarif-${name}.json${compressedPrices ? ".gz" : ""}`)
   //       console.log(`Fetch tarif /tarifs/tarif-${key}.json`)
   //       // fetch(`/tarifs/tarif-${name}.json${compressedPrices ? ".gz" : ""}`)
   //       fetch(`/tarifs/tarif-${key}.json`)
   //          .then(response => response.text())
   //          .catch(err => {
   //             console.error(`Pb de fetch tarif ${key}`)
   //          })
   //          .then(data => {
   //             try {
   //                JSON.parse(data)  // Test if JSON string...
   //                setTarifs(key, data)
   //                console.log(`Fetch tarif ${key} OK`)
   //             } catch (err) {
   //                console.error(`Pb de fetch tarif ${key} - Pas du JSON !`)
   //             }
   //          })
   //    }
   // }, [tarif_regions, setTarifs])

   const emptyTarifs = () => {
      // setInPurge(true)
      _emptyTarifs()
      _emptyTarifsChecksums()
      // setInPurge(false)
      if (curTarifRegionName) {
         // Un timer de 1sec pour laisser le temps au ModelStore de se vider.
         setTimeout(() => {
            console.log('fetchTarifsForSingleRegion > GO');
            fetchTarifsForSingleRegion(curTarifRegionName, true, () => {      // true = force le DL du tarif sans tenir compte du checksum
               console.log('fetchTarifsForSingleRegion > OK');
            }, (err) => {
               console.log('fetchTarifsForSingleRegion > ERR', err);
            })
         }, 1000)
      }
   }

   /**
    * Vérifie la validité d'un tarif {hjo:{ces:...,ppu:...},other:...}
    * Retourne true/false
    */
   function isTarifValid(tarif) {
      if (!tarif) {
         return false
      }
      return (
         (tarif.hjo.ces.tarif !== null) &&
         (tarif.hjo.ces.currency !== null) &&
         (tarif.hjo.ppu.tarif !== null) &&
         (tarif.hjo.ppu.currency !== null) &&
         (tarif.other.ces.tarif !== null) &&
         (tarif.other.ces.currency !== null) &&
         (tarif.other.ppu.tarif !== null) &&
         (tarif.other.ppu.currency !== null)
      )
   }

   /**
    * Vérifie la un tarif de région est totalement chargé
    * Retourne true/false
    */
   const isTarifLoadedForRegion = useCallback((region_name) => {
      if (tarifs && tarif_regions && tarif_regions[region_name]) {
         const a_region = tarif_regions[region_name]

         return (tarifs[a_region.currencies.hjo.ces.tarif] &&
            tarifs[a_region.currencies.hjo.ppu.tarif] &&
            tarifs[a_region.currencies.other.ces.tarif] &&
            tarifs[a_region.currencies.other.ppu.tarif]) ? true : false
      } else {
         return false
      }
   }, [tarif_regions, tarifs])

   // (Re)fetch au moins la région sélectionnée. Utile en cas de purge
   // useEffect(() => {
   //    console.log(inPurge, fetchTarifsForSingleRegion ? 'Y' : 'N', curTarifRegionName, isTarifLoadedForRegion(curTarifRegionName), isTarifLoadedForRegion(curTarifRegionName) ? 'Y' : 'N');
   //    if (!inPurge && fetchTarifsForSingleRegion && curTarifRegionName && !isTarifLoadedForRegion(curTarifRegionName)) {
   //       console.log('fetchTarifsForSingleRegion > GO');
   //       fetchTarifsForSingleRegion(curTarifRegionName, () => {
   //          console.log('fetchTarifsForSingleRegion > OK');
   //       }, (err) => {
   //          console.log('fetchTarifsForSingleRegion > ERR', err);
   //       })
   //    }
   // }, [curTarifRegionName, fetchTarifsForSingleRegion, inPurge, isTarifLoadedForRegion])

   /**
    * Vérifie si le tarif d'un client est identique à celui de sa région {other.[ppu & ces].[tarif & devise]}
    * Retourne true/false
    */
   function isCustomerTarifSameAsRegion(tarif_customer, tarif_region) {
      if (!tarif_customer || !tarif_region) {
         return false
      }
      // console.log('===ICI===', tarif_customer, tarif_region, (
      //    (tarif_customer.other.ces.tarif === tarif_region.other.ces.tarif) &&
      //    (tarif_customer.other.ces.currency === tarif_region.other.ces.currency) &&
      //    (tarif_customer.other.ppu.tarif === tarif_region.other.ppu.tarif) &&
      //    (tarif_customer.other.ppu.currency === tarif_region.other.ppu.currency)
      // ));
      return (
         (tarif_customer.other.ces.tarif === tarif_region.other.ces.tarif) &&
         (tarif_customer.other.ces.currency === tarif_region.other.ces.currency) &&
         (tarif_customer.other.ppu.tarif === tarif_region.other.ppu.tarif) &&
         (tarif_customer.other.ppu.currency === tarif_region.other.ppu.currency)
      )
   }

   /**
    * @param {string} tarif : Nom du tarif (CES001...) ou null pour le tarif par défaut
    * @returns: {{}|null} Objet ou null si tarif pas trouvé ou vide
    */
   const getTarifByName = useCallback((tarif = null) => {
      const name = tarif ?? curTarifRegionName
      // console.log('getTarifByName', name)
      // console.log('getTarifByName', tarifs, name)
      if (tarifs[name]) {
         // console.log('Avant JSON.parse()')
         // console.log(tarifs[name])
         // return JSON.parse(tarifs[name]) || null
         return tarifs[name] || null
      } else {
         return null
      }
   }, [curTarifRegionName, tarifs])

   /**
    * Retourne un objet :
    *    {PG: 9999, YG: 8888...}
    */
   const _getTarifForModel = useCallback((forCession, model_id, region = null, currency = null) => {
      const region_name = region ?? curTarifRegionName
      // const currency_name = currency ?? curCurrency
      let currency_name
      let tarif_name = null

      // console.log(tarif_regions[region_name])
      if (isHJOModel(model_id)) {
         if (forCession) {
            if (!region && !currency && curTarifCustomer) {
               tarif_name = curTarifCustomer.hjo.ces.tarif
               currency_name = curTarifCustomer.hjo.ces.currency
            } else {
               tarif_name = tarif_regions[region_name]?.currencies.hjo.ces.tarif
               currency_name = currency ?? tarif_regions[region_name]?.currencies.hjo.ces.currency
            }
         } else {
            if (!region && !currency && curTarifCustomer) {
               tarif_name = curTarifCustomer.hjo.ppu.tarif
               currency_name = curTarifCustomer.hjo.ppu.currency
            } else {
               tarif_name = tarif_regions[region_name]?.currencies.hjo.ppu.tarif
               currency_name = currency ?? tarif_regions[region_name]?.currencies.hjo.ppu.currency
            }
         }
      } else {
         if (forCession) {
            if (!region && !currency && curTarifCustomer) {
               tarif_name = curTarifCustomer.other.ces.tarif
               currency_name = curTarifCustomer.other.ces.currency
            } else {
               tarif_name = tarif_regions[region_name]?.currencies.other.ces.tarif
               currency_name = currency ?? tarif_regions[region_name]?.currencies.other.ces.currency
            }
         } else {
            if (!region && !currency && curTarifCustomer) {
               tarif_name = curTarifCustomer.other.ppu.tarif
               currency_name = curTarifCustomer.other.ppu.currency
            } else {
               tarif_name = tarif_regions[region_name]?.currencies.other.ppu.tarif
               currency_name = currency ?? tarif_regions[region_name]?.currencies.other.ppu.currency
            }
         }
      }
      // console.log('**::', model_id, forCession, region, currency, tarif_name, currency_name);

      // console.log('XXXX', tarif_name, forCession, model_id, region, currency)
      let obj_tarif = getTarifByName(tarif_name)

      // console.log(`getTarifForModel(${model_id}, ${region_name} (${region}), ${currency_name} (${currency}))`, obj_tarif)

      if (!obj_tarif) {
         console.log(`Tarif-région ${tarif_name} inconnu pour région ${region_name}`)
         return null
      } else {
         obj_tarif = obj_tarif[currency_name]
         if (!obj_tarif) {
            console.log(`Tarif-region ${tarif_name} pour région ${region_name} - Devise inconnue : currency ${currency}, ${currency_name}`)
            return null
         } else {
            obj_tarif = obj_tarif[model_id]
            if (!obj_tarif) {
               // console.log("Tarif", tarif, tarif_name, " - Devise", currency, currency_name, " - Modèle inconnu :", model_id)
               return null
            } else {
               // console.log('>>>ICI>>>', obj_tarif)
               return obj_tarif
            }
         }
      }
   }, [curTarifCustomer, curTarifRegionName, getTarifByName, isHJOModel, tarif_regions])

   /**
    * Voir _getTarifForModel pour les params
    * Prix CESSION
    */
   const getCessTarifForModel = useCallback((model_id, region = null, currency = null) => {
      return _getTarifForModel(true, model_id, region, currency)
   }, [_getTarifForModel])

   /**
    * Voir _getTarifForModel pour les params
    * Prix PPU
    */
   const getTarifForModel = useCallback((model_id, region = null, currency = null) => {
      return _getTarifForModel(false, model_id, region, currency)
   }, [_getTarifForModel])

   /**
    * Récupère les couleurs d'un modèle d'un tarif donné (ou actif si null) et une devise donnée (ou active si null)
    * @param {string} model_id : ID modèle
    * @param {string} tarif  : Nom du tarif (ou tarif actif si null)
    * @param {string} currency : Nom de la devise (ou devise active si null)
    * @returns : {{string[]|null} colors, {string|null} reason}, les couleurs trouvées dans le tarif ou null si pas trouvé + reason indique la raison de l'échec. Pas de tri
    */
   const getColorsForModel = useCallback((model_id, region = null, currency = null) => {
      let a_tarif = getTarifForModel(model_id, region, currency)

      // console.log('getColorsForModel', model_id, a_tarif)

      if (a_tarif) {
         // Cas 1 `a_tarif` = {"YG": { "44,45,..": 9999}, ...}
         // Cas 2 `a_tarif` = {"YG": 9999, ...}
         // Cas 3 `a_tarif` = 9999
         if (typeof a_tarif === 'number') {
            // Cas 3 `a_tarif` = 9999
            return { colors: [], reason: null }
         } else if (a_tarif && (typeof a_tarif === 'object')) {
            // Cas 1 `a_tarif` = {"YG": { "44,45,..": 9999}, ...}
            // Cas 2 `a_tarif` = {"YG": 9999, ...}
            const ret = Object.keys(a_tarif)
            return { colors: ret.sort(colorSort), reason: null }
         } else {
            return { colors: null, reason: buildError(errCodes.UNK_TYPE, `getColorsForModel - Unknown type for tarif/currency/model "${region}/${currency}/${model_id}"!`) }
         }
      } else {
         return { colors: null, reason: buildError(errCodes.UNK_TARIF, `getColorsForModel - Unknown tarif/currency/model "${region}/${currency}/${model_id}"!`) }
      }
   }, [buildError, errCodes, getTarifForModel, colorSort])

   /**
    * Récupère les tailles d'un modèle pour une couleur donnée d'un tarif donné (ou actif si null) et une devise donnée (ou active si null)
    * @param {string} model_id : ID modèle
    * @param {string} tarif  : Nom du tarif (ou tarif actif si null)
    * @param {string} currency : Nom de la devise (ou devise active si null)
    * @param {string} color : Couleur
    * @returns : {{int|[{int}...]|null} sizes, {string|null} reason}, les tailles trouvées dans le tarif ou null si pas trouvé ou s'il manque des infos (couleur) + reason indique la raison de l'échec. Les tailles sont triées par ordre croissant
    */
   const getSizesForModelColor = useCallback((model_id, color, region = null, currency = null) => {
      let a_tarif = getTarifForModel(model_id, region, currency)

      // console.log('getSizesForModelColor', a_tarif)

      if (a_tarif) {
         // Cas 1 `a_tarif` = {"YG": { "44,45,..": 9999}, ...}
         // Cas 2 `a_tarif` = {"YG": 9999, ...}
         // Cas 3 `a_tarif` = 9999
         if (typeof a_tarif === 'number') {
            // Cas 3 `a_tarif` = 9999
            return { sizes: null, reason: buildError(errCodes.NO_COLOR, `getSizesForModelColor - No color!`) }
         } else if (a_tarif && (typeof a_tarif === 'object')) {
            // Cas 1 et Cas 2
            a_tarif = a_tarif[color] // On ne garde que le tarif de cette couleur
            if (a_tarif && (typeof a_tarif === 'object')) {
               // Cas `a_tarif` = { "44,45,..": 9999}
               const ret = Object.keys(a_tarif).reduce((prev, key) => {
                  return [...prev, ...key.split(",")].sort(sizeSort)
               }, [])
               return { sizes: ret, reason: null }
            } else if (typeof a_tarif === 'number') {
               // Cas `a_tarif` = 9999 (origine Cas 2)
               // return { sizes: null, reason: buildError(errCodes.NO_SIZE, `getSizesForModelColor - No size!`) }
               return { sizes: null, reason: null }   // Ce n'est pas une erreur en soi...
            } else {
               // Origine Cas 2
               return { sizes: null, reason: buildError(errCodes.UNK_COLOR, `getSizesForModelColor - Unknown color "${color}"!`) }
            }
         } else {
            return { sizes: null, reason: buildError(errCodes.UNK_TYPE2, `getColorsForModel - Unknown type for tarif/currency/model "${region}/${currency}/${model_id}"!`) }
         }
      } else {
         return { sizes: null, reason: buildError(errCodes.UNK_TARIF2, `getColorsForModel - Unknown tarif/currency/model "${region}/${currency}/${model_id}"!`) }
      }
   }, [buildError, errCodes, getTarifForModel, sizeSort])

   /**
    * Récupère toutes les tailles d'un modèle (toutes couleurs confondues) d'un tarif donné (ou actif si null) et une devise donnée (ou active si null)
    * @param {string} model_id : ID modèle
    * @param {string} tarif  : Nom du tarif (ou tarif actif si null)
    * @param {string} currency : Nom de la devise (ou devise active si null)
    * @returns : {{int|[{int}...]|null} sizes, {string|null} reason}, les tailles trouvées dans le tarif ou null si pas trouvé ou s'il manque des infos (couleur) + reason indique la raison de l'échec. Les tailles sont triées par ordre croissant
    */
   const getAllSizesForModel = useCallback((model_id, region = null, currency = null) => {
      const allColors = getColorsForModel(model_id, region, currency)

      // Pb détail sur 11586, 10031 (1er GATSBY)
      if (allColors.reason) {
         return { sizes: null, reason: allColors.reason }
      } else {
         const ret = allColors.colors.reduce((prev, color) => {
            if (prev.reason) {
               // On ne retourne rien si erreur précédemment
               return prev
            }
            const obj_sizes = getSizesForModelColor(model_id, color, region, currency)

            if (obj_sizes.reason) {
               return { size: null, reason: obj_sizes.reason }
            } else if (obj_sizes.sizes === null) {
               return prev
            } else {
               return { sizes: [...prev.sizes, ...obj_sizes.sizes], reason: null }
            }
         }, { sizes: [], reason: null })
         if (ret.sizes) {
            return { sizes: [...new Set(ret.sizes)].sort(sizeSort), reason: ret.reason } // Set(...) pour récupérer les valeurs uniques
         } else {
            return ret
         }
      }
   }, [getColorsForModel, getSizesForModelColor, sizeSort])

   /**
    * Récupère le prix d'un modèle pour une couleur et une taille d'un tarif donné (ou actif si null) et une devise donnée (ou active si null)
    * @param {string} model_id : ID modèle
    * @param {string|null} color : Couleur ou null si le modèle n'a pas d'attribut de couleur
    * @param {string|null} size : Taille ou null si le modèle n'a pas d'attribut de taille
    * @param {string} tarif  : Nom du tarif (ou tarif actif si null)
    * @param {string} currency : Nom de la devise (ou devise active si null)
    * @returns : {{int|null} price, {string|null} reason}, le prix trouvé dans le tarif ou null si pas trouvé ou s'il manque des infos (couleur, taille) + reason indique la raison de l'échec
    */
   const _getPriceForModelColorSize = useCallback((forCession, model_id, color, size, region = null, currency = null) => {
      let a_tarif = getTarifForModel(model_id, region, currency)

      if (forCession) {
         a_tarif = getCessTarifForModel(model_id, region, currency)
      } else {
         a_tarif = getTarifForModel(model_id, region, currency)
      }

      console.log('getPriceForModelColorSize', a_tarif)

      if (a_tarif) {
         // Cas 1 `a_tarif` = {"YG": { "44,45,..": 9999}, ...}
         // Cas 2 `a_tarif` = {"YG": 9999, ...}
         // Cas 3 `a_tarif` = 9999
         if (color) {   // On cherche une couleur donnée
            if (a_tarif[color]) {
               // Cas 1 `a_tarif` = {"YG": { "44,45,..": 9999}, ...}
               // Cas 2 `a_tarif` = {"YG": 9999, ...}
               a_tarif = a_tarif[color] // On ne garde que le tarif de cette couleur
               if (size) {
                  // Cas 1 `a_tarif` = {"YG": { "44,45,..": 9999}, ...}
                  const ret = Object.keys(a_tarif).reduce((prev, key) => {
                     const price_for_sizes = a_tarif[key]
                     // Ici `key` = "44,45,..." et `price_for_sizes` = 9999
                     if (key.split(",").includes(size)) {
                        return price_for_sizes
                     } else {
                        return prev // càd null
                     }
                  }, null)
                  return { price: ret, reason: (ret ? null : buildError(errCodes.UNK_SIZE, `getPriceForModelColorSize - Unknown size "${size}"`)) }
               } else {    // On ne cherche pas une taille donnée, càd qu'on "sait" que ce modèle n'a pas de taille !
                  // Cas 2 `a_tarif` = {"YG": 9999, ...}
                  if (typeof a_tarif !== 'number') { // ... mais on n'a PAS trouvé un nombre !!! Donc il y a des tailles !!!
                     return { price: null, reason: buildError(errCodes.MISS_SIZE, `getPriceForModelColorSize - Missing size argument!`) }
                  } else {
                     return { price: a_tarif, reason: null }
                  }
               }
            } else { // Pas trouvé la couleur !
               return { price: null, reason: buildError(errCodes.UNK_COLOR2, `getPriceForModelColorSize - Unknown color "${color}"`) }
            }
         } else {   // On ne cherche pas une couleur donnée, càd qu'on "sait" que ce modèle n'a pas de couleur !
            // Cas 3 `a_tarif` = 9999
            if (typeof a_tarif !== 'number') { // ... mais on n'a PAS trouvé un nombre !!! Donc il y a des couleurs !!!
               return { price: null, reason: buildError(errCodes.NO_COLOR2, `getPriceForModelColorSize - Missing color argument!`) }
            } else {
               return { price: a_tarif, reason: null }
            }
         }
      } else {
         return { price: null, reason: buildError(errCodes.UNK_TARIF3, `getPriceForModelColorSize - Unknown tarif/currency/model "${region}/${currency}/${model_id}"!`) }
      }
   }, [getTarifForModel, getCessTarifForModel, buildError, errCodes])

   /**
    * Voir _getMinMaxPriceForModel pour les params
    * Prix PPU
    */
   const getPriceForModelColorSize = useCallback((model_id, color, size, region = null, currency = null) => {
      return _getPriceForModelColorSize(false, model_id, color, size, region, currency)
   }, [_getPriceForModelColorSize])

   /**
    * Voir _getMinMaxPriceForModel pour les params
    * Prix CESSION
    */
   const getCessPriceForModelColorSize = useCallback((model_id, color, size, region = null, currency = null) => {
      return _getPriceForModelColorSize(true, model_id, color, size, region, currency)
   }, [_getPriceForModelColorSize])

   const _getCurrency = useCallback((type) => {
      let name
      const lang = (curLang === 'en') ? 'en' : 'fr'

      if (curTarifCustomer) {
         name = curTarifCustomer.other[type].currency
      } else if (curTarifRegion) {
         name = curTarifRegion.other[type].currency
      } else {
         return null
      }
      if (devises) {
         return devises[name]?.label[lang] ?? name
      } else {
         return name
      }
   }, [curLang, curTarifCustomer, curTarifRegion, devises])

   /**
    * Voir _getTarifForModel pour les params
    * Prix PPU
    */
   const getPriceForModelBySKU = useCallback((sku, region = null, currency = null) => {
      console.log(splitSKU(sku));
      const { model, color, size } = { ...splitSKU(sku) }

      return getPriceForModelColorSize(model, color, size, region, currency)
   }, [getPriceForModelColorSize, splitSKU])

   /**
    * Voir _getTarifForModel pour les params
    * Prix PPU
    */
   const getCessPriceForModelBySKU = useCallback((sku, region = null, currency = null) => {
      const { model, color, size } = { ...splitSKU(sku) }

      return getCessPriceForModelColorSize(model, color, size, region, currency)
   }, [getCessPriceForModelColorSize, splitSKU])

   /**
    * Retourne le nom de la devise PPU en tenant compte du curCustomer si existe, sinon du curRegion
    * C'est toujours le tarif Other qui est utilisé
    * null si aucun des 2 n'est dispo
    */

   const getPPUCurrency = useCallback(() => {
      return _getCurrency('ppu')
   }, [_getCurrency])

   /**
    * Retourne le nom de la devise CES en tenant compte du curCustomer si existe, sinon du curRegion
    * C'est toujours le tarif Other qui est utilisé
    * null si aucun des 2 n'est dispo
    */

   const getCESCurrency = useCallback(() => {
      return _getCurrency('ces')
   }, [_getCurrency])

   /**
    * Calcule les prix mini et maxi d'un modèle pour un tarif donné (ou actif si null) et une devise donnée (ou active si null)
    * @param {string} model_id : ID modèle
    * @param {string} tarif  : Nom du tarif (ou tarif actif si null)
    * @param {string} currency : Nom de la devise (ou devise active si null)
    * @returns : {{int|null} min, {int|null} max, {string|null} reason}, les prix mini et maxi trouvés dans le tarif ou null si pas trouvé + reason indique la raison
    */

   const _getMinMaxPriceForModel = useCallback((forCession, model_id, region = null, currency = null) => {
      const region_name = region ?? curTarifRegionName
      // const currency_name = currency ?? curCurrency
      let currency_name
      let a_tarif, min_max_idx, ret

      if (forCession) {
         a_tarif = getCessTarifForModel(model_id, region, currency)  // Surtout PAS region_name !!! Afin de conserver un null éventuel
         if (!region && !currency && curTarifCustomer) {
            currency_name = curTarifCustomer.other.ces.currency
         } else {
            currency_name = currency ?? tarif_regions[region_name]?.currencies.other.ces.currency
         }
         min_max_idx = `${model_id}_${region}_${currency}_CES`
      } else {
         a_tarif = getTarifForModel(model_id, region, currency)  // Surtout PAS region_name !!! Afin de conserver un null éventuel
         if (!region && !currency && curTarifCustomer) {
            currency_name = curTarifCustomer.other.ppu.currency
         } else {
            currency_name = currency ?? tarif_regions[region_name]?.currencies.other.ppu.currency
         }
         min_max_idx = `${model_id}_${region}_${currency}_PP`
      }

      const found = allMinMax[min_max_idx]
      if (found) {
         // console.log('FOUND min_max', min_max_idx, found);
         return found
      }

      // console.log('getMinMaxPriceForModel - a_tarif', a_tarif)

      // console.log(a_tarif, (typeof a_tarif === 'object'), a_tarif ? "Y" : "N")
      if (a_tarif && (typeof a_tarif === 'object')) {
         // console.log(a_tarif)
         ret = Object.keys(a_tarif).reduce((prev, key) => {
            const value = a_tarif[key]
            // console.log('getMinMaxPriceForModel - Cur', key, value, typeof value)
            if (typeof value === 'number') {
               // Cas "99999": 999
               // Ici `key` = "99999" et `value` = 999
               return { ...prev, min: Math.min(prev.min ?? value, value), max: Math.max(prev.max ?? value, value) }  // ...prev pour garder "reason"
            } else if (value && (typeof value === 'object')) {
               // Cas 1 : "99999": {"YG": 999, ...}
               // Cas 2 : "99999": {"YG": {"44,45...": 999}, ...}
               const ret = Object.keys(value).reduce((prev, key) => {
                  const value2 = value[key]
                  if (typeof value2 === 'number') {
                     // Cas "99999": {"YG": 999, ...}
                     // Ici `key` = "YG" et `value2` = 999
                     return { ...prev, min: Math.min(prev.min ?? value2, value2), max: Math.max(prev.max ?? value2, value2) }
                  } else if (value2 && (typeof value2 === 'object')) {
                     // Cas "99999": {"YG": {"44,45...": 999}, ...}.
                     // Ici `key` = "YG" et `value2` = {"44,45...": 999}
                     const ret = Object.keys(value2).reduce((prev, key) => {
                        const value3 = value2[key]
                        if (typeof value3 !== 'number') {
                           console.log("Type inconnu (3) : ", region, region_name, " - Devise", currency, currency_name, " - Modèle :", model_id, " - Type :", typeof value3, value3)
                           return { ...prev, reason: buildError(errCodes.UNK_TYPE3, `getMinMaxPriceForModel - Unknown tarif type [3/${value3}]`) }
                        } else {
                           return { ...prev, min: Math.min(prev.min ?? value3, value3), max: Math.max(prev.max ?? value3, value3) }
                        }
                     }, { min: null, max: null, reason: null })
                     return { ...prev, min: Math.min(prev.min ?? ret.min, ret.min), max: Math.max(prev.max ?? ret.max, ret.max) }
                  } else {
                     console.log("Type inconnu (2) : ", region, region_name, " - Devise", currency, currency_name, " - Modèle :", model_id, " - Type :", typeof value2, value2)
                     return { ...prev, reason: buildError(errCodes.UNK_TYPE4, `getMinMaxPriceForModel - Unknown tarif type [2/${value2}]`) }
                  }
               }, { min: null, max: null, reason: null })
               return { ...prev, min: Math.min(prev.min ?? ret.min, ret.min), max: Math.max(prev.max ?? ret.max, ret.max) }
            } else {
               console.log("Type inconnu (1) : ", region, region_name, " - Devise", currency, currency_name, " - Modèle :", model_id, " - Type :", typeof value, value)
               return { ...prev, reason: buildError(errCodes.UNK_TYPE5, `getMinMaxPriceForModel - Unknown tarif type [1${value}]`) }
            }
         }, { min: null, max: null, reason: null })
      } else if (typeof a_tarif === 'number') {
         // Le tarif est une valeur unique, c'est donc à la fois le min et le max
         ret = { min: a_tarif, max: a_tarif, reason: null }
      } else {
         console.debug("Tarif inconnu : ", region, region_name, " - Devise", currency, currency_name, " - Modèle :", model_id)
         ret = { min: null, max: null, reason: buildError(errCodes.UNK_TARIF4, `getMinMaxPriceForModel - Unknown tarif ${region_name}/${currency_name}/${model_id}`) }
      }
      setAllMinMax(cur => {
         return { ...cur, [min_max_idx]: ret }
      })
      return ret
   }, [allMinMax, buildError, curTarifCustomer, curTarifRegionName, errCodes, getCessTarifForModel, getTarifForModel, tarif_regions])

   /**
    * Voir _getMinMaxPriceForModel pour les params
    * Prix CESSION
    */
   const getMinMaxCessPriceForModel = useCallback((model_id, region = null, currency = null) => {
      // console.log('::MINMAX - getMinMaxCessPriceForModel')
      return _getMinMaxPriceForModel(true, model_id, region, currency)
   }, [_getMinMaxPriceForModel])

   /**
    * Voir _getMinMaxPriceForModel pour les params
    * Prix PPU
    */
   const getMinMaxPriceForModel = useCallback((model_id, region = null, currency = null) => {
      // console.log('::MINMAX - getMinMaxPriceForModel', model_id, region, currency, _getMinMaxPriceForModel(false, model_id, region, currency))
      return _getMinMaxPriceForModel(false, model_id, region, currency)
   }, [_getMinMaxPriceForModel])

   /**
    * Calcule les prix mini et maxi de chaque modèle d'une liste de modèles pour un tarif donné (ou actif si null) et une devise donnée (ou active si null)
    * @param {[]} models : IDs des modèles (liste)
    * @param {string} tarif  : Nom du tarif (ou tarif actif si null)
    * @param {string} currency : Nom de la devise (ou devise active si null)
    * @returns : { string mod_id: { { int | null } min, { int | null} max }, ... }, un "tableau indexé" (i.e. un objet) associant chaque modèle (du tableau models) et ses prix mini et maxi trouvés dans le tarif, ou null si pas trouvé
    */
   const _getMinMaxPriceForEachModelInArray = useCallback((forCession, models, region = null, currency = null) => {
      // console.log('::MINMAX - _getMinMaxPriceForEachModelInArray')
      if (!Array.isArray(models)) {
         console.error('getMinMaxPriceForEachModelInArray - Not array!', models)
         return null
      }
      return models.reduce((prev, model) => {
         let miniMax

         if (forCession) {
            miniMax = getMinMaxCessPriceForModel(model.id, region, currency)
         } else {
            miniMax = getMinMaxPriceForModel(model.id, region, currency)
         }
         // console.log('CUR_MINMAX', miniMax, model, prev)
         return {
            ...prev,
            [model.id]: { min: miniMax.min, max: miniMax.max }   // Permet de ne pas prendre l'attribut `reason`
         }
      }, {})
   }, [getMinMaxCessPriceForModel, getMinMaxPriceForModel])

   /**
    * Voir _getMinMaxPriceForModel pour les params
    * Prix PPU
    */
   const getMinMaxCessPriceForEachModelInArray = useCallback((models, region = null, currency = null) => {
      return _getMinMaxPriceForEachModelInArray(true, models, region, currency)
   }, [_getMinMaxPriceForEachModelInArray])

   /**
    * Voir _getMinMaxPriceForModel pour les params
    * Prix PPU
    */
   const getMinMaxPriceForEachModelInArray = useCallback((models, region = null, currency = null) => {
      // console.log('::MINMAX - getMinMaxPriceForEachModelInArray')
      return _getMinMaxPriceForEachModelInArray(false, models, region, currency)
   }, [_getMinMaxPriceForEachModelInArray])

   return {
      // testFetchTarifNames,
      // fetchTarifRegions,
      // fetchTarifsForSelectedRegions,
      getTarifsForRegion,
      findRegionFromCessionTarifNames,
      findRegionFromPublicTarifNames,
      // buildFullTarifFromCustomer,
      emptyTarifs,
      isTarifValid,
      isTarifLoadedForRegion,
      isCustomerTarifSameAsRegion,
      getTarifByName,
      getTarifForModel,
      getCessTarifForModel,
      getMinMaxPriceForModel,
      getMinMaxCessPriceForModel,
      getMinMaxPriceForEachModelInArray,
      getMinMaxCessPriceForEachModelInArray,
      getPriceForModelColorSize,
      getCessPriceForModelColorSize,
      getPriceForModelBySKU,
      getCessPriceForModelBySKU,
      getPPUCurrency,
      getCESCurrency,
      getColorsForModel,
      getSizesForModelColor,
      getAllSizesForModel
   }
}

export default useTarifs