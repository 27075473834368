import usePrefStore from "../stores/prefs"
import { Client, Account, Databases, Query, ID } from 'appwrite'
import { useCallback, useEffect, useState, createContext, useContext } from "react";
import { logNames } from "../Hooks/useLog"
// import useCollections from "../Hooks/useCollections";
import useModelsStore from "../stores/models";
import useSalesStore from "../stores/sales";
// import { useLogger } from "../Contexts/Logger";
import useTranslation from '../Hooks/useTranslation';
import moment from 'moment';
import { db as dexie_db } from "../stores/db"
import glob_const from '../libs/glob_const'
import useSales from "../Hooks/useSales";
import useHelpers from "../Hooks/useHelpers";
import axios from 'axios'

const theContext = createContext()

const AppWriteContext = (() => useContext(theContext))

const fetch_names = {
   // tarifRegions: 'tarifRegions',
   // tarifTrads: 'tarifTrads',
   // models: 'models',
   // customers: 'customers',
   collections: 'collections',
   couleurs: 'couleurs',
   countries: 'countries',
   customers: 'customers',
   cycles: 'cycles',
   devises: 'devises',
   etapes: 'etapes',
   familles: 'familles',
   genres: 'genres',
   modeles: 'modeles',
   // orders: 'orders',
   portes: 'portes',
   tarif_regions: 'tarif-regions',
   tarif_trads: 'tarif-trads',
}

const MAX_FETCH_RETRIES = 5      // Nb de retry sur erreur de fetch (réseau), avec RETRY_PAUSE sec entre chaque
const RETRY_PAUSE = 2000         // Pause entre 2 retry
const QUERY_PAGE_SIZE = 750      // Taille d'un bloc de fetch des commandes sur AppWrite (pour éviter saturation sur grosses syncho, genre les Grands Utilisateurs)
const QUERY_PAGE_SIZE2 = 2500    // Taille d'un bloc de fetch pour le comptage (records plus "légers")

const AppWriteContextProvider = ({ children }) => {
   // const [appWriteInitDone, setAppWriteInitDone] = useState()  // undefined = pas encore testé, true = OK, false = afficher page "login"
   const [client, setClient] = useState(null)
   const [account, setAccount] = useState(null)
   const [db, setDb] = useState(null)
   const [otp, setOtp] = useState(null)
   // const [canSubscribe, setCanSubscribe] = useState(false)
   // const { log } = useLogger()
   //
   // const appWriteEndpoint = usePrefStore((state) => state.appWriteEndpoint)
   // const appWriteProjectId = usePrefStore((state) => state.appWriteProjectId)
   // const isLogged = usePrefStore((state) => state.isLogged)
   // const setIsLogged = usePrefStore((state) => state.setIsLogged)
   const { appWriteEndpoint, appWriteProjectId, appWriteDatabaseId, appWriteCollStockId, appWriteCollOtpId, appWriteCollCustomersId, appWriteCollBiOrdersId, appWriteCollOrdersId, appWriteCollPDFId, appWriteCollUdidId, isLogged, setIsLogged, appWriteCollListsId, appWriteCollTarifsId, appWriteCollVentesRALId, appWriteCollConfiesId, appWriteCollConfiesRALId, setCurTimeout, currentLang, login, udid, pwaApiKey, pwaApiEndpoint } = usePrefStore()
   //
   const { miscLogs, getVersion, formatNumber } = useHelpers()
   //
   const { getOrderById, order_status, updateOrderLocally } = useSales()
   //
   // const { fetch_names, fetchList } = useCollections()
   //
   const {
      tarif_regions,
      setCollections, checksum_collections, setChecksumCollections,
      setCouleurs, checksum_couleurs, setChecksumCouleurs,
      setCycles, checksum_cycles, setChecksumCycles,
      setDevises, checksum_devises, setChecksumDevises,
      setEtapes, checksum_etapes, setChecksumEtapes,
      setFamilles, checksum_familles, setChecksumFamilles,
      setGenres, checksum_genres, setChecksumGenres,
      setModels, checksum_models, setChecksumModels,
      setPortes, checksum_portes, setChecksumPortes,
      setTarifRegions, checksum_tarif_regions, setChecksumTarifRegions,
      setTarifTrads, checksum_tarif_trads, setChecksumTarifTrads,
      setTarifs, checksums_tarifs, setChecksumsTarifs,
   } = useModelsStore()

   const {
      setCountries, checksum_countries, setChecksumCountries,
      last_updated_customers, setLastUpdatedCustomers,
      last_updated_ventes_ral, setLastUpdatedVentesRAL,
      last_updated_confies, setLastUpdatedConfies,
      last_updated_confies_ral, setLastUpdatedConfiesRAL,
      last_updated_bi_orders, setLastUpdatedBiOrders,
      last_updated_orders, setLastUpdatedOrders,
   } = useSalesStore()
   //
   const { trans, transWithParams } = useTranslation();

   // console.log(logNames.appWrite, 'useAppwrite()')

   // console.log('*** AppWriteContext');

   /*
   ██ ███    ██ ██ ████████
   ██ ████   ██ ██    ██
   ██ ██ ██  ██ ██    ██
   ██ ██  ██ ██ ██    ██
   ██ ██   ████ ██    ██
   */

   useEffect(() => {
      if (appWriteEndpoint && appWriteProjectId) {
         const _client = new Client()
         const _account = new Account(_client)
         const _db = new Databases(_client)

         // console.log(logNames.appWrite, 'Client', _client)
         // console.log(logNames.appWrite, 'Account', _account)
         // console.log(logNames.appWrite, 'DB', _db)

         setClient(_client)
         setAccount(_account)
         setDb(_db)

         _client
            .setEndpoint(appWriteEndpoint)
            .setProject(appWriteProjectId)
         console.log(logNames.appWrite, `Connexion Appwrite(${appWriteEndpoint}, ${appWriteProjectId})`, _client)

         console.log('Check Account', appWriteEndpoint, appWriteProjectId);
         _account.get().then((data) => {
            console.log('Account OK', data);
            // setAppWriteInitDone(true)
            console.log('setIsLogged(true)');
            setIsLogged(true)
         }).catch(err => {
            if (err?.code === 401) {
               console.log('Account pas OK', err);
               // setAppWriteInitDone(false)
               console.log('setIsLogged(false)');
               setIsLogged(false)
            } else {
               // setAppWriteInitDone(true)
               console.log('Account pas OK mais erreur inconnue... sans doute offline!', JSON.stringify(err));
            }
         })
      }
   }, [appWriteEndpoint, appWriteProjectId, setIsLogged])

   /*
   ██       ██████   ██████  ██ ███    ██
   ██      ██    ██ ██       ██ ████   ██
   ██      ██    ██ ██   ███ ██ ██ ██  ██
   ██      ██    ██ ██    ██ ██ ██  ██ ██
   ███████  ██████   ██████  ██ ██   ████
   */

   const doLogin = useCallback(async (login, pwd) => {
      if (account) {
         try {
            const ret = await account.createEmailSession(login, pwd)
            // setIsLogged(true)
            console.log('Do login OK', account, ret)

            const prefs = await account.getPrefs()
            console.log('Prefs après', prefs);
            // setAppWriteDatabaseId(prefs.database_id)
            // setAppWriteProjectId(prefs.project_id)

            return { status: true, data: ret }
         } catch (err) {  // Failure
            console.log('Do login ERR', err)
            // setIsLogged(false)
            // setAppWriteDatabaseId(null)
            // setAppWriteProjectId(null)
            return { status: false, data: err }
         }
      } else {
         console.log('Do login ERR : account est null')
         return { status: false, data: null }
      }
   }, [account])

   const doLogout = useCallback(async () => {
      console.log('setIsLogged(false)');
      setIsLogged(false)   // Peu importe, on considère que c'est logged out...
      setCurTimeout(null)  // Idem pour le timer de session
      if (account) {
         try {
            await account.deleteSessions()
            console.log('Do logout OK', account)
            return true
         }
         catch (err) {  // Failure
            console.log('Do logout ERR', err)
            return false
         }
      } else {
         return true
      }
   }, [account, setCurTimeout, setIsLogged])

   const awPushUdid = useCallback((login) => {
      console.log('awPushUdid');
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            const obj = {
               login: login,
               version: getVersion(),
               last_seen: moment().toISOString(),
               user_agent: JSON.stringify({ user_agent: navigator.userAgent, standalone: (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) ? 'YES' : 'NO' }),
            }
            const id = udid
            try {
               // console.log(`createDocument()`, obj, id);
               await db.createDocument(appWriteDatabaseId, appWriteCollUdidId, id, obj)
            } catch (err) {
               if (err.code === 409) { // Already exists
                  // console.log(`updateDocument()`, obj);
                  try {
                     await db.updateDocument(appWriteDatabaseId, appWriteCollUdidId, id, obj)
                  } catch (err) {
                     console.error('UpdateDocument - CATCH ERR', err)
                  }
                  resolve()
               } else {
                  console.error('CreateDocument - CATCH ERR', err)
                  reject(new Error(err))
               }
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollUdidId, appWriteDatabaseId, client, db, getVersion, udid])

   const fetchOtp = useCallback(async () => {
      if (db) {
         try {
            const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollOtpId)

            console.log('getOtp OK', docs)
            if (docs.documents.length > 0) {
               setOtp(docs.documents[0])
               console.log(docs.documents[0]);
            } else {
               setOtp(null)
            }
         } catch (err) {
            console.log('getTOTP ERR', err)
            setOtp(null)
         }
      } else {
         setOtp(null)
      }
   }, [appWriteCollOtpId, appWriteDatabaseId, db])

   const verifOtp = useCallback(async (token) => {
      let ret = false
      try {
         console.log('API_KEY', pwaApiKey);
         console.log('login', login);
         await axios.get(`${pwaApiEndpoint}/otp/${login}/check`, {
            headers: { 'X-API-Key': pwaApiKey },
            params: {
               token
            },
         })
         console.log('Verif OTP OK')
         ret = true
      } catch (err) {
         console.log('Verif OTP NOK')
      }
      return ret
   }, [pwaApiEndpoint, pwaApiKey, login])

   const resetPasswordOtp = useCallback(async (token, password) => {
      let ret = false
      try {
         console.log('API_KEY', pwaApiKey);
         await axios.post(`${pwaApiEndpoint}/otp/${login}/reset_password`, {}, {
            headers: { 'X-API-Key': pwaApiKey },
            params: {
               token,
               password
            },
         })
         console.log('Reset password OTP OK')
         ret = true
      } catch (err) {
         console.log('Reset password OTP NOK')
      }
      return ret
   }, [pwaApiEndpoint, pwaApiKey, login])

   /*
    ██████  ██████  ███    ███ ███    ███  ██████  ███    ██
   ██      ██    ██ ████  ████ ████  ████ ██    ██ ████   ██
   ██      ██    ██ ██ ████ ██ ██ ████ ██ ██    ██ ██ ██  ██
   ██      ██    ██ ██  ██  ██ ██  ██  ██ ██    ██ ██  ██ ██
    ██████  ██████  ██      ██ ██      ██  ██████  ██   ████
   */

   const findMostRecentUpdate = useCallback(async (table) => {
      const all_items = await dexie_db[table].toCollection().toArray()

      return all_items.reduce((prev, an_item) => {
         const utc = moment.utc(an_item.last_updated)
         // console.log(an_item.last_updated, utc, utc.toISOString());
         if (!prev || utc > prev) {
            return utc
         } else {
            return prev
         }
      }, null)
   }, [])

   /**
   * Récupération d'un document d'une collection (AppWrite) comme un "Tarif" ou une "Liste" (collections, familles, portes etc) à partir de son nom et du dernier checksum connu.
   * On récupère si checksum différent
   */
   const getCollectionItem = useCallback(async (coll_id, name, checksum) => {
      console.log('getCollectionItem', coll_id, name, checksum);
      if (db) {
         try {
            const docs = await db.listDocuments(appWriteDatabaseId, coll_id, [
               Query.equal('$id', name),
               Query.notEqual('checksum', checksum),
               // Query.limit(99999),
            ])

            if (docs.total === 0) {
               return { status: true, data: null }    // data = null indique qu'il n'y a pas de doc plus récent
            } else {
               return { status: true, data: docs.documents[0] }
            }
         } catch (err) {
            // Si err.code === 401 -> pas loggué
            // Sinon c'est peut-être un pb de connexion...
            return { status: false, data: err }
         }
      } else {
         return { status: false, data: null }
      }
   }, [appWriteDatabaseId, db])

   /*
   ███████ ████████  ██████   ██████ ██   ██ ███████
   ██         ██    ██    ██ ██      ██  ██  ██
   ███████    ██    ██    ██ ██      █████   ███████
        ██    ██    ██    ██ ██      ██  ██       ██
   ███████    ██     ██████   ██████ ██   ██ ███████
   */

   /*
      - J01 Messika group France 
      - U01 Messika USA 
      - H01 Messika HKG 
      - Y01 Messika Japon 
      - C01 Messika Chine 
   */

   const getStock = useCallback(async (model_id, depot) => {
      const doc_id = `${depot ?? glob_const.depot_defaut}-${model_id}`

      console.log(doc_id);
      if (db) {
         try {
            const stocks = await db.getDocument(appWriteDatabaseId, appWriteCollStockId, doc_id)

            console.log('getStock OK', stocks)
            return { status: true, data: stocks.sku_stocks }
         } catch (err) {
            // Si err.code === 401 -> pas loggué
            // Sinon c'est peut-être un pb de connexion...
            console.log('getStock ERR', err)
            return { status: false, data: err }
         }
      } else {
         return { status: false, data: null }
      }
   }, [appWriteCollStockId, appWriteDatabaseId, db])

   /*
   ██      ██ ███████ ████████ ███████
   ██      ██ ██         ██    ██
   ██      ██ ███████    ██    ███████
   ██      ██      ██    ██         ██
   ███████ ██ ███████    ██    ███████
   */

   // Fetch générique pour collections, couleurs, cycles, etapes, familles, genres, portes
   const fetchList = useCallback((name, setList, checksum, setChecksum) => {
      return new Promise((resolve, reject) => {
         console.log(`Fetch ${name}`)

         getCollectionItem(appWriteCollListsId, name, checksum ?? '').then(res => {
            if (res.status) {
               const doc = res.data

               if (doc) {  // On a fetché un doc plus récent
                  console.log(`fetch ${name} > OK`);
                  // console.log(doc.data);
                  // console.log(JSON.parse(doc.data));
                  // console.log(JSON.parse(doc.data));
                  try { // Permet de s'assurer que le JSON est OK
                     setList(JSON.parse(doc.data))
                     setChecksum(doc.checksum)
                     resolve()
                  } catch (err) {
                     console.log(`fetch ${name} > ERR`, err);
                     reject(new Error(err))
                  }
               } else {    // On n'a pas fetché de doc, donc pas trouvé de plus récent
                  console.log(`fetch ${name} > Doc null`);
                  if (!checksum) {  // Oui, mais on n'a rien actuellement dans la base, donc ça signifie qu'il y a un pb !
                     console.log(`fetch ${name} > ERR`);
                     reject(new Error(`${name}: Not found and no local data!`))
                  } else {    // On garde les data locales récupérées d'un fetch précédent
                     resolve()
                  }
               }
            } else {
               console.log(`fetch ${name} > ERR`, res);
               reject(new Error(res?.data?.message ?? 'Erreur inconnue'))
            }
         }).catch(err => {
            console.log(`fetch ${name} > ERR`, err);
            reject(new Error('Erreur inconnue'))
         })
      })
   }, [appWriteCollListsId, getCollectionItem])

   /*
    ██████ ██    ██ ███████ ████████  ██████  ███    ███ ███████ ██████  ███████
   ██      ██    ██ ██         ██    ██    ██ ████  ████ ██      ██   ██ ██
   ██      ██    ██ ███████    ██    ██    ██ ██ ████ ██ █████   ██████  ███████
   ██      ██    ██      ██    ██    ██    ██ ██  ██  ██ ██      ██   ██      ██
    ██████  ██████  ███████    ██     ██████  ██      ██ ███████ ██   ██ ███████
   */

   const buildCustomerObject = (appWriteObject) => {
      // console.log(appWriteObject['$updatedAt'], moment.utc(appWriteObject['$updatedAt']), moment.utc(appWriteObject['$updatedAt']).toISOString());
      return {
         id: appWriteObject.number,
         name: appWriteObject.name,
         phones: appWriteObject.phones,
         email: appWriteObject.email,
         tarifs: JSON.parse(appWriteObject.tariffs),
         // agent: appWriteObject.agent,
         // representant: appWriteObject.representant,
         // responsable: appWriteObject.responsable,
         depot: appWriteObject.depot,
         // groupe: appWriteObject.groupe,
         credit: appWriteObject.credit,
         factures_ouvertes: appWriteObject.factures_ouvertes,
         contacts: appWriteObject.contacts.reduce((prev, a_contact) => {
            const contact = JSON.parse(a_contact)
            return { ...prev, [contact.id]: contact }
         }, {}),
         addresses: appWriteObject.addresses.reduce((prev, an_address) => {
            const address = JSON.parse(an_address)
            return { ...prev, [address.id]: address }
         }, {}),
         last_updated: moment.utc(appWriteObject['$updatedAt']).toISOString(),//format(),
      }
   }

   const addCustomer = useCallback(async (a_customer) => {
      let added = 0, updated = 0

      const res = await dexie_db.customers.get(a_customer.number);
      // console.log('>>>3', res);
      if (res === undefined) {
         // console.log('>>>4', buildCustomerObject(a_customer));
         await dexie_db.customers.add(buildCustomerObject(a_customer));
         added = 1
         // console.log(`Order ${an_order.id} added as ${id}`);
      } else {
         // console.log('>>>5', buildCustomerObject(a_customer));
         await dexie_db.customers.put(buildCustomerObject(a_customer));
         updated = 1
         // console.log(`Order ${an_order.id} updated`);
      }
      return { added, updated }
   }, [])

   const deleteCustomer = useCallback(async (an_id) => {
      try {
         await dexie_db.customers.delete(an_id);
      } catch (err) {
         console.log(`Pb delete customer ${an_id}`, err);
      }
   }, [])

   const fetchCustomers = useCallback((newLogin) => {
      return new Promise(async (resolve, reject) => {
         let added = 0, updated = 0

         if (db && client) {
            try {
               const arr_queries = [
                  Query.limit(99999),
               ]
               if (!newLogin && last_updated_customers) {
                  arr_queries.push(Query.greaterThan('$updatedAt', moment.utc(last_updated_customers).toISOString()))
               }
               arr_queries.push(Query.orderAsc('$updatedAt'))
               console.log('last_updated_customers', last_updated_customers,);
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollCustomersId, arr_queries)

               console.log('fetchCustomers', docs);
               if (docs.total === 0) {
                  resolve()
                  // return { status: true, data: null }    // data = null indique qu'il n'y a pas de doc plus récent
               } else {
                  // console.log('>>>1');
                  for (const a_customer of docs.documents) {
                     // console.log('>>>2');
                     const ret = await addCustomer(a_customer)
                     added += ret.added
                     updated += ret.updated
                     // console.log('>>>6');
                  }
                  // console.log('>>>7');
                  console.log(`fetchCustomers: added=${added}, updated=${updated}`);
                  resolve()
                  // return { status: true, data: null }
               }
            } catch (err) {
               // console.log('>>>8', err);
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               console.log('Erreur de format de données pour Customers !');
               reject(new Error(err))
               // return { status: false, data: err }
            }
         } else {
            // console.log('>>>9');
            reject(new Error('Pas de connection DB'))
            // return { status: false, data: null }
         }
      })
   }, [addCustomer, appWriteCollCustomersId, appWriteDatabaseId, client, db, last_updated_customers])

   const cleanCustomers = useCallback(() => {
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollCustomersId, [
                  Query.select(['number']),  // On n'a besoin que de l'ID pour purger
                  Query.limit(99999),
               ])

               console.log(`cleanCustomers : ${docs.documents.length} docs`)
               if (docs.total > 0) {
                  const keep_ids = docs.documents.reduce((prev, a_doc) => {
                     // console.log(a_doc);
                     return new Set([...prev, a_doc.number])
                  }, new Set())

                  console.log(`Keep ${keep_ids.size} ids`);

                  const all_customers = await dexie_db.customers.toCollection().toArray()

                  for (const a_customer of all_customers) {
                     const an_id = a_customer.id
                     // console.log(`Check ${an_id} `);
                     if (!keep_ids.has(an_id)) {
                        console.log(`Delete customer ${an_id}`)
                        deleteCustomer(an_id)
                        // } else {
                        //    console.log(`Garder customer ${an_id}`)
                     }
                  }
               }
               resolve()
            } catch (err) {
               // console.log('>>>8', err);
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               reject(new Error(err))
               // return { status: false, data: err }
            }
         } else {
            // console.log('>>>9');
            reject(new Error('Pas de connection DB'))
            // return { status: false, data: null }
         }
      })
   }, [appWriteCollCustomersId, appWriteDatabaseId, client, db, deleteCustomer])

   /*
    ██████  ██████  ██████  ███████ ██████  ███████
   ██    ██ ██   ██ ██   ██ ██      ██   ██ ██
   ██    ██ ██████  ██   ██ █████   ██████  ███████
   ██    ██ ██   ██ ██   ██ ██      ██   ██      ██
    ██████  ██   ██ ██████  ███████ ██   ██ ███████
   */

   // Sert à préparer un objet pour un 'add' ou un 'put' dans Dexie, depuis un objet AppWrite
   const _buildOrderObjectFromAppWriteObject = useCallback((is_bi, appWriteObject, readonly) => {
      // console.log(appWriteObject['$updatedAt'], moment.utc(appWriteObject['$updatedAt']), moment.utc(appWriteObject['$updatedAt']).toISOString());
      const obj = {
         /**
          * ATTENTION, ce modèle doit être compatible avec useSales.makeTemplate()
          */
         id: appWriteObject.id,
         customer_id: appWriteObject.cust_id,
         type: appWriteObject.type,
         date: moment(appWriteObject.date).format('YYYY-MM-DD'),
         time: moment(appWriteObject.date).format('HH:mm'),
         delivery: moment(appWriteObject.deliv_date).format('YYYY-MM-DD'),
         addresses: {
            jewels: appWriteObject.deliv_addr,
            ecrins: appWriteObject.ecrins_addr,
         },
         comment: appWriteObject.comment,
         mark: appWriteObject.mark,
         signature: appWriteObject.signature ? JSON.parse(appWriteObject.signature) : null,
         lines: appWriteObject.lines.map(a_line => {
            return JSON.parse(a_line)
         }),
         // synced: is_bi ? null : false,
         readonly: is_bi || readonly,
         status: is_bi ? order_status.done_m3 : appWriteObject.status,
         last_pushed: null,      // Sert a ne pas refresh l'objet dans Dexie quand on push vers AppWrite et qu'on obtient un event en retour. S'il y a moins de {glob_const.min_delay_on_push} secondes (15), on ne refresh pas la BDD locale (Dexie)
         last_updated: moment.utc(appWriteObject['$updatedAt']).toISOString(),//format(),
      }
      if (is_bi) {
         return {
            currency: is_bi ? appWriteObject.currency : null,
            ...obj
         }
      } else {
         // short_udid: appWriteObject.short_udid,
         return obj
      }
      // return obj
   }, [order_status])

   const buildOrderObjectFromAppWriteObject = useCallback((appWriteObject, readonly) => {
      return _buildOrderObjectFromAppWriteObject(false, appWriteObject, readonly)
   }, [_buildOrderObjectFromAppWriteObject])

   const buildBiOrderObject = useCallback((appWriteObject) => {
      return _buildOrderObjectFromAppWriteObject(true, appWriteObject, true)
   }, [_buildOrderObjectFromAppWriteObject])

   const _addOrder = useCallback(async (is_bi, an_order) => {
      let added = 0, updated = 0, res

      // console.log('_addOrder', is_bi, an_order);
      if (is_bi) {
         res = await dexie_db.bi_orders.get(an_order.id);
      } else {
         res = await dexie_db.orders.get(an_order.id);
      }
      if (res === undefined) {
         if (is_bi) {
            await dexie_db.bi_orders.add(buildBiOrderObject(an_order));
         } else {
            await dexie_db.orders.add(buildOrderObjectFromAppWriteObject(an_order, an_order.status !== order_status.new));
         }
         added = 1
      } else {
         if (is_bi) {
            await dexie_db.bi_orders.put(buildBiOrderObject(an_order));
         } else {
            await dexie_db.orders.put(buildOrderObjectFromAppWriteObject(an_order, an_order.status !== order_status.new));
         }
         updated = 1
      }
      return { added, updated }
   }, [buildBiOrderObject, buildOrderObjectFromAppWriteObject, order_status.new])

   const addOrder = useCallback((an_order) => {
      return _addOrder(false, an_order)
   }, [_addOrder])

   const addBiOrder = useCallback((an_order) => {
      return _addOrder(true, an_order)
   }, [_addOrder])

   const _deleteLocalOrder = useCallback(async (is_bi, an_id) => {
      try {
         if (is_bi) {
            await dexie_db.bi_orders.delete(an_id);
         } else {
            await dexie_db.orders.delete(an_id);
         }
         return true
      } catch (err) {
         console.log(`Pb delete ${is_bi ? 'bi_' : ''}order ${an_id}`, err);
         return false
      }
   }, [])

   const deleteLocalOrder = useCallback(async (an_id) => {
      return await _deleteLocalOrder(false, an_id)
   }, [_deleteLocalOrder])

   /* Vraiment utile ? Pq voudrait-on supprimer une commande BI depuis l'appli ? */
   // const deleteBiOrder = useCallback(async (an_id) => {
   //    return await _deleteLocalOrder(true, an_id)
   // }, [_deleteLocalOrder])

   const awDeleteOrder = useCallback((id) => {
      console.log('awDeleteOrder');
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               await db.deleteDocument(appWriteDatabaseId, appWriteCollOrdersId, id)
               // await deleteLocalOrder(id)  // Tout s'est bien passé, on ne supprime de la base locale, ce sera fait par un retour d'événement (subscription)
               resolve()
            } catch (err) {
               console.error('deleteDocument - CATCH ERR', err)
               if (err.code === 404) {
                  await deleteLocalOrder(id)  // Document inconnu sur AW, on le supprime de la base locale car on n'aura pas de retour d'événement (subscription)
                  resolve() // On considère que tout s'est finalement bien passé
               } else {
                  reject(new Error('Pb suppression doc'))
               }
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollOrdersId, appWriteDatabaseId, client, db, deleteLocalOrder])

   const _fetchOrders = useCallback((newLogin, is_bi, fetchSubProgress) => {
      return new Promise(async (resolve, reject) => {
         let added = 0, updated = 0
         let loop = 0
         let total_to_fetch = 0
         let total_fetched = 0

         // console.log('QUERY', Query.limit(QUERY_PAGE_SIZE), Query.offset(10), Query.greaterThan('$updatedAt', moment.utc(last_updated_bi_orders).toISOString()));

         console.log('LAST BI', last_updated_bi_orders, newLogin)

         if (db && client) {
            try {
               const arr_queries = []
               if (!newLogin) {
                  if (is_bi && last_updated_bi_orders) {
                     arr_queries.push(Query.greaterThan('$updatedAt', moment.utc(last_updated_bi_orders).toISOString()))
                     console.log('last_updated_bi_orders', last_updated_bi_orders);
                  } else if (!is_bi && last_updated_orders) {
                     arr_queries.push(Query.greaterThan('$updatedAt', moment.utc(last_updated_orders).toISOString()))
                     console.log('last_updated_orders', last_updated_orders);
                  }
               }
               arr_queries.push(Query.orderAsc('$updatedAt'))

               // On fait une 1ère série de requetes juste pour compter le nb de docs.
               // On perd du temps mais impossible de récupérer un comptage autrement.
               // Ca permet ensuite de donner une info d'attente à l'utilisateur.
               let docs
               fetchSubProgress(`${trans("?Vérification des commandes")}...`)
               let finished = false
               loop = 0
               // Etape 1, on cherche le dernier segment de QUERY_PAGE_SIZE2 records en fetchant seulement le dernier record de chaque segment pour rester "léger"
               while (!finished) {
                  let max_retry = MAX_FETCH_RETRIES
                  while (max_retry > 0) {
                     try {
                        // console.log('***1', is_bi, loop);
                        docs = await db.listDocuments(appWriteDatabaseId, is_bi ? appWriteCollBiOrdersId : appWriteCollOrdersId, [
                           ...arr_queries,
                           Query.limit(1),         // Un seul record
                           Query.offset(((loop + 1) * QUERY_PAGE_SIZE2) - 1),
                           Query.select(['$id'])   // Un seul champ
                        ])
                        if (docs.documents.length === 1) {
                           loop++
                        } else {
                           finished = true
                        }
                        // console.log('***2', is_bi, docs, docs.documents.length);
                        max_retry = 0  // On arrête les tentatives puisque c'est réussi.
                     } catch (err) {
                        console.log('***5bis', err);
                        // On fait une pause de 2sec pour éviter des erreurs à répétition. Est-ce utile ???
                        await new Promise(resolve => setTimeout(resolve, RETRY_PAUSE))
                        console.log('***6', 'Après pause')
                        if (--max_retry <= 0) {
                           throw err
                        }
                     }
                  }
               }
               // Etape 2, on fetch le dernier segment pour connaître le compte total
               // console.log('***3', is_bi, loop);
               docs = await db.listDocuments(appWriteDatabaseId, is_bi ? appWriteCollBiOrdersId : appWriteCollOrdersId, [
                  ...arr_queries,
                  Query.limit(QUERY_PAGE_SIZE2),    // Au max QUERY_PAGE_SIZE2 records
                  Query.offset(loop * QUERY_PAGE_SIZE2),
                  Query.select(['$id'])   // Un seul champ
               ])
               total_to_fetch = (loop * QUERY_PAGE_SIZE2) + docs.documents.length
               // console.log('***4', is_bi, loop, docs.documents.length, total_to_fetch);

               finished = false
               loop = 0
               // Maintenant, on boucle sans fin des fetch jusqu'à plus de fetch ou erreur
               while (!finished) {
                  console.log(`Gonna fetch${is_bi ? 'Bi' : ''}Orders: bloc ${loop}`, [
                     ...arr_queries,
                     Query.limit(QUERY_PAGE_SIZE),
                     Query.offset(loop * QUERY_PAGE_SIZE)
                  ]);

                  let max_retry = MAX_FETCH_RETRIES
                  while (max_retry > 0) {
                     try {
                        docs = await db.listDocuments(appWriteDatabaseId, is_bi ? appWriteCollBiOrdersId : appWriteCollOrdersId, [
                           ...arr_queries,
                           Query.limit(QUERY_PAGE_SIZE),
                           Query.offset(loop * QUERY_PAGE_SIZE)
                        ])
                        loop++
                        max_retry = 0  // On arrête les tentatives puisque c'est réussi.
                     } catch (err) {
                        console.log('***5', err);
                        // On fait une pause de 2sec pour éviter des erreurs à répétition. Est-ce utile ???
                        await new Promise(resolve => setTimeout(resolve, RETRY_PAUSE))
                        console.log('***6', 'Après pause')
                        if (--max_retry <= 0) {
                           throw err
                        }
                     }
                  }

                  console.log(`fetch${is_bi ? 'Bi' : ''}Orders: bloc ${loop}, ${docs.documents.length} docs`);
                  if (docs.documents.length === 0) {
                     finished = true
                     // resolve()
                  } else {
                     total_fetched += docs.documents.length
                     if (is_bi) {
                        fetchSubProgress(`${transWithParams("?hist_commandes", formatNumber(total_to_fetch), total_to_fetch)} ${Math.round(total_fetched * 100 / total_to_fetch)}%`)
                     }
                     for (const an_order of docs.documents) {
                        let ret

                        if (is_bi) {
                           ret = await addBiOrder(an_order)
                        } else {
                           ret = await addOrder(an_order)
                        }
                        added += ret.added
                        updated += ret.updated
                     }
                     console.log(`fetch${is_bi ? 'Bi' : ''}Orders: bloc=${loop} added=${added}, updated=${updated}`);
                     // finished = true
                     // resolve()
                     if (docs.documents.length < QUERY_PAGE_SIZE) {
                        finished = true
                     }
                  }
               }
               resolve()
            } catch (err) {
               console.log(`Erreur de format de données (bloc=${loop}) pour ${is_bi ? 'Bi' : ''}Orders !`);
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [last_updated_bi_orders, db, client, trans, appWriteDatabaseId, appWriteCollBiOrdersId, appWriteCollOrdersId, last_updated_orders, transWithParams, formatNumber, addBiOrder, addOrder])

   const fetchOrders = useCallback((newLogin, fetchSubProgress) => {
      return _fetchOrders(newLogin, false, fetchSubProgress)
   }, [_fetchOrders])

   const fetchBiOrders = useCallback((newLogin, fetchSubProgress) => {
      return _fetchOrders(newLogin, true, fetchSubProgress)
   }, [_fetchOrders])

   // IMPORTANT : On ne clean plus les Orders BI comme les Orders. Si 1ere sync (last_updated_bi_orders === null) on purge la table Dexie simplement.
   const _cleanOrders = useCallback((is_bi) => {
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               console.log(`${moment().unix()} : Avant query clean${is_bi ? 'Bi' : ''}Orders`)

               if (is_bi) {
                  if (!last_updated_bi_orders) {
                     console.log(`${moment().unix()} : Purge BiOrders`)
                     await dexie_db.bi_orders.toCollection().toArray()
                  }
               } else {
                  const arr_queries = [
                     Query.select(['id']),  // On n'a besoin que de l'ID pour purger
                     Query.limit(99999),
                  ]
                  if (last_updated_orders) {
                     arr_queries.push(Query.greaterThan('$updatedAt', moment.utc(last_updated_orders).subtract(1, 'month').toISOString()))
                  }
                  arr_queries.push(Query.orderAsc('$updatedAt'))
                  const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollOrdersId, arr_queries)

                  console.log(`${moment().unix()} : clean Orders : ${docs.documents.length} docs`)
                  if (docs.total > 0) {
                     // const keep_ids = docs.documents.reduce((prev, a_doc) => {
                     //    return new Set([...prev, a_doc.id])
                     // }, new Set())
                     const keep_ids = new Set()
                     for (const a_doc of docs.documents) {
                        keep_ids.add(a_doc.id)
                     }

                     console.log(`${moment().unix()} : Keep ${keep_ids.size} ids`);

                     let all_orders
                     all_orders = await dexie_db.orders.toCollection().toArray()

                     console.log(`${moment().unix()} : Processing...`);

                     for (const an_order of all_orders) {
                        if ((an_order.last_updated !== null) || (an_order.last_pushed !== null)) {
                           /* On ne vérifie la suppression éventuelle dans AW que si la commande
                               a déjà été poussée au moins une fois vers AW. C'est pour éviter de
                               détecter une absence sur AW pour une commande qui aurait été créée
                               durant une période de déconnexion d'Internet
                           */
                           const an_id = an_order.id
                           if (!keep_ids.has(an_id)) {
                              // console.log(`Delete order ${an_id}`)
                              deleteLocalOrder(an_id)
                           }
                        } else {
                           if ((an_order.last_updated === null) && (an_order.last_pushed === null)) {
                              console.log('LAST UPD null', an_order)
                           }
                        }
                     }
                     console.log(`${moment().unix()} : Done!`);
                  }
               }
               resolve()
            } catch (err) {
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollOrdersId, appWriteDatabaseId, client, db, deleteLocalOrder, last_updated_bi_orders, last_updated_orders])

   const cleanOrders = useCallback(() => {
      return _cleanOrders(false)
   }, [_cleanOrders])

   const cleanBiOrders = useCallback(() => {
      return _cleanOrders(true)
   }, [_cleanOrders])

   // Push une commande locale. Les commandes BI ne sont pas concernées, évidemment...
   const awPushOrder = useCallback((id) => {
      // console.log('awPushOrder');
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               const doc = await getOrderById(id)
               const obj = {
                  id,
                  // short_udid: doc.short_udid,
                  type: doc.type,
                  cust_id: doc.customer_id,
                  date: doc.date,
                  deliv_date: doc.delivery,
                  comment: doc.comment,
                  mark: doc.mark,
                  signature: doc.signature ? JSON.stringify(doc.signature) : null,
                  deliv_addr: doc.addresses.jewels,
                  ecrins_addr: doc.addresses.ecrins,
                  lines: doc.lines.map(a_line => JSON.stringify(a_line)),
                  status: (doc.status === order_status.locked4sync) ? order_status.locked : doc.status,
               }
               // console.log(`getOrderById(${id})`, doc);
               // console.log('Update last pushed:', id, { last_pushed: moment().toISOString() });
               // await dexie_db.orders.update(id, { last_pushed: moment().toISOString() });
               await updateOrderLocally(id, { last_pushed: moment().toISOString() })
               try {
                  // console.log(`createDocument()`, obj);
                  await db.createDocument(appWriteDatabaseId, appWriteCollOrdersId, id, obj)
                  await updateOrderLocally(id, { status: obj.status })  // Tout s'est bien passé, on remplace le status local qui a peut-être été envoyé en "locked" vers AppWrite (cas où il était encore locaen locked4sync, voir qq lignes plus haut)
               } catch (err) {
                  if (err.code === 409) { // Already exists
                     // console.log(`updateDocument()`, obj);
                     try {
                        await db.updateDocument(appWriteDatabaseId, appWriteCollOrdersId, id, obj)
                        await updateOrderLocally(id, { status: obj.status })  // Tout s'est bien passé, on remplace le status local qui a peut-être été envoyé en "locked" vers AppWrite (cas où il était encore locaen locked4sync, voir qq lignes plus haut)
                     } catch (err) {
                        console.error('updateDocument - CATCH ERR', err)
                        // await dexie_db.orders.update(id, { last_pushed: null });
                        await updateOrderLocally(id, { last_pushed: null })
                     }
                  } else {
                     console.error('CreateDocument - CATCH ERR', err)
                     // await dexie_db.orders.update(id, { last_pushed: null });
                     await updateOrderLocally(id, { last_pushed: null })
                  }
               }
               resolve()
            } catch (err) {
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollOrdersId, appWriteDatabaseId, client, db, getOrderById, order_status, updateOrderLocally])

   /**
    * Renvoie les commandes qui sont restées non syncho (push vers M3 ou deleted)
    */
   const awPushWaitingOrders = useCallback(async () => {
      // console.log('awPushWaitingOrders');
      const arr = await dexie_db.orders.where('status').anyOf([order_status.locked4sync, order_status.deleted]).toArray()

      console.log('awPushWaitingOrders', arr);
      for (const an_order of arr) {
         console.log('ICI', an_order);
         if (an_order.status === order_status.locked4sync) {
            awPushOrder(an_order.id)
         } else if (an_order.status === order_status.deleted) {
            awDeleteOrder(an_order.id)
         }
      }
      // console.log('LA');
   }, [awDeleteOrder, order_status.deleted, order_status.locked4sync, awPushOrder])

   /*
   ██████  ███████ ███████ ████████ ███████      █████      ██      ██ ██    ██ ██████  ███████ ██████
   ██   ██ ██      ██         ██    ██          ██   ██     ██      ██ ██    ██ ██   ██ ██      ██   ██
   ██████  █████   ███████    ██    █████       ███████     ██      ██ ██    ██ ██████  █████   ██████
   ██   ██ ██           ██    ██    ██          ██   ██     ██      ██  ██  ██  ██   ██ ██      ██   ██
   ██   ██ ███████ ███████    ██    ███████     ██   ██     ███████ ██   ████   ██   ██ ███████ ██   ██
   */

   // Fetch Reste à Livrer Ventes
   const buildVentesRALObject = (appWriteObject) => {
      return {
         id: appWriteObject.cust_id,
         ral_euro: appWriteObject.valeur_backorder_euro,
         ral_dollar: appWriteObject.valeur_backorder_dollar,
         ral: appWriteObject.qte_backorder,
         qte: appWriteObject.qte,
         ca_euro: appWriteObject.ca_euro,
         ca_dollar: appWriteObject.ca_dollar,
         last_updated: moment.utc(appWriteObject['$updatedAt']).toISOString(),
      }
   }

   const addVentesRAL = useCallback(async (a_ral) => {
      let added = 0, updated = 0

      // console.log('addVentesRAL', a_ral)
      const res = await dexie_db.ventes_ral.get(a_ral.cust_id);
      if (res === undefined) {
         await dexie_db.ventes_ral.add(buildVentesRALObject(a_ral));
         added = 1
      } else {
         await dexie_db.ventes_ral.put(buildVentesRALObject(a_ral));
         updated = 1
      }
      return { added, updated }
   }, [])

   const deleteVentesRAL = useCallback(async (an_id) => {
      try {
         await dexie_db.ventes_ral.delete(an_id);
      } catch (err) {
         console.log(`Pb delete Ventes RAL ${an_id}`, err);
      }
   }, [])

   const fetchVentesRAL = useCallback((newLogin) => {
      return new Promise(async (resolve, reject) => {
         let added = 0, updated = 0

         if (db && client) {
            try {
               const arr_queries = [
                  Query.limit(99999),
               ]
               if (!newLogin && last_updated_ventes_ral) {
                  arr_queries.push(Query.greaterThan('$updatedAt', moment.utc(last_updated_ventes_ral).toISOString()))
               }
               arr_queries.push(Query.orderAsc('$updatedAt'))
               console.log('last_updated_ventes_ral', last_updated_ventes_ral,);
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollVentesRALId, arr_queries)

               // console.log('fetchVentesRAL', docs);
               if (docs.total === 0) {
                  resolve()
               } else {
                  for (const a_ral of docs.documents) {
                     const ret = await addVentesRAL(a_ral)
                     added += ret.added
                     updated += ret.updated
                  }
                  console.log(`fetchVentesRAL: added=${added}, updated=${updated}`);
                  resolve()
               }
            } catch (err) {
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               console.log('Erreur de format de données pour Ventes RAL !');
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [addVentesRAL, appWriteCollVentesRALId, appWriteDatabaseId, client, db, last_updated_ventes_ral])

   const cleanVentesRAL = useCallback(() => {
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollVentesRALId, [
                  Query.select(['cust_id']),  // On n'a besoin que de l'ID pour purger
                  Query.limit(99999),
               ])

               console.log(`cleanVentesRAL : ${docs.documents.length} docs`)
               // if (docs.total > 0) {
               const keep_ids = docs.documents.reduce((prev, a_doc) => {
                  // console.log(a_doc);
                  return new Set([...prev, a_doc.cust_id])
               }, new Set())

               console.log(`Keep ${keep_ids.size} Ventes RAL ids`);

               const all_customers = await dexie_db.ventes_ral.toCollection().toArray()

               for (const a_customer of all_customers) {
                  const an_id = a_customer.id
                  if (!keep_ids.has(an_id)) {
                     console.log(`Delete RAL for customer ${an_id}`)
                     deleteVentesRAL(an_id)
                  }
               }
               // }
               resolve()
            } catch (err) {
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollVentesRALId, appWriteDatabaseId, client, db, deleteVentesRAL])

   /*
    ██████  ██████  ███    ██ ███████ ██ ███████ ███████
   ██      ██    ██ ████   ██ ██      ██ ██      ██
   ██      ██    ██ ██ ██  ██ █████   ██ █████   ███████
   ██      ██    ██ ██  ██ ██ ██      ██ ██           ██
    ██████  ██████  ██   ████ ██      ██ ███████ ███████
   */

   // Fetch Confies
   const buildConfiesObject = (appWriteObject) => {
      return {
         id: appWriteObject.cust_id,
         qte_site: appWriteObject.qte_site,
         ca_site_euro: appWriteObject.ca_site_euro,
         ca_site_dollar: appWriteObject.ca_site_dollar,
         last_updated: moment.utc(appWriteObject['$updatedAt']).toISOString(),
      }
   }

   const addConfies = useCallback(async (a_ral) => {
      let added = 0, updated = 0

      // console.log('addConfies', a_ral)
      const res = await dexie_db.confies.get(a_ral.cust_id);
      if (res === undefined) {
         await dexie_db.confies.add(buildConfiesObject(a_ral));
         added = 1
      } else {
         await dexie_db.confies.put(buildConfiesObject(a_ral));
         updated = 1
      }
      return { added, updated }
   }, [])

   const deleteConfies = useCallback(async (an_id) => {
      try {
         await dexie_db.confies.delete(an_id);
      } catch (err) {
         console.log(`Pb delete Confies RAL ${an_id}`, err);
      }
   }, [])

   const fetchConfies = useCallback((newLogin) => {
      return new Promise(async (resolve, reject) => {
         let added = 0, updated = 0

         if (db && client) {
            try {
               const arr_queries = [
                  Query.limit(99999),
               ]
               if (!newLogin && last_updated_confies) {
                  arr_queries.push(Query.greaterThan('$updatedAt', moment.utc(last_updated_confies).toISOString()))
               }
               arr_queries.push(Query.orderAsc('$updatedAt'))
               console.log('last_updated_confies', last_updated_confies,);
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollConfiesId, arr_queries)

               // console.log('fetchConfies', docs);
               if (docs.total === 0) {
                  resolve()
               } else {
                  for (const a_ral of docs.documents) {
                     const ret = await addConfies(a_ral)
                     added += ret.added
                     updated += ret.updated
                  }
                  console.log(`fetchConfies: added=${added}, updated=${updated}`);
                  resolve()
               }
            } catch (err) {
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               console.log('Erreur de format de données pour Confies RAL !');
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [addConfies, appWriteCollConfiesId, appWriteDatabaseId, client, db, last_updated_confies])

   const cleanConfies = useCallback(() => {
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollConfiesId, [
                  Query.select(['cust_id']),  // On n'a besoin que de l'ID pour purger
                  Query.limit(99999),
               ])

               console.log(`cleanConfies : ${docs.documents.length} docs`)
               if (docs.total > 0) {
                  const keep_ids = docs.documents.reduce((prev, a_doc) => {
                     // console.log(a_doc);
                     return new Set([...prev, a_doc.cust_id])
                  }, new Set())

                  console.log(`Keep ${keep_ids.size} Confies RAL ids`);

                  const all_customers = await dexie_db.confies.toCollection().toArray()

                  for (const a_customer of all_customers) {
                     const an_id = a_customer.id
                     if (!keep_ids.has(an_id)) {
                        console.log(`Delete customer ${an_id}`)
                        deleteConfies(an_id)
                     }
                  }
               }
               resolve()
            } catch (err) {
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollConfiesId, appWriteDatabaseId, client, db, deleteConfies])

   /*
    ██████  ██████  ███    ██ ███████ ██ ███████ ███████     ██████   █████  ██
   ██      ██    ██ ████   ██ ██      ██ ██      ██          ██   ██ ██   ██ ██
   ██      ██    ██ ██ ██  ██ █████   ██ █████   ███████     ██████  ███████ ██
   ██      ██    ██ ██  ██ ██ ██      ██ ██           ██     ██   ██ ██   ██ ██
    ██████  ██████  ██   ████ ██      ██ ███████ ███████     ██   ██ ██   ██ ███████
   */

   // Fetch Reste à Livrer Confiés
   const buildConfiesRALObject = (appWriteObject) => {
      return {
         id: appWriteObject.cust_id,
         qte: appWriteObject.qte,
         // ca: appWriteObject.ca,
         ca_euro: appWriteObject.ca_euro,
         ca_dollar: appWriteObject.ca_dollar,
         last_updated: moment.utc(appWriteObject['$updatedAt']).toISOString(),
      }
   }

   const addConfiesRAL = useCallback(async (a_ral) => {
      let added = 0, updated = 0

      // console.log('addConfiesRAL', a_ral)
      const res = await dexie_db.confies_ral.get(a_ral.cust_id);
      if (res === undefined) {
         await dexie_db.confies_ral.add(buildConfiesRALObject(a_ral));
         added = 1
      } else {
         await dexie_db.confies_ral.put(buildConfiesRALObject(a_ral));
         updated = 1
      }
      return { added, updated }
   }, [])

   const deleteConfiesRAL = useCallback(async (an_id) => {
      try {
         await dexie_db.confies_ral.delete(an_id);
      } catch (err) {
         console.log(`Pb delete Confies RAL ${an_id}`, err);
      }
   }, [])

   const fetchConfiesRAL = useCallback((newLogin) => {
      return new Promise(async (resolve, reject) => {
         let added = 0, updated = 0

         if (db && client) {
            try {
               const arr_queries = [
                  Query.limit(99999),
               ]
               if (!newLogin && last_updated_confies_ral) {
                  arr_queries.push(Query.greaterThan('$updatedAt', moment.utc(last_updated_confies_ral).toISOString()))
               }
               arr_queries.push(Query.orderAsc('$updatedAt'))
               console.log('last_updated_confies_ral', last_updated_confies_ral);
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollConfiesRALId, arr_queries)

               // console.log('fetchConfiesRAL', docs);
               if (docs.total === 0) {
                  resolve()
               } else {
                  for (const a_ral of docs.documents) {
                     const ret = await addConfiesRAL(a_ral)
                     added += ret.added
                     updated += ret.updated
                  }
                  console.log(`fetchConfiesRAL: added=${added}, updated=${updated}`);
                  resolve()
               }
            } catch (err) {
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               console.log('Erreur de format de données pour Confies RAL !');
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [addConfiesRAL, appWriteCollConfiesRALId, appWriteDatabaseId, client, db, last_updated_confies_ral])

   const cleanConfiesRAL = useCallback(() => {
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               const docs = await db.listDocuments(appWriteDatabaseId, appWriteCollConfiesRALId, [
                  Query.select(['cust_id']),  // On n'a besoin que de l'ID pour purger
                  Query.limit(99999),
               ])

               console.log(`cleanConfiesRAL : ${docs.documents.length} docs`)
               // if (docs.total > 0) {
               const keep_ids = docs.documents.reduce((prev, a_doc) => {
                  // console.log(a_doc);
                  return new Set([...prev, a_doc.cust_id])
               }, new Set())

               console.log(`Keep ${keep_ids.size} Confies RAL ids`);

               const all_customers = await dexie_db.confies_ral.toCollection().toArray()

               for (const a_customer of all_customers) {
                  const an_id = a_customer.id
                  if (!keep_ids.has(an_id)) {
                     console.log(`Delete Confies RAL for customer ${an_id}`)
                     deleteConfiesRAL(an_id)
                  }
               }
               // }
               resolve()
            } catch (err) {
               // Si err.code === 401 -> pas loggué
               // Sinon c'est peut-être un pb de connexion...
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollConfiesRALId, appWriteDatabaseId, client, db, deleteConfiesRAL])

   /*
   ████████  █████  ██████  ██ ███████ ███████
      ██    ██   ██ ██   ██ ██ ██      ██
      ██    ███████ ██████  ██ █████   ███████
      ██    ██   ██ ██   ██ ██ ██           ██
      ██    ██   ██ ██   ██ ██ ██      ███████
   */

   // Fetch générique pour collections, couleurs, cycles, etapes, familles, genres, portes
   const fetchTarif = useCallback((name, setTarif, checksum, setChecksum) => {
      return new Promise((resolve, reject) => {
         console.log(`Fetch ${name} - CHK ${checksum}`)

         getCollectionItem(appWriteCollTarifsId, name, checksum ?? '').then(res => {
            if (res.status) {
               const doc = res.data

               if (doc) {  // On a fetché un doc plus récent
                  console.log(`fetch ${name} > OK`);
                  // console.log(doc.data);
                  // console.log(JSON.parse(doc.data));
                  // console.log(JSON.parse(doc.data));
                  try { // Permet de s'assurer que le JSON est OK
                     setTarif(JSON.parse(doc.data))
                     setChecksum(doc.checksum)
                     resolve()
                  } catch (err) {
                     console.log(`fetch ${name} > ERR`, err);
                     reject(new Error(err))
                  }
               } else {    // On n'a pas fetché de doc, donc pas trouvé de plus récent
                  console.log(`fetch ${name} > Doc null`);
                  if (!checksum) {  // Oui, mais on n'a rien actuellement dans la base, donc ça signifie qu'il y a un pb !
                     console.log(`fetch ${name} > ERR`);
                     reject(new Error(`${name}: Not found and no local data!`))
                  } else {    // On garde les data locales récupérées d'un fetch précédent
                     resolve()
                  }
               }
            } else {
               console.log(`fetch ${name} > ERR`, res);
               reject(new Error(res?.data?.message ?? 'Erreur inconnue'))
            }
         }).catch(err => {
            console.log(`fetch ${name} > ERR`, err);
            reject(new Error('Erreur inconnue'))
         })
      })
   }, [appWriteCollTarifsId, getCollectionItem])

   // regionToForce : nom de la région à charger même si elle n'est pas cochée "preload" (i.e. pour OFFLINE)
   // Sert quand on choisit une région qui est dans la liste mais pas encore en mémoire et pas preload
   // forOffLine = true permet de fetcher même si jamais fetché. Sinon c'est lors du login et on ne fetch
   // que si fetché et donc présent en mémoire.
   const fetchTarifsForSelectedRegions = useCallback((regionToForce, forOffLine, incDone) => {
      const arr = []

      console.log('fetchTarifsForSelectedRegions', tarif_regions)

      /* On utilise un Set pour éviter les doublons */
      let tarifs_to_fetch = new Set()
      for (const key in tarif_regions) {
         const a_tarif = tarif_regions[key]
         // console.log(name)
         // if (a_tarif.preload || (key === regionToForce)) {  // Ancienne technique, pb détecté aux MDAYS 2024
         if (forOffLine || (key === regionToForce)) {  // Nv technique. On refetch si tarif en mémoire
            const names = [
               a_tarif.currencies.hjo.ces.tarif,
               a_tarif.currencies.hjo.ppu.tarif,
               a_tarif.currencies.other.ces.tarif,
               a_tarif.currencies.other.ppu.tarif
            ]
            console.log(`Tarif names "${key}" Add`, [...names])
            /* Tweak d'ajout d'un array de valeurs à un Set : on recréer un nv Set */
            tarifs_to_fetch = new Set([...tarifs_to_fetch, ...names])
         } else if (checksums_tarifs) {
            if (checksums_tarifs[a_tarif.currencies.hjo.ces.tarif]) {
               console.log(`Tarif names "${key}" Add ${a_tarif.currencies.hjo.ces.tarif}`)
               tarifs_to_fetch = new Set([...tarifs_to_fetch, a_tarif.currencies.hjo.ces.tarif])
            }
            if (checksums_tarifs[a_tarif.currencies.hjo.ppu.tarif]) {
               console.log(`Tarif names "${key}" Add ${a_tarif.currencies.hjo.ppu.tarif}`)
               tarifs_to_fetch = new Set([...tarifs_to_fetch, a_tarif.currencies.hjo.ppu.tarif])
            }
            if (checksums_tarifs[a_tarif.currencies.other.ces.tarif]) {
               console.log(`Tarif names "${key}" Add ${a_tarif.currencies.other.ces.tarif}`)
               tarifs_to_fetch = new Set([...tarifs_to_fetch, a_tarif.currencies.other.ces.tarif])
            }
            if (checksums_tarifs[a_tarif.currencies.other.ppu.tarif]) {
               console.log(`Tarif names "${key}" Add ${a_tarif.currencies.other.ppu.tarif}`)
               tarifs_to_fetch = new Set([...tarifs_to_fetch, a_tarif.currencies.other.ppu.tarif])
            }
         }
      }
      console.log('Tarifs à fetcher', tarifs_to_fetch)
      for (const key of tarifs_to_fetch) {
         // console.log(`Fetching prices /tarifs/tarif-${name}.json${compressedPrices ? ".gz" : ""}`)
         console.log(`Fetch tarif ${key}`, checksums_tarifs)
         arr.push(
            fetchTarif(key, (a_tarif) => {
               console.log(setTarifs, key, a_tarif);
               setTarifs(key, a_tarif)
            }, (checksums_tarifs ? checksums_tarifs[key] : null), (checksum) => {
               setChecksumsTarifs(key, checksum)
            }).finally(() => {
               incDone()
            })
         )
      }
      return arr
   }, [checksums_tarifs, fetchTarif, setChecksumsTarifs, setTarifs, tarif_regions])

   // regionToForce : nom de la région à charger même si elle n'est pas cochée "preload" (i.e. pour OFFLINE)
   // Sert quand on choisit une région qui est dans la liste mais pas encore en mémoire et pas preload
   // SI force === true, pas de vérif du checksum, tjs DL.
   const fetchTarifsForSingleRegion = useCallback((region_name, force, success, failure) => {
      console.log('fetchTarifsForSingleRegion', region_name)

      /* On utilise un Set pour éviter les doublons */
      let tarifs_to_fetch = new Set()
      const a_tarif = tarif_regions[region_name]
      // console.log(name)
      // if (a_tarif?.in_list) {
      if (a_tarif) {
         const names = [
            a_tarif.currencies.hjo.ces.tarif,
            a_tarif.currencies.hjo.ppu.tarif,
            a_tarif.currencies.other.ces.tarif,
            a_tarif.currencies.other.ppu.tarif
         ]
         const arr = []
         console.log(`Tarif names "${region_name}" Add`, [...names])
         /* Tweak d'ajout d'un array de valeurs à un Set : on recréer un nv Set */
         tarifs_to_fetch = new Set([...tarifs_to_fetch, ...names])

         console.log('Tarifs à fetcher', tarifs_to_fetch)
         for (const key of tarifs_to_fetch) {
            // console.log(`Fetching prices /tarifs/tarif-${name}.json${compressedPrices ? ".gz" : ""}`)
            console.log(`Fetch tarif ${key}`, checksums_tarifs)
            arr.push(
               fetchTarif(key, (a_tarif) => {
                  console.log(setTarifs, key, a_tarif);
                  setTarifs(key, a_tarif)
               }, ((!force && checksums_tarifs) ? checksums_tarifs[key] : null), (checksum) => {
                  setChecksumsTarifs(key, checksum)
               })
            )

            Promise.allSettled(arr).then(res => {
               const not_good = res.find(a_promise => a_promise.status !== 'fulfilled')
               console.log('Fini', res, not_good, not_good ? 'N' : 'Y');
               // setIsLogged(is_good)
               if (not_good) {
                  failure(not_good?.reason?.message ?? trans("?Erreur de récupération des données"))
               } else {
                  success()
               }
            })
         }
      } else {
         // failure(trans("?Le tarif du client n'est pas dans votre liste !"))
         failure(trans("?Le tarif du client est inconnu !"))
      }
   }, [checksums_tarifs, fetchTarif, setChecksumsTarifs, setTarifs, tarif_regions, trans])

   /*
   ██████  ██████  ███████
   ██   ██ ██   ██ ██
   ██████  ██   ██ █████
   ██      ██   ██ ██
   ██      ██████  ██
   */

   // arr_data est un objet JSON
   const awPushPDF = useCallback((type, obj_data, emails, message) => {
      console.log('awPushPDF');
      return new Promise(async (resolve, reject) => {
         if (db && client) {
            try {
               const obj = {
                  data: JSON.stringify(obj_data),
                  type,
                  emails: [...emails, login],
                  lang: currentLang,
                  message,
               }
               try {
                  const id = ID.unique()
                  console.log(`createDocument()`, obj, id);
                  await db.createDocument(appWriteDatabaseId, appWriteCollPDFId, id, obj)
               } catch (err) {
                  console.error('CreateDocument - CATCH ERR', err)
               }
               resolve()
            } catch (err) {
               reject(new Error(err))
            }
         } else {
            reject(new Error('Pas de connection DB'))
         }
      })
   }, [appWriteCollPDFId, appWriteDatabaseId, client, currentLang, db, login])

   /*
   ███████ ████████  █████  ██████  ████████ ██    ██ ██████
   ██         ██    ██   ██ ██   ██    ██    ██    ██ ██   ██
   ███████    ██    ███████ ██████     ██    ██    ██ ██████
        ██    ██    ██   ██ ██   ██    ██    ██    ██ ██
   ███████    ██    ██   ██ ██   ██    ██     ██████  ██
   */

   const initialFetch = useCallback((newLogin, success, failure, setTotal, incDone, fetchSubProgress) => {
      // let total = 0
      // let done = 0
      console.log('last_updated_customers', last_updated_customers);
      console.log('last_updated_bi_orders', last_updated_bi_orders);
      console.log('last_updated_orders', last_updated_orders);
      const arr = [
         fetchList(fetch_names.collections, setCollections, checksum_collections, setChecksumCollections).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.couleurs, setCouleurs, checksum_couleurs, setChecksumCouleurs).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.countries, setCountries, checksum_countries, setChecksumCountries).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.cycles, setCycles, checksum_cycles, setChecksumCycles).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.devises, setDevises, checksum_devises, setChecksumDevises).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.etapes, setEtapes, checksum_etapes, setChecksumEtapes).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.familles, setFamilles, checksum_familles, setChecksumFamilles).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.genres, setGenres, checksum_genres, setChecksumGenres).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.modeles, setModels, checksum_models, setChecksumModels).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.portes, setPortes, checksum_portes, setChecksumPortes).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.tarif_trads, setTarifTrads, checksum_tarif_trads, setChecksumTarifTrads).finally(() => {
            incDone()
         }),
         fetchList(fetch_names.tarif_regions, setTarifRegions, checksum_tarif_regions, setChecksumTarifRegions).finally(() => {
            incDone()
         }),
         fetchCustomers(newLogin).finally(async () => {
            console.log('fetchCustomers - DONE');
            incDone()
            await cleanCustomers().finally(async () => {
               console.log('cleanCustomers - DONE');
               incDone()   // !!! Ne pas oublier de compter +1 plus bas
               setLastUpdatedCustomers(await findMostRecentUpdate('customers'))
            })
         }),
         fetchVentesRAL(newLogin).finally(async () => {
            console.log('fetchVentesRAL - DONE');
            incDone()
            await cleanVentesRAL().finally(async () => {
               console.log('cleanVentesRAL - DONE');
               incDone()   // !!! Ne pas oublier de compter +1 plus bas
               setLastUpdatedVentesRAL(await findMostRecentUpdate('ventes_ral'))
            })
         }),
         fetchConfies(newLogin).finally(async () => {
            console.log('fetchConfies - DONE');
            incDone()
            await cleanConfies().finally(async () => {
               console.log('cleanConfies - DONE');
               incDone()   // !!! Ne pas oublier de compter +1 plus bas
               setLastUpdatedConfies(await findMostRecentUpdate('confies'))
            })
         }),
         fetchConfiesRAL(newLogin).finally(async () => {
            console.log('fetchConfiesRAL - DONE');
            incDone()
            await cleanConfiesRAL().finally(async () => {
               console.log('cleanConfiesRAL - DONE');
               incDone()   // !!! Ne pas oublier de compter +1 plus bas
               setLastUpdatedConfiesRAL(await findMostRecentUpdate('confies_ral'))
            })
         }),
         // Fetch BI Orders
         fetchBiOrders(newLogin, fetchSubProgress).finally(async () => {
            incDone()
            await cleanBiOrders().finally(async () => {
               incDone()   // !!! Ne pas oublier de compter +1 plus bas
            })
            setLastUpdatedBiOrders(await findMostRecentUpdate('bi_orders'))
         }),
         // Fetch Orders
         fetchOrders(newLogin, fetchSubProgress).finally(async () => {
            incDone()
            await cleanOrders().finally(async () => {
               incDone()   // !!! Ne pas oublier de compter +1 plus bas
            })
            setLastUpdatedOrders(await findMostRecentUpdate('orders'))
         }),
      ]

      const arr_tarifs = fetchTarifsForSelectedRegions(null, false, incDone)
      if (arr_tarifs.length > 0) {
         arr.push(arr_tarifs)
      }

      // Nb de tarifs + les listes +6 car on en a imbriqués dans des Promises et +1 permet d'afficher un bout de la barre de progression dès le départ
      setTotal(arr.length + 6 + 1)
      // done = 1

      Promise.allSettled(arr).then(res => {
         const not_good = res.find(a_promise => a_promise.status !== 'fulfilled')
         console.log('Fini', res, not_good, not_good ? 'N' : 'Y');
         // setIsLogged(is_good)
         if (not_good) {
            failure(not_good?.reason?.message ?? trans("?Erreur de récupération des données"))
            miscLogs('Pb initialFetch', res)
         } else {
            success()
            // miscLogs('OK initialFetch2', 'AAA')
            // miscLogs('OK initialFetch', [1, 2, res])
         }
      })

   }, [last_updated_customers, last_updated_bi_orders, last_updated_orders, fetchList, setCollections, checksum_collections, setChecksumCollections, setCouleurs, checksum_couleurs, setChecksumCouleurs, setCountries, checksum_countries, setChecksumCountries, setCycles, checksum_cycles, setChecksumCycles, setDevises, checksum_devises, setChecksumDevises, setEtapes, checksum_etapes, setChecksumEtapes, setFamilles, checksum_familles, setChecksumFamilles, setGenres, checksum_genres, setChecksumGenres, setModels, checksum_models, setChecksumModels, setPortes, checksum_portes, setChecksumPortes, setTarifTrads, checksum_tarif_trads, setChecksumTarifTrads, setTarifRegions, checksum_tarif_regions, setChecksumTarifRegions, fetchCustomers, fetchVentesRAL, fetchConfies, fetchConfiesRAL, fetchBiOrders, fetchOrders, fetchTarifsForSelectedRegions, cleanCustomers, setLastUpdatedCustomers, findMostRecentUpdate, cleanVentesRAL, setLastUpdatedVentesRAL, cleanConfies, setLastUpdatedConfies, cleanConfiesRAL, setLastUpdatedConfiesRAL, cleanBiOrders, setLastUpdatedBiOrders, cleanOrders, setLastUpdatedOrders, trans, miscLogs])

   /*
   ███████ ██    ██ ██████  ███████  ██████ ██████  ██ ██████  ████████ ██  ██████  ███    ██ ███████
   ██      ██    ██ ██   ██ ██      ██      ██   ██ ██ ██   ██    ██    ██ ██    ██ ████   ██ ██
   ███████ ██    ██ ██████  ███████ ██      ██████  ██ ██████     ██    ██ ██    ██ ██ ██  ██ ███████
        ██ ██    ██ ██   ██      ██ ██      ██   ██ ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
   ███████  ██████  ██████  ███████  ██████ ██   ██ ██ ██         ██    ██  ██████  ██   ████ ███████
   */

   /*
      Channels possibles : (https://appwrite.io/docs/realtime#channels)
      account :                 All account related events (session create, name update...)
      databases.[ID].collections.[ID].documents	: Any create/update/delete events to any document in a collection
      documents :               Any create/update/delete events to any document
      databases.[ID].collections.[ID].documents.[ID] : Any update/delete events to a given document
      files :                   Any create/update/delete events to any file
      buckets.[ID].files.[ID] : Any update/delete events to a given file of the given bucket
      buckets.[ID].files :      Any update/delete events to any file of the given bucket
      teams :                   Any create/update/delete events to a any team
      teams.[ID] :              Any update/delete events to a given team
      memberships :             Any create/update/delete events to a any membership
      memberships.[ID] :        Any update/delete events to a given membership
      executions :              Any update to executions
      executions.[ID] :         Any update to a given execution
      functions.[ID] :          Any execution event to a given function
    
      La fonction retournée sert à unsubscribe() mais ne coupe pas de WebSocket !
   */

   /**
    * Souscription aux modifs "live" des documents de paramétrage (modèles, collections, familles, portés etc.)
    */
   const subscribeToCollectionEvents = useCallback((collection_id, callback) => {
      if (client) {
         // console.log('SUB OK : subscribeToCollectionEvents', `databases.${appWriteDatabaseId}.collections.${collection_id}.documents`)
         const unsub = client.subscribe([
            `databases.${appWriteDatabaseId}.collections.${collection_id}.documents`
         ], response => {
            // if (response.events.includes('databases.*.collections.*.documents.*.create')) {
            // console.log(response, response.payload)
            // const dt = Date.parse(response.payload.$updatedAt)
            // console.log('>>', response.payload.$updatedAt, dt, new Date().toISOString(), Date.now(), Date.now() - dt, new Date().getTime() - dt)
            if (callback) {
               callback(response)
            }
            // }
         })
         // console.log('SUB', unsub)
         return unsub
         // return () => {
         //    console.log('ICI...', unsub)
         //    console.log('Call unsub')
         //    unsub()
         // }
      } else {
         return null
      }
   }, [appWriteDatabaseId, client])

   // Les listes
   useEffect(() => {
      const prefix = `databases.${appWriteDatabaseId}.collections.${appWriteCollListsId}.documents`
      // console.log('SUB CanSubscribe? ', isLogged ? 'Yes' : 'No');
      if (isLogged) {
         console.log('ICI >>> subscribeToCollectionEvents >>>>2');
         // console.log('SUB Go sub...');
         const unsub = subscribeToCollectionEvents(appWriteCollListsId, (event) => {
            console.log(event);
            try {
               if (
                  event.events.includes(`${prefix}.${fetch_names.collections}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.collections}.update`)
               ) {
                  console.log('Event collections !');
                  setCollections(JSON.parse(event?.payload?.data))
                  setChecksumCollections(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.couleurs}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.couleurs}.update`)
               ) {
                  console.log('Event couleurs !');
                  setCouleurs(JSON.parse(event?.payload?.data))
                  setChecksumCouleurs(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.countries}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.countries}.update`)
               ) {
                  console.log('Event countries !');
                  setCountries(JSON.parse(event?.payload?.data))
                  setChecksumCountries(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.cycles}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.cycles}.update`)
               ) {
                  console.log('Event cycles !');
                  setCycles(JSON.parse(event?.payload?.data))
                  setChecksumCycles(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.devises}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.devises}.update`)
               ) {
                  console.log('Event devises !');
                  setDevises(JSON.parse(event?.payload?.data))
                  setChecksumDevises(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.etapes}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.etapes}.update`)
               ) {
                  console.log('Event etapes !');
                  setEtapes(JSON.parse(event?.payload?.data))
                  setChecksumEtapes(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.familles}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.familles}.update`)
               ) {
                  console.log('Event familles !');
                  setFamilles(JSON.parse(event?.payload?.data))
                  setChecksumFamilles(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.genres}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.genres}.update`)
               ) {
                  console.log('Event genres !');
                  setGenres(JSON.parse(event?.payload?.data))
                  setChecksumGenres(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.modeles}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.modeles}.update`)
               ) {
                  console.log('Event modeles !');
                  setModels(JSON.parse(event?.payload?.data))
                  setChecksumModels(event?.payload?.checksum)
               } else if (
                  event.events.includes(`${prefix}.${fetch_names.portes}.create`) ||
                  event.events.includes(`${prefix}.${fetch_names.portes}.update`)
               ) {
                  console.log('Event portes !');
                  setPortes(JSON.parse(event?.payload?.data))
                  setChecksumPortes(event?.payload?.checksum)
               } else {
                  console.log('Event inconnu !', event.events);
               }
            } catch (err) {
               console.log('Erreur de format de données pour Collections !');
            }
         })
         return (() => {   // Unmount, on unsub()
            // console.log('SUB unmount useEffect (>>>>2)');
            if (unsub) {
               // console.log('SUB unsub (unmount >>>>2)');
               unsub()
            }
         })
      }
   }, [appWriteCollListsId, appWriteDatabaseId, isLogged, setChecksumCollections, setChecksumCouleurs, setChecksumCountries, setChecksumCycles, setChecksumDevises, setChecksumEtapes, setChecksumFamilles, setChecksumGenres, setChecksumModels, setChecksumPortes, setCollections, setCouleurs, setCountries, setCycles, setDevises, setEtapes, setFamilles, setGenres, setModels, setPortes, subscribeToCollectionEvents])

   // Les clients
   useEffect(() => {
      const prefix = `databases.${appWriteDatabaseId}.collections.${appWriteCollCustomersId}.documents.*`
      // console.log('SUB CanSubscribe? ', isLogged ? 'Yes' : 'No');
      if (isLogged) {
         // console.log('ICI >>> subscribeToCollectionEvents >>>>1');
         // console.log('SUB Go sub custo...');
         const unsub = subscribeToCollectionEvents(appWriteCollCustomersId, async (event) => {
            // console.log(event, prefix);
            try {
               if (
                  event.events.includes(`${prefix}.create`) ||
                  event.events.includes(`${prefix}.update`)
               ) {
                  // console.log('Event customers !');
                  await addCustomer(event.payload)
                  // console.log(ret.added ? 'Added' : 'Updated');
                  // setCollections(JSON.parse(event?.payload?.data))
                  // setChecksumCollections(event?.payload?.checksum)
               } else if (event.events.includes(`${prefix}.delete`)) {
                  // console.log('Event customers (delete) !');
                  await deleteCustomer(event.payload.number)
               } else {
                  console.error('Event inconnu !', event.events);
               }
            } catch (err) {
               console.error('Erreur de format de données pour Customers !');
            }
         })
         return (() => {   // Unmount, on unsub()
            // console.log('SUB unmount useEffect (>>>>1)');
            if (unsub) {
               // console.log('SUB unsub (unmount >>>>2)');
               unsub()
            }
         })
      }
   }, [addCustomer, appWriteCollCustomersId, appWriteDatabaseId, deleteCustomer, isLogged, subscribeToCollectionEvents])

   // Les commandes (locales, donc hors BI). Permet aussi de sync des appareils distincts loggués sur le même compte
   useEffect(() => {
      const prefix = `databases.${appWriteDatabaseId}.collections.${appWriteCollOrdersId}.documents.*`
      // console.log('SUB CanSubscribe? ', isLogged ? 'Yes' : 'No');
      if (isLogged) {
         // console.log('ICI >>> subscribeToCollectionEvents >>>>1');
         // console.log('SUB Go sub orders...');
         const unsub = subscribeToCollectionEvents(appWriteCollOrdersId, async (event) => {
            // console.log(event, prefix);
            try {
               if (
                  event.events.includes(`${prefix}.create`) ||
                  event.events.includes(`${prefix}.update`)
               ) {
                  // console.log('Event orders !', event.payload);
                  const doc = await getOrderById(event.payload.id)
                  if (!doc || !doc.last_pushed || (moment().diff(doc.last_pushed, 'seconds') > glob_const.min_delay_on_push)) {
                     await addOrder(event.payload)
                     // console.log(ret.added ? 'Added' : 'Updated');
                  } else {
                     console.log('Too quick, not updated (only last_updated)!');
                     await updateOrderLocally(event.payload.id, { last_updated: moment.utc(event.payload['$updatedAt']).toISOString() })
                     setLastUpdatedOrders(await findMostRecentUpdate('orders'))
                  }
               } else if (event.events.includes(`${prefix}.delete`)) {
                  // console.log('Event orders (delete) !');
                  await deleteLocalOrder(event.payload.id)
               } else {
                  console.error('Event inconnu !', event.events);
               }
            } catch (err) {
               console.error('Erreur de format de données pour Orders !');
            }
         })
         return (() => {   // Unmount, on unsub()
            // console.log('SUB unmount useEffect (>>>>1)');
            if (unsub) {
               // console.log('SUB unsub (unmount >>>>2)');
               unsub()
            }
         })
      }
   }, [addOrder, appWriteCollOrdersId, appWriteDatabaseId, deleteLocalOrder, findMostRecentUpdate, getOrderById, isLogged, setLastUpdatedOrders, subscribeToCollectionEvents, updateOrderLocally])

   return (
      <theContext.Provider value={{ context: theContext, client, account, doLogin, doLogout, getStock, fetchOtp, otp, setOtp, getCollectionItem, initialFetch, subscribeToCollectionEvents, fetchTarifsForSelectedRegions, fetchTarifsForSingleRegion, awPushOrder, awPushWaitingOrders, awDeleteOrder, awPushPDF, awPushUdid, verifOtp, resetPasswordOtp }}>
         {children}
      </theContext.Provider>
   )
}

export { AppWriteContext, AppWriteContextProvider }