import styles from './Slider.module.scss';
import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
// import { useLogger } from '../Contexts/Logger';

const handleWidth = 25; // Largeur des handles. Doit correspondre à la valeur de --handle-width dans Slider.module.scss

export const sliderFrom = 'from';
export const sliderTo = 'to';

function SliderHandle(props) {
   const [curPixPosition, setCurPixPosition] = useState(0);

   useEffect(() => {
      setCurPixPosition(props.position);
   }, [props]);

   function onDrag(evt, ui) {
      // On corrige la position et le taquet correspondant pour éviter une dérive de qq pixels parfois
      const fixedIdxPosition = Math.floor(ui.x / props.step_pix_size);
      const fixedPixPosition = fixedIdxPosition * props.step_pix_size;

      setCurPixPosition(fixedPixPosition);
      if (props.onDrag) {
         props.onDrag(fixedPixPosition, fixedIdxPosition);
      }
   }

   return (
      <Draggable
         bounds="parent"
         axis="x"
         grid={[props.step_pix_size, 0]}
         position={{ x: curPixPosition, y: 0 }}
         onDrag={onDrag}
      >
         <div className={styles.handle} />
      </Draggable>
   );
}

/**
 *
 * Props :
 * - from : int, index du taquet de départ (mini = 0)
 * - to : int, index du taquet de fin (maxi = valeur de "steps")
 * - steps : int, nb de positions sur la règle, début et fin incluses.
 * - steps : int, nb de positions sur la règle, début et fin incluses.
 *           Calcul de steps : (largeur slider / step_pix_size) + 1
 * - step_pix_size : int, l'espace (en pixels) entre 2 positions sur la règle.
 *                   Le nb de positions dépend de la longueur du composant.
 *                   Il faut s'arranger pour que ce soit un diviseur entier de la longueur
 * @returns
 */

function Slider(props) {
   /* Index 0 = 1er handle, Index 1 = 2eme handle. Même si les handles s'inversent lors du drag de l'utilisateur, on garde ce sens mais les données sont inversées (celui de gauche représentant alors une valeur > à celui de droite) */
   const [pixPoshandle1, setPixPosHandle1] = useState(0); // En pixels
   const [pixPosHandle2, setPixPosHandle2] = useState(0); // En pixels
   const [idxPosHandle1, setIdxPosHandle1] = useState(0); // En taquets
   const [idxPosHandle2, setIdxPosHandle2] = useState(0); // En taquets
   // const { log } = useLogger();

   useEffect(() => {
      const index1 = Math.min(Math.max(props.handle1, 0), props.steps - 1);
      const index2 = Math.min(Math.max(props.handle2, 0), props.steps - 1);

      setPixPosHandle1(index1 * props.step_pix_size);
      setPixPosHandle2(index2 * props.step_pix_size);
      setIdxPosHandle1(index1);
      setIdxPosHandle2(index2);
   }, [props]);

   function onDragHandle1(newPixPos, newIdxPos) {
      console.log(
         'CatalogFilter - onDragHandle1',
         newPixPos,
         newIdxPos,
         idxPosHandle2
      );
      setPixPosHandle1(newPixPos);
      setIdxPosHandle1(newIdxPos);
      if (props.onHandle1Change) {
         props.onHandle1Change(newIdxPos);
      }
   }

   function onDragHandle2(newPixPos, newIdxPos) {
      console.log(
         'CatalogFilter - onDragHandle2',
         newPixPos,
         newIdxPos,
         idxPosHandle1
      );
      setPixPosHandle2(newPixPos);
      setIdxPosHandle2(newIdxPos);
      if (props.onHandle2Change) {
         props.onHandle2Change(newIdxPos);
      }
   }

   console.log('Slider - FromTo', idxPosHandle1, idxPosHandle2);

   return (
      <div className={styles.slider}>
         <div className={styles.innerline_bg} />
         <div
            className={styles.innerline}
            style={{
               left: Math.min(pixPoshandle1, pixPosHandle2),
               width: Math.abs(pixPosHandle2 - pixPoshandle1) + handleWidth
            }}
         />
         <SliderHandle
            position={pixPoshandle1}
            step_pix_size={props.step_pix_size}
            onDrag={onDragHandle1}
         ></SliderHandle>
         <SliderHandle
            position={pixPosHandle2}
            step_pix_size={props.step_pix_size}
            onDrag={onDragHandle2}
         ></SliderHandle>
      </div>
   );
}

export default Slider;
