import { useEffect, useState } from "react"
import styles from "./MenuDashboardItem.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown } from "@fortawesome/free-solid-svg-icons"

function MenuDashboardItem(props) {
   const [isHovered, setHovered] = useState(false);
   const [addClasses, setAddClasses] = useState(null);

   useEffect(() => {
      let addStyle = ''

      if (props.selected) {
         addStyle = `${addStyle} ${styles.selected}`
      }
      if (isHovered) {
         addStyle = `${addStyle} ${styles.hovered}`
      }
      setAddClasses(addStyle)
   }, [props, isHovered])

   function mouseEnterHandler() {
      if (!props.selected) {
         setHovered(true)
      }
   }

   function mouseLeaveHandler() {
      if (!props.selected) {
         setHovered(false)
      }
   }

   function onClick(evt) {
      if (props.onClick) {
         props.onClick(evt)
      }
   }

   function onDropdown(evt) {
      evt.stopPropagation()
      if (props.onDropdown) {
         props.onDropdown(evt)
      }
   }

   return (
      <button className={styles.menuitem} onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} onClick={onClick} >
         <div className={`${styles.selectedbox} ${addClasses}`} />
         <div className={`${styles.innerbox} ${addClasses}`}>
            {props.icon}
            <span>{props.children}</span>
            {props.hasDropdown &&
               <div className={styles.dropdown_arrow} onClick={onDropdown}>
                  <FontAwesomeIcon icon={faSortDown} style={{ transformOrigin: 'center', transform: `rotate(${props.dropdownVisible ? "0" : "-90deg"})` }} />
               </div>
            }
         </div>
      </button>
   )
}

export default MenuDashboardItem
