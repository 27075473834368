import styles from "./CardCollection.module.scss"
// import { Img } from 'react-image'

function CardCollection(props) {
   function onClick(evt) {
      if (props.onClick) {
         props.onClick(evt)
      }
   }

   return (
      <button className={styles.card_collection} onClick={onClick}>
         {/* {props.image} */}
         <img src={props.image} alt="Model" />
         <div>
            <label>{props.label}</label>
         </div>
      </button>
   )
}

export default CardCollection
