import styles from './DropdownMenu.module.scss';
import usePrefStore from '../stores/prefs';
import { useEffect, useState } from 'react';
import useHelpers from '../Hooks/useHelpers';
// import { useLogger } from '../Contexts/Logger';

function DropdownMenu(props) {
   // const noScrollbars = usePrefStore((state) => state.noScrollbars);
   const { noScrollbars } = usePrefStore();
   const [addStyle, setAddStyle] = useState({});
   const { inlineStyleFromProps } = useHelpers();
   // const { log } = useLogger();

   useEffect(() => {
      const style = inlineStyleFromProps(props); // On reconstruit un style avec les propriétés trouvées dans props
      style['display'] = 'initial'; // Pour éviter un flickering lié au repositionnement du menu, il est "display=none" au départ
      setAddStyle(style);
   }, [props, inlineStyleFromProps]);

   function onClick(evt) {
      // console.log('DropdownMenu', evt.target?.id, evt.target, evt.target.getAttribute('disabled') ? 'Y' : 'N');
      evt.stopPropagation();
      if (evt.target.getAttribute('disabled') === null) {
         if (props.onSelect) {
            props.onSelect(evt.target?.id);
         }
      }
   }

   // console.log('ddm', addStyle)

   return (
      <div className={`${styles.dropdown_menu} ${props.className}`} style={addStyle}>
         <ul className={`${styles.list} ${noScrollbars || props.noscrollbars ? styles.noscrollbars : ''}`} onClick={onClick}>
            {props.children}
         </ul>
      </div>
   );
}

export default DropdownMenu;
