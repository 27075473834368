import styles from "./DebugInfo.module.scss"
import { useEffect, useState } from "react"
import { faUserSecret } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePrefStore from "../stores/prefs";
import Button from './Button';
import useModelsStore from "../stores/models";
import { GlobalContext } from '../Contexts/GlobalContext';
import useSales from "../Hooks/useSales";
import { useLiveQuery } from "dexie-react-hooks";

const tabs = {
   orders: 'order',
   ui: 'ui',
   tarifs: 'tarifs',
   regions: 'regions',
   logs: 'logs',
}

function DebugInfo(props) {
   const [showDebug, setShowDebug] = useState(false);
   const [showTab, setShowTab] = useState(1);
   //
   // const setDebug = usePrefStore((state) => state.setDebug);
   // const curTarifRegionName = usePrefStore((state) => state.curTarifRegionName);
   // const curTarifRegion = usePrefStore((state) => state.curTarifRegion);
   // const curTarifCustomer = usePrefStore((state) => state.curTarifCustomer);
   // const curCustomer = usePrefStore((state) => state.curCustomer);
   // const localOrderId = usePrefStore((state) => state.localOrderId);
   const { setDebug, curTarifRegionName, curTarifRegion, curTarifCustomer, curCustomer, localOrderId } = usePrefStore()
   // const tarif_regions = useModelsStore((state) => state.tarif_regions)
   const { tarif_regions } = useModelsStore()
   //
   const { isDarkLayerVisible, keepSearchBarAccessible, allLogs, setAllLogs } = { ...GlobalContext() }
   const { getUnsyncOrdersAsArray } = useSales()
   //
   const [maxOrderId, setMaxOrderId] = useState(null);
   const live_orders = useLiveQuery(async () => await getUnsyncOrdersAsArray(), [])

   useEffect(() => {
      console.log('LIVE', live_orders);
      if (live_orders) {
         setMaxOrderId(live_orders.reduce((prev, cur) => {
            return Math.max(Number(cur.id ?? 0), prev)
         }, -1))
      } else {
         setMaxOrderId(null)
      }
   }, [live_orders, setMaxOrderId])

   function setCatchLogs() {
      setAllLogs([])
   }

   function unsetCatchLogs() {
      setAllLogs(null)
   }

   function sendMail(email) {
      window.location = `mailto:gildas@bigpapoo.com?subject=Params&body=${JSON.stringify(allLogs)}`
   }

   return (
      <div className={`${styles.debuginfo} ${showDebug ? '' : styles.hidden}`}>
         <FontAwesomeIcon icon={faUserSecret} onClick={() => setShowDebug(val => !val)} />
         <div className={styles.inner}>
            <div className={styles.buttons}>
               <Button onClick={() => setShowTab(tabs.ui)}>UI</Button>
               <Button onClick={() => setShowTab(tabs.orders)}>Commandes</Button>
               <Button onClick={() => setShowTab(tabs.tarifs)}>Tarifs</Button>
               <Button onClick={() => setShowTab(tabs.regions)}>Tarifs régions</Button>
               <Button onClick={() => setShowTab(tabs.logs)}>Logs</Button>
               <Button onClick={() => setDebug(false)}>Fermer debug</Button>
            </div>
            {(showTab === tabs.ui) && <>
               <div>
                  darkLayer : {isDarkLayerVisible ? 'Visible' : 'Caché'} {keepSearchBarAccessible ? 'avec searchbar' : 'sans searchbar'}
               </div>
            </>
            }
            {(showTab === tabs.orders) && <>
               <div>
                  curCustomer : {curCustomer ?? 'null'}
               </div>
               <div>
                  localOrderId : {localOrderId ?? 'null'}
               </div>
               <div>
                  maxOrderId : {maxOrderId ?? 'null'}
               </div>
            </>
            }
            {(showTab === tabs.tarifs) && <>
               <div>
                  curTarifRegionName : {curTarifRegionName ?? 'null'}
               </div>
               <div>
                  curTarifRegion :
                  <pre>
                     {JSON.stringify(curTarifRegion, null, 4)}
                  </pre>
               </div>
               <div>
                  curTarifCustomer :
                  <pre>
                     {JSON.stringify(curTarifCustomer, null, 4)}
                  </pre>
               </div>
            </>
            }
            {
               (showTab === tabs.regions) && <div>
                  tarif_regions :
                  <pre>
                     {JSON.stringify(tarif_regions, null, 4)}
                  </pre>
               </div>
            }
            {
               (showTab === tabs.logs) && <div>
                  {!allLogs && <Button onClick={() => setCatchLogs()}>ON</Button>}
                  {allLogs && <Button onClick={() => unsetCatchLogs()}>OFF</Button>}
                  <Button onClick={() => sendMail()}>Envoyer mail</Button>
                  Logs :
                  <pre>
                     {allLogs?.join("\n")}
                  </pre>
               </div>
            }
         </div>
      </div >
   )
}

export default DebugInfo
