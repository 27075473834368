import styles from './DropdownCB.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox, States as CheckboxStates } from './Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import usePrefStore from '../stores/prefs';
import useHelpers from '../Hooks/useHelpers';
// import { useLogger } from '../Contexts/Logger';

/**
 * Props :
 * - allowNoneChecked : autorise de ne rien sélectionner dans la liste
 * - onChange : fonction appelée lors de la sélection/déselection
 * - values : voir les exemples ci-dessous
 * Exemple :
 * props.values sert à initialiser l'état mais on n'altère rien ensuite.
 * [
 *    { text: "Joailleri", value: "JOI", checked: true },
 *    ...
 * ]
 * -------
 * checked set à maintenir l'état checked/unchecked durant la vie du composant.
 * Il est iniatilsé à partir de props.value
 * {
 *    "JOI": true,
 *    ...
 * }
 */

function DropdownCB(props) {
   // const noScrollbars = usePrefStore((state) => state.noScrollbars);
   const { noScrollbars } = usePrefStore();
   //
   const [isOpen, setIsOpen] = useState(false);
   const [allCheckboxesState, setAllCheckboxesState] = useState({}); // Etat de toutes les checkboxes de la dropdown
   const [strChecked, setStrChecked] = useState(''); // Liste (chaine) des items cochés
   const [isEmpty, setIsEmpty] = useState(true);
   const [listItems, setListItems] = useState(null);
   const { tweakJOI } = useHelpers();
   // const { log } = useLogger();

   // console.log(props)

   useEffect(() => {
      let valEmpty;

      setAllCheckboxesState(
         props.values?.reduce((prev, cur) => {
            return { ...prev, [cur.id]: cur.checked };
         }, {}) ?? {}
      );
      valEmpty = !props.values || !Array.isArray(props.values) || props.values.length <= 0;
      setIsEmpty(valEmpty);
      setIsOpen(props.open && !valEmpty);
   }, [props]);

   const canChangeChoice = useCallback((key, state) => {
      console.log('***', 'willChangeChoice', key, state)
      // Teste si on coche la case ou qu'on décoche et que ce n'est pas la dernière (sauf si OK NoneChecked)
      if (state === CheckboxStates.Checked || props.allowNoneChecked ||
         props.values?.reduce((prev, cur) => prev + (allCheckboxesState[cur.id] ? 1 : 0), 0) > 1) {
         // const newStates = {
         //    ...allCheckboxesState,
         //    [key]: state === CheckboxStates.Checked
         // };
         // setAllCheckboxesState(newStates); // On mémorise le nv état de toutes les checkboxes
         // // console.log("NewStates", newStates, props.onChange)
         // if (props.onChange) {
         //    console.log('props.onChange');
         //    // On retourne au propriétaire le nouvel état des checkboxes
         //    // props.onChange(newStates);
         //    props.onChange(key, state);
         // }
         return true; // OK pour changer la checkbox
      } else {
         return false; // Pas OK de décocher (car c'est le dernier encore coché)
      }
   }, [allCheckboxesState, props]);

   const onChangeChoice = useCallback((key, state) => {
      console.log('***', 'onChangeChoice', key, state)
      // Teste si on coche la case ou qu'on décoche et que ce n'est pas la dernière (sauf si OK NoneChecked)
      // if (state === CheckboxStates.Checked || props.allowNoneChecked ||
      //    props.values?.reduce((prev, cur) => prev + (allCheckboxesState[cur.id] ? 1 : 0), 0) > 1) {
      //    const newStates = {
      //       ...allCheckboxesState,
      //       [key]: state === CheckboxStates.Checked
      //    };
      //    setAllCheckboxesState(newStates); // On mémorise le nv état de toutes les checkboxes
      //    // console.log("NewStates", newStates, props.onChange)
      if (props.onChange) {
         console.log('props.onChange');
         // On retourne au propriétaire le nouvel état des checkboxes
         // props.onChange(newStates);
         props.onChange(key, state);
      }
      //    return true; // OK pour changer la checkbox
      // } else {
      //    return false; // Pas OK de décocher (car c'est le dernier encore coché)
      // }
   }, [props]);

   // useEffect(() => {
   //    console.log('***', 'props changed', props)
   // }, [props])

   // useEffect(() => {
   //    console.log('***', 'allCheckboxesState changed', allCheckboxesState)
   // }, [allCheckboxesState])

   function toggleList(evt) {
      const newVal = !isOpen && !isEmpty;

      evt.stopPropagation();
      // console.log('ICI2', isOpen)
      setIsOpen(newVal);
      if (props.onOpenClose) {
         props.onOpenClose(newVal);
      }
   }

   // function showList() {
   //    const newVal = !isEmpty
   //    setIsOpen(newVal)
   //    if (props.onOpenClose) {
   //       props.onOpenClose(newVal)
   //    }
   // }

   // function hideList() {
   //    const newVal = false
   //    setIsOpen(newVal)
   //    if (props.onOpenClose) {
   //       props.onOpenClose(newVal)
   //    }
   // }

   // Calcule la "valeur" (texte) du champ résumant la liste des cases cochées
   useEffect(() => {
      // console.log('***', 'ICI2>>', allCheckboxesState)
      setStrChecked(props.values?.reduce((prev, cur) => {
         if (allCheckboxesState[cur.id]) {
            // console.log('CUR-->', cur)
            return [...prev, tweakJOI(cur.short) ?? cur.caption];
         } else {
            return prev;
         }
      }, []).join(', ') ?? ''
      );
   }, [allCheckboxesState, props, tweakJOI]);

   useEffect(() => {
      setListItems(
         props.values?.map((item) => {
            // console.log("+=+=+", item, checked[item.id])
            // console.log(props);
            return (
               <li key={item.id}>
                  <Checkbox small light item_id={item.id} caption={item.caption} icon={item.icon} checked={
                     allCheckboxesState[item.id] ? CheckboxStates.Checked : CheckboxStates.Unchecked
                  } canChange={(state) => canChangeChoice(item.id, state)} onChange={(state) => onChangeChoice(item.id, state)}
                  />
               </li>
            );
         })
      );
   }, [allCheckboxesState, onChangeChoice, props.values, canChangeChoice]);

   // console.log("INPUT", strChecked)

   return (
      <div className={`${styles.dropdown_cb} ${props.className}`}>
         <input type="text" readOnly className={`${styles.main_input} ${isOpen ? styles.selected : ''}`} onClick={toggleList} value={strChecked}></input>
         <button className={styles.open_close} onClick={toggleList}>
            <FontAwesomeIcon icon={faAngleDown} />
         </button>
         {isOpen && ( // isOpen ne peut être true que s'il y a un tableau non vide dans props.value
            <ul className={`${styles.list} ${noScrollbars || props.noscrollbars ? styles.noscrollbars : ''}`}>
               {listItems}
            </ul>
         )}
      </div>
   );
}

export default DropdownCB;
