import styles from "./GraphPie.module.scss"
import { ResponsivePie } from '@nivo/pie'

function GraphPie(props) {
   const CenteredMetric = ({ centerX, centerY }) => {
      return (
         <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
               fontSize: '32px',
               fontWeight: 600,
            }}
         >
            {props.center_title}
         </text>
      )
   }

   // const makeDataPie = useCallback((min_hjo, max_hjo, min_joi, max_joi, min_acc, max_acc) => {
   //    return [
   //       {
   //          "id": "hjo",
   //          "label": "Haute-Joaillerie",
   //          "value": Math.floor(Math.random() * (max_hjo - min_hjo)) + max_hjo,
   //          "color": "hsl(302, 70%, 50%)"
   //       },
   //       {
   //          "id": "joi",
   //          "label": "Joaillerie",
   //          "value": Math.floor(Math.random() * (max_joi - min_joi)) + max_joi,
   //          "color": "hsl(214, 70%, 50%)"
   //       },
   //       {
   //          "id": "acc",
   //          "label": "Accessoires",
   //          "value": Math.floor(Math.random() * (max_acc - min_acc)) + max_acc,
   //          "color": "hsl(5, 70%, 50%)"
   //       }
   //    ]
   // }, [])

   // useEffect(() => {
   //    if ((props.num % 2) === 0) {
   //       setData(makeDataPie(100, 300, 400, 1200, 40, 80))
   //    } else {
   //       setData(makeDataPie(700, 1300, 200, 800, 20, 180))
   //    }
   // }, [makeDataPie, props.num])

   return (
      <div className={styles.graph_pie}>
         <ResponsivePie
            theme={{
               labels: {
                  text: {
                     fontSize: 16,
                     fontWeight: 900
                  }
               }
            }}
            animate={true}   // ***BUG ne pas utiliser à true ! (log des erreurs) - Finalement pb uniquement en resize fenêtre (parfois)
            isInteractive={false}
            data={props.data}
            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            sortByValue={true}
            innerRadius={0.45}
            padAngle={3}
            // cornerRadius={9}
            activeOuterRadiusOffset={8}
            colors={{ datum: 'data.color' }}
            // colors={{ scheme: 'red_yellow_green' }}
            borderWidth={2}
            borderColor={{
               from: 'color',
               modifiers: [
                  [
                     'darker',
                     0.2
                  ]
               ]
            }}
            enableArcLinkLabels={false}
            // arcLinkLabel={(d) => d.label}
            // arcLinkLabelsSkipAngle={6}
            // arcLinkLabelsTextOffset={14}
            // arcLinkLabelsTextColor="#333333"
            // arcLinkLabelsOffset={-1}
            // arcLinkLabelsDiagonalLength={32}
            // arcLinkLabelsStraightLength={30}
            // arcLinkLabelsThickness={3}
            // arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
            // arcLabelsSkipAngle={15}
            // arcLabelsTextColor={{
            //    from: 'color',
            //    modifiers: [
            //       [
            //          'darker',
            //          2
            //       ]
            //    ]
            // }}
            // motionConfig={{      ***BUG ne pas utiliser motionConfig ! (log des erreurs)
            //    mass: 1,
            //    tension: 20,
            //    friction: 7,
            //    clamp: false,
            //    precision: 0.01,
            //    velocity: 0
            // }}
            legends={[
               {
                  anchor: 'bottom',
                  direction: 'column',
                  justify: false,
                  translateX: props.legendTranslateX,
                  translateY: 5,
                  itemsSpacing: 1,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#000',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                     {
                        on: 'hover',
                        style: {
                           itemTextColor: '#000'
                        }
                     }
                  ]
               }
            ]}
            layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
         />
      </div>
   )
}

export default GraphPie
