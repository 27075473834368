import styles from "./DropdownField.module.scss"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import DropdownMenu from "./DropdownMenu"
import useHelpers from "../Hooks/useHelpers"

function DropdownField(props) {
   const [items, setItems] = useState(null)
   const [value, setValue] = useState(null)
   const [isDropdownVisible, setIsDropdownVisible] = useState(false)
   const [topValue, setTopValue] = useState(null)
   const [bottomValue, setBottomValue] = useState(null)
   const [addStyle, setAddStyle] = useState({})
   const { inlineStyleFromProps } = useHelpers()

   useEffect(() => {
      let val = null
      // log("====", props.children)
      const arr = props.children?.map((child) => {
         // log(child)
         if (child.key === props.selectedValue) {
            val = child
         }
         return <li id={child.key} key={child.key}>{child}</li>
      })
      // log('>>', props.children, arr)

      setItems(arr)
      setValue(val)
   }, [props.children, props.selectedValue, setValue])

   useEffect(() => {
      setIsDropdownVisible(props.isOpen)
   }, [props.isOpen])

   useEffect(() => {
      const style = inlineStyleFromProps(props) // On reconstruit un style avec les propriétés trouvées dans props
      setAddStyle(style)
      if (props.showAbove) {
         // log('LA', `calc(${style.height ? (style.height + "px + 5px") : "1em + 15px"})`);
         setBottomValue(`calc(${style.height ? (style.height + "px") : "1em + 20px"})`)
      } else {
         // log('ICI', props, props.showAbove, `calc(${style.height ? (style.height + "px + 5px") : "1em + 25px"})`);
         setTopValue(`calc(${style.height ? (style.height + "px + 5px") : "1em + 25px"})`)
      }
   }, [props, inlineStyleFromProps, setTopValue, setBottomValue])

   function toggleList(evt) {
      evt.stopPropagation()
      if (props.onToggle) {
         props.onToggle()
      }
      // setIsDropdownVisible(!isDropdownVisible)
   }

   function onSelect(id) {
      // log('DropdownField', id)
      // setIsDropdownVisible(false)
      if (props.onSelect) {
         props.onSelect(id)
      }
   }

   // log(addStyle)
   // log('ICI', props)

   return (
      <div className={styles.dropdown_field}>
         <div className={styles.field} style={addStyle} onClick={toggleList}>
            <span>{value}</span><FontAwesomeIcon icon={faAngleDown} />
         </div>
         {
            (isDropdownVisible &&
               <DropdownMenu noscrollbars onSelect={onSelect} right={0} top={topValue} bottom={bottomValue} >
                  {/* <DropdownMenu noscrollbars onChange={onChange} right={0} bottom={`calc(${addStyle.height ? (addStyle.height + "px + 5px") : "1em + 25px"})`}> */}
                  {items}
               </DropdownMenu>
            )
         }
      </div >
   )
}

export default DropdownField
