import { useEffect, useState } from "react"
import styles from "./Link.module.scss"

function Link(props) {
   const [addClasses, setAddClasses] = useState("")

   useEffect(() => {
      let newClass = ""
      if (props.disabled) {
         newClass = newClass + " " + styles.disabled
      }
      setAddClasses(newClass)
   }, [props])

   function onClick(evt) {
      evt.preventDefault()
      if (!props.disabled && props.onClick) {
         props.onClick()
      }
   }

   return (
      <a href={props.href || ""} className={`${styles.link}${addClasses}`} onClick={onClick}>{props.children}</a>
   )
}

export default Link
