import styles from "./SyncLayer.module.scss"
import { GlobalContext } from '../Contexts/GlobalContext';
import { useEffect, useState } from "react";
import useCollections from "../Hooks/useCollections";
import axios from 'axios'
import { Progress } from '@mantine/core';
import Button from './Button';
import useTranslation from '../Hooks/useTranslation';
// import useTarifs from "../Hooks/useTarifs";
import { AppWriteContext } from "../Contexts/AppWriteContext";

// Layer semi opaque utilisé quand on affiche une popup.

function SyncLayer(props) {
   const { getAllModelsAsArray } = useCollections()
   const { trans } = useTranslation();
   // const { fetchTarifsForSelectedRegions } = useTarifs();
   //
   const [isSyncing, setIsSyncing] = useState(false)
   const [finishedSyncing, setFinishedSyncing] = useState(false)
   const [canceled, setCanceled] = useState(false)
   const [totalSteps, setTotalSteps] = useState(0);
   const [curStep, setCurStep] = useState(null);
   const [progressPos, setProgressPos] = useState(0);
   // const [, setPrepCount] = useState(0);
   const [controller, setController] = useState();
   const { setIsSyncLayerVisible } = { ...GlobalContext() };
   const { fetchTarifsForSelectedRegions } = { ...AppWriteContext() };

   function doCancel() {
      controller.abort()
      setIsSyncing(false)
      setCanceled(true)
      // setIsSyncLayerVisible(false)
   }

   function doClose() {
      setIsSyncLayerVisible(false)
   }

   useEffect(() => {
      // console.log(curStep, totalSteps);
      setProgressPos(Math.round((100 * curStep) / totalSteps))
      setIsSyncing((curStep !== null) && (curStep < totalSteps))
      setFinishedSyncing((curStep !== null) && (curStep >= totalSteps))
   }, [totalSteps, curStep])

   function doStart() {
      const all_models = getAllModelsAsArray()
      // const nbModels = all_models.length

      if (!isSyncing) {
         const a_controller = new AbortController()
         const promises_tarifs = fetchTarifsForSelectedRegions(null, true, () => setCurStep(cur => { console.log('fetchTarifsForSelectedRegions - Step', cur); return cur + 1 }))
         const nb_tarifs = promises_tarifs.length
         setController(a_controller);
         setTotalSteps(nb_tarifs + (all_models.length * 2)) // *2 car assets M3 + bymessika
         setCurStep(0)
         // setIsSyncing(true)
         // setFinishedSyncing(false)
         setCanceled(false)
         // setPrepCount(0)
         // setProgressPos(0)
         all_models.forEach(a_model => {
            axios.get(`catalogue/modeles/${a_model?.id}@2x.png`, {
               signal: a_controller.signal
            })
               .then(() => setCurStep(cur => cur + 1))
               .catch(() => setCurStep(cur => cur + 1));
            axios.get(`https://www.bymessika.com/media/catalog/product${a_model.bymessika ? a_model.bymessika[0] : null}`, {
               signal: a_controller.signal
            })
               .then(() => setCurStep(cur => cur + 1))
               .catch(() => setCurStep(cur => cur + 1));
         })
      }
   }

   return (
      <div className={styles.synclayer}>
         {(isSyncing || finishedSyncing || canceled) &&
            <>
               <Progress color={finishedSyncing && !canceled ? "green" : "red"} radius="xl" size="xl" value={progressPos} animate={!finishedSyncing} className={styles.progress} />
               {canceled && <span>{trans("?txt_sync_annulé")}</span>}
               {!canceled && finishedSyncing && <span>{trans("?Terminé")}</span>}
               {!canceled && isSyncing && <span>{trans("?load_offline_assets")}...</span>}
               {isSyncing && <Button primary onClick={doCancel}>
                  {trans('?Annuler')}
               </Button>}
            </>
         }
         <div className={styles.buttons}>
            {!isSyncing && (!finishedSyncing || canceled) && <Button primary onClick={doStart}>
               {trans('?Préparer le mode offline')}
            </Button>
            }
            {!isSyncing && <Button primary green onClick={doClose}>
               {trans('?Fermer')}
            </Button>}
         </div>
      </div>
   )
}

export default SyncLayer
