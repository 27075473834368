import { useCallback, useEffect } from "react"
// import useSalesStore from "../stores/sales"
// import { useLogger } from "../Contexts/Logger"
import parsePhoneNumber from 'libphonenumber-js'
import usePrefStore from "../stores/prefs"
import { db } from "../stores/db"
import moment from 'moment'
import useTarifs from "./useTarifs"
import useModelsStore from "../stores/models"
import { useLiveQuery } from "dexie-react-hooks"
import { GlobalContext } from "../Contexts/GlobalContext"
import { AppWriteContext } from '../Contexts/AppWriteContext';

const order_status = {
   new: "new",                   // Commande en cours d'édition. Seul état qui permet sa modification dans l'appli.
   locked4sync: "locked4sync",   // Elle va bientôt être envoyée à M3. Elle n'est plus modifiable. Une fois l'envoi OK, elle passe en locked (localement, car elle est directement en locked sur AppWrite)
   locked: "locked",             // Elle va bientôt être envoyée à M3. Elle n'est plus modifiable.
   wait_m3: "wait_m3",           // Elle a été a été envoyée à M3. Elle n'est plus modifiable.
   done_m3: "done_m3",           // Elle est réapparue dans la BI avec avoir été intégrée à M3. Elle n'est plus synchro dans les commandes "locales" de l'appli et va d'ailleurs en être supprimée (pour ce faire, plus aucun utilisateur n'a de droit de lecture sur cet objet)
   deleted: "deleted",           // Marquée pour suppression. C'est temporaire le temps que la suppression soit faite sur AppWrite, sauf si pas de connexion, auquel cas ça se fera à la synchro forcée suivante.
}

const type_address = {
   siege: "SIEGE",   // ===
   ecrins: "ECR",    // startWith
   pos: "BTQ",       // startWith
}

const tag_address = {   // Pour afficher sous icone dans les listes
   siege: { fr: "SIEGE", en: "OFFICE" },
   ecrins: { fr: "ECR", en: "ECR" },
   pos: { fr: "POS", en: "POS" },
   other: { fr: "LIVR", en: "DELIV" },
}

const type_order = {
   commande: 'CDE',
   implant: 'CDI',
   confie: 'CON',
   devis: 'DEV',
}

const default_delivery_delay = 21      // Livraison par défaut dans 21 J

const useSales = () => {
   const customers = useLiveQuery(
      // () => db.customers.where("age").between(50, 75).toArray()
      async () => {
         // console.log('+++ Query Customers');
         const arr = await db.customers.toCollection().toArray() ?? []
         // console.log('arr', arr);
         return arr.reduce((prev, a_cust) => {
            return { ...prev, [a_cust.id]: { ...a_cust } }
         }, {})
      }
   )
   //
   const { curCustomer, setCurCustomer, curTarifRegionName, setCurTarifRegionName, setMissingTarifRegionName, curOrder, setCurOrder, localOrderId, setLocalOrderId, maxRecentItems, recentCustomers, setRecentCustomers, recentOrders, setRecentOrders, setCurTarifRegion, setCurTarifCustomer, udid } = usePrefStore();
   //
   const { setIsMissingTarifLayerVisible } = { ...GlobalContext() };
   //
   const { awPushOrder, awDeleteOrder } = { ...AppWriteContext() }
   // const tarif_regions = useModelsStore((state) => state.tarif_regions)
   const { tarif_regions, tarifs } = useModelsStore()

   //
   const { findRegionFromCessionTarifNames, findRegionFromPublicTarifNames, isTarifLoadedForRegion } = useTarifs()

   const sortOnIdString = useCallback((a, b) => {
      return a.id.localeCompare(b.id)
   }, [])

   /*
    ██████ ██    ██ ███████ ████████  ██████  ███    ███ ███████ ██████  ███████
   ██      ██    ██ ██         ██    ██    ██ ████  ████ ██      ██   ██ ██
   ██      ██    ██ ███████    ██    ██    ██ ██ ████ ██ █████   ██████  ███████
   ██      ██    ██      ██    ██    ██    ██ ██  ██  ██ ██      ██   ██      ██
    ██████  ██████  ███████    ██     ██████  ██      ██ ███████ ██   ██ ███████
   */

   /**
    * Retourne simplement l'objet customers (JSON) complet, avec tous les customers
    */
   // const getCustomers = useCallback(() => customers ?? {}, [customers])
   const getCustomers = useCallback(() => customers, [customers])

   /**
    * Retourne un tableau de customers :
    */
   const getCustomersAsArray = useCallback(() => {
      const obj = getCustomers()

      if (!obj) {
         return []
      }
      // console.log(('getCustomersAsArray', obj));
      return Object.keys(obj).map((id) => {
         return obj[id]
      })
   }, [getCustomers])

   /**
    * Retourne un tableau de customers, trié sur le champ dont le nom est passé en 1er paramètre.
    * Possibilités : [name, shortname, id]
    */
   const getCustomersAsSortedArray = useCallback((field = "name", fallback_field = "shortname") => {
      // console.log('getCustomersAsArray()', getCustomersAsArray())
      return getCustomersAsArray().sort((a, b) => {
         let val_a, val_b

         val_a = a[field] || a.id || a[fallback_field]
         val_b = b[field] || b.id || b[fallback_field]
         return val_a.localeCompare(val_b)
      })
   }, [getCustomersAsArray])

   /**
    * Retourne un seul objet customer
    * ou null si pas trouvé
    */
   const getCustomerById = useCallback((id, tag) => {
      // console.log('+++getCustomerById > 1', id, tag);
      if (!id) {
         // console.log('+++getCustomerById > 2');
         return null
      }
      // console.log('+++getCustomerById > 3');

      // const a_customer = customers[id]
      const all_customers = getCustomers()
      // console.log('+++getCustomerById > 4');
      if (!all_customers) {
         // console.log('+++getCustomerById > 5');
         return null
      }
      // console.log('+++getCustomerById > 6');
      const a_customer = all_customers[id]
      // console.log(id, a_customer, getCustomers());

      if (a_customer) {
         // console.log('+++getCustomerById > 7', a_customer, { ...a_customer });
         return a_customer
      } else {
         // console.log('+++getCustomerById > 8');
         console.error(`getCustomerById(${id}) - Pas trouvé !`);
         return null
      }
      // }, [customers])
   }, [getCustomers])

   /**
    * Retourne l'adresse du SIEGE d'un objet customer
    * ou null si pas trouvée
    */
   const getSiegeFromCustomer = useCallback((customer) => {
      return customer?.addresses?.SIEGE
   }, [])

   /**
    * Retourne l'adresse du SIEGE d'un customer
    * ou null si pas trouvée
    */
   const getSiegeForCustomerId = useCallback((id) => {
      const a_customer = getCustomerById(id)

      return getSiegeFromCustomer(a_customer)
   }, [getCustomerById, getSiegeFromCustomer])

   /**
    * Retourne les contacts d'un objet customer sous forme d'un objet.
    * ou null si pas trouvée
    * Les objets contacts et adresses sont identique mais "name" n'a pas la même signification
    * Exemple :
    * {
         "#00007": {name: "MME XXX", addr1: "YYY", addr2: "ZZZ", zip: "999", city: "DOHA", country: "QA" },
         ...
    * }
    */
   const getContactsFromCustomer = useCallback((customer) => {
      return customer?.contacts
   }, [])

   /**
    * Retourne les contacts d'un objet customer sous forme d'un tableau.
    * ou null si pas trouvée
    * Les objets contacts et adresses sont identique mais "name" n'a pas la même signification
    * Exemple :
    * [
         {id: "#00007", name: "MME XXX", addr1: "YYY", addr2: "ZZZ", zip: "999", city: "DOHA", country: "QA" },
         ...
    * ]
    */
   const getContactsFromCustomerAsArray = useCallback((customer) => {
      const contacts = getContactsFromCustomer(customer)

      if (contacts) {
         return Object.keys(contacts).map((id) => {
            return { ...contacts[id], id }
         })
      } else {
         return null
      }
   }, [getContactsFromCustomer])

   /**
    * Idem mais en triant sur l'ID
    */
   const getContactsFromCustomerAsSortedArray = useCallback((customer) => {
      const contacts = getContactsFromCustomerAsArray(customer)
      if (contacts) {
         return contacts.sort(sortOnIdString)
      } else {
         return null
      }
   }, [getContactsFromCustomerAsArray, sortOnIdString])

   /**
    * Retourne les adresses d'un objet customer sous forme d'un objet.
    * ou null si pas trouvée
    * Les objets adresses et contacts sont identique mais "name" n'a pas la même signification
    * Exemple :
    * {
         "#00007": {name: "XXX", addr1: "YYY", addr2: "ZZZ", zip: "999", city: "DOHA", country: "QA" },
         ...
    * }
    */
   const getAddressesFromCustomer = useCallback((customer) => {
      return customer?.addresses
   }, [])

   /**
    * Retourne les adresses d'un objet customer sous forme d'un tableau.
    * ou null si pas trouvée
    * Les objets adresses et contacts sont identique mais "name" n'a pas la même signification
    * Exemple :
    * [
         {id: "#00007", name: "MME XXX", addr1: "YYY", addr2: "ZZZ", zip: "999", city: "DOHA", country: "QA" },
         ...
    * ]
    */
   const getAddressesFromCustomerAsArray = useCallback((customer) => {
      const addresses = getAddressesFromCustomer(customer)

      if (addresses) {
         return Object.keys(addresses).map((id) => {
            return { ...addresses[id], id }
         })
      } else {
         return null
      }
   }, [getAddressesFromCustomer])

   /**
    * Trie un tableau d'adresses sur le type (SIEGE > BIJOUX > ECRINS)
    */
   const sortCustomerAddresses = (addresses) => {
      // console.log('ADDRESSES', addresses);
      if (addresses) {
         return addresses.sort((a, b) => {
            if (a.id === type_address.siege) {
               if (b.id === type_address.siege) { // Cas impossible en principe il n'y a qu'un seul SIEGE !!!
                  return a.id.localeCompare(b.id)
               } else {
                  return -1   // a vient avant b
               }
            } else if (b.id === type_address.siege) {
               return 1   // b vient avant a
            } else if (a.id.startsWith(type_address.ecrins)) {
               if (b.id.startsWith(type_address.ecrins)) {
                  return a.id.localeCompare(b.id)
               } else {
                  return 1    // b vient avant a
               }
            } else if (b.id.startsWith(type_address.ecrins)) {
               return -1    // a vient avant b
            } else { // Les 2 sont de type bijoux
               return a.id.localeCompare(b.id)
            }
         })
      } else {
         return null
      }
   }

   /**
    * Idem mais en triant sur le type (SIEGE > BIJOUX > ECRINS)
    */
   const getAddressesFromCustomerAsSortedArray = useCallback((customer) => {
      const addresses = getAddressesFromCustomerAsArray(customer)
      return sortCustomerAddresses(addresses)
   }, [getAddressesFromCustomerAsArray])

   /**
    * Retourne le 1er numéro de téléphone d'un customer
    * ou null si pas trouvé
    */
   const getPhoneForCustomerId = useCallback((id, format = false) => {
      const a_customer = getCustomerById(id)
      const siege = getSiegeForCustomerId(id)

      // console.log('ICI1', a_customer);
      if (a_customer?.phones) {
         // console.log('ICI2');
         let phone = a_customer.phones[0]
         if (format && phone) {
            // console.log('ICI3', phone, siege);
            let num = parsePhoneNumber(phone.replaceAll(' ', ''), (siege?.country ?? 'FR'))
            // console.log('ICI3bis', num);
            if (num) {
               // console.log('ICI3ter');
               phone = num.formatInternational()
            }
            // console.log('ICI4');
         }
         // console.log('ICI5', phone);
         return phone
      } else {
         return null
      }
   }, [getCustomerById, getSiegeForCustomerId])

   const updateRecentCustomers = useCallback((customer_id) => {
      setRecentCustomers([...recentCustomers.reduce((prev, cur) => {
         const exists = getCustomerById(cur)

         if (!exists) {
            return [...prev]
         } else {
            if (cur === customer_id) {
               return [...prev]
            } else {
               return [...prev, cur]
            }
         }
      }, [customer_id]).slice(0, maxRecentItems)])
   }, [getCustomerById, maxRecentItems, recentCustomers, setRecentCustomers])

   // Retiré car l'édition du champ max dans la page Préféence faisait purger tout l'historique avec cet Effect
   // useEffect(() => {
   //    if (recentCustomers.length > maxRecentItems) {  // Pour empêcher loop d'event
   //       setRecentCustomers([...recentCustomers?.slice(0, maxRecentItems)])
   //    }
   //    if (recentOrders.length > maxRecentItems) {  // Pour empêcher loop d'event
   //       setRecentOrders([...recentOrders?.slice(0, maxRecentItems)])
   //    }
   // }, [maxRecentItems, recentCustomers, recentOrders, setRecentCustomers, setRecentOrders])

   // useEffect(() => {
   //    console.log('***CHANGED', recentCustomers);
   // }, [recentCustomers])

   const setCustomerIdAsCurrent = useCallback((id, raz_cur_order = true) => {
      const a_customer = getCustomerById(id)

      if (!a_customer) {
         console.error(`setCustomerIdAsCurrent(${id}) - Pas trouvé !`);
      } else {
         // "tariff": { "other": { "ppu": "CES999", "ces": "PPU999" } },
         // const region_name = findRegionFromTarifNames(a_customer.tarifs.ppu.tarif, a_customer.tarifs.ppu.currency)
         let region_name = findRegionFromCessionTarifNames(a_customer.tarifs.ces.tarif, a_customer.tarifs.ces.currency)
         if (!region_name) {
            // !!!!! NB : OUI, c'est bien CES qui est pris dans a_customer pour chercher la région. C'est pour les clients qui n'ont pas de tarif cession mais un tarif public remisé
            region_name = findRegionFromPublicTarifNames(a_customer?.tarifs.ces.tarif, a_customer?.tarifs.ces.currency)
         }

         console.log('===', a_customer.tarifs.ces.tarif, a_customer.tarifs.ces.currency, region_name);
         setCurCustomer(id)
         console.log('isTarifLoadedForRegion', region_name, isTarifLoadedForRegion(region_name));
         if (isTarifLoadedForRegion(region_name)) {
            setCurTarifRegionName(region_name)
         } else {
            setIsMissingTarifLayerVisible(true)
            setMissingTarifRegionName(region_name)
         }

         if (raz_cur_order) {
            setCurOrder(null)
         }
         updateRecentCustomers(id)
      }
   }, [findRegionFromCessionTarifNames, findRegionFromPublicTarifNames, getCustomerById, isTarifLoadedForRegion, setCurCustomer, setCurOrder, setCurTarifRegionName, setIsMissingTarifLayerVisible, setMissingTarifRegionName, updateRecentCustomers])

   useEffect(() => {
      const a_customer = getCustomerById(curCustomer)

      let valCES = null
      let valPPU = null
      let valCurrencyCES = null
      let valCurrencyPPU = null
      let valHJO_CES = null
      let valHJO_PPU = null
      let valCurrencyHJO_CES = null
      let valCurrencyHJO_PPU = null
      // if (setCurTarifRegionName) {
      if (curTarifRegionName) {
         const full_tarif = tarif_regions[curTarifRegionName]

         // console.log('full_tarif', full_tarif);
         if (full_tarif) {
            //       { "hjo": { "ces": { "currency": "EUR", "tarif": "CES017" }, "ppu": { "currency": "EUR", "tarif": "PPU017" } }, "other": { "ces": { "currency": "EUR", "tarif": "CES017" }, "ppu": { "currency": "EUR", "tarif": "PPU017" } } },

            valCES = full_tarif.currencies.other.ces.tarif
            valPPU = full_tarif.currencies.other.ppu.tarif
            valCurrencyCES = full_tarif.currencies.other.ces.currency
            valCurrencyPPU = full_tarif.currencies.other.ppu.currency
            valHJO_CES = full_tarif.currencies.hjo.ces.tarif
            valHJO_PPU = full_tarif.currencies.hjo.ppu.tarif
            valCurrencyHJO_CES = full_tarif.currencies.hjo.ces.currency
            valCurrencyHJO_PPU = full_tarif.currencies.hjo.ppu.currency
         }
      }
      setCurTarifRegion({
         hjo: {
            ces: {
               tarif: valHJO_CES,
               currency: valCurrencyHJO_CES,
            },
            ppu: {
               tarif: valHJO_PPU,
               currency: valCurrencyHJO_PPU,
            },
         },
         other: {
            ces: {
               tarif: valCES,
               currency: valCurrencyCES,
            },
            ppu: {
               tarif: valPPU,
               currency: valCurrencyPPU,
            },
         },
      })
      if (!a_customer) {
         setCurTarifCustomer(null)
      } else {
         let region_name = findRegionFromCessionTarifNames(a_customer.tarifs.ces.tarif, a_customer?.tarifs.ces.currency)
         if (!region_name) {
            // !!!!! NB : OUI, c'est bien CES qui est pris dans a_customer pour chercher la région. C'est pour les clients qui n'ont pas de tarif cession mais un tarif public remisé
            region_name = findRegionFromPublicTarifNames(a_customer?.tarifs.ces.tarif, a_customer?.tarifs.ces.currency)
         }

         // console.log('FOUND region_name', region_name);
         const a_tarif = (tarif_regions ? tarif_regions[region_name] : null)
         // console.log('FOUND a_tarif', a_tarif);

         if (a_tarif) {
            setCurTarifCustomer({
               hjo: {
                  ces: {
                     tarif: valHJO_CES,
                     currency: valCurrencyHJO_CES,
                  },
                  ppu: {
                     tarif: valHJO_PPU,
                     currency: valCurrencyHJO_PPU,
                  },
               },
               other: {
                  ces: {
                     tarif: a_tarif.currencies.other.ces.tarif,
                     currency: a_tarif.currencies.other.ces.currency,
                  },
                  ppu: {
                     tarif: a_tarif.currencies.other.ppu.tarif,
                     currency: a_tarif.currencies.other.ppu.currency,
                  },
               },
            })
         } else {
            setCurTarifCustomer(null)
         }
      }
   }, [curCustomer, curTarifRegionName, findRegionFromCessionTarifNames, findRegionFromPublicTarifNames, getCustomerById, setCurTarifCustomer, setCurTarifRegion, setCurTarifRegionName, tarif_regions, tarifs])

   /*
    ██████  ██████  ██████  ███████ ██████  ███████
   ██    ██ ██   ██ ██   ██ ██      ██   ██ ██
   ██    ██ ██████  ██   ██ █████   ██████  ███████
   ██    ██ ██   ██ ██   ██ ██      ██   ██      ██
    ██████  ██   ██ ██████  ███████ ██   ██ ███████
   */

   /**OK
    * Retourne une collection (Dexie) d'objet orders (JSON), avec tous les orders.
    * Si sync === true : uniquement les commandes synchronisées (provenant de la BI)
    * Si sync === false : uniquement les non synchro (locales, ou envoyées vers M3 mais pas encore revenues dans la BI)
    */
   const _getOrders = useCallback((sync) => {
      if (sync) {
         return db.bi_orders.toCollection()
      } else {
         return db.orders.toCollection()
         // return db.orders.where('status').equals(order_status.unsync)
      }
   }, [])

   const getSyncOrders = useCallback(() => {
      return _getOrders(true)
   }, [_getOrders])

   const getUnsyncOrders = useCallback(() => {
      return _getOrders(false)
   }, [_getOrders])

   /**OK
    * Retourne PROMISE, then :
    * ...un tableau d'objets order
    * Si sync === true : uniquement les commandes synchronisées (provenant de la BI)
    * Si sync === false : uniquement les non synchro (locales, ou envoyées vers M3 mais pas encore revenues dans la BI)
    */
   const _getOrdersAsArray = useCallback((sync) => {
      return _getOrders(sync).toArray()
   }, [_getOrders])

   const getSyncOrdersAsArray = useCallback(() => {
      return _getOrdersAsArray(true)
   }, [_getOrdersAsArray])

   const getUnsyncOrdersAsArray = useCallback(() => {
      return _getOrdersAsArray(false)
   }, [_getOrdersAsArray])

   /** PAS DE SORTED ARRAY */

   /**OK
    * Retourne PROMISE, then :
    * ...un seul objet order
    * ou null si pas trouvé
    * C'est un objet "copie" de la base de données, on peut faire ce qu'on veut de cet objet sans impacter la BDD
    */

   const getOrderById = useCallback(async (id) => {
      if (!id) {
         return null
      }

      let ret = await db.orders.where('id').equals(id).first()
      if (!ret) {
         ret = await db.bi_orders.where('id').equals(id).first()
      }

      return ret || null
   }, [])

   /**OK
    * Retourne PROMISE, then :
    * ...un tableau d'objets order
    * ou [] si pas trouvé
    */

   const getOrdersByIds = useCallback(async (ids) => {
      if (!(ids?.length)) {
         return []
      }

      const ret = await db.orders.where('id').anyOf([...ids]).toArray()
      const ret_bi = await db.bi_orders.where('id').anyOf([...ids]).toArray()
      const all = [...ret, ...ret_bi]

      // return ret || {}
      return all
   }, [])

   const removeOrderIdFromRecentOrders = useCallback(async (order_id) => {
      console.log('removeOrderIdFromRecentOrders', order_id);
      const new_list = recentOrders.filter((an_id) => an_id !== order_id)
      console.log('removeOrderIdFromRecentOrders', new_list);
      setRecentOrders(new_list)
   }, [recentOrders, setRecentOrders])

   const updateRecentOrders = useCallback(async (order_id) => {
      const new_id = String(order_id)
      const res = []
      let exists = await getOrderById(new_id)

      if (exists) {
         res.push(new_id)
      }
      for (const cur of recentOrders) {   // Plus simple qu'un `reduce()` quand il y a des appels async
         exists = await getOrderById(cur)

         // console.log('EXISTS?', cur, exists);

         if (exists && (cur !== new_id)) {
            res.push(cur)
         }
      }

      // console.log('RES=', res, maxRecentItems);
      setRecentOrders(res.slice(0, maxRecentItems))
   }, [getOrderById, maxRecentItems, recentOrders, setRecentOrders])

   const setOrderIdAsCurrent = useCallback(async (id) => {
      const an_order = await getOrderById(id)

      console.log(`getOrderById(${id})`, an_order)
      console.log('+=+=', an_order);
      if (!an_order.readonly) {
         setCurOrder(id)
         setCustomerIdAsCurrent(an_order.customer_id, false)   // false = on ne ràz pas curOrder, bien sûr !!!
         updateRecentOrders(id)
      } else {
         console.log(`setOrderIdAsCurrent(${id}) - Read only!`);
      }
   }, [getOrderById, setCurOrder, setCustomerIdAsCurrent, updateRecentOrders])


   /**OK
    * Retourne un tableau d'IDs d'objets order
    */
   const getOrderIdsForCustomerId = useCallback(async (id) => {
      const arr = await db.orders.where('customer_id').equals(id).toArray()
      const arr_bi = await db.bi_orders.where('customer_id').equals(id).toArray()
      return [...arr, ...arr_bi].map(an_order => an_order.id)
   }, [])

   /**OK
    * Retourne une collection d'objets order
    * Si sync === true : uniquement les commandes synchronisées (provenant de la BI)
    * Si sync === false : uniquement les non synchro (locales, ou envoyées vers M3 mais pas encore revenues dans la BI)
    */
   const _getOrdersForCustomerId = useCallback(async (id, sync) => {
      console.log('_getOrdersForCustomerId', id, sync);
      if (!id) {
         return null
      } else {
         if (sync) {
            return await db.bi_orders.where('customer_id').equals(id).toArray()
         } else {
            // return await db.orders.where('customer_id', 'status').equals(id, sync).toArray()
            return await db.orders.where('customer_id').equals(id).toArray()
         }
      }
   }, [])

   const getSyncOrdersForCustomerId = useCallback(async (id) => {
      return await _getOrdersForCustomerId(id, true)
   }, [_getOrdersForCustomerId])

   const getUnsyncOrdersForCustomerId = useCallback(async (id) => {
      return await _getOrdersForCustomerId(id, false)
   }, [_getOrdersForCustomerId])

   /**
    * Calcule le prix total des lignes d'un objet commande
    */
   const calcTotalForOrder = useCallback((an_order) => {
      return an_order?.lines?.reduce((prev, a_line) => {
         return prev + a_line.price * a_line.qty
      }, 0) ?? 0
   }, [])

   /**
    * Retourne le nb d'items d'un objet commande
    */
   const getItemsCountForOrder = useCallback((an_order) => {
      return an_order?.lines?.reduce((prev, a_line) => {
         return prev + a_line.qty
      }, 0) ?? 0
   }, [])

   /**
    * Création d'un objet modèle de commande locale
    */
   const makeTemplateOrder = useCallback((customer_id = null) => {
      const now = moment()
      return {
         /**
          * ATTENTION, ce modèle doit être compatible avec AppWriteContext._buildOrderObjectFromAppWriteObject()
          */
         id: null,   // Sera remplacé au moment du dexie_db.add()
         customer_id: customer_id ?? curCustomer,
         type: type_order.commande,
         date: now.format("YYYY-MM-DD"),
         time: now.format("HH:mm"),
         delivery: now.add(default_delivery_delay, 'days').format("YYYY-MM-DD"),
         addresses: {
            jewels: null,
            ecrins: null,
         },
         comment: null,
         mark: null,
         signature: null,
         lines: [],
         // synced: false,
         readonly: false,
         status: order_status.new,
         last_pushed: null,
         last_updated: null,
      }
   }, [curCustomer])

   /**
    * Création d'une commande locale
    */
   const createOrder = useCallback(async (an_order) => {
      const short_udid = udid.substring(0, 4)
      let loop = 100
      let orderId = Number(localOrderId)
      let orderIdStr
      while (loop > 0) {
         orderIdStr = String(`${short_udid}-${orderId}`)

         console.log('createOrder (trying)', orderId, { ...an_order, id: orderIdStr, status: order_status.new });
         try {
            await db.orders.add({ ...an_order, id: orderIdStr, status: order_status.new });
            loop = 0 // Sortie de boucle
         } catch (err) {
            if (err.name === 'ConstraintError') {
               console.log(`orderId ${orderId} already exists, trying next one (remaining: ${loop})`);
               orderId = orderId + 1
               loop = loop - 1
            } else {
               throw err
            }
         }
      }
      awPushOrder(orderIdStr)

      setCurOrder(orderIdStr)
      setCustomerIdAsCurrent(an_order.customer_id, false)   // false = on ne ràz pas curOrder, bien sûr !!!
      updateRecentOrders(orderIdStr)

      const curId = Number(orderId)
      setLocalOrderId(String(curId + 1))
      return orderIdStr
   }, [localOrderId, awPushOrder, setCurOrder, setCustomerIdAsCurrent, setLocalOrderId, udid, updateRecentOrders])

   /**
    * Création ou MàJ de lignes dans une commande
    * La MàJ se fait sur la base du SKU
    * La MàJ ne change que les attributs présents dans les objets du tableau new_lines, donc pas de màj du
    * commentaire si l'attribut comment n'est pas présent.
    * Le seul attribut obligatoire est le SKU
    * update_line_num : si pas null indique le numéro de la ligne mise à jour. Pas de nettoyage !
    */
   const createOrUpdateLinesInOrder = useCallback(async (order_id, new_lines, update_line_num = null) => {
      const an_order = await getOrderById(order_id)

      let cur_lines = an_order.lines
      // console.log('cur_lines', cur_lines);

      if (!update_line_num ||    // On n'est pas en update d'une ligne
         (new_lines.length > 1) ||  // On a fourni plus d'une ligne
         (cur_lines.length < update_line_num) ||   // Le numéro de ligne a updater est trop grand
         (cur_lines[update_line_num - 1].sku !== new_lines[0].sku)   // Le SKU a updater ne matche pas !
      ) {
         // Alors on ajoute la ou les lignes sans chercher à updater...
         for (const a_line of new_lines) {
            // console.log('a_line', a_line);
            // On cherche une ancienne ligne avec model+couleur+taille
            let found_index = cur_lines.findIndex((scan_line) => {
               // console.log('check', `${scan_line.sku} === ${a_line.sku}`, (scan_line.sku === a_line.sku));
               return ((scan_line.sku === a_line.sku) && !scan_line.comment)  // On cherche une ligne existante par son SKU mais on exclut une ligne qui aurait un commentaire. Dans ce cas on ajoutera une autre ligne.
            })
            if (found_index < 0) {
               // console.log('not found');
               // Ligne n'existe pas, on la crée
               cur_lines = [...cur_lines, { ...a_line }]
               // console.log('CUR_LINES', cur_lines);
            } else {
               // console.log('found');
               // Ligne existe, on la met à jour ou on supprime si qty <= 0
               cur_lines[found_index] = { ...cur_lines[found_index], ...a_line }
               // console.log('FOUND_INDEX', found_index);
            }
         }
      } else {
         // Sinon on peut updater la ligne ciblée
         cur_lines[update_line_num - 1] = { ...cur_lines[update_line_num - 1], ...new_lines[0] }
      }

      // console.log('APRÈS', an_order);

      // console.log('createLinesInCurOrder', an_order, cur_lines);

      // On vire les qty <= 0
      cur_lines = cur_lines.filter(a_line => (a_line.qty > 0))

      await db.orders.put({ ...an_order, lines: [...cur_lines] });
      awPushOrder(order_id)
   }, [getOrderById, awPushOrder])

   /**
    * Clonage d'une commande
    */
   const cloneOrder = useCallback(async (order_id) => {
      const orig = await getOrderById(order_id)
      const now = moment()

      if (orig) {
         orig.date = now.format("YYYY-MM-DD")
         orig.time = now.format("HH:mm")
         orig.delivery = now.add(default_delivery_delay, 'days').format("YYYY-MM-DD")
         orig.comment = null
         orig.mark = null
         orig.signature = null
         orig.readonly = false
         orig.last_pushed = null
         orig.last_updated = null
         // On reconstruit les lignes pour exclure des champs inutiles (remain_qty par exemple), qui peuvent exister dans les commandes BI si on clone une commande BI !
         orig.lines = orig.lines.map(a_line => {
            const obj = {
               sku: a_line.sku,
               qty: a_line.qty,
               price: a_line.price,
            }
            if (a_line.comment) {
               obj.comment = a_line.comment
            }
            return obj
         })
         return await createOrder(orig)
      } else {
         return null
      }
   }, [createOrder, getOrderById])

   /**
    * Suppression d'une ligne dans une commande à partir de son numéro de ligne
    */
   const deleteSingleLineInOrder = useCallback(async (order_id, line_num) => {
      const an_order = await getOrderById(order_id)

      // console.log('AVANT', { ...an_order });

      let cur_lines = an_order.lines
      // console.log('cur_lines', cur_lines);

      cur_lines = cur_lines.filter((a_line, num) => {
         // console.log(skus, a_line.sku);
         return (num + 1) !== line_num
      })
      // console.log('deleteLinesInOrder', an_order, cur_lines);

      await db.orders.put({ ...an_order, lines: [...cur_lines] });
      awPushOrder(order_id)
   }, [getOrderById, awPushOrder])

   /**
    * Suppression de lignes dans une commande à partir de SKUs
    * Reçoit un tableau de SKUs, même si l'usage sera sans doute des SKU individuels
    */
   const deleteLinesInOrder = useCallback(async (order_id, skus) => {
      const an_order = await getOrderById(order_id)

      // console.log('AVANT', { ...an_order });

      let cur_lines = an_order.lines
      // console.log('cur_lines', cur_lines);

      cur_lines = cur_lines.filter(a_line => {
         // console.log(skus, a_line.sku);
         return !skus.includes(a_line.sku)
      })
      // console.log('deleteLinesInOrder', an_order, cur_lines);

      await db.orders.put({ ...an_order, lines: [...cur_lines] });
      awPushOrder(order_id)
   }, [getOrderById, awPushOrder])

   /**
    * MàJ d'attributs d'une commande
    * Seuls les attributs spécifiés dans l'objet
    */
   const _updateOrder = useCallback(async (order_id, attribs, push) => {
      const an_order = await getOrderById(order_id)

      if (!an_order.readonly || (an_order.status === order_status.locked) || (an_order.status === order_status.locked4sync)) {
         console.log('AN_ORDER', an_order);
         console.log('ATTRIBS', attribs);
         const newAttribs = { ...an_order, ...attribs }
         await db.orders.put(newAttribs);
         if (push) {
            awPushOrder(order_id)
         }
         if (newAttribs.status !== order_status.new) {
            setCurOrder(null)
         }
      } else {
         console.log(`updateOrder(${order_id}) - Read only!`);
      }
   }, [getOrderById, awPushOrder, setCurOrder])

   const updateOrderLocally = useCallback(async (order_id, attribs) => {
      return await _updateOrder(order_id, attribs, false)
   }, [_updateOrder])

   const updateOrder = useCallback(async (order_id, attribs) => {
      return await _updateOrder(order_id, attribs, true)
   }, [_updateOrder])

   /**
    * Suppression d'une commande
    */
   const deleteOrder = useCallback(async (order_id) => {
      if (curOrder === order_id) {
         setCurOrder(null)
      }
      await updateOrderLocally(order_id, {
         status: order_status.deleted,   // On la passe en deleted localement, ce n'est pas un état stocké sur le serveur
         readonly: true,
      })
      await awDeleteOrder(order_id)
      removeOrderIdFromRecentOrders(order_id)
   }, [awDeleteOrder, curOrder, setCurOrder, updateOrderLocally, removeOrderIdFromRecentOrders])

   /**
    * Retourne si on peut synchroniser une commande (true) ou pas (false)
    * Doit :
    * - ne pas être une commande déjà sync
    * - avoir une adresse de livraison bijoux
    * - avoir une contremarque
    */
   const canSyncOrder = useCallback((order) => {
      return order && !order.readonly && order.addresses.jewels && order.mark
   }, [])

   return {
      order_status,
      type_address,
      tag_address,
      type_order,
      // fetchOrders,
      getCustomers,
      getCustomersAsArray,
      getCustomersAsSortedArray,
      getCustomerById,
      setCustomerIdAsCurrent,
      getSiegeForCustomerId,
      getSiegeFromCustomer,
      getContactsFromCustomer,
      getContactsFromCustomerAsArray,
      getContactsFromCustomerAsSortedArray,
      getAddressesFromCustomer,
      getAddressesFromCustomerAsArray,
      getAddressesFromCustomerAsSortedArray,
      sortCustomerAddresses,
      getPhoneForCustomerId,
      updateRecentCustomers,
      removeOrderIdFromRecentOrders,
      getSyncOrders,
      getUnsyncOrders,
      getSyncOrdersAsArray,
      getUnsyncOrdersAsArray,
      getOrderById,
      getOrdersByIds,
      setOrderIdAsCurrent,
      getSyncOrdersForCustomerId,
      getUnsyncOrdersForCustomerId,
      getOrderIdsForCustomerId,
      updateRecentOrders,
      calcTotalForOrder,
      getItemsCountForOrder,
      makeTemplateOrder,
      createOrder,
      createOrUpdateLinesInOrder,
      cloneOrder,
      deleteSingleLineInOrder,
      deleteLinesInOrder,
      updateOrder,
      updateOrderLocally,
      deleteOrder,
      canSyncOrder
   }
}

export default useSales