import styles from "./Keypad.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDeleteLeft, faCheck } from "@fortawesome/free-solid-svg-icons"
import { forwardRef, Fragment, useCallback, useEffect, useState } from "react"

/**
 * Pour que le Keypad recoive les frappes clavier il faut focus() le div du clavier (https://bobbyhadz.com/blog/react-onkeydown-div)
 * 
 */

const Keypad = forwardRef((props, ref) => {     // forwardRef() car un composant React ne peut pas avoir de ref, il faut un composant DOM (https://beta.reactjs.org/reference/react/forwardRef)
   const [curQty, setCurQty] = useState(null)
   const [keys, setKeys] = useState(null)

   useEffect(() => {
      setCurQty(props.value)
      if (props.visible && ref) {
         // log('DO FOCUS')
         ref.current.focus()
      }
   }, [props, ref])

   const gotKey = useCallback((val) => {
      const newValue = curQty * 10 + val

      // log("gotKey>>", val, curQty, newValue, props.min, props.max);
      if ((newValue >= props.min) && (newValue <= props.max)) {
         setCurQty(newValue)
         if (props.onChange) {
            props.onChange(newValue)
         }
      }
   }, [curQty, props])

   function gotDelete() {
      const newValue = Math.floor(curQty / 10)

      if ((newValue >= props.min) && (newValue <= props.max)) {
         setCurQty(newValue)
         if (props.onChange) {
            props.onChange(newValue)
         }
      }
   }

   function gotValidate() {
      if (props.onValidate) {
         props.onValidate(curQty)
      }
   }

   function doNothing(evt) {
      // log('Keypad - doNothing')
      evt.stopPropagation()
   }

   const doClick = useCallback((evt, val) => {
      evt.stopPropagation()
      if (!props.disabled) {
         gotKey(val)
      }
   }, [gotKey, props.disabled])

   function doDelete(evt) {
      evt.stopPropagation()
      if (!props.disabled) {
         gotDelete()
      }
   }

   function doValidate(evt) {
      evt.stopPropagation()
      if (!props.disabled) {
         gotValidate()
      }
   }

   function onKeyDown(evt) {
      console.log('onKeyDown', evt);
      // log(props)
      if (!props.disabled) {
         // log('KEYDOWN', evt.key)
         if ((evt.key >= '0') && (evt.key <= '9')) {
            gotKey(evt.key - '0')
         } else if (evt.key === 'Enter') {
            gotValidate()
         } else if (evt.key === 'Backspace') {
            gotDelete()
         } else if (evt.key === 'Escape') {
            if (props.onCancel) {
               props.onCancel()
            }
         }
      }
   }

   useEffect(() => {
      let arr = []
      for (let loop = 1; loop <= 9; loop++) {
         arr.push(
            <Fragment key={`keypad-key-${loop}`}>
               <span onClick={(evt) => { doClick(evt, loop) }}>{loop}</span>
            </Fragment>
         )
      }
      setKeys(arr)
   }, [doClick])

   // log('Keypad', curQty, props)

   return (
      <div ref={ref} className={`${styles.keypad} ${props.disabled ? styles.disabled : ""}`} tabIndex={0} onKeyDown={onKeyDown} onClick={doNothing}>
         {keys}
         <span onClick={(evt) => { doDelete(evt) }}><FontAwesomeIcon icon={faDeleteLeft} /></span>
         <span onClick={(evt) => { doClick(evt, 0) }}>0</span>
         <span onClick={(evt) => { doValidate(evt) }}><FontAwesomeIcon icon={faCheck} /></span>
      </div>
   )
})

export default Keypad
