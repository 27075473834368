import { Fragment, useEffect, useState } from 'react';
import Button from '../components/Button';
import { Checkbox, States as CheckboxStates } from '../components/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown, faCircleDashed } from '@fortawesome/pro-solid-svg-icons'
import Menu from '../components/Menu/Menu';
import useTranslation from '../Hooks/useTranslation';
// import { useTranslation } from "react-i18next"
import usePrefStore from '../stores/prefs';
import useModelsStore from '../stores/models';
import styles from './Tarifs.module.scss';
import useTarifs from '../Hooks/useTarifs';
// import { useLogger } from '../Contexts/Logger';

function Tarifs() {
   // Prefs
   // const maxTarifsOffline = usePrefStore((state) => state.maxTarifsOffline);
   // const curLang = usePrefStore((state) => state.currentLang)
   const { maxTarifsOffline, currentLang: curLang } = usePrefStore()
   // Model store
   // const tarif_regions = useModelsStore((state) => state.tarif_regions);
   // const tarifs = useModelsStore((state) => state.tarifs);
   // const setPreloadOnTarifRegion = useModelsStore((state) => state.setPreloadOnTarifRegion);
   // const setInListOnTarifRegion = useModelsStore((state) => state.setInListOnTarifRegion);
   // const tarif_trads = useModelsStore((state) => state.tarif_trads);
   const { tarif_regions, tarifs, setPreloadOnTarifRegion, setInListOnTarifRegion, tarif_trads } = useModelsStore();
   //
   const [tarifItems, setTarifItems] = useState(null);
   const [showOnlySel, setShowOnlySel] = useState(false);
   const [nbInList, setNbInList] = useState(null);
   const [nbOffline, setNbOffline] = useState(null);
   const [nbTotal, setNbTotal] = useState(null);
   const [showStatus, setShowStatus] = useState(false);
   //
   const { trans, transWithParams } = useTranslation();
   const { emptyTarifs } = useTarifs();
   // const { log } = useLogger();

   function doCleanTarifs() {
      console.log('doCleanTarifs');
      emptyTarifs();
   }

   // console.log(tarif_regions);
   // console.log(tarifs);

   useEffect(() => {
      let countInList = 0, countOffline = 0
      setTarifItems(
         Object.keys(tarif_regions).filter((key => !showOnlySel || tarif_regions[key].in_list)).map((key) => {
            let label
            const lang = (curLang === 'en') ? 'en' : 'fr'
            const a_tarif = tarif_regions[key]

            if (a_tarif.in_list) {
               countInList++
            }
            if (a_tarif.preload) {
               countOffline++
            }

            // console.log(tarif_trads, name, lang);
            if (lang !== 'en') {
               label = key
            } else {
               label = tarif_trads[key]?.label.en ?? key
            }

            return [
               <tr key={`tr_${key}`}>
                  <td>{label}</td>
                  <td>
                     <Checkbox key={`check_${key}`}
                        caption=''
                        checked={a_tarif.in_list ? CheckboxStates.Checked : CheckboxStates.Unchecked}
                        onChange={(status) => { setInListOnTarifRegion(key, status === CheckboxStates.Checked) }}
                     />
                  </td>
                  <td>
                     <Checkbox key={key}
                        caption=''
                        checked={a_tarif.preload ? CheckboxStates.Checked : CheckboxStates.Unchecked}
                        disabled={!a_tarif.preload && (nbOffline >= maxTarifsOffline)}
                        onChange={(status) => { setPreloadOnTarifRegion(key, status === CheckboxStates.Checked) }}
                     />
                  </td>
                  {showStatus &&
                     <>
                        <td>
                           <div>
                              {a_tarif.in_list && tarifs[a_tarif.currencies.hjo.ppu.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-green)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && !a_tarif.preload && !tarifs[a_tarif.currencies.hjo.ppu.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-20)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && a_tarif.preload && !tarifs[a_tarif.currencies.hjo.ppu.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-red)' }} icon={faCloudArrowDown} />
                              }
                              {!a_tarif.in_list &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-10)' }} icon={faCircleDashed} />
                              }
                              <span>{a_tarif.currencies.hjo.ppu.tarif}</span>
                           </div>
                        </td>
                        <td>
                           <div>
                              {a_tarif.in_list && tarifs[a_tarif.currencies.hjo.ces.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-green)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && !a_tarif.preload && !tarifs[a_tarif.currencies.hjo.ces.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-20)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && a_tarif.preload && !tarifs[a_tarif.currencies.hjo.ces.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-red)' }} icon={faCloudArrowDown} />
                              }
                              {!a_tarif.in_list &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-10)' }} icon={faCircleDashed} />
                              }
                              <span>{a_tarif.currencies.hjo.ces.tarif}</span>
                           </div>
                        </td>
                        <td>
                           <div>
                              {a_tarif.in_list && tarifs[a_tarif.currencies.other.ppu.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-green)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && !a_tarif.preload && !tarifs[a_tarif.currencies.other.ppu.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-20)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && a_tarif.preload && !tarifs[a_tarif.currencies.other.ppu.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-red)' }} icon={faCloudArrowDown} />
                              }
                              {!a_tarif.in_list &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-10)' }} icon={faCircleDashed} />
                              }
                              <span>{a_tarif.currencies.other.ppu.tarif}</span>
                           </div>
                        </td>
                        <td>
                           <div>
                              {a_tarif.in_list && tarifs[a_tarif.currencies.other.ces.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-green)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && !a_tarif.preload && !tarifs[a_tarif.currencies.other.ces.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-20)' }} icon={faCloudArrowDown} />
                              }
                              {a_tarif.in_list && a_tarif.preload && !tarifs[a_tarif.currencies.other.ces.tarif] &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-primary-red)' }} icon={faCloudArrowDown} />
                              }
                              {!a_tarif.in_list &&
                                 <FontAwesomeIcon style={{ color: 'var(--color-gray-10)' }} icon={faCircleDashed} />
                              }
                              <span>{a_tarif.currencies.other.ces.tarif}</span>
                           </div>
                        </td>
                     </>
                  }
                  <td></td>
               </tr>
            ];
         })
      );
      setNbInList(countInList)
      setNbOffline(countOffline)
      setNbTotal(Object.keys(tarif_regions).length)
   }, [maxTarifsOffline, nbOffline, setInListOnTarifRegion, setPreloadOnTarifRegion, showStatus, showOnlySel, tarif_regions, tarifs, trans, curLang, tarif_trads]);

   return (
      <Fragment>
         <Menu page="tarifs" />
         <div className={styles.tarifs}>
            <div className={styles.content}>
               <div className={styles.header_bg}>
                  <div className={styles.header}>
                     <h1>{trans('?Tarif', true)}</h1>
                     <div className={styles.buttons}>
                        <Button small onClick={doCleanTarifs}>
                           {trans('?Purger les tarifs')}
                        </Button>
                        <Checkbox caption={trans("?Montrer l'état")}
                           checked={(showStatus ? CheckboxStates.Checked : CheckboxStates.Unchecked)}
                           onChange={(status) => { setShowStatus(status === CheckboxStates.Checked) }}
                        />
                        <Checkbox caption={trans('?Seulement les tarifs cochés')}
                           checked={(showOnlySel ? CheckboxStates.Checked : CheckboxStates.Unchecked)}
                           onChange={(status) => { setShowOnlySel(status === CheckboxStates.Checked) }}
                        />
                     </div>
                  </div>
               </div>
               <div className={styles.detail}>
                  <table>
                     <thead>
                        <tr>
                           <th>{trans('?Région')}</th>
                           <th>{trans('?Listé')}</th>
                           <th><span>{trans('?Préchargé')}</span><span>({trans('?Mode déconnecté')})</span></th>
                           {showStatus &&
                              <>
                                 <th>HJO PPU</th>
                                 <th>HJO CES</th>
                                 <th>{trans('?Autre', true)} PPU</th>
                                 <th>{trans('?Autre', true)} CES</th>
                              </>
                           }
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {tarifItems}
                     </tbody>
                  </table>
               </div>
               <div className={styles.footer_bg}>
                  <div className={styles.footer}>
                     <div className={styles.summary}>
                        <span>
                           {transWithParams('?nb_tarifs_sel_sur_x', [nbInList, nbTotal], nbInList)}
                        </span>
                        <span>
                           {transWithParams('?dont_x_dispo_deco', [nbOffline, maxTarifsOffline], nbOffline)}
                        </span>
                     </div>
                     {showStatus &&
                        <div className={styles.help}>
                           <span>
                              {trans("?Chargé")} : <FontAwesomeIcon style={{ color: 'var(--color-primary-green)' }} icon={faCloudArrowDown} />
                           </span>
                           <span>
                              {trans("?Chargé si besoin")} : <FontAwesomeIcon style={{ color: 'var(--color-gray-20)' }} icon={faCloudArrowDown} />
                           </span>
                           <span>
                              {trans("?Erreur de chargement")} : <FontAwesomeIcon style={{ color: 'var(--color-primary-red)' }} icon={faCloudArrowDown} />
                           </span>
                           <span>
                              {trans("?Non sélectionné")} : <FontAwesomeIcon style={{ color: 'var(--color-gray-10)' }} icon={faCircleDashed} />
                           </span>
                        </div>
                     }
                  </div>
               </div>
            </div>
         </div >
      </Fragment >
   )
}

export default Tarifs;
