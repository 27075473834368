import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
// import { db } from "./db"

const modelsStore = (set) => {
   // Reduit la liste des tarifs pour ne garder que les "price_keep_count" les plus récemment utilisés
   // function reducePriceList(arr) {
   //    arr.sort((item1, item2) => {
   //       return item2.lastused - item1.lastused
   //    })
   //    return {}
   // }

   return {
      models: [],
      tarifs: {},                   // Les tarifs chargés ({nom: [data],...})

      // Les listes
      collections: [],
      couleurs: {},
      cycles: {},
      devises: {},
      etapes: {},
      familles: {},
      genres: {},
      portes: {},
      tarif_regions: {},            // Toutes les régions (tarifs) + date-heure de dernière sélection
      tarif_trads: {},              // Traduction des noms de région des tarifs

      // Checksum du dernier fetch valide (collection, modèles, familles, tarifs etc.)
      checksum_collections: null,
      checksum_couleurs: null,
      checksum_cycles: null,
      checksum_devises: null,
      checksum_etapes: null,
      checksum_familles: null,
      checksum_genres: null,
      checksum_models: null,
      checksum_portes: null,
      checksum_tarif_regions: null,
      checksum_tarif_trads: null,
      checksums_tarifs: null,

      setTarifRegions: (list) => { // [CodeTag: MOD_1]
         if (list && (typeof list === 'object')) {
            // console.log(list)
            // console.log(Object.keys(list))
            return set((state) => {
               // Construction d'une liste de noms de tarif (provenant d'un fetch) et essayant de conserver le lastused et le preload si possible
               const newlist = Object.keys(list).reduce((prev, key) => ({
                  ...prev, [key]: {
                     ...state[key],
                     currencies: list[key],
                     lastused: state.tarif_regions[key]?.lastused || null,
                     in_list: state.tarif_regions[key]?.in_list || false,  // Marqué pour affichage dans la liste
                     preload: state.tarif_regions[key]?.preload || false,  // Marqué pour offline (nécessite `in_list` aussi)
                     // loaded: state.tarif_regions[key]?.loaded || false,    // Chargé
                  }
               }), {})
               // console.log(newlist)
               return { tarif_regions: newlist }
            })
         } else {
            return []
         }
      },
      setPreloadOnTarifRegion: (name, newState) => {   // [CodeTag: MOD_2]
         // console.log(name, newState)
         return set((state) => {
            return {
               tarif_regions: Object.keys(state.tarif_regions).reduce((prev, key) => {
                  return {
                     ...prev, [key]: {
                        ...state.tarif_regions[key],
                        in_list: ((key === name) ? (newState ? newState : state.tarif_regions[key].in_list) : state.tarif_regions[key].in_list),   // On force aussi le in_list si preload === true
                        preload: ((key === name) ? newState : state.tarif_regions[key].preload),
                     }
                  }
               }, {})
            }
         })
      },
      // setLoadedOnTarifRegion: (name, newState) => {   // [CodeTag: MOD_2]
      //    // console.log(name, newState)
      //    return set((state) => {
      //       return {
      //          tarif_regions: Object.keys(state.tarif_regions).reduce((prev, key) => {
      //             return {
      //                ...prev, [key]: {
      //                   ...state.tarif_regions[key],
      //                   in_list: ((key === name) ? (newState ? newState : state.tarif_regions[key].in_list) : state.tarif_regions[key].in_list),   // On force aussi le in_list si loaded === true
      //                   loaded: ((key === name) ? newState : state.tarif_regions[key].loaded)
      //                }
      //             }
      //          }, {})
      //       }
      //    })
      // },
      setInListOnTarifRegion: (name, newState) => {
         return set((state) => {
            return {
               tarif_regions: Object.keys(state.tarif_regions).reduce((prev, key) => {
                  return {
                     ...prev, [key]: {
                        ...state.tarif_regions[key],
                        in_list: ((key === name) ? newState : state.tarif_regions[key].in_list),
                        preload: ((key === name) ? (!newState ? newState : state.tarif_regions[key].preload) : state.tarif_regions[key].preload),   // On force aussi le preload si in_list === false
                     }
                  }
               }, {})
            }
         })
      },
      setTarifs: (name, list) => {
         // console.log(name)
         set((state) => ({
            tarifs: { ...state.tarifs, [name]: list },
         }))
      },
      emptyTarifs: () =>
         set((state) => ({
            tarifs: {},
         })),
      emptyTarifsChecksums: () =>
         set((state) => ({
            checksums_tarifs: null,
         })),
      setPriceKeys: (list) => {
         set((state) => ({
            price_keys: list,
         }))
      },
      setCollections: (list) =>     // Les collections sont réparties dans des gammes (HJO, JO, ACC, AUT)
         set((state) => ({
            collections: list,
         })),
      setCouleurs: (list) => {
         set((state) => ({
            couleurs: list,
         }))
      },
      setCycles: (list) => {
         set((state) => ({
            cycles: list,
         }))
      },
      setDevises: (list) => {
         set((state) => ({
            devises: list,
         }))
      },
      setEtapes: (list) => {
         set((state) => ({
            etapes: list,
         }))
      },
      setFamilles: (list) => {
         set((state) => ({
            familles: list,
         }))
      },
      setGenres: (list) => {
         set((state) => ({
            genres: list,
         }))
      },
      setModels: (list) => {
         set((state) => ({
            models: list,
         }))
      },
      setPortes: (list) => {
         set((state) => ({
            portes: list,
         }))
      },
      setTarifTrads: (list) =>
         set((state) => ({
            tarif_trads: list,
         })),

      setChecksumCollections: (val) =>
         set((state) => ({
            checksum_collections: val,
         })),
      setChecksumCouleurs: (val) =>
         set((state) => ({
            checksum_couleurs: val,
         })),
      setChecksumCycles: (val) =>
         set((state) => ({
            checksum_cycles: val,
         })),
      setChecksumDevises: (val) =>
         set((state) => ({
            checksum_devises: val,
         })),
      setChecksumEtapes: (val) =>
         set((state) => ({
            checksum_etapes: val,
         })),
      setChecksumFamilles: (val) =>
         set((state) => ({
            checksum_familles: val,
         })),
      setChecksumGenres: (val) =>
         set((state) => ({
            checksum_genres: val,
         })),
      setChecksumModels: (val) =>
         set((state) => ({
            checksum_models: val,
         })),
      setChecksumPortes: (val) =>
         set((state) => ({
            checksum_portes: val,
         })),
      setChecksumTarifTrads: (val) =>
         set((state) => ({
            checksum_tarif_trads: val,
         })),
      setChecksumTarifRegions: (val) =>
         set((state) => ({
            checksum_tarif_regions: val,
         })),
      setChecksumsTarifs: (name, val) => {
         // console.log(name)
         set((state) => ({
            checksums_tarifs: { ...state.checksums_tarifs, [name]: val },
         }))
      },
   }
}

/**
const getItem = (name) => {
   // console.log(`getItem(${name})`)
   return db.test[name].value
}
 
const setItem = (name, value) => {
   // console.log(`set ${name}`, JSON.stringify(value).substring(0, 100))
   // console.log(`set ${name} ${value.length / 1024 / 1024} Mo`)
   //
   // db.test.delete(name).then(status => {
   db.test.put({
      name,
      value: value.substring(0, 270000)
   }).then(status => {
      // console.log("ADD OK")
      db.close()
      // console.log("close ok")
      db.open()
   }).catch(err => {
      console.error("Err1", err)
   })
   // }).catch(err => {
   //    console.error("Err2", err)
   // })
   //
}
 
const IDBStorage = {
   getItem,
   setItem,
   removeItem: (name) => { console.log(`remove ${name}`) }
}
 
const useModelsStore = create(devtools(persist(modelsStore, { name: "models", getStorage: () => IDBStorage })))
**/
const useModelsStore = create(devtools(persist(modelsStore, { name: "models" })))

export default useModelsStore