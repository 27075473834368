import styles from './InputField.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState, forwardRef } from 'react';
// import { useLogger } from '../Contexts/Logger';

const InputField = forwardRef((props, ref) => {
   const [isPwdHidden, setIsPwdHidden] = useState(true);
   // const [value, setValue] = useState('')
   // const { log } = useLogger();

   // useEffect(() => {
   //    setValue(props.value)
   // }, [props])

   function togglePassword() {
      console.log('Clicked');
      setIsPwdHidden(!isPwdHidden);
   }

   function onChange(event) {
      let newValue = event.target.value;
      // setValue(newValue)
      if (props.onChange) {
         props.onChange(newValue);
      }
   }

   return (
      <div className={`${styles.container} ${props.className}`}>
         {props.title?.length && <label>{props.title}</label>}
         <div className={styles.input_container}>
            {props.numeric &&
               <input
                  className={props.password && styles.password}
                  pattern="[0-9]*"
                  inputmode="numeric"
                  type={props.password && isPwdHidden ? 'password' : 'text'}
                  placeholder={props.placeholder}
                  disabled={props.disabled ? true : false}
                  name={props.name}
                  value={props.value}
                  autoComplete="off"
                  onChange={onChange}
                  ref={ref}
               />
            }
            {!props.numeric &&
               <input
                  className={props.password && styles.password}
                  type={props.password && isPwdHidden ? 'password' : 'text'}
                  placeholder={props.placeholder}
                  disabled={props.disabled ? true : false}
                  name={props.name}
                  value={props.value}
                  autoComplete="off"
                  onChange={onChange}
                  ref={ref}
               />
            }
            {props.button}
            {props.password && !isPwdHidden && (
               <button onClick={togglePassword} className={styles.pass_button}>
                  <FontAwesomeIcon icon={faEye} />
               </button>
            )}
            {props.password && isPwdHidden && (
               <button onClick={togglePassword} className={styles.pass_button}>
                  <FontAwesomeIcon icon={faEyeSlash} />
               </button>
            )}
         </div>
      </div>
   );
})

export default InputField;
