import { faSortDown, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faUserTieHairLong, faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import PopupCustomer from './Popups/PopupCustomer';
import PopupOrder from './Popups/PopupOrder';
import styles from './Searchbar.module.scss';
import usePrefStore from '../stores/prefs';
// import { useLogger } from '../Contexts/Logger';
import useSales from '../Hooks/useSales'
import useTranslation from '../Hooks/useTranslation'
import useHelpers from '../Hooks/useHelpers';
import { useLiveQuery } from "dexie-react-hooks"
import { GlobalContext } from '../Contexts/GlobalContext';

function Searchbar(props) {
   const [searchText, setSearchText] = useState('');
   // const [customer, setCustomer] = useState(null);
   const [totalForOrder, setTotalForOrder] = useState(null);
   const [itemsCountForOrder, setItemsCountForOrder] = useState(null);
   const [isPopupCustomerVisible, setIsPopupCustomerVisible] = useState(false);
   const [isPopupOrderVisible, setIsPopupOrderVisible] = useState(false);
   const [customer, setCustomer] = useState();
   // const [order, setOrder] = useState();
   //
   const { isDarkLayerVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible } = { ...GlobalContext() };
   // const [siege, setSiege] = useState(null);
   // const { log } = useLogger();
   //
   // const curCustomer = usePrefStore((state) => state.curCustomer);
   // const curOrder = usePrefStore((state) => state.curOrder);
   const { curCustomer, curOrder } = usePrefStore();
   const { getCustomerById, calcTotalForOrder, getItemsCountForOrder, getOrderById, setOrderIdAsCurrent } = useSales()
   //
   const { trans } = useTranslation()
   const { formatCESPrice, formatNumber } = useHelpers();
   //
   // const live_customer = useLiveQuery(async () => {
   //    const ret = await getCustomerById(curCustomer, 'ici');
   //    console.log('+++ RET', ret, curCustomer);
   //    return ret
   // }, [curCustomer])
   const order = useLiveQuery(async () => await getOrderById(curOrder), [curOrder])
   // const live_order = useLiveQuery(async () => await db.orders.where('id').equals(curOrder).first(), [curOrder])

   useEffect(() => {
      if (curCustomer) {
         setCustomer(getCustomerById(curCustomer))
      } else {
         setCustomer(null)
      }
   }, [curCustomer, getCustomerById])

   // const setCurOrderObj = useCallback(async () => {
   //    console.log('setCurOrderObj', curOrder, await getOrderById(curOrder));
   //    if (curOrder) {
   //       setOrder(await getOrderById(curOrder))
   //    } else {
   //       setOrder(null)
   //    }
   // }, [curOrder, getOrderById])

   // useEffect(() => {
   //    setCurOrderObj()  // C'est un async/await
   // }, [setCurOrderObj])

   useEffect(() => {
      setSearchText(props.text ?? '')
   }, [props])

   // useEffect(() => {
   //    if (curCustomer) {
   //       setCustomer(getCustomerById(curCustomer))
   //       // setSiege(getSiegeForCustomerId(curCustomer))
   //    }
   // }, [curCustomer, getCustomerById, getSiegeForCustomerId])

   useEffect(() => {
      setTotalForOrder(calcTotalForOrder(order))
      setItemsCountForOrder(getItemsCountForOrder(order))
   }, [calcTotalForOrder, getItemsCountForOrder, order])

   function onSearchChange(evt) {
      setSearchText(evt.target.value);
      if (props.onChange) {
         props.onChange(evt.target.value);
      }
   }

   const clearSearchText = useCallback(() => {
      setSearchText('')
      if (props.onChange) {
         props.onChange('');
      }
   }, [props])

   // useEffect(() => {
   //    if (props.onChange) {
   //       props.onChange(searchText);
   //    }
   // }, [searchText, props])

   function onSubmit(evt) {
      evt.stopPropagation()
      evt.preventDefault()
      console.log('ICICIC>>>', searchText);
      if (props.onSearch) {
         props.onSearch(searchText);
      }
   }

   // function onShowTools() {
   //    console.log('ShowTools', props);
   //    if (props.onShowTools) {
   //       props.onShowTools();
   //    }
   // }

   useEffect(() => {
      setKeepSearchBarAccessible(true)
      setIsDarkLayerVisible(isPopupCustomerVisible || isPopupOrderVisible)
   }, [isPopupCustomerVisible, isPopupOrderVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible])

   useEffect(() => {
      if (!isDarkLayerVisible) {
         setIsPopupCustomerVisible(false)
         setIsPopupOrderVisible(false)
      }
   }, [isDarkLayerVisible])

   useEffect(() => {
      setSearchText(props.text ?? '')
   }, [props.text])

   function togglePopupCustomer() {
      setIsPopupCustomerVisible(val => !val)
      setIsPopupOrderVisible(false)
   }

   function togglePopupOrder() {
      setIsPopupOrderVisible(val => !val)
      setIsPopupCustomerVisible(false)
   }

   function onSearchFocus() {
      // console.log('onSearchFocus');
      setIsPopupCustomerVisible(false)
      setIsPopupOrderVisible(false)
   }

   function onOrderChange(new_order) {
      setOrderIdAsCurrent(new_order)
      setIsPopupOrderVisible(false)
      if (props.onOrderChange) {
         props.onOrderChange(new_order)
      }
   }

   // console.log(props, searchText);
   // console.log('SIEGE', siege);

   return (
      <div className={styles.searchbar}>
         <div className={styles.cur_customer} onClick={togglePopupCustomer}>
            {customer &&
               <>
                  <FontAwesomeIcon icon={faUserTieHairLong} />
                  <div className={styles.customer_name}>
                     <span>{customer?.name}</span>
                     <span># {customer?.id}</span>
                     {isPopupCustomerVisible &&
                        <PopupCustomer onClose={() => setIsPopupCustomerVisible(false)} />
                     }
                  </div>
                  <FontAwesomeIcon className={styles.arrow} icon={faSortDown} style={{ transform: 'rotate(-90deg)' }} />
               </>
            }
         </div>
         <div className={styles.searchfield_and_buttons}>
            <div className={styles.searchfield}>
               <FontAwesomeIcon className={styles.magnifyglass} icon={props.lefticon || faMagnifyingGlass} />
               <form onSubmit={onSubmit}>
                  <input type="text" onFocus={onSearchFocus} onChange={onSearchChange} placeholder={props.placeholder} value={searchText} />
               </form>
               <FontAwesomeIcon className={styles.clearfield} icon={faXmark} onClick={clearSearchText} />
            </div>
            {props.children}
         </div>
         <div className={styles.cur_order} onClick={togglePopupOrder}>
            {order &&
               <>
                  <FontAwesomeIcon icon={faFileLines} />
                  <div>
                     <span># {curOrder}</span>
                     <span>{formatCESPrice(totalForOrder)}</span>
                     <span>{formatNumber(itemsCountForOrder)} {trans("?article", itemsCountForOrder)}</span>
                  </div>
                  <FontAwesomeIcon className={styles.arrow} icon={faSortDown} style={{ transform: 'rotate(-90deg)' }} />
               </>
            }
            {isPopupOrderVisible &&
               <PopupOrder onOrderChange={onOrderChange} onClose={() => setIsPopupOrderVisible(false)} />
            }
         </div>
      </div>
   );
}

export default Searchbar;
