const glob_const = {
   http_codes: {                    // https://fr.wikipedia.org/wiki/Liste_des_codes_HTTP
      OK: 200,                      // Requête traitée avec succès
      OK_NO_CONTENT: 204,           // Requête traitée avec succès mais pas d’information à renvoyer
      BAD_REQUEST: 400,             // La syntaxe de la requête est erronée
      UNAUTHORIZED: 401,            // Une authentification est nécessaire pour accéder à la ressource
      FORBIDDEN: 403,               // Le serveur a compris la requête, mais refuse de l'exécuter
      NOT_FOUND: 404,               // Ressource non trouvée
      CONFLICT: 409,                // La requête ne peut être traitée à la suite d'un conflit avec l'état actuel du serveur
      INTERNAL_SERVER_ERROR: 500,   // Erreur interne du serveur
   },

   min_delay_on_push: 5,            /* Délai minimum (sec) quand on push vers AppWrite et qu'on obtient
                                       un event en retour. Si le délai est inférieur à cette valeur,
                                       on ne refresh pas la BDD locale (Dexie) */

   stock_levels: {
      WARN: 5,
      NOK: 0,
   },

   depot_defaut: 'J01',

   auto_familles: {                 /* Cochage auto d'autres familles quand on coche une famille
                                       Exemple : 'XX': ['YY', 'ZZ', 'AA']
                                       Aussi utilisé pour le groupage de familles à l'affichage :
                                       la clé sert à grouper le reste
                                    */
      '01': ['18', '19', '20']      // Bagues (01) -> Alliances (18). Solitaire <1ct et Solitaire >=1ct
   },

   check_sw_update_delay: 15 * 60 * 1000,  // Toutes les 15min on check si nv version dispo. 1er check fait dès l'arrivée au login
}

export default glob_const