// import { useCallback, useEffect, useState } from "react"
import styles from "./GraphLine.module.scss"
import { ResponsiveLine } from '@nivo/line'

function GraphLine(props) {

   return (
      <div className={styles.graph_line}>
         <ResponsiveLine
            animate={false}   // ***BUG ne pas utiliser à true ! (log des erreurs) - Finalement pb uniquement en resize fenêtre (parfois)
            data={props.data}
            margin={{ top: 50, right: 50, bottom: 50, left: 80 }}

            theme={{
               axis: {
                  legend: {
                     text: {
                        fontSize: 14,
                        fontWeight: 900,
                     }
                  }
               },
            }}
            xScale={{ type: 'point' }}
            yScale={{
               type: 'linear',
               min: 'auto',
               max: 'auto',
               stacked: false,
               reverse: false
            }}
            yFormat=" >-.2f"
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
               orient: 'bottom',
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: props.legendX,
               legendOffset: 36,
               legendPosition: 'middle'
            }}
            axisLeft={{
               orient: 'left',
               tickSize: 5,
               tickPadding: 5,
               tickRotation: 0,
               legend: props.legendY,
               legendOffset: -70,
               legendPosition: 'middle',
               format: (val) => { return (val >= 1000) ? `${Math.round(val / 100) / 10} K` : val }
            }}
            // enableGridY={false}
            // colors={{ scheme: 'set1' }}
            // colors={{ from: 'color' }}
            colors={{ datum: 'color' }}
            lineWidth={2}
            pointSize={6}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableArea={true}
            areaBaselineValue={150} // Pour empêcher remplissage trop bas. ce serait mieux de le fixer en fonction de la valeur la plus basse en Y
            areaOpacity={0.1}
            isInteractive={false}
            useMesh={true}
            legends={[
               {
                  anchor: 'top',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: -35,
                  itemsSpacing: 25,
                  itemDirection: 'left-to-right',
                  itemWidth: 120,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                     {
                        on: 'hover',
                        style: {
                           itemBackground: 'rgba(0, 0, 0, .03)',
                           itemOpacity: 1
                        }
                     }
                  ]
               }
            ]}
         />
      </div>
   )
}

export default GraphLine
