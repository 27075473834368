import styles from "./DarkLayer.module.scss"
import { GlobalContext } from '../Contexts/GlobalContext';
import usePrefStore from "../stores/prefs";
import useScrollBlock from "../Hooks/useScrollBlock";
import { useEffect } from "react";

// Layer semi opaque utilisé quand on affiche une popup.

function DarkLayer(props) {
   const { setIsDarkLayerVisible, keepSearchBarAccessible } = { ...GlobalContext() }
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups)
   const { globalClosePopups } = usePrefStore()
   const [blockScroll, allowScroll] = useScrollBlock();

   function onClick(evt) {
      if (globalClosePopups !== 'button') {
         setIsDarkLayerVisible(false)
      }
   }

   useEffect(() => {
      blockScroll();
      return () => {
         console.log('DarkLayer unmounting')
         allowScroll()
      }
   }, [blockScroll, allowScroll])

   return (
      <div className={`${styles.darklayer} ${(keepSearchBarAccessible ? '' : styles.topmost)}`} onClick={onClick}>
      </div>
   )
}

export default DarkLayer
