import { useCallback } from "react"

const errCodes = {
   // useTarifs
   UNK_TYPE: 101,
   UNK_TYPE2: 102,
   UNK_TYPE3: 103,
   UNK_TYPE4: 104,
   UNK_TYPE5: 105,
   UNK_TARIF: 106,
   UNK_TARIF2: 107,
   UNK_TARIF3: 108,
   UNK_TARIF4: 109,
   NO_COLOR: 110,
   NO_COLOR2: 111,
   UNK_COLOR: 112,
   UNK_COLOR2: 113,
   MISS_SIZE: 114,
   // XX
}

const useErrors = () => {

   const buildError = useCallback((code, text) => {
      const errId = Math.floor(Math.random() * 1000000)
      // TODO : Logguer le texte sur le serveur pour pouvoir faire référence à l'id après coup.
      return { code, id: errId, text }
   }, [])

   return {
      errCodes,
      buildError
   }
}
export default useErrors