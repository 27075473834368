import styles from './PagesButtons.module.scss';
import { useCallback, useEffect, useState } from 'react';
import Button from './Button';
import usePrefStore from '../stores/prefs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import useTranslation from '../Hooks/useTranslation';
// import { useLogger } from '../Contexts/Logger';

function PagesButtons(props) {
   const [buttons, setButtons] = useState(null);
   const [from, setFrom] = useState(1);
   //
   // const maxPageButtonsOnLists = usePrefStore(
   //    (state) => state.maxPageButtonsOnLists
   // );
   const { maxPageButtonsOnLists } = usePrefStore();
   //
   const { trans } = useTranslation();
   // const { log } = useLogger();

   useEffect(() => {
      setFrom(props.from);
   }, [props.from]);

   const onClickPrev = useCallback(() => {
      const val = Math.max(1, props.from - maxPageButtonsOnLists);

      // console.log("onClickPrev")
      setFrom(val);
      if (props.onFirstPageChange) {
         props.onFirstPageChange(val);
      }
   }, [maxPageButtonsOnLists, props]);

   const onClickNext = useCallback(() => {
      const val = Math.min(props.max, props.from + maxPageButtonsOnLists);

      setFrom(val);
      if (props.onFirstPageChange) {
         props.onFirstPageChange(val);
      }
   }, [maxPageButtonsOnLists, props]);

   const onClickPage = useCallback(
      (page) => {
         if (props.onPageChange) {
            props.onPageChange(page);
         }
      },
      [props]
   );

   useEffect(() => {
      const arr = [];

      // console.log(props)
      arr.push(
         <Button key="page_button_prev" small disabled={from <= 1} tertiary onClick={onClickPrev}>
            <FontAwesomeIcon icon={faAngleLeft} />
            {trans('?Précédentes')}
         </Button>
      );
      for (let loop = from; loop <= Math.min(from + maxPageButtonsOnLists - 1, props.max); loop++) {
         // console.log('>>>', props, loop, props.selected !== loop);
         arr.push(
            <Button key={`page_button${loop}`} className={styles.page_button} small nobackground={props.selected !== loop ? true : false} onClick={() => { onClickPage(loop); }}>
               {loop}
            </Button>
         );
      }
      console.log(from, maxPageButtonsOnLists, props.max);
      arr.push(
         <Button key="page_button_next" small disabled={from + maxPageButtonsOnLists > props.max} tertiary onClick={onClickNext}>
            {trans('?Suivantes')}
            <FontAwesomeIcon icon={faAngleRight} />
         </Button>
      );
      setButtons(arr);
   }, [maxPageButtonsOnLists, onClickNext, onClickPage, onClickPrev, from, props, trans]);

   // console.log(props)

   return <div className={styles.pages_buttons}>{buttons}</div>;
}

export default PagesButtons;
