import styles from './CatalogFilter.module.scss'
import useTranslation from '../../Hooks/useTranslation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faFilter } from '@fortawesome/free-solid-svg-icons'
import { faFilterSlash } from '@fortawesome/pro-regular-svg-icons'
import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons'
import Button from '../Button'
import DropdownCB from '../DropdownCB'
import { useCallback, useEffect, useRef, useState } from 'react'
// import ColorBullet from '../ColorBullet'
import useHelpers from '../../Hooks/useHelpers'
import useTarifs from '../../Hooks/useTarifs'
// import useTimeout from '../../Hooks/useTimeout'
// import Link from "../../components/Link"
import Keypad from '../Keypad'
// import useModelsStore from '../../stores/models'
// import { useLogger } from '../../Contexts/Logger';
// import useTest from "../../Hooks/useTest"
import { States as CheckboxStates } from '../../components/Checkbox';
import glob_const from '../../libs/glob_const';

const dropdowns = { Gammes: 'gammes', Collections: 'collections', Familles: 'familles', Portes: 'portes', Couleurs: 'couleurs' }

const keypadForMin = 1
const keypadForMax = 2

/**
 * allGammes :
 * - Chargé depuis la liste des Gammes
 * gammesSelState :
 * - Etat `checked` de chaque Gamme
 * - Chaque changement d'état dans la dropbox est répercuté sur `gammesSelState` dans `onGammesChange`
 *
 * allCollectionsInSelectedGammes :
 * - Chargé à l'aide d'appels à `getCollectionsInGammesAsSortedArray` pour chaque collection `checked` dans `allGammes`
 * collectionsSelState :
 * - Etat `checked` de chaque Collection
 * - Chaque changement d'état dans la dropbox est répercuté sur `collectionsSelState` dans `onCollectionsChange`
 * -
 */

function CatalogFilter(props) {
   // Liste des infos de toutes les gammes + Etat checked/unchecked de la selection de chaque gamme (true/false)
   // const [allGammes, setAllGammes] = useState([])
   // Liste des Collections des Gammes sélectionnées + Etat checked/unchecked de chaque Collection
   // const [allCollectionsInSelectedGammes, setAllCollectionsInSelectedGammes] = useState([])
   // Liste des familles + Etat checked/unchecked de la selection de chaque famille (true/false)
   // const [allFamilles, setAllFamilles] = useState([])
   // Liste des portés + Etat checked/unchecked de la selection de chaque porté (true/false)
   // const [allPortes, setAllPortes] = useState([])
   // Liste des couleurs + Etat checked/unchecked de la selection de chaque couleur (true/false)
   // const [allCouleurs, setAllCouleurs] = useState([])
   // const [allColors, setAllColors] = useState(null)         // Toutes les couleurs sans notion d'état
   // const [availColors, setAvailColors] = useState(null)     // Une sélection des couleurs possibles en fonction du filtrage, sans notion d'état
   // const [checkedColors, setCheckedColors] = useState(new Set())   // Liste (Set) des couleurs cochées
   // Etat d'ouverture des dropdowns
   const [openDropdowns, setOpenDropdowns] = useState({ [dropdowns.Gammes]: false, [dropdowns.Collections]: false, [dropdowns.Familles]: false, [dropdowns.Portes]: false, [dropdowns.Couleurs]: false })
   // Compte du nb d'éléms sélectionnées
   const [selCounts, setSelCounts] = useState({ [dropdowns.Gammes]: 0, [dropdowns.Collections]: 0, [dropdowns.Familles]: 0, [dropdowns.Portes]: 0, [dropdowns.Couleurs]: 0 })
   // Tableau prêts pour critères de filtrage. On prépare à l'avance pour gain de perf
   // const [arrFilterGammes, setArrFilterGammes] = useState(null)
   // const [arrFilterCollections, setArrFilterCollections] = useState(null)
   // const [arrFilterFamilles, setArrFilterFamilles] = useState(null)
   // const [arrFilterPortes, setArrFilterPortes] = useState(null)
   // const [arrFilterCouleurs, setArrFilterCouleurs] = useState(null)
   const [gammeItems, setGammeItems] = useState(null)
   const [collectionItems, setCollectionItems] = useState(null)
   const [familleItems, setFamilleItems] = useState(null)
   const [porteItems, setPorteItems] = useState(null)
   const [couleurItems, setCouleurItems] = useState(null)
   const [keypadTarget, setKeypadTarget] = useState(null)
   const [manualMinPrice, setManualMinPrice] = useState(null) // Min saisi au keypad ou null
   const [manualMaxPrice, setManualMaxPrice] = useState(null) // Max saisi au keypad ou min
   const [curManualPrice, setCurManualPrice] = useState(null) // Valeur en cours de saisie au keypad
   const [keypad, setKeypad] = useState(null)
   //
   const { trans } = useTranslation()
   // const { test } = useTest()
   const { formatNumber } = useHelpers()
   const { getPPUCurrency } = useTarifs()
   //
   const refKeypad = useRef(null)

   // Chargement des Familles
   // useEffect(() => {
   //    setAllFamilles(
   //       props.familles?.reduce((prev, elem) => {
   //          if (elem.visible) {
   //             return [...prev, { id: elem.id, short: elem.label, caption: elem.label, checked: elem.checked }]
   //          } else {
   //             return [...prev]
   //          }
   //       }, [])
   //    )
   // }, [props.familles])

   // Chargement des Portés
   // useEffect(() => {
   //    // console.log('useEffect1 - Portés', props.portes)
   //    setAllPortes(
   //       props.portes?.reduce((prev, elem) => {
   //          if (elem.visible) {
   //             return [...prev, { id: elem.id, short: elem.label, caption: elem.label, checked: elem.checked }]
   //          } else {
   //             return [...prev]
   //          }
   //       }, [])
   //    )
   // }, [props.portes])

   // Chargement d'un tableau avec toutes les couleurs possibles, sans notion d'état
   // useEffect(() => {
   //    setAllColors(
   //       props.couleurs?.reduce((prev, elem) => {
   //          if (elem.visible) {
   //             return [...prev, { id: elem.id, short: elem.id, caption: elem.label, checked: elem.checked }]
   //          } else {
   //             return [...prev]
   //          }
   //       }, [])
   //    )
   // }, [props.couleurs])

   // useEffect(() => {
   //    if (props?.couleurs) {
   //       // console.log('PROPS.COUL', props.couleurs)
   //       setAvailColors(
   //          allColors?.filter((elem) => props.couleurs.includes(elem.id))
   //       )
   //    } else {
   //       setAvailColors(null)
   //    }
   // }, [allColors, props])

   useEffect(() => {
      setSelCounts({
         [dropdowns.Gammes]: (props.showGammes ? (props.gammes?.filter((item) => (item.visible && item.checked)).length ?? 0) : 0), // Par défaut c'est 0 si pas visible, peu importe le contenu
         [dropdowns.Collections]: (props.showGammes ? (props.collections?.filter((item) => (item.visible && item.checked)).length ?? 0) : 0), // Par défaut c'est 0 si pas visible, peu importe le contenu
         [dropdowns.Familles]: props.familles?.filter((item) => (item.visible && item.checked)).length ?? 0,
         [dropdowns.Portes]: props.portes?.filter((item) => (item.visible && item.checked)).length ?? 0,
         [dropdowns.Couleurs]: props.couleurs?.filter((item) => (item.visible && item.checked)).length ?? 0
      })
   }, [props.collections, props.couleurs, props.familles, props.gammes, props.portes, props.showGammes])

   // const sendFilter = useCallback(() => {
   //    if (props.onFilter) {
   //       props.onFilter({ gammes: arrFilterGammes, collections: arrFilterCollections, familles: arrFilterFamilles, portes: arrFilterPortes, colors: arrFilterCouleurs, price_min: manualMinPrice, price_max: manualMaxPrice })
   //    }
   // }, [arrFilterCollections, arrFilterCouleurs, arrFilterFamilles, arrFilterGammes, arrFilterPortes, manualMaxPrice, manualMinPrice, props])

   // useEffect(() => {
   //    if (props.onPricesChange) {
   //       props.onPricesChange(manualMinPrice, manualMaxPrice)
   //    }
   // }, [manualMaxPrice, manualMinPrice, props])

   useEffect(() => {
      setManualMinPrice(props.manual_price_min)
      setManualMaxPrice(props.manual_price_max)
   }, [props])

   const validateManualPrice = useCallback((new_price) => {
      let new_min = manualMinPrice
      let new_max = manualMaxPrice

      if (new_price !== null) {
         if (keypadTarget === keypadForMin) {
            if (manualMaxPrice === null || manualMaxPrice >= new_price) {
               // setManualMinPrice(new_price)
               new_min = new_price
            } else {
               // setManualMinPrice(manualMaxPrice)
               // setManualMaxPrice(new_price)
               new_min = manualMaxPrice
               new_max = new_price
            }
         } else {
            if (manualMinPrice === null || manualMinPrice <= new_price) {
               // setManualMaxPrice(new_price)
               new_max = new_price
            } else {
               // setManualMinPrice(new_price)
               // setManualMaxPrice(manualMinPrice)
               new_min = new_price
               new_max = manualMinPrice
            }
         }
         setCurManualPrice(null)
         if (props.onPricesChange) {
            props.onPricesChange(new_min, new_max)
         }
      }
   }, [keypadTarget, manualMaxPrice, manualMinPrice, props])

   const closeAll = useCallback(() => {
      const obj = Object.keys(openDropdowns).reduce((prev, cur) => {
         return { ...prev, [cur]: false }
      }, {})
      setOpenDropdowns(obj)
      if (keypadTarget && curManualPrice) {
         validateManualPrice(curManualPrice)
      }
      closeKeypad()
   }, [curManualPrice, keypadTarget, openDropdowns, validateManualPrice])

   // Remet les états de tous les dropdown à false (fermé), sauf un dont on fixe le nv état (qui peut aussi être fermé)
   const closeAllExcept = useCallback((key, state) => {
      const obj = Object.keys(openDropdowns).reduce((prev, cur) => {
         return { ...prev, [cur]: false }
      }, {})
      setOpenDropdowns({ ...obj, [key]: state })
      if (keypadTarget && curManualPrice) {
         validateManualPrice(curManualPrice)
      }
      closeKeypad()
   }, [curManualPrice, keypadTarget, openDropdowns, validateManualPrice])

   function onGammesChange(id, state) {
      // console.log("onGammesChange")
      if (props.onGammeChange) {
         props.onGammeChange(id, state === CheckboxStates.Checked)
      }
   }

   function onOpenCloseGammes(state) {
      closeAllExcept(dropdowns.Gammes, state)
   }

   function onCollectionsChange(id, state) {
      // console.log("onCollectionsChange")
      if (props.onCollectionChange) {
         props.onCollectionChange(id, state === CheckboxStates.Checked)
      }
   }

   function onOpenCloseCollections(state) {
      // console.log("onOpenCloseCollections", state)
      closeAllExcept(dropdowns.Collections, state)
   }

   function onFamillesChange(id, state) {
      console.log("onFamillesChange", id, state)
      if (props.onFamilleChange) {
         if (state === CheckboxStates.Checked) {
            if (glob_const.auto_familles[id] !== undefined) {
               props.onFamilleChange({
                  [id]: true,
                  ...glob_const.auto_familles[id].reduce((prev, auto_id) => {
                     return { ...prev, [auto_id]: true }
                  }, {})
               })
            } else {
               props.onFamilleChange({ [id]: true })
            }
         } else {
            props.onFamilleChange({ [id]: false })
         }
      }
   }

   function onOpenCloseFamilles(state) {
      closeAllExcept(dropdowns.Familles, state)
   }

   function onPortesChange(id, state) {
      // console.log("onPortesChange")
      if (props.onPorteChange) {
         props.onPorteChange(id, state === CheckboxStates.Checked)
      }
   }

   function onOpenClosePortes(state) {
      closeAllExcept(dropdowns.Portes, state)
   }

   function onCouleursChange(id, state) {
      // console.log("onCouleursChange")
      if (props.onCouleurChange) {
         props.onCouleurChange(id, state === CheckboxStates.Checked)
      }
   }

   function onOpenCloseCouleurs(state) {
      closeAllExcept(dropdowns.Couleurs, state)
   }

   function onClick(evt) {
      closeAll()
   }

   function hidePopup() {
      closeAll()
      if (props.onHide) {
         props.onHide()
      }
   }

   function applyFilter() {
      hidePopup()
      if (props.onFilter) {
         props.onFilter()
      }
   }

   useEffect(() => {
      // console.log('ALL GAMMES', props.gammes)

      const arr = props.gammes?.reduce((prev, elem) => {
         if (elem.visible) {
            return [...prev, { id: elem.id, short: elem.id, caption: elem.label, checked: elem.checked }]
         } else {
            return [...prev]
         }
      }, [])
      // console.log('ALL GAMMES ARR', arr)
      setGammeItems(arr)
   }, [props.gammes])

   useEffect(() => {
      const arr = props.collections?.reduce((prev, elem) => {
         if (elem.visible) {
            return [...prev, { id: elem.id, short: elem.label, caption: elem.label, checked: elem.checked }]
         } else {
            return [...prev]
         }
      }, [])
      // console.log('ALL COLLECTIONS ARR', arr, props.collections)
      setCollectionItems(arr)
   }, [props.collections])

   useEffect(() => {
      const arr = props.familles?.reduce((prev, elem) => {
         if (elem.visible) {
            return [...prev, { id: elem.id, short: elem.label, caption: elem.label, checked: elem.checked }]
         } else {
            return [...prev]
         }
      }, [])
      // console.log(arr)
      setFamilleItems(arr)
   }, [props.familles])

   useEffect(() => {
      const arr = props.portes?.reduce((prev, elem) => {
         if (elem.visible) {
            return [...prev, { id: elem.id, short: elem.label, caption: elem.label, checked: elem.checked }]
         } else {
            return [...prev]
         }
      }, [])
      // console.log(arr)
      setPorteItems(arr)
   }, [props.portes])

   useEffect(() => {
      // setCouleurItems(availColors?.map((elem) => ({ id: elem.id, caption: elem.caption, short: elem.short, icon: elem.icon, checked: elem.checked })))
      const arr = props.couleurs?.reduce((prev, elem) => {
         if (elem.visible) {
            return [...prev, { id: elem.id, short: elem.id, caption: elem.label, checked: elem.checked }]
         } else {
            return [...prev]
         }
      }, [])
      // console.log(arr)
      setCouleurItems(arr)
   }, [props.couleurs])

   function clearFilterGammes() {
      if (props.onGammesClear) {
         props.onGammesClear()
      }
   }

   function clearFilterCollections() {
      if (props.onCollectionsClear) {
         props.onCollectionsClear()
      }
   }

   function clearFilterFamilles() {
      if (props.onFamillesClear) {
         props.onFamillesClear()
      }
   }

   function clearFilterPortes() {
      if (props.onPortesClear) {
         props.onPortesClear()
      }
   }

   function clearFilterCouleurs() {
      if (props.onCouleursClear) {
         props.onCouleursClear()
      }
   }

   function clearFilterPrice() {
      // setManualMinPrice(props.price_min)
      // setManualMaxPrice(props.price_max)
      if (props.onPricesChange) {
         props.onPricesChange(props.price_min, props.price_max)
      }
   }

   function clearFilters() {
      if (props.showGammes) {
         // On clear seulement si on n'est pas arrivé sur la présélection d'une collection (et de sa gamme)
         clearFilterGammes()
      }
      if (props.showCollections) {
         // On clear seulement si on n'est pas arrivé sur la présélection d'une collection (et de sa gamme)
         clearFilterCollections()
      }
      clearFilterFamilles()
      clearFilterPortes()
      clearFilterCouleurs()
      clearFilterPrice()
   }

   function closeKeypad() {
      console.log('CLOSE KEYPAD')
      setKeypadTarget(null)
   }

   const showKeypadMin = useCallback((evt) => {
      evt.stopPropagation()
      if (keypadTarget) {
         validateManualPrice(curManualPrice)
      }
      if (keypadTarget === keypadForMin) {
         setKeypadTarget(null)
      } else {
         // setKeypadTarget(keypadTarget ? null : keypadForMin)
         setKeypadTarget(keypadForMin)
      }
   }, [curManualPrice, keypadTarget, validateManualPrice])

   const showKeypadMax = useCallback(
      (evt) => {
         evt.stopPropagation()
         if (keypadTarget) {
            validateManualPrice(curManualPrice)
         }
         if (keypadTarget === keypadForMax) {
            setKeypadTarget(null)
         } else {
            // setKeypadTarget(keypadTarget ? null : keypadForMax)
            setKeypadTarget(keypadForMax)
         }
      },
      [curManualPrice, keypadTarget, validateManualPrice]
   )

   const onKeypadChange = useCallback((val) => {
      setCurManualPrice(val)
   }, [])

   const onKeypadValidate = useCallback((val) => {
      // if (keypadTarget === keypadForMin) {
      //    if (manualMaxPrice === null || manualMaxPrice >= val) {
      //       setManualMinPrice(val)
      //    } else {
      //       setManualMinPrice(manualMaxPrice)
      //       setManualMaxPrice(val)
      //    }
      // } else {
      //    if (manualMinPrice === null || manualMinPrice <= val) {
      //       setManualMaxPrice(val)
      //    } else {
      //       setManualMaxPrice(manualMinPrice)
      //       setManualMinPrice(val)
      //    }
      // }
      // setCurManualPrice(null)
      validateManualPrice(val)
      setKeypadTarget(null)
   }, [validateManualPrice])

   const onKeypadCancel = useCallback(() => {
      setCurManualPrice(null)
      setKeypadTarget(null)
   }, [])

   useEffect(() => {
      // console.log('*****LA');
      setKeypad(
         <div className={`${styles.keypad} ${(keypadTarget === keypadForMax) ? styles.onright : ""}`}>
            <Keypad ref={refKeypad} onValidate={onKeypadValidate} visible={true} disabled={false} value={0} min={0} max={99999999} onChange={onKeypadChange} onCancel={onKeypadCancel}></Keypad>
         </div>
      )
   }, [keypadTarget, onKeypadCancel, onKeypadChange, onKeypadValidate])

   // console.log('FILTER', props)

   return (
      <div className={`${styles.catalogfilter} ${props.visible ? '' : styles.hidden}`} onClick={onClick}>
         <div className={styles.top}>
            <span>{trans('?Filtre', true)}</span>
            {/* ***{manualMinPrice}***{manualMaxPrice}***{curManualPrice}*** */}
            <button onClick={hidePopup}>
               <FontAwesomeIcon icon={faXmark} />
            </button>
         </div>
         <div className={styles.detail}>
            {props.showGammes && (
               <section>
                  <span>
                     {trans('?Gamme', true)}
                     {selCounts[dropdowns.Gammes] > 0 && ` (${selCounts[dropdowns.Gammes]})`}
                     <FontAwesomeIcon icon={faCircleXmark} onClick={clearFilterGammes} />
                  </span>
                  <DropdownCB key="gammes" className={styles.dropdown_gammes} values={gammeItems} onChange={onGammesChange} open={openDropdowns[dropdowns.Gammes]} onOpenClose={onOpenCloseGammes} allowNoneChecked />
               </section>
            )}
            {props.showCollections && (
               <section>
                  <span>
                     {trans('?Collection', true)}
                     {selCounts[dropdowns.Collections] > 0 && ` (${selCounts[dropdowns.Collections]})`}
                     <FontAwesomeIcon icon={faCircleXmark} onClick={clearFilterCollections} />
                  </span>
                  <DropdownCB key="collec" className={styles.dropdown_collec} values={collectionItems} onChange={onCollectionsChange} open={openDropdowns[dropdowns.Collections]} onOpenClose={onOpenCloseCollections} allowNoneChecked />
               </section>
            )}
            <section>
               <span>
                  {trans('?Famille', true)}
                  {selCounts[dropdowns.Familles] > 0 && ` (${selCounts[dropdowns.Familles]})`}
                  <FontAwesomeIcon icon={faCircleXmark} onClick={clearFilterFamilles} />
               </span>
               <DropdownCB key="familles" className={styles.dropdown_famille} values={familleItems} onChange={onFamillesChange} open={openDropdowns[dropdowns.Familles]} onOpenClose={onOpenCloseFamilles} allowNoneChecked />
            </section>
            <section>
               <span>
                  {trans('?Porté', true)}
                  {selCounts[dropdowns.Portes] > 0 && ` (${selCounts[dropdowns.Portes]})`}
                  <FontAwesomeIcon icon={faCircleXmark} onClick={clearFilterPortes} />
               </span>
               <DropdownCB key="portes" className={styles.dropdown_porte} values={porteItems} onChange={onPortesChange} open={openDropdowns[dropdowns.Portes]} onOpenClose={onOpenClosePortes} allowNoneChecked />
            </section>
            <section>
               <span>
                  {trans('?Couleur', true)}
                  {selCounts[dropdowns.Couleurs] > 0 && ` (${selCounts[dropdowns.Couleurs]})`}
                  <FontAwesomeIcon icon={faCircleXmark} onClick={clearFilterCouleurs} />
               </span>
               <DropdownCB key="couleurs" className={styles.dropdown_couleurs} values={couleurItems} onChange={onCouleursChange} open={openDropdowns[dropdowns.Couleurs]} onOpenClose={onOpenCloseCouleurs} allowNoneChecked />
            </section>
            {/* On affiche le filtrage des prix seulement si au moins une famille sélectionnée (perf)
               Voir idem (2 fois) dans CatalogDetail.jsx */}
            {/* {(selCounts[dropdowns.Familles] > 0) && <section> */}
            <section>
               <span>
                  {trans('?Prix')} ({getPPUCurrency()})
                  <FontAwesomeIcon icon={faCircleXmark} onClick={clearFilterPrice} />
               </span>
               <div className={styles.prices}>
                  <span onClick={showKeypadMin} className={`${keypadTarget === keypadForMin ? styles.focused : ''}`}>
                     {formatNumber(curManualPrice && keypadTarget && (keypadTarget === keypadForMin) ? curManualPrice : (manualMinPrice ?? props.price_min))}
                  </span>
                  <span onClick={showKeypadMax} className={`${keypadTarget === keypadForMax ? styles.focused : ''}`}>
                     {formatNumber(curManualPrice && keypadTarget && (keypadTarget === keypadForMax) ? curManualPrice : (manualMaxPrice ?? props.price_max))}
                  </span>
                  {keypadTarget && keypad}
               </div>
            </section>
            {/* } */}
         </div>
         <div className={styles.footer}>
            <Button secondary lefticon={<FontAwesomeIcon icon={faFilterSlash} />} onClick={clearFilters}>
               {trans('?Vider')}
            </Button>
            <Button lefticon={<FontAwesomeIcon icon={faFilter} />} onClick={applyFilter}>
               {trans('?Appliquer')}
            </Button>
         </div>
      </div>
   )
}

export default CatalogFilter
