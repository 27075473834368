import styles from "./PopupCustomer.module.scss"
import { faBagsShopping, faUserTieHairLong, faBuilding, faBox, faEye, faDoorOpen } from '@fortawesome/pro-solid-svg-icons';
import { faAddressBook, faLocationDot, faStore, faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from "react"
// import { useLiveQuery } from "dexie-react-hooks"
import usePrefStore from "../../stores/prefs";
import useSales from "../../Hooks/useSales";
import useTranslation from '../../Hooks/useTranslation'
import { useNavigate } from 'react-router-dom'
import useHelpers from "../../Hooks/useHelpers";

const tab_names = {
   contacts: "contacts",
   addresses: "addresses"
}

function PopupCustomer(props) {
   // const curCustomer = usePrefStore((state) => state.curCustomer);
   // const setCurCustomer = usePrefStore((state) => state.setCurCustomer);
   // const setCurOrder = usePrefStore((state) => state.setCurOrder);
   const { curCustomer, setCurCustomer, setCurOrder, currentLang } = usePrefStore();
   //
   const { getCustomerById, type_address, tag_address, getAddressesFromCustomerAsSortedArray, getContactsFromCustomerAsSortedArray } = useSales()
   // const live_customer = useLiveQuery(async () => await getCustomerById(curCustomer), [curCustomer])
   //
   // const [addClasses, setAddClasses] = useState("")
   const [curTab, setCurTab] = useState()
   const [addresses, setAddresses] = useState(null)
   const [contacts, setContacts] = useState(null)
   const [customer, setCustomer] = useState(null)
   //
   const { trans } = useTranslation()
   const navigate = useNavigate()
   const { buildAddress } = useHelpers()

   // useEffect(() => {
   //    let newClass = ""
   //    if (props.secondary) {
   //       newClass = newClass + " " + styles.secondary
   //    }
   //    setAddClasses(newClass)
   // }, [props])

   useEffect(() => {
      setCurTab(tab_names.contacts)
   }, [])

   useEffect(() => {
      setCustomer(getCustomerById(curCustomer))
   }, [curCustomer, getCustomerById])

   function onClick(evt) {
      evt.stopPropagation()
   }

   function onClose(evt) {
      evt.stopPropagation()
      if (props.onClose) {
         props.onClose()
      }
   }

   function switchTab(tab) {
      setCurTab(tab)
   }

   useEffect(() => {
      if (customer?.addresses) {
         setAddresses(getAddressesFromCustomerAsSortedArray(customer))
      } else {
         setAddresses(null)
      }
      if (customer?.contacts) {
         setContacts(getContactsFromCustomerAsSortedArray(customer))
      } else {
         setContacts(null)
      }
   }, [customer, getAddressesFromCustomerAsSortedArray, getContactsFromCustomerAsSortedArray])

   function sendMail(email) {
      window.location = `mailto:${email}`
   }

   function goFiche() {
      navigate(`/customer-detail?id=${customer?.id}&tab=info`, { replace: false })
   }

   function leaveCustomer() {
      setCurCustomer(null)
      setCurOrder(null)
      props.onClose()
   }

   // console.log('LIVE CUST', live_customer)

   return (
      // <div className={`${styles.popup_customer}${addClasses}`} onClick={onClick}>
      <div className={styles.popup_customer} onClick={onClick}>
         <div className={styles.header} onClick={onClose}>
            <div className={styles.header_content}>
               <FontAwesomeIcon icon={faUserTieHairLong} />
               <div className={styles.customer_name}>
                  <span>{customer?.name}</span>
                  <span># {customer?.id}</span>
               </div>
               <button onClick={onClose}>
                  <FontAwesomeIcon icon={faXmark} />
               </button>
            </div>
         </div>
         <div className={styles.detail}>
            <div className={styles.filters}>
               <span className={(curTab === tab_names.contacts) ? styles.selected : ''} onClick={() => switchTab(tab_names.contacts)}>
                  <FontAwesomeIcon icon={faAddressBook} />
                  {trans('?Contact', true)}
                  <div className={styles.bot_border} />
               </span>
               <span className={(curTab === tab_names.addresses) ? styles.selected : ''} onClick={() => switchTab(tab_names.addresses)}>
                  <FontAwesomeIcon icon={faLocationDot} />
                  {trans('?Adresse', true)}
                  <div className={styles.bot_border} />
               </span>
               <div onClick={goFiche}>
                  <FontAwesomeIcon icon={faEye} />
               </div>
               <div onClick={leaveCustomer}>
                  <FontAwesomeIcon icon={faDoorOpen} />
               </div>
            </div>
            {(curTab === tab_names.addresses) && <div className={styles.addresses}>
               <ul>
                  {
                     addresses?.map((addr) => {
                        let icon, text
                        if (addr.id === type_address.siege) {
                           icon = faBuilding
                           text = tag_address.siege[currentLang ?? 'fr']
                        } else if (addr.id.startsWith(type_address.ecrins)) {
                           icon = faBox
                           text = tag_address.ecrins[currentLang ?? 'fr']
                        } else if (addr.id.startsWith(type_address.pos)) {
                           icon = faBagsShopping
                           text = tag_address.pos[currentLang ?? 'fr']
                        } else {
                           icon = faStore
                           text = tag_address.other[currentLang ?? 'fr']
                        }
                        return (
                           <li key={`addr_${addr.id}`}>
                              <div className={styles.type_addr}>
                                 <FontAwesomeIcon icon={icon} />
                                 <span>{text}</span>
                              </div>
                              <div className={styles.detail_lines}>
                                 {buildAddress(addr).map(line => <span>{line}</span>)}
                              </div>
                           </li>
                        )
                     })
                  }
               </ul>
            </div>
            }
            {(curTab === tab_names.contacts) && <div className={styles.contacts}>
               <ul>
                  {
                     contacts?.map((cont) => {
                        const email = cont.email
                        return (
                           <li key={`cont_${cont.id}`}>
                              <FontAwesomeIcon icon={faUserTieHairLong} />
                              <div className={styles.detail_lines}>
                                 <span>{cont.name}</span>
                                 <span>{cont.email}</span>
                                 <span>{cont.phone1}</span>
                                 <span>{cont.phone2}</span>
                              </div>
                              <button disabled={!email} onClick={() => sendMail(email)}>
                                 <FontAwesomeIcon icon={faEnvelope} />
                              </button>
                              {/* <button>
                                 <FontAwesomeIcon icon={faPhone} />
                              </button> */}
                           </li>
                        )
                     })
                  }
               </ul>
            </div>
            }
         </div>
      </div>
   )
}

export default PopupCustomer
