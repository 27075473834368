import { Fragment, useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faGlobe, /*faGear,*/ faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faUpToLine, faEnvelope as faEnvelopeProSolid } from '@fortawesome/pro-solid-svg-icons'
import { faEnvelope, faBagShopping, faBadgeCheck, faShop } from '@fortawesome/pro-light-svg-icons'
import DropdownMenu from '../components/DropdownMenu'
import Menu from '../components/Menu/Menu'
import Searchbar from '../components/Searchbar'
import usePrefStore from '../stores/prefs'
import styles from './Customers.module.scss'
// import useHelpers from '../Hooks/useHelpers'
import useSales from '../Hooks/useSales'
import PagesButtons from '../components/PagesButtons'
import useTranslation from '../Hooks/useTranslation'
// import { useNavigate } from 'react-router-dom'
import DropdownField from '../components/DropdownField'
import Button from '../components/Button'
import parsePhoneNumber from 'libphonenumber-js'
import useStateWithHistory from '../Hooks/useStateWithHistory'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useHelpers from '../Hooks/useHelpers'
import useSalesStore from '../stores/sales'

const menu_items = {
   setActive: "active",
   createOrder: "neworder",
   showInfo: "showinfo",
   // showAddresses: "showaddr",
   // showContacts: "showcont",
   // showStats: "showstats",
   writeMail: "writemail"
}

function Customers() {
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups)
   const listPageLength = Number(usePrefStore((state) => state.listPageLength))
   // const setListPageLength = usePrefStore((state) => state.setListPageLength)
   // const curCustomer = usePrefStore((state) => state.curCustomer);
   // const formatPhone = usePrefStore((state) => state.formatPhone);
   // const curLang = usePrefStore((state) => state.currentLang)
   // const debug = usePrefStore((state) => state.debug);
   const { setListPageLength, curCustomer, formatPhone, currentLang: curLang } = usePrefStore();
   // const countries = useSalesStore((state) => state.countries)
   const { countries } = useSalesStore()
   // States mémo pour historique
   const [curFilter, setCurFilter] = useStateWithHistory('cust_cur_filter', null)   // null === Tous
   const [curPage, setCurPage] = useStateWithHistory('cust_cur_page', 1)
   // const [searchText, setSearchText] = useStateWithHistory('cust_search_text', '')  // Impossible à cause du searchParams("search")
   // States
   // const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false)
   const [searchText, setSearchText] = useState('')
   const [searchBar, setSearchBar] = useState(null)
   const [allCustomers, setAllCustomers] = useState(null)
   const [filteredCustomers, setFilteredCustomers] = useState(null)
   const [curPageCustomers, setCurPageCustomers] = useState(null) // Clients affichés sur la page courante
   const [pagesButtons, setPagesButtons] = useState(null)
   const [lines, setLines] = useState(null)
   const [letterCounts, setLetterCounts] = useState(null)
   const [letterTabs, setLetterTabs] = useState(null)
   const [nbPages, setNbPages] = useState(1)
   const [firstPage, setFirstPage] = useState(1)
   const [dropdownMenu, setDropdownMenu] = useState(null)
   const [showDropdownMenuOnCustomerId, setShowDropdownMenuOnCustomerId] = useState(null)
   const [isItemsPerPageOpen, setIsItemsPerPageOpen] = useState(false)
   //
   const { getCustomersAsSortedArray, getCustomerById, setCustomerIdAsCurrent, getSiegeFromCustomer, createOrder, makeTemplateOrder, updateRecentCustomers } = useSales()
   const { trans, lowtrans } = useTranslation()
   // const { buildFullTarifFromCustomer } = useTarifs()
   const navigate = useNavigate()
   const [searchParams] = useSearchParams()
   const { buildAddress } = useHelpers()

   const onLineClick = useCallback(
      (evt, num) => {
         // console.log('doNothing')
         evt.stopPropagation()
         if (showDropdownMenuOnCustomerId !== null) {
            setShowDropdownMenuOnCustomerId(null)
         } else {
            navigate(`/customer-detail?id=${num}`, { replace: true })
         }
      },
      [navigate, showDropdownMenuOnCustomerId]
   )

   useEffect(() => {
      const search = searchParams.get('search')

      setSearchText(search ?? '')
   }, [searchParams])

   const onSearchChange = useCallback((text) => {
      setSearchText(text);
   }, [])

   useEffect(() => {
   }, [searchText])

   const onSelect = useCallback(async (id) => {
      // console.log('ID>>', id, showDropdownMenuOnCustomerId);
      if (id === menu_items.setActive) {
         setCustomerIdAsCurrent(showDropdownMenuOnCustomerId)
      } else if (id === menu_items.createOrder) {
         const new_order = makeTemplateOrder(showDropdownMenuOnCustomerId)
         const curid = await createOrder(new_order)

         // On a la même chose (avec Toaster dans CatalogDetail.jsx)
         // toast.success(transWithParams('?Nouvelle commande %1 créée et activée', curid), {
         //    position: "top-right",
         //    autoClose: 2500,
         //    hideProgressBar: false,
         //    closeOnClick: true,
         //    pauseOnHover: true,
         //    draggable: true,
         //    // progress: ,
         //    theme: "colored",
         // });
         navigate(`/order-detail?id=${curid}`, { replace: false })
      } else if (id === menu_items.showInfo) {
         updateRecentCustomers(showDropdownMenuOnCustomerId)
         navigate(`/customer-detail?id=${showDropdownMenuOnCustomerId}&tab=info`, { replace: false })
         // } else if (id === menu_items.showAddresses) {
         //    updateRecentCustomers(showDropdownMenuOnCustomerId)
         //    navigate(`/customer-detail?id=${showDropdownMenuOnCustomerId}&tab=addresses`, { replace: false })
         // } else if (id === menu_items.showContacts) {
         //    updateRecentCustomers(showDropdownMenuOnCustomerId)
         //    navigate(`/customer-detail?id=${showDropdownMenuOnCustomerId}&tab=contacts`, { replace: false })
         // } else if (id === menu_items.showStats) {
         //    updateRecentCustomers(showDropdownMenuOnCustomerId)
         //    navigate(`/customer-detail?id=${showDropdownMenuOnCustomerId}&tab=stats`, { replace: false })
      } else if (id === menu_items.writeMail) {
         const customer = getCustomerById(showDropdownMenuOnCustomerId)
         // console.log(customer);
         window.location = `mailto:${customer.email}`
      }
      setShowDropdownMenuOnCustomerId(null)
   }, [createOrder, getCustomerById, makeTemplateOrder, navigate, setCustomerIdAsCurrent, showDropdownMenuOnCustomerId, updateRecentCustomers])

   useEffect(() => {
      if (showDropdownMenuOnCustomerId !== null) {
         const customer = getCustomerById(showDropdownMenuOnCustomerId)
         setDropdownMenu(
            <DropdownMenu noscrollbars right={-20} top={20} onSelect={onSelect}>
               {/* <li onClick={onLineClick}> */}
               <li disabled={curCustomer === showDropdownMenuOnCustomerId} id={menu_items.setActive}>
                  <FontAwesomeIcon icon={faBadgeCheck} />
                  {trans('?Sélectionner')}
               </li>
               <li id={menu_items.createOrder}>
                  <FontAwesomeIcon icon={faBagShopping} />
                  {trans('?Créer une commande')}
               </li>
               <li id={menu_items.showInfo}>
                  <FontAwesomeIcon icon={faShop} />
                  {trans('?Voir les infos')}
               </li>
               {/* <li id={menu_items.showStats}>
                  <FontAwesomeIcon icon={faChartPie} />
                  {trans('?Voir les stats')}
               </li>
               <li id={menu_items.showContacts}>
                  <FontAwesomeIcon icon={faAddressBook} />
                  {trans('?Voir les contacts')}
               </li>
               <li id={menu_items.showAddresses}>
                  <FontAwesomeIcon icon={faLocationDot} />
                  {trans('?Voir les adresses')}
               </li> */}
               <li disabled={(customer.email === null) || (customer.email.indexOf('@') === -1)} id={menu_items.writeMail}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {trans('?Ecrire un mail')}
               </li>
            </DropdownMenu>
         )
      }
      setSearchBar(<Searchbar onChange={onSearchChange} text={searchText} />)
   }, [curCustomer, getCustomerById, onSearchChange, onSelect, searchText, showDropdownMenuOnCustomerId, trans])

   const onShowMenu = useCallback(
      (evt, id) => {
         evt.stopPropagation()
         if (showDropdownMenuOnCustomerId === id) {
            setShowDropdownMenuOnCustomerId(null)
         } else {
            setShowDropdownMenuOnCustomerId(id)
         }
      },
      [showDropdownMenuOnCustomerId]
   )

   // useEffect(() => {
   //    setAllCustomers(getCustomersAsSortedArray())
   // }, [getCustomersAsSortedArray])

   useEffect(() => {
      const arr = getCustomersAsSortedArray()

      setAllCustomers(arr)
      // Comptage par lettre. Pas besoin réellement du compte pour le moment, mais juste savoir s'il y en a
      setLetterCounts(arr.reduce((prev, a_customer) => {
         if (a_customer.name) {
            const letter = a_customer.name.trim().toUpperCase().charAt(0)
            // console.log('LETTER', letter);
            if (prev[letter]) {
               return { ...prev, [letter]: prev[letter] + 1 }
            } else {
               return { ...prev, [letter]: 1 }
            }
         } else if (a_customer.shortname) {
            const letter = a_customer.shortname.trim().toUpperCase().charAt(0)
            // console.log('LETTER2', letter);
            if (prev[letter]) {
               return { ...prev, [letter]: prev[letter] + 1 }
            } else {
               return { ...prev, [letter]: 1 }
            }
         } else {
            // console.log('LETTER3');
            return { ...prev }
         }
      }, {}))
   }, [getCustomersAsSortedArray])

   useEffect(() => {
      const lang = (curLang === 'en') ? 'en' : 'fr'
      let arr
      const parts = searchText?.trim().toUpperCase().split(' ')

      if (curFilter === null) {
         // console.log('PARTS', parts);
         if ((parts.length > 0) && (parts[0].length)) {
            arr = allCustomers?.filter((a_customer) => {
               const cust_name = a_customer.name?.toUpperCase()
               const cust_shortname = a_customer.shortname?.toUpperCase()
               const cust_id = a_customer.id
               let cust_city = a_customer?.addresses?.SIEGE?.city ?? null
               const country_id = a_customer?.addresses?.SIEGE?.country?.toUpperCase() ?? null
               let cust_country

               if (country_id && countries && countries[country_id]) {
                  cust_country = countries[country_id][lang] ?? country_id
               } else {
                  cust_country = country_id
               }
               cust_country = cust_country?.toUpperCase()
               // console.log(a_customer, country, str);
               return parts.find((str) => {
                  const subs = str.split('+')

                  return subs.reduce((prev, a_sub) => {
                     // console.log('SUB', a_sub);
                     if (!prev) {
                        return false
                     }
                     return cust_name?.includes(a_sub) || cust_id?.includes(a_sub) || cust_country?.includes(a_sub) || cust_city?.includes(a_sub) || cust_shortname?.includes(str)
                  }, true)
               })
            })
         } else {
            arr = allCustomers
         }
      } else { // Lettre
         arr = allCustomers?.filter((a_customer) => {
            if (a_customer.name) {
               return a_customer.name.trim().toUpperCase().charAt(0) === curFilter
            } else if (a_customer.shortname) {
               return a_customer.shortname.trim().toUpperCase().charAt(0) === curFilter
            } else {
               return false
            }
         })
      }
      setFilteredCustomers(arr)
   }, [allCustomers, countries, curFilter, curLang, searchText])

   useEffect(() => {
      if (searchText?.length > 0) {
         setCurFilter(null)
      }
   }, [searchText, setCurFilter])

   useEffect(() => {
      const start = (curPage - 1) * listPageLength
      // console.log('SLICE', typeof start, typeof listPageLength);
      // console.log('SLICE', start, listPageLength, start + listPageLength);
      setCurPageCustomers(filteredCustomers?.slice(start, start + listPageLength))
   }, [curPage, filteredCustomers, listPageLength])

   useEffect(() => {
      let pageLength = listPageLength

      if (pageLength <= 0) {
         pageLength = 1
         console.error(
            `Erreur ListPageLength ! ${listPageLength} - Modifié temporairement en 1`
         )
      }
      // const len = listPageLength
      const max = Math.max(1, Math.floor((filteredCustomers?.length + pageLength - 1) / pageLength)); // Nb maxi de pages
      setNbPages(max)
      if (curPage < 1 || curPage > max) {
         setCurPage(1)
      }
   }, [curPage, filteredCustomers, listPageLength, setCurPage])

   const buildSiegeAddress = useCallback((a_customer) => {
      const siege = getSiegeFromCustomer(a_customer)

      return buildAddress(siege).map((line, idx) => <span key={`${a_customer.id}-${idx}`}>{line}</span>)
   }, [buildAddress, getSiegeFromCustomer])

   const buildPhones = useCallback((a_customer, nb_max) => {
      const addr = getSiegeFromCustomer(a_customer)
      const country = (addr && addr.country) ? addr.country : null
      let num_line = 0

      return (
         a_customer.phones.slice(0, nb_max).map((a_phone) => {
            let num
            num_line++

            if (formatPhone) {
               // https://www.npmjs.com/package/libphonenumber-js
               num = parsePhoneNumber(a_phone.replaceAll(' ', ''), country ?? 'FR')?.formatInternational()
            } else {
               num = a_phone
            }
            return (
               <div key={`phone_${num_line}`}><FontAwesomeIcon icon={faPhone} />{num}</div>
            )
         })
      )
   }, [formatPhone, getSiegeFromCustomer])

   function buildEmail(a_customer) {
      const email = a_customer.email
      // console.log(a_customer);
      if (email) {
         return (
            <div>
               <FontAwesomeIcon icon={faEnvelopeProSolid} />
               <span>{email}</span>
            </div>
         )
      }
   }

   const buildLines = useCallback(() => {
      // console.log('***ICI');
      return curPageCustomers?.map((a_customer) => {
         // const full_tarif = buildFullTarifFromCustomer(a_customer)
         return (
            <tr key={`line_${a_customer.id}`} className={(a_customer.id === curCustomer) ? styles.selected : ''} onClick={(evt) => { onLineClick(evt, a_customer.id) }}>
               <td>{a_customer.id}</td>
               <td className={styles.name}>
                  <div>
                     <span>{a_customer.name}</span>
                     <span>{a_customer.shortname}</span>
                     {/* {!full_tarif &&
                        <span>
                           <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: 'red', paddingRight: '10px' }} />
                           {!debug && <span>Pb tarif !!!</span>}
                           {debug && <span>Pb tarif !!!<pre>{JSON.stringify(a_customer.tariff, null, 4)}</pre></span>}
                        </span>
                     }
                     {full_tarif && debug &&
                        <span>
                           <pre>{JSON.stringify(full_tarif, null, 4)}</pre>
                        </span>
                     } */}
                  </div>
               </td>
               <td className={styles.address}>
                  <div>
                     {buildSiegeAddress(a_customer)}
                  </div>
               </td>
               <td className={styles.phone_mail}>
                  {buildPhones(a_customer, 1)}
                  {buildEmail(a_customer)}
               </td>
               <td className={styles.actions}>
                  <span onClick={(evt) => onShowMenu(evt, a_customer.id)}>
                     {/* <FontAwesomeIcon icon={faGear} /> */}
                     {trans('?Détail', true)}
                     <FontAwesomeIcon icon={faAngleRight} />
                     {showDropdownMenuOnCustomerId === a_customer.id ? dropdownMenu : ''}
                  </span>
               </td>
            </tr>
         )
      })

   }, [curPageCustomers, curCustomer, buildSiegeAddress, buildPhones, trans, showDropdownMenuOnCustomerId, dropdownMenu, onLineClick, onShowMenu])

   const switchCurFilter = useCallback((letter) => {
      setCurFilter(letter)
      console.log('+++ICI6');
      setSearchText('')
   }, [setCurFilter])

   useEffect(() => {
      const symbols = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const letters = []
      for (let loop = 0; loop < symbols.length; loop++) {
         const letter = symbols.charAt(loop)
         const enabled = (letterCounts && letterCounts[letter])
         // console.log(letter, enabled);
         if (enabled) {
            letters.push(
               <span key={`letter_${letter}`} className={`${styles.letter} ${(curFilter === letter) ? styles.selected : ''}`} onClick={() => switchCurFilter(letter)}>
                  {letter}
                  <div className={styles.bot_border} />
               </span>
            )
         } else {
            letters.push(
               <span key={`letter_${letter}`} className={`${styles.letter} ${styles.disabled}`}>
                  {letter}
                  <div className={styles.bot_border} />
               </span>
            )
         }
      }
      setLetterTabs(letters)
   }, [curFilter, letterCounts, switchCurFilter])

   useEffect(() => {
      setLines(buildLines())
   }, [buildLines])

   const onFirstPageChange = useCallback((val) => {
      console.log('onFirstPageChange', val)
      setFirstPage(val)
   }, [])

   const onPageChange = useCallback((val) => {
      console.log('onPageChange', val)
      setCurPage(val)
   }, [setCurPage])

   useEffect(() => {
      setPagesButtons(
         <PagesButtons from={firstPage} max={nbPages} selected={curPage} onFirstPageChange={onFirstPageChange} onPageChange={onPageChange}></PagesButtons>
      )
   }, [curPage, firstPage, nbPages, onFirstPageChange, onPageChange])

   // useEffect(() => {
   //    console.log('CUST', 'curPage')
   // }, [curPage])
   // useEffect(() => {
   //    console.log('CUST', 'firstPage')
   // }, [firstPage])
   // useEffect(() => {
   //    console.log('CUST', 'nbPages')
   // }, [nbPages])
   // useEffect(() => {
   //    console.log('CUST', 'onFirstPageChange')
   // }, [onFirstPageChange])
   // useEffect(() => {
   //    console.log('CUST', 'onPageChange')
   // }, [onPageChange])

   function onGlobalClick(evt) {
      evt.stopPropagation()
      setShowDropdownMenuOnCustomerId(null)
   }

   function toggleItemsPerPageShowList() {
      setIsItemsPerPageOpen(!isItemsPerPageOpen)
   }

   function onItemsPerPageSelect(id) {
      // console.log('>>>>>>', id)
      setListPageLength(id)
      setIsItemsPerPageOpen(false)
   }

   return (
      <Fragment>
         <Menu page="customers" />
         <div className={styles.customers} onClick={onGlobalClick}>
            {searchBar}
            <div className={styles.content}>
               <div className={styles.header_bg}>
                  <div className={styles.header}>
                     <h1>
                        {trans('?Client', true)}
                        {' '}
                        {(filteredCustomers?.length !== allCustomers?.length) &&
                           `(${filteredCustomers?.length} ${trans("?x_sur_y")} ${allCustomers?.length})`
                        }
                        {(filteredCustomers?.length === allCustomers?.length) &&
                           `(${filteredCustomers?.length})`
                        }
                     </h1>
                     <div className={styles.filters}>
                        <span className={!curFilter ? styles.selected : ''} onClick={() => switchCurFilter(null)}>
                           <FontAwesomeIcon icon={faGlobe} />
                           {trans('?Tous')}
                           <div className={styles.bot_border} />
                        </span>
                        {letterTabs}
                     </div>
                  </div>
               </div>
               <div className={styles.detail}>
                  <table>
                     <thead>
                        <tr>
                           <th>{trans('?N°')}</th>
                           <th>{trans('?Nom')}</th>
                           <th>{trans('?Adresse')}</th>
                           <th>{trans('?Téléphone')} / {trans('?Email')}</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {lines}
                     </tbody>
                  </table>
               </div>
               <div className={styles.footer_bg}>
                  <div className={styles.footer}>
                     <span>{trans('?Affichage de')}</span>
                     <DropdownField selectedValue={String(listPageLength)} showAbove height={36} onSelect={onItemsPerPageSelect} isOpen={isItemsPerPageOpen} onToggle={toggleItemsPerPageShowList} >
                        <Fragment key="10">10</Fragment>
                        <Fragment key="20">20</Fragment>
                        <Fragment key="50">50</Fragment>
                        <Fragment key="100">100</Fragment>
                        {/* <Fragment key="999999">{lowtrans("?Tous")}</Fragment> */}
                     </DropdownField>
                     <span>
                        {(listPageLength < 99999) ? trans('?par page') : ''}
                     </span>
                     <div>
                        {(listPageLength < 99999) && pagesButtons}
                        <span></span>
                        <Button>
                           <FontAwesomeIcon icon={faUpToLine} />
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <ToastContainer />
      </Fragment>
   )
}

export default Customers
