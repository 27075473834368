import { Fragment, useCallback, useEffect, useState } from 'react'
import Searchbar from '../Searchbar'
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom'
// import useTranslation from '../../Hooks/useTranslation'
import useCollections from '../../Hooks/useCollections'
import CatalogFilter from './CatalogFilter'
// import DarkLayer from '../DarkLayer'
import useFilters from '../../Hooks/useFilters'
import usePrefStore from '../../stores/prefs'
import useTarifs from '../../Hooks/useTarifs'
// import useHelpers from '../../Hooks/useHelpers'
import { CatalogFilterContext } from '../../Contexts/CatalogFilterContext';
import { useLongPress } from 'use-long-press';

import styles_Searchbar from '../Searchbar.module.scss';
import { GlobalContext } from '../../Contexts/GlobalContext';

function CatalogSearchBar(props) {
   // Prefs store
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups)
   // const showCessDelay = usePrefStore((state) => state.showCessDelay)
   // const showCess = usePrefStore((state) => state.showCess)
   // const setShowCess = usePrefStore((state) => state.setShowCess)
   const { showCessDelay, showCess, setShowCess } = usePrefStore()

   const {
      minManualPrice,            // Prix mini choisi manuellement pour le filtrage
      setMinManualPrice,
      maxManualPrice,            // Prix maxi choisi manuellement pour le filtrage
      setMaxManualPrice,
      searchText,                // Prix maxi choisi manuellement pour le filtrage
      setSearchText,
      minPrice,                  // Prix mini tous modèles filtrés confondus (sans filtrer sur prix évidemment !)
      setMinPrice,
      maxPrice,                  // Prix max tous modèles filtrés confondus (sans filtrer sur prix évidemment !)
      setMaxPrice,
      paramCollectionId,
      setParamCollectionId,
      setParamGammeId,
      gammes,                    // Toutes les gammes + état coché ou pas
      setGammes,
      collections,               // Toutes les collections + état coché ou pas
      setCollections,
      familles,                  // Toutes les familles + état coché ou pas
      setFamilles,
      portes,                    // Tous les portés + état coché ou pas
      setPortes,
      couleurs,                  // Toutes les couleurs + état coché ou pas
      setCouleurs,
      allMinMax,                 // Les prix min/max de tous les modèles
      doFilter,                  /* Pour éviter les rerenders, on décide du moment où le filtrage peut se faire.
                                    Il peut se faire quand le bouillonnement de la mise à jour
                                    Gammes > Collections > Familles > Portes > Couleurs est fini (dans Couleur) */
      setDoFilter,
      suspendRecalc,
      getSelectedGammes, getSelectedGammesIds, getSelectedCollections, getSelectedCollectionsIds, getSelectedFamilles, getSelectedFamillesIds, getSelectedPortesIds, getSelectedCouleursIds, clearGammes, clearCollections, clearFamilles, clearPortes, clearCouleurs
   } = { ...CatalogFilterContext() }

   // States
   // const [enteredByCollectionId, setEnteredByCollectionId] = useState(null)   // L'ID de la collection par laquelle on est arrivé (ou null)
   // setAllMinMax] = useState(null)    // Les prix min/max de tous les modèles
   // const [searchCriteria, setSearchCriteria] = useState(null)
   const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false)
   const [filterPopup, setFilterPopup] = useState(null)
   const [hasCriteriaEnabled, setHasCriteriaEnabled] = useState(false)
   //
   const { getCollectionsInGammes, getGammes } = useCollections()
   const { getColorsForModel } = useTarifs()
   const [searchParams] = useSearchParams()
   const { filterOnCriteria } = useFilters()
   const { isDarkLayerVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible } = { ...GlobalContext() }

   /*******
    ******* CRÉATION DES LISTES (GAMMES, COLLECTIONS, FAMILLES, PORTES, COULEURS)
    *******/

   // On prépare la liste de toutes les valeurs possibles pour les listes
   // On commence par les gammes, le reste va cascader
   useEffect(() => {
      const id = searchParams.get('collec')
      const id_gamme = searchParams.get('gamme')
      // const search = searchParams.get('search')
      // const gamme_id = (id ? getGammeForCollectionId(id)?.id : null)

      // if (search) {
      //    setSearchText(search)
      // }
      setParamCollectionId(id)
      setParamGammeId(id_gamme)
      // setEnteredByCollectionId(id)
      // // setMainSortMode(id ? sortFamille : sortGamme)      
      // if (gamme_id) {
      //    setGammes(
      //       getGammesAsSortedArray().map((gamme) => {
      //          // console.log('SET GAMMES', id, gamme_id, gamme.id, gamme)
      //          return {
      //             id: gamme.id,
      //             label: gamme.label,
      //             visible: true,
      //             // visible: (id === null),       // Pas visible si on a ciblé directement une collection
      //             checked: (gamme_id === gamme.id)      // Checké si on a ciblé directement une collection, donc une gamme
      //          }
      //       })
      //    )
      // }
   }, [searchParams, setParamCollectionId, setParamGammeId])

   useEffect(() => {
      // NB : on ne peut pas avoir de porté ou de couleur si pas au moins une collection !
      // Donc pas la peine de checker au dela de la collection.
      if (paramCollectionId) {   // On est entré par une collection
         setHasCriteriaEnabled(getSelectedFamilles()?.length || (minManualPrice != null) || (maxManualPrice !== null))
      } else {
         setHasCriteriaEnabled(getSelectedGammes()?.length || getSelectedCollections()?.length || getSelectedFamilles()?.length || (minManualPrice != null) || (maxManualPrice !== null))
      }
   }, [getSelectedCollections, getSelectedFamilles, getSelectedGammes, maxManualPrice, minManualPrice, paramCollectionId])

   /*******
    ******* AFFICHAGE/MASQUAGE DES ITEMS DES LISTES (GAMMES, COLLECTIONS, FAMILLES, PORTES, COULEURS)
    *******
    ******* Rien à faire pour les Gammes, c'est le point de départ du reste
    ******* Pour Collections, on fait un traitement sans appeler le filtrage
    ******* Pour Familles, Portes et Couleur, on utilise l'aide du filtrage
    *******/

   // Affichage/Masquage des collections en fonction des gammes sélectionnées
   useEffect(() => {
      const selectedGammesIds = getSelectedGammesIds()
      let arr

      // Si aucune gamme sélectionnée, on va quand même afficher toutes les collections
      if (selectedGammesIds.length === 0) {
         arr = Object.keys(getCollectionsInGammes(Object.keys(getGammes())))
      } else {
         arr = Object.keys(getCollectionsInGammes(selectedGammesIds))
      }

      setCollections(coll =>
         coll?.map((a_coll) => {
            return { ...a_coll, visible: arr.includes(a_coll.id) }
         })
      )
   }, [getCollectionsInGammes, getGammes, getSelectedGammesIds, setCollections])

   // Affichage/Masquage des familles des modèles des collections sélectionnées.
   // Si aucune collection sélectionnée, on affiche toutes les familles ! NB : uniquement celles des collections qui sont dans les gammes retenues, ou toutes les familles si pas toutes gammes.
   useEffect(() => {
      const selected_gammes_id = getSelectedGammesIds()
      let selected_collections_id = getSelectedCollectionsIds()

      // Cas particulier où on n'aurait pas choisi de collection, il faut donc ne proposer que les familles des collections des gammes retenues.
      if ((selected_gammes_id.length > 0) && (selected_collections_id.length === 0)) {
         selected_collections_id = getCollectionsInGammes(selected_gammes_id)
      }
      const filter_res = filterOnCriteria({ gammes: selected_gammes_id, collections: selected_collections_id })

      setFamilles(fam => fam?.map(a_fam => {
         const show = (
            (selected_collections_id.length === 0) ||
            (filter_res.find((model) => { return (model.fam === a_fam.id) }) !== undefined)
         )
         return { ...a_fam, visible: show }
      }))
   }, [filterOnCriteria, getCollectionsInGammes, getSelectedCollectionsIds, getSelectedGammesIds, setFamilles])

   // Affichage/Masquage des portés des modèles des familles sélectionnées
   useEffect(() => {
      const selected_gammes_id = getSelectedGammesIds()
      const selected_collections_id = getSelectedCollectionsIds()
      const selected_familles_id = getSelectedFamillesIds()
      const filter_res = filterOnCriteria({ gammes: selected_gammes_id, collections: selected_collections_id, familles: selected_familles_id })

      setPortes(porte => porte?.map(a_porte => {
         const show = (
            (selected_familles_id.length !== 0) &&
            (filter_res.find((model) => { return (model.porte === a_porte.id) }) !== undefined)
         )
         return { ...a_porte, visible: show }
      }))
   }, [filterOnCriteria, getSelectedCollectionsIds, getSelectedFamillesIds, getSelectedGammesIds, setPortes])

   // Affichage/Masquage des couleurs des modèles des collections (+ familles et portés éventuels) sélectionnées
   useEffect(() => {
      const selected_gammes_id = getSelectedGammesIds()
      const selected_collections_id = getSelectedCollectionsIds()
      const selected_familles_id = getSelectedFamillesIds()

      if (selected_familles_id.length > 0) {
         // On ne s'occupe des couleurs que s'il y a au moins une famille de choisie !!!
         // Sinon c'est sans intérêt et ça ralentit l'appli...

         const selected_portes_id = getSelectedPortesIds()
         const filter_res = filterOnCriteria({ gammes: selected_gammes_id, collections: selected_collections_id, familles: selected_familles_id, portes: selected_portes_id })
         // Liste des couleurs trouvées parmi les modèles correspondant au filtrage
         const common_colors = [...filter_res.reduce((prev, model) => {
            const ret = getColorsForModel(model.id).colors || []
            // console.log('COMMON COLORS2', ...(getColorsForModel(model.id).colors || []), prev)
            // debugger
            return new Set([...prev, ...ret])
         }, new Set())]

         // console.log('COMMON COLORS', common_colors, filter_res, getColorsForModel('000113'))

         setCouleurs(couleur => couleur?.map(a_couleur => {
            const show = (
               (filter_res.find((model) => {
                  // const models_colors = getColorsForModel(model.id)
                  return common_colors.includes(a_couleur.id)
               }) !== undefined)
            )
            // console.log('COMMON COLORS3', '_', common_colors, '_', a_couleur, show)
            return { ...a_couleur, visible: show }
         }))
      } else {
         // Sinon, on cache tout.
         setCouleurs(couleur => couleur?.map(a_couleur => {
            return { ...a_couleur, visible: false }
         }))
      }
      setDoFilter(true)   // Prêt à filtrer, il ne reste qu'à s'occuper (éventuellement) des prix et du filtrage final
   }, [filterOnCriteria, getColorsForModel, getSelectedCollectionsIds, getSelectedFamillesIds, getSelectedGammesIds, getSelectedPortesIds, setCouleurs, setDoFilter])

   useEffect(() => {
      if (doFilter && !suspendRecalc && allMinMax)  // OK pour calculer les min/max de prix
      {
         const selected_familles_id = getSelectedFamillesIds()
         const selected_gammes_id = getSelectedGammesIds()
         const selected_collections_id = getSelectedCollectionsIds()
         const selected_portes_id = getSelectedPortesIds()
         const selected_couleurs_id = getSelectedCouleursIds()

         let arr = filterOnCriteria({ gammes: selected_gammes_id, collections: selected_collections_id, familles: selected_familles_id, portes: selected_portes_id, couleurs: selected_couleurs_id })

         // Calcul du min/max global tous modèles filtrés à ce stade
         const min_max = arr?.reduce((prev, model) => {
            const model_min_max = allMinMax[model.id]

            if (!model_min_max || (model_min_max.min === null) || (model_min_max.max === null)) {
               return { ...prev }
            } else {
               return {
                  min: prev.min !== null ? Math.min(prev.min, model_min_max.min) : model_min_max.min,
                  max: prev.max !== null ? Math.max(prev.max, model_min_max.max) : model_min_max.max,
               }
            }
         }, { min: null, max: null })

         if (minPrice !== min_max.min || maxPrice !== min_max.max) {
            // console.log('SET minMax', min_max)
            setMinPrice((min_max.min < 100) ? 0 : min_max.min)
            setMaxPrice(min_max.max)
         }

         if ((min_max.min > minManualPrice) || (min_max.max < minManualPrice)) {
            setMinManualPrice(null)
         }
         if (min_max.max < maxManualPrice) {
            setMaxManualPrice(null)
         }
         // const filterMinPrice = (((minManualPrice !== null) && (minManualPrice >= min_max.min)) ? minManualPrice : null) // null permet de ne pas tenir compte du filtrage
         // const filterMaxPrice = (((maxManualPrice !== null) && (maxManualPrice <= min_max.max)) ? maxManualPrice : null) // null permet de ne pas tenir compte du filtrage

         // // On ne filtrera réellement sur le prix que si on a choisi des prix manuellement (min et max non null)
         // arr = filterOnPrice(arr, { price_min: filterMinPrice, price_max: filterMaxPrice }, allMinMax)
         // setFilteredList(arr)
         // setDoFilter(false)   // Fini, on bloque
      }
   }, [allMinMax, doFilter, filterOnCriteria, getSelectedCollectionsIds, getSelectedCouleursIds, getSelectedFamillesIds, getSelectedGammesIds, getSelectedPortesIds, maxManualPrice, maxPrice, minManualPrice, minPrice, setMaxManualPrice, setMaxPrice, setMinManualPrice, setMinPrice, suspendRecalc])

   const onFilter = useCallback(() => {
      console.debug('onFilter !!!')
      // setDoFilter(true)
      if (props.onFilter) {
         props.onFilter()
      }
   }, [props])

   const onGammeChange = useCallback((id, state) => {
      console.log('ON GAMME CHANGE')
      const arr = gammes.map((gamme) => {
         if (gamme.id === id) {
            return { ...gamme, checked: state }
         } else {
            return { ...gamme }
         }
      })
      setGammes(arr)
   }, [gammes, setGammes])

   const onCollectionChange = useCallback((id, state) => {
      const arr = collections.map((collection) => {
         if (collection.id === id) {
            return { ...collection, checked: state }
         } else {
            return { ...collection }
         }
      })
      setCollections(arr)
   }, [collections, setCollections])

   const onFamilleChange = useCallback((new_states) => {
      console.log(new_states, familles);
      const arr = familles.map((famille) => {
         console.log(famille.id, new_states[famille.id]);
         if (new_states[famille.id] !== undefined) {
            return { ...famille, checked: new_states[famille.id] }
         } else {
            return { ...famille }
         }
      })
      setFamilles(arr)
   }, [familles, setFamilles])

   const onPorteChange = useCallback((id, state) => {
      const arr = portes.map((porte) => {
         if (porte.id === id) {
            return { ...porte, checked: state }
         } else {
            return { ...porte }
         }
      })
      setPortes(arr)
   }, [portes, setPortes])

   const onCouleurChange = useCallback((id, state) => {
      const arr = couleurs.map((couleur) => {
         if (couleur.id === id) {
            return { ...couleur, checked: state }
         } else {
            return { ...couleur }
         }
      })
      setCouleurs(arr)
   }, [couleurs, setCouleurs])

   const onPricesChange = useCallback((min, max) => {
      console.log('***PRICE', min, max, minPrice, maxPrice, (min > minPrice) ? min : null, (max < maxPrice) ? max : null)
      setMinManualPrice((min > minPrice) ? min : null)
      setMaxManualPrice((max < maxPrice) ? max : null)
      setDoFilter(true)
   }, [maxPrice, minPrice, setDoFilter, setMaxManualPrice, setMinManualPrice])

   const onGammesClear = useCallback(() => {
      console.log('ON GAMME CLEAR')
      clearGammes()
   }, [clearGammes])

   const onCollectionsClear = useCallback(() => {
      clearCollections()
   }, [clearCollections])

   const onFamillesClear = useCallback(() => {
      clearFamilles()
   }, [clearFamilles])

   const onPortesClear = useCallback(() => {
      clearPortes()
   }, [clearPortes])

   const onCouleursClear = useCallback(() => {
      clearCouleurs()
   }, [clearCouleurs])

   const onHideSearchFilters = useCallback(() => {
      console.log('onHideTools')
      setIsSearchFilterVisible(false)
   }, [])

   useEffect(() => {
      // console.log('New setFilterPopup')
      setFilterPopup(
         <CatalogFilter onHide={onHideSearchFilters} visible={isSearchFilterVisible} onFilter={onFilter} price_min={minPrice} price_max={maxPrice} manual_price_min={minManualPrice} manual_price_max={maxManualPrice} onPricesChange={onPricesChange} showGammes={(paramCollectionId === null)} gammes={gammes} onGammeChange={onGammeChange} onGammesClear={onGammesClear} showCollections={(paramCollectionId === null)} collections={collections} onCollectionChange={onCollectionChange} onCollectionsClear={onCollectionsClear} familles={familles} onFamilleChange={onFamilleChange} onFamillesClear={onFamillesClear} portes={portes} onPorteChange={onPorteChange} onPortesClear={onPortesClear} couleurs={couleurs} onCouleurChange={onCouleurChange} onCouleursClear={onCouleursClear} />
      )
   }, [collections, couleurs, familles, gammes, isSearchFilterVisible, maxManualPrice, maxPrice, minManualPrice, minPrice, onCollectionChange, onCollectionsClear, onCouleurChange, onCouleursClear, onFamilleChange, onFamillesClear, onFilter, onGammeChange, onGammesClear, onHideSearchFilters, onPorteChange, onPortesClear, onPricesChange, paramCollectionId, portes])

   useEffect(() => {
      setKeepSearchBarAccessible(true)
      setIsDarkLayerVisible(isSearchFilterVisible)
   }, [isSearchFilterVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible])

   function onSearchChange(text) {
      setSearchText(text)
   }
   const onSearch = useCallback(() => {
      console.log('ON SEARCH')
      if (props.onSearch) {
         props.onSearch(searchText)
      }

   }, [props, searchText])

   const onToggleSearchFilters = useCallback(() => {
      setIsSearchFilterVisible(!isSearchFilterVisible)
   }, [isSearchFilterVisible])

   useEffect(() => {
      if (!isDarkLayerVisible) {
         setIsSearchFilterVisible(false)
      }
   }, [isDarkLayerVisible])

   const toggleShowCess = () => {
      setShowCess(true)
   }

   const longPressOnShowCess = useLongPress(toggleShowCess, { threshold: showCessDelay })

   return (
      <Fragment>
         <Searchbar text={searchText} onChange={onSearchChange} onSearch={onSearch} >
            <div className={styles_Searchbar.search_tools}>
               <button className={hasCriteriaEnabled ? styles_Searchbar.red : ''} onClick={onToggleSearchFilters}>
                  <FontAwesomeIcon icon={faSliders} />
               </button>
               {showCess &&
                  <button onClick={() => setShowCess(false)}>
                     <FontAwesomeIcon icon={faEye} />
                  </button>
               }
               {!showCess &&
                  <button {...longPressOnShowCess()}>
                     <FontAwesomeIcon icon={faEyeSlash} />
                  </button>
               }
               {filterPopup}
            </div>
         </Searchbar>
      </Fragment>
   )
}

export default CatalogSearchBar;
