import { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '../components/Button';
import { Checkbox, States as CheckboxStates } from '../components/Checkbox';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import Menu from '../components/Menu/Menu';
import useTranslation from '../Hooks/useTranslation';
// import { useTranslation } from "react-i18next"
import usePrefStore from '../stores/prefs';
// import useModelsStore from '../stores/models';
import styles from './Params.module.scss';
// import useTarifs from '../Hooks/useTarifs';
import SegmentedButtons from '../components/SegmentedButtons';
import InputField from '../components/InputField';
import useHelpers from '../Hooks/useHelpers';
// import useSales from '../Hooks/useSales';
import { useNavigate } from 'react-router-dom';
// import { useLogger } from '../Contexts/Logger';
// import { db } from "../stores/db"

// const secretPhrase = 'run forest run'

function Params() {
   // const staticCacheName = "site-static"
   const dynamicCacheName = 'site-dynamic';
   const assetsCacheName = 'assets';
   const fontsCacheName = 'fonts';
   // Prefs store
   // // const logToServer = usePrefStore((state) => state.logToServer);
   // // const setLogToServer = usePrefStore((state) => state.setLogToServer);
   // // const logToConsole = usePrefStore((state) => state.logToConsole);
   // // const setLogToConsole = usePrefStore((state) => state.setLogToConsole);
   // // const setFilterDelay = usePrefStore((state) => state.setFilterDelay);
   // const resetPrefs = usePrefStore((state) => state.reset);
   // const catalogMainAlign = usePrefStore((state) => state.catalogMainAlign);
   // const setCatalogMainAlign = usePrefStore((state) => state.setCatalogMainAlign);
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups);
   // const setGlobalClosePopups = usePrefStore((state) => state.setGlobalClosePopups);
   // const debug = usePrefStore((state) => state.debug);
   // const setDebug = usePrefStore((state) => state.setDebug);
   // const localOrderId = usePrefStore((state) => state.localOrderId);
   // const setLocalOrderId = usePrefStore((state) => state.setLocalOrderId);
   // const maxTarifsOffline = usePrefStore((state) => state.maxTarifsOffline);
   // const setMaxTarifsOffline = usePrefStore((state) => state.setMaxTarifsOffline);
   // const qtyMaxOrder = usePrefStore((state) => state.qtyMaxOrder);
   // const setQtyMaxOrder = usePrefStore((state) => state.setQtyMaxOrder);
   // const showWeeksNum = usePrefStore((state) => state.showWeeksNum);
   // const setShowWeeksNum = usePrefStore((state) => state.setShowWeeksNum);
   // const minFilterLength = usePrefStore((state) => state.minFilterLength);
   // const setMinFilterLength = usePrefStore((state) => state.setMinFilterLength);
   // const noScrollbars = usePrefStore((state) => state.noScrollbars);
   // const setNoScrollbars = usePrefStore((state) => state.setNoScrollbars);
   // const imgSourcePrio = usePrefStore((state) => state.imgSourcePrio);
   // const setImgSourcePrio = usePrefStore((state) => state.setImgSourcePrio);
   // const timeFormat = usePrefStore((state) => state.timeFormat);
   // const setTimeFormat = usePrefStore((state) => state.setTimeFormat);
   // const dateFormat = usePrefStore((state) => state.dateFormat);
   // const setDateFormat = usePrefStore((state) => state.setDateFormat);
   const listPageLength = Number(usePrefStore((state) => state.listPageLength));
   // const setListPageLength = usePrefStore((state) => state.setListPageLength);
   // const maxPageButtonsOnLists = usePrefStore((state) => state.maxPageButtonsOnLists);
   // const setMaxPageButtonsOnLists = usePrefStore((state) => state.setMaxPageButtonsOnLists);
   // const showImageSource = usePrefStore((state) => state.showImageSource);
   // const setShowImageSource = usePrefStore((state) => state.setShowImageSource);
   // const formatPhone = usePrefStore((state) => state.formatPhone);
   // const setFormatPhone = usePrefStore((state) => state.setFormatPhone);
   // const maxRecentItems = usePrefStore((state) => state.maxRecentItems);
   // const setMaxRecentItems = usePrefStore((state) => state.setMaxRecentItems);
   // const showCessDelay = usePrefStore((state) => state.showCessDelay);
   // const setShowCessDelay = usePrefStore((state) => state.setShowCessDelay);
   // const keepHistoryOpen = usePrefStore((state) => state.keepHistoryOpen);
   // const setKeepHistoryOpen = usePrefStore((state) => state.setKeepHistoryOpen);
   const { reset: resetPrefs, catalogMainAlign, setCatalogMainAlign, globalClosePopups, setGlobalClosePopups, debug, setDebug, localOrderId, setLocalOrderId, maxTarifsOffline, setMaxTarifsOffline, qtyMaxOrder, setQtyMaxOrder, showWeeksNum, setShowWeeksNum, minFilterLength, setMinFilterLength, noScrollbars, setNoScrollbars, imgSourcePrio, setImgSourcePrio, timeFormat, setTimeFormat, dateFormat, setDateFormat, setListPageLength, maxPageButtonsOnLists, setMaxPageButtonsOnLists, showImageSource, setShowImageSource, formatPhone, setFormatPhone, maxRecentItems, setMaxRecentItems, showCessDelay, setShowCessDelay, keepHistoryOpen, setKeepHistoryOpen, sessionDuration, setSessionDuration } = usePrefStore();
   //
   const [showExpert, setShowExpert] = useState(false);
   // const [secret, setSecret] = useState('');  // Un code "secret" pour autoriser qq actions dangereuses
   const [cacheSize, setCacheSize] = useState(0);
   const [segPrioImages, setSegPrioImages] = useState(null);
   const [segPosCatalog, setSegPosCatalog] = useState(null);
   const [segClosePopups, setSegClosePopups] = useState(null);
   // const [segSliderPrice, setSegSliderPrice] = useState(null)
   const [segTimeFormat, setSegTimeFormat] = useState(null);
   const [segDateFormat, setSegDateFormat] = useState(null);
   const [segListPageLength, setSegListPageLength] = useState(null);
   const [segSessionDuration, setSegSessionDuration] = useState(null);
   const [segMaxPageButtonsOnLists, setSegMaxPageButtonsOnLists] = useState(null);
   const [tmpMaxRecentItems, setTmpMaxRecentItems] = useState(null); // Juste le temps de l'édition
   //
   // const { fetchOrders } = useSales()
   //
   const { trans } = useTranslation();
   const { getVersion, getVersionDate, getCopyright, getDevRef } = useHelpers();
   const navigate = useNavigate();
   // const { log } = useLogger();

   useEffect(() => {
      calcCacheSize().then((val) => setCacheSize(val));
   }, [setCacheSize]);

   useEffect(() => {
      setTmpMaxRecentItems(maxRecentItems)
   }, [maxRecentItems])

   function calcCacheSize() {
      // console.log('ICI2 CACHES');
      // console.log("calcCacheSize")
      return (async () => {
         let total = 0

         total += (await (await caches.open(dynamicCacheName)).keys()).length
         total += (await (await caches.open(assetsCacheName)).keys()).length
         total += (await (await caches.open(fontsCacheName)).keys()).length
         return total;
      })();
   }

   // console.log(">>", cacheSize)
   // console.log(">>..", calcCacheSize())
   // console.log(tarif_regions)

   function doResetPrefs() {
      resetPrefs();
   }

   function toggleExpert() {
      setShowExpert(!showExpert);
   }

   function cleanCache(evt) {
      try {
         // const test=... Petit tweak (try...catch) pour éviter erreur de "cannot find variable caches" dans Safari
         // eslint-disable-next-line no-unused-vars
         const test = caches ?? true
         caches
            .keys()
            .then((keys) =>
               keys
                  .filter((key) => {
                     return ((key === assetsCacheName) || (key === fontsCacheName) || (key === dynamicCacheName))
                  })
                  .map((key) => {
                     console.log('Suppression du cache', key);
                     return caches.delete(key);
                  })
            )
            .then((res) => {
               calcCacheSize().then((val) => setCacheSize(val));
            });
      } catch (error) {
         console.log('Pas de cache!!!');
      }      // console.log('ICI3 CACHES');
   }

   const onCatalogMainAlignChange = useCallback((val) => {
      setCatalogMainAlign(val);
   }, [setCatalogMainAlign]);

   const onGlobalClosePopupsChange = useCallback((val) => {
      // console.log(val)
      setGlobalClosePopups(val);
   }, [setGlobalClosePopups]);

   function onMinFilterLengthChange(val) {
      setMinFilterLength(val);
   }

   // function onFilterDelayChange(val) {
   //    setFilterDelay(val);
   // }

   const onListPageLengthChange = useCallback((val) => {
      setListPageLength(val);
   }, [setListPageLength]);

   const onSessionDurationChange = useCallback((val) => {
      setSessionDuration(val);
   }, [setSessionDuration]);

   const onImgSourcePrioChange = useCallback((val) => {
      setImgSourcePrio(val);
   }, [setImgSourcePrio]);

   const onTimeFormatChange = useCallback((val) => {
      setTimeFormat(val);
   }, [setTimeFormat]);

   const onDateFormatChange = useCallback((val) => {
      setDateFormat(val);
   }, [setDateFormat]);

   const onMaxPageButtonsOnListsChange = useCallback((val) => {
      setMaxPageButtonsOnLists(val);
   }, [setMaxPageButtonsOnLists]);

   const onLocalOrderIdChange = useCallback((val) => {
      setLocalOrderId(val);
   }, [setLocalOrderId]);

   const onQtyMaxOrderChange = useCallback((val) => {
      setQtyMaxOrder(val);
   }, [setQtyMaxOrder]);

   // const onSecretChange = useCallback((val) => {
   //    setSecret(val);
   // }, [setSecret]);

   // const doCleanOrders = useCallback((val) => {
   //    db.orders.clear()
   //    fetchOrders()
   // }, [fetchOrders]);

   useEffect(() => {
      const segments = [
         { value: 'bymessika', label: trans('?by Messika') },
         { value: 'interne', label: trans('?Interne') }
      ];

      setSegPrioImages(
         <SegmentedButtons segments={segments} default={imgSourcePrio} medium_padding opaque onChange={onImgSourcePrioChange} />
      );
   }, [imgSourcePrio, onImgSourcePrioChange, trans]);

   useEffect(() => {
      const segments = [
         { value: 'left', label: trans('?Gauche') },
         { value: 'center', label: trans('?Centré') }
      ];
      setSegPosCatalog(
         <SegmentedButtons segments={segments} default={catalogMainAlign} medium_padding opaque onChange={onCatalogMainAlignChange} />
      );
   }, [catalogMainAlign, onCatalogMainAlignChange, trans]);

   useEffect(() => {
      const segments = [
         { value: 'button', label: trans('?Bouton seulement') },
         { value: 'all', label: trans('?Partout') }
      ];

      setSegClosePopups(
         <SegmentedButtons segments={segments} name="orderby" medium_padding opaque default={globalClosePopups} onChange={onGlobalClosePopupsChange} />
      );
   }, [globalClosePopups, onGlobalClosePopupsChange, trans]);

   useEffect(() => {
      const segments = [
         { value: '24', label: '24H' },
         { value: '12', label: '12H AM/PM' }
      ];

      setSegTimeFormat(
         <SegmentedButtons segments={segments} name="orderby" medium_padding default={timeFormat} opaque onChange={onTimeFormatChange} />
      );
   }, [onTimeFormatChange, timeFormat]);

   useEffect(() => {
      const segments = [
         { value: 'dmy', label: trans('?DD-MM-YYYY') },
         { value: 'mdy', label: trans('?MM-DD-YYYY') },
         { value: 'ymd', label: trans('?YYYY-MM-DD') },
         { value: 'ydm', label: trans('?YYYY-DD-MM') }
      ];

      setSegDateFormat(
         <SegmentedButtons segments={segments} name="orderby" medium_padding default={dateFormat} opaque onChange={onDateFormatChange} />
      );
   }, [onDateFormatChange, dateFormat, trans]);

   useEffect(() => {
      const segments = [
         { value: '10', label: '10' },
         { value: '20', label: '20' },
         { value: '50', label: '50' },
         { value: '100', label: '100' },
         { value: '999999', label: 'Tous' }
      ];

      setSegListPageLength(
         <SegmentedButtons segments={segments} name="orderby" medium_padding default={String(listPageLength)} opaque onChange={onListPageLengthChange} />
      );
   }, [onListPageLengthChange, listPageLength]);

   useEffect(() => {
      const segments = [
         { value: '600', label: '10 min' },
         { value: '1800', label: '30 min' },
         { value: '3600', label: '1 H' },
         { value: '14400', label: '4 H' },
         { value: '86400', label: '1 J' },
         { value: '31536000', label: 'Sans limite' }
      ];

      setSegSessionDuration(
         <SegmentedButtons segments={segments} name="orderby" medium_padding default={String(sessionDuration)} opaque onChange={onSessionDurationChange} />
      );
   }, [onSessionDurationChange, sessionDuration]);

   useEffect(() => {
      const segments = [
         { value: 3, label: '3' },
         { value: 5, label: '5' },
         { value: 10, label: '10' }
      ];

      setSegMaxPageButtonsOnLists(
         <SegmentedButtons segments={segments} name="orderby" medium_padding default={maxPageButtonsOnLists} opaque onChange={onMaxPageButtonsOnListsChange} />
      );
   }, [onMaxPageButtonsOnListsChange, maxPageButtonsOnLists]);

   function onChangeMaxRecentItems(val) {
      if (val > 0) {
         // Pour pouvoir supprimer complètement le champ de saisi, sinon ça revient auto à la valeur par défaut (5)
         setMaxRecentItems(val)
      } else {
         setTmpMaxRecentItems(val)
      }
   }

   // console.log('SEGS0', segMaxPageButtonsOnLists)

   return (
      <Fragment>
         <Menu page="params" />
         <div
            className={`${styles.params} ${noScrollbars ? '' : styles.noscrollbars
               }`}
         >
            <div className={styles.innerbox}>
               <div>
                  <Button small onClick={() => navigate(-1)}>
                     {trans('?Retour')}
                  </Button>
                  <Button small onClick={toggleExpert}>
                     {showExpert ? trans('?Repasser en mode Normal') : trans('?Passer en mode Expert')}
                  </Button>
               </div>
               {showExpert && <h3>{trans('?warn_expert_mode')}</h3>}
               <h1>{trans('?Paramètres régionaux')}</h1>
               <section className={styles.region}>
                  <div>
                     {trans("?Format d'heure")}
                     {segTimeFormat}
                  </div>
                  <div>
                     {trans('?Format de date')}
                     {segDateFormat}
                  </div>
               </section>
               <h1>{trans('?Global')}</h1>
               <section className={styles.global}>
                  <div>
                     {trans('?N° de commande')}
                     <div style={{ width: 100 }}>
                        <InputField placeholder="3" value={localOrderId} onChange={onLocalOrderIdChange} ></InputField>
                     </div>
                  </div>
                  <div>
                     {trans('?Quantité maxi par ligne de commande')}
                     <div style={{ width: 60 }}>
                        <InputField placeholder="3" value={qtyMaxOrder} onChange={onQtyMaxOrderChange} ></InputField>
                     </div>
                  </div>
                  <div>
                     {trans('?max_tarifs_offline')}
                     <div style={{ width: 60 }}>
                        <InputField placeholder="3" value={maxTarifsOffline} onChange={(evt) => setMaxTarifsOffline(evt.target.value)} ></InputField>
                     </div>
                  </div>
                  {/* {showExpert && (
                     <div>
                        <div style={{ width: 200 }}>
                           <InputField placeholder="secret" value={secret} password onChange={onSecretChange} ></InputField>
                        </div>
                        <div>
                           <Button small onClick={doCleanOrders} inactive={secret !== secretPhrase} tertiary={secret !== secretPhrase}>
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                              {trans('?Supprimer toutes les commandes')}
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                           </Button>
                        </div>
                     </div>
                  )} */}
               </section>
               <h1>{trans('?Interface')}</h1>
               <section className={styles.gui}>
                  <div>
                     {trans('?Priorité des images')}
                     {segPrioImages}
                  </div>
                  <Checkbox caption={trans('?Afficher puce pour byMessika')} checked={`${showImageSource ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setShowImageSource(status === CheckboxStates.Checked)} />
                  <Checkbox caption={trans('?Formater numéros de téléphone')} checked={`${formatPhone ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setFormatPhone(status === CheckboxStates.Checked)} />
                  <Checkbox caption={trans('?Afficher les numéros de semaine (date picker)')} checked={`${showWeeksNum ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setShowWeeksNum(status === CheckboxStates.Checked)} />
                  <div>
                     {trans('?history_size')}
                     <div style={{ width: 60 }}>
                        <InputField placeholder="3" value={tmpMaxRecentItems} onChange={(val) => onChangeMaxRecentItems(val)} ></InputField>
                     </div>
                  </div>
                  <Checkbox caption={trans('?Garder historique ouvert')} checked={`${keepHistoryOpen ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setKeepHistoryOpen(status === CheckboxStates.Checked)} />
                  <div>
                     {trans('?Fermeture popups')}
                     {segClosePopups}
                  </div>
                  {/* <div>
                     {trans("?Borneur de prix")}{segSliderPrice}
                  </div> */}
                  <div>
                     {trans('?min_length')}
                     <div style={{ width: 50 }}>
                        <InputField placeholder="3" value={minFilterLength} onChange={onMinFilterLengthChange} ></InputField>
                     </div>
                  </div>
                  <div>
                     {trans('?delay_show_cess')}
                     <div style={{ width: 80 }}>
                        <InputField placeholder="3" value={showCessDelay} onChange={(val) => setShowCessDelay(val)} ></InputField>
                     </div>
                  </div>
                  <div>
                     {trans("?Durée maxi d'inactivité")}
                     {segSessionDuration}
                  </div>
                  <div>
                     {trans('?list_length_per_page')}
                     {segListPageLength}
                  </div>
                  <Checkbox caption={trans('?Afficher ascenseurs')} checked={`${!noScrollbars ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setNoScrollbars(status !== CheckboxStates.Checked)} />
                  {showExpert && (
                     <>
                        <Button small onClick={doResetPrefs}>
                           {trans('?Réinitialiser les préférences')}
                        </Button>
                        <Button small onClick={cleanCache}>
                           {trans('?Purger le cache')} ({cacheSize})
                        </Button>
                        <Checkbox caption="Debug" checked={`${debug ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setDebug(status === CheckboxStates.Checked)} />
                        {/* <Checkbox caption="Log server" checked={`${logToServer ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setLogToServer(status === CheckboxStates.Checked)}/> */}
                        {/* <Checkbox caption="Log console" checked={`${logToConsole ? CheckboxStates.Checked : CheckboxStates.Unchecked}`} onChange={(status) => setLogToConsole(status === CheckboxStates.Checked)} /> */}
                        <div>
                           {trans('?Position catalogue')}
                           {segPosCatalog}
                        </div>
                        <div>
                           {trans('?max_buttons_on_lists')}
                           {segMaxPageButtonsOnLists}
                        </div>
                     </>
                  )}
               </section>
            </div>
            <div className={styles.footer}>
               <span>
                  Version : {getVersion()} ({getVersionDate()}) -{' '}
                  {getCopyright()}
               </span>
               <span>{getDevRef()}</span>
            </div>
         </div>
      </Fragment>
   );
}

export default Params;
