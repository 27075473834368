import styles from './CatalogDetail.module.scss'
// import styles_catalogfilter from "../components/Catalog/CatalogFilter.module.scss"
import { Fragment, useCallback, useEffect, useState } from 'react'
import Menu from '../components/Menu/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faPlus, faMagnifyingGlass, faRing, faGem, faBox, faCubesStacked } from '@fortawesome/free-solid-svg-icons'
import { faGrid2, faGrid, faBars, faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import CatalogSearchBar from '../components/Catalog/CatalogSearchBar'
import Button from '../components/Button'
import ButtonLink from '../components/ButtonLink'
import QtyPicker from '../components/QtyPicker'
import ListItem from '../components/Catalog/ListItem'
import { useNavigate } from 'react-router-dom'
import useTranslation from '../Hooks/useTranslation'
import useCollections from '../Hooks/useCollections'
import useFilters from '../Hooks/useFilters'
import usePrefStore from '../stores/prefs'
// import { useLogger } from '../Contexts/Logger'
import useTarifs from '../Hooks/useTarifs'
import SegmentedButtons from '../components/SegmentedButtons'
import RadioButtons, { SingleRadioButton } from '../components/RadioButtons'
import DropdownField from '../components/DropdownField'
import CardModel from '../components/CardModel'
import PagesButtons from '../components/PagesButtons'
import useHelpers from '../Hooks/useHelpers'
// import useTest from "../Hooks/useTest"
// import useTarifs from "../Hooks/useTarifs"
// import Timeout from '../Hooks/useTimeout'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useStateWithHistory from '../Hooks/useStateWithHistory' // TRES COMPLIQUÉ AVEC CATALOG_DETAIL !!! OK seulement sur qq states
import useScrollBlock from "../Hooks/useScrollBlock";
import useSales from '../Hooks/useSales'
import { CatalogFilterContext } from '../Contexts/CatalogFilterContext';
import { GlobalContext } from '../Contexts/GlobalContext'
import DynImage from '../components/DynImage'
import glob_const from '../libs/glob_const';

const modeList = 'radbtn_list'
const modeGrid = 'radbtn_grid'
const modeSmallGrid = 'radbtn_smallgrid'

// const sortPorte = "sort_porte"
const sortFamille = 'sort_famille'
const sortGamme = 'sort_gamme'
const sortCollection = 'sort_collection'

const sortAsc = 'sort_asc'
const sortDesc = 'sort_desc'

function CatalogDetail() {
   // Prefs store
   // const minFilterLength = usePrefStore((state) => state.minFilterLength)
   // const globalClosePopups = usePrefStore((state) => state.globalClosePopups)
   const listPageLength = Math.min(100, Number(usePrefStore((state) => state.listPageLength)))
   // const setListPageLength = usePrefStore((state) => state.setListPageLength)
   // const curOrder = usePrefStore((state) => state.curOrder)
   // const curCustomer = usePrefStore((state) => state.curCustomer)
   const { minFilterLength, setListPageLength, curOrder, curCustomer, currentLang } = usePrefStore()

   // States mémo pour historique
   const [displayMode, setDisplayMode] = useStateWithHistory('catdet_display_mode', modeGrid)
   const [subSortOrder, setSubSortOrder] = useStateWithHistory('catdet_sub_sort_order', sortAsc)
   // const [searchText, setSearchText] = useStateWithHistory('catdet_search_text', '')
   // const [enteredByCollectionId, setEnteredByCollectionId] = useStateWithHistory('catdet_entered_coll_id', null)   // L'ID de la collection par laquelle on est arrivé (ou null)
   // const [minManualPrice, setMinManualPrice] = useStateWithHistory('catdet_min_manual_price', null) // Prix mini choisi manuellement pour le filtrage
   // const [maxManualPrice, setMaxManualPrice] = useStateWithHistory('catdet_max_manual_price', null) // Prix maxi choisi manuellement pour le filtrage
   // const [curPage, setCurPage] = useStateWithHistory('catdet_cur_page', 1)
   // const [nbPages, setNbPages] = useStateWithHistory('catdet_nb_pages', 1)
   // const [firstPage, setFirstPage] = useStateWithHistory('catdet_first_page', 1)

   // States potentiellement utilisables avec useStateWithHistory() mais compliqué...
   // const [displayMode, setDisplayMode] = useState(modeGrid)
   // const [subSortOrder, setSubSortOrder] = useState(sortAsc)

   // Variables/fonctions de contexte. Le tweak {...} est en cas de undefined avec CatalogFilterContext()
   const { minManualPrice, setMinManualPrice } = { ...CatalogFilterContext() } // Prix mini choisi manuellement pour le filtrage.
   const { maxManualPrice, setMaxManualPrice } = { ...CatalogFilterContext() } // Prix maxi choisi manuellement pour le filtrage.
   const { minPrice, setMinPrice } = { ...CatalogFilterContext() }  // Prix mini tous modèles filtrés confondus (sans filtrer sur prix évidemment !)
   const { maxPrice, setMaxPrice } = { ...CatalogFilterContext() }  // Prix max tous modèles filtrés confondus (sans filtrer sur prix évidemment !)
   const { allMinMax } = { ...CatalogFilterContext() }           // Les prix min/max de tous les modèles
   const { paramCollectionId } = { ...CatalogFilterContext() }                 // La collection choisie si entrée par une collection

   const { searchText } = { ...CatalogFilterContext() }         // Texte de recherche
   const { getSelectedGammesIds, getSelectedCollectionsIds, getSelectedFamillesIds, getSelectedPortesIds, getSelectedCouleursIds } = { ...CatalogFilterContext() }
   // *******************************************
   // Pour éviter les rerenders, on décide du moment où le filtrage peut se faire.
   // Il peut se faire quand le bouillonnement de la mise à jour Gammes > Collections > Familles > Portes > Couleurs est fini (dans Couleur)
   // const [doFilter, setDoFilter] = useState(false)
   const { doFilter, setDoFilter } = { ...CatalogFilterContext() }
   const { isDarkLayerVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible } = { ...GlobalContext() }

   // States
   const [curPage, setCurPage] = useState(1)
   const [nbPages, setNbPages] = useState(1)
   const [firstPage, setFirstPage] = useState(1)
   const [mainSortMode, setMainSortMode] = useState(null) // Gamme / Famille / Collection. Pas possible useStateWithHistory()
   // const [enteredByCollectionId, setEnteredByCollectionId] = useState(null)   // L'ID de la collection par laquelle on est arrivé (ou null)
   const [selectedModelId, setSelectedModelId] = useState(null)
   const [selectedModel, setSelectedModel] = useState(null)
   const [minMaxForSelectedModel, setMinMaxForSelectedModel] = useState()
   // const [strColorsForSelectedModel, setStrColorsForSelectedModel] = useState(null)
   const [arrColorsForSelectedModel, setArrColorsForSelectedModel] = useState(null)
   const [filteredList, setFilteredList] = useState([])  // Les objets des modèles à afficher (avant searchText)
   const [finalList, setFinalList] = useState(null)        // Les objets des modèles réellement affichés (après searchText)

   const [subTitle, setSubTitle] = useState(null)
   const [modelItems, setModelItems] = useState(null)
   const [sortSegItems, setSortSegItems] = useState(null)
   // const [searchBar, setSearchBar] = useState(null)
   const [catSearchBar, setCatSearchBar] = useState(null)
   const [isQtyPickerVisible, setIsQtyPickerVisible] = useState(false) //16
   const [isItemsPerPageOpen, setIsItemsPerPageOpen] = useState(false)
   const [strPath, setStrPath] = useState('')
   const [items, setItems] = useState(null)
   const [pagesButtons, setPagesButtons] = useState(null)
   const [critSummerize, setCritSummerize] = useState(null)
   const [showInfoDown, setShowInfoDown] = useState(false)    // true = animer l'affichage, false = animer le masquage
   const [isInfoVisible, setIsInfoVisible] = useState(false)   // info présent ou absent de la page
   const [theQtyPicker, setTheQtyPicker] = useState()   // info présent ou absent de la page
   const [selectedThumb, setSelectedThumb] = useState()   // Indice de la vignette choisie dans la fiche détail
   const [zoomed, setZoomed] = useState(false)   // Affichage des vignettes en mode Zoomé
   // const [refreshFilterTimer, setRefreshFilterTimer] = useState(null)
   //
   const navigate = useNavigate()
   const { trans, lowtrans, transWithParams } = useTranslation()
   const { getCollectionById, gammeSort, makeSKU, isHJOModel, getModelById, getCycleById } = useCollections()
   const { getCessPriceForModelColorSize, getMinMaxPriceForModel, getColorsForModel } = useTarifs()
   const { formatPPUPrice, formatWeightWithNC, formatDiamWeightWithNC, formatNumberWithNC } = useHelpers()
   const { filterOnText, filterOnPrice, filterOnCriteria } = useFilters()
   const { createOrUpdateLinesInOrder, createOrder, makeTemplateOrder } = useSales()
   const [blockScroll, allowScroll] = useScrollBlock();

   useEffect(() => {
      // setEnteredByCollectionId(paramCollectionId)
      setMainSortMode(paramCollectionId ? sortFamille : sortGamme)
   }, [paramCollectionId])

   function compareFamilles(item_a, item_b) {
      if ((glob_const.auto_familles[item_a.fam] !== undefined) && glob_const.auto_familles[item_a.fam].includes(item_b.fam)) {
         return 0 // Ce sont 2 items considérés comme étant dans la même famille
      } else if ((glob_const.auto_familles[item_b.fam] !== undefined) && glob_const.auto_familles[item_b.fam].includes(item_a.fam)) {
         return 0 // Ce sont 2 items considérés comme étant dans la même famille
      } else {
         return item_a.fam?.localeCompare(item_b.fam)
      }
   }

   const sortList = useCallback((arr, eachMinMax) => {
      if (mainSortMode === sortGamme) {
         // Gamme > Collection Famille > Porté > Prix
         setStrPath(
            <>
               {trans('?Gamme')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Collection')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Famille')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Porté')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Prix')}
            </>
         )
         return [
            ...arr.sort((item1, item2) => {
               let ret = gammeSort(item1.id, item2.id)
               // console.log('Compare', item1, item2, ret)
               if (ret === 0) {
                  // Les 2 gammes sont identiques, on trie sur la collection
                  ret = item1.collec?.localeCompare(item2.collec)
                  // console.log('Compare collections', item1.collec, item2.collec, ret)
                  if (ret === 0) {
                     // Les 2 collections sont identiques, on trie sur la famille
                     // ret = item1.fam?.localeCompare(item2.fam)
                     ret = compareFamilles(item1, item2)
                     // console.log('Compare familles', item1.fam, item2.fam, ret)
                     if (ret === 0) {
                        // Les 2 familles sont identiques, on trie sur le porté
                        ret = item1.porte?.localeCompare(item2.porte)
                        // console.log('Compare portes', item1.porte, item2.porte, ret)
                        if ((ret === 0) && (eachMinMax != null)) {
                           // Les 2 portés sont identiques, on trie sur le prix
                           ret = eachMinMax[item1.id]?.min - eachMinMax[item2.id]?.min;
                           if (subSortOrder === sortAsc) {
                              ret = -ret;
                           }
                           // console.log('Compare prix', eachMinMax[item1.id].min, eachMinMax[item2.id].min, ret)
                        }
                     }
                  }
               }
               return ret;
            })
         ]
      } else if (mainSortMode === sortCollection) {
         // Collection > Famille > Porté > Prix|Nom
         setStrPath(
            <>
               {trans('?Collection')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Famille')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Porté')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Prix')}
            </>
         )
         return [
            ...arr.sort((item1, item2) => {
               let ret = item1.collec?.localeCompare(item2.collec)
               // console.log('Compare collections', item1.collec, item2.collec, ret)
               if (ret === 0) {
                  // Les 2 collections sont identiques, on trie sur le famille
                  // ret = item1.fam?.localeCompare(item2.fam)
                  ret = compareFamilles(item1, item2)
                  // console.log('Compare familles', item1.fam, item2.fam, ret)
                  if (ret === 0) {
                     // Les 2 familles sont identiques, on trie sur le porté
                     ret = item1.porte?.localeCompare(item2.porte)
                     // console.log('Compare portes', item1.porte, item2.porte, ret)
                     if ((ret === 0) && (eachMinMax != null)) {
                        // Les 2 portés sont identiques, on trie sur le prix
                        ret = eachMinMax[item1.id]?.min - eachMinMax[item2.id]?.min;
                        if (subSortOrder === sortAsc) {
                           ret = -ret;
                        }
                        // console.log('Compare prix', eachMinMax[item1.id].min, eachMinMax[item2.id].min, ret)
                     }
                  }
               }
               return ret;
            })
         ]
      } else if (mainSortMode === sortFamille) {
         // Famille > Porté > Prix|Nom
         setStrPath(
            <>
               {trans('?Famille')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Porté')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Prix')}
            </>
         )
         return [
            ...arr.sort((item1, item2) => {
               // let ret = item1.fam?.localeCompare(item2.fam)
               let ret = compareFamilles(item1, item2)
               // console.log('Compare famille', item1.fam, item2.fam, ret)
               if (ret === 0) {
                  // Les 2 familles sont identiques, on trie sur le porté
                  ret = item1.porte?.localeCompare(item2.porte)
                  // console.log('Compare portes', item1.porte, item2.porte, ret)
                  if ((ret === 0) && (eachMinMax != null)) {
                     // Les 2 portés sont identiques, on trie sur le prix
                     ret = eachMinMax[item1.id]?.min - eachMinMax[item2.id]?.min;
                     if (subSortOrder === sortAsc) {
                        ret = -ret;
                     }
                     // console.log('Compare prix', eachMinMax[item1.id].min, eachMinMax[item2.id].min, ret)
                  }
               }
               return ret;
            })
         ]
      } /*if (mainSortMode === sortPorte)*/ else {
         // Porté > Prix
         setStrPath(
            <>
               {trans('?Porté')} <FontAwesomeIcon icon={faAngleRight} />{' '}
               {trans('?Prix')}
            </>
         )
         return [
            ...arr.sort((item1, item2) => {
               let ret = item1.porte?.localeCompare(item2.porte)
               // console.log('Compare portes', item1.porte, item2.porte, ret)
               if ((ret === 0) && (eachMinMax != null)) {
                  // Les 2 portés sont identiques, on trie sur le prix
                  ret = eachMinMax[item1.id]?.min - eachMinMax[item2.id]?.min;
                  if (subSortOrder === sortAsc) {
                     ret = -ret;
                  }
                  // console.log('Compare prix', eachMinMax[item1.id].min, eachMinMax[item2.id].min, ret)
               }
               return ret;
            })
         ]
      }
   }, [gammeSort, mainSortMode, subSortOrder, trans])

   /**************
    ************** FILTRAGE FINAL !!!
    **************/
   useEffect(() => {
      if (doFilter && allMinMax)  // OK pour calculer les min/max de prix
      {
         const selected_familles_id = getSelectedFamillesIds()
         const selected_gammes_id = getSelectedGammesIds()
         const selected_collections_id = getSelectedCollectionsIds()
         const selected_portes_id = getSelectedPortesIds()
         const selected_couleurs_id = getSelectedCouleursIds()

         let arr = filterOnCriteria({ gammes: selected_gammes_id, collections: selected_collections_id, familles: selected_familles_id, portes: selected_portes_id, couleurs: selected_couleurs_id })

         // console.log('**>**', arr);
         // Calcul du min/max global tous modèles filtrés à ce stade
         const min_max = arr?.reduce((prev, model) => {
            // console.log('**>**1', model, allMinMax);
            const model_min_max = allMinMax[model.id]

            if ((model_min_max === null) || (model_min_max.min === null) || (model_min_max.max === null)) {
               return { ...prev }
            } else {
               return {
                  min: prev.min !== null ? Math.min(prev.min, model_min_max.min) : model_min_max.min,
                  max: prev.max !== null ? Math.max(prev.max, model_min_max.max) : model_min_max.max,
               }
            }
         }, { min: null, max: null })

         if (minPrice !== min_max.min || maxPrice !== min_max.max) {
            console.log('SET minMax', min_max)
            setMinPrice((min_max.min < 100) ? 0 : min_max.min)
            setMaxPrice(min_max.max)
         }

         if ((min_max.min > minManualPrice) || (min_max.max < minManualPrice)) {
            setMinManualPrice(null)
         }
         if (min_max.max < maxManualPrice) {
            setMaxManualPrice(null)
         }

         const filterMinPrice = (((minManualPrice !== null) && (minManualPrice >= min_max.min)) ? minManualPrice : null) // null permet de ne pas tenir compte du filtrage
         const filterMaxPrice = (((maxManualPrice !== null) && (maxManualPrice <= min_max.max)) ? maxManualPrice : null) // null permet de ne pas tenir compte du filtrage

         // On ne filtrera réellement sur le prix que si on a choisi des prix manuellement (min et max non null)
         arr = filterOnPrice(arr, { price_min: filterMinPrice, price_max: filterMaxPrice }, allMinMax)
         setFilteredList(arr)
         setDoFilter(false)   // Fini, on bloque
      }
   }, [allMinMax, doFilter, filterOnCriteria, filterOnPrice, getSelectedCollectionsIds, getSelectedCouleursIds, getSelectedFamillesIds, getSelectedGammesIds, getSelectedPortesIds, maxManualPrice, maxPrice, minManualPrice, minPrice, setDoFilter, setMaxManualPrice, setMaxPrice, setMinManualPrice, setMinPrice])

   // useEffect(() => {
   //    if (searchText.length >= minFilterLength) {
   //       setFinalList(filterOnText(filteredList, searchText))
   //    } else {
   //       setFinalList(filteredList)
   //    }
   // }, [filterOnText, filteredList, minFilterLength, searchText])

   useEffect(() => {
      // console.log(finalList);
      // console.log(listPageLength);
      if (finalList?.length) {
         const len = listPageLength
         const max = Math.floor((finalList.length + len - 1) / len) // Nb maxi de pages
         setNbPages(max)
         console.log('>>>>>>', max, finalList.length, finalList.length + len, listPageLength)
         // if (finalList.length > 0) {   // Pour corriger pb avec useStateWithHistory()
         if (curPage < 1 || curPage > max) {
            setCurPage(1)
         }
         // }
      }
   }, [curPage, finalList, listPageLength, setCurPage, setNbPages])

   useEffect(() => {
      const selected_gammes_id = getSelectedGammesIds()
      const selected_collections_id = getSelectedCollectionsIds()
      const selected_familles_id = getSelectedFamillesIds()
      const selected_portes_id = getSelectedPortesIds()
      const selected_couleurs_id = getSelectedCouleursIds()

      let arr = [];

      if ((paramCollectionId === null) && (selected_gammes_id.length > 0)) {
         arr.push(`${selected_gammes_id.length} ${lowtrans('?Gamme', selected_gammes_id.length)}`)
      }
      if ((paramCollectionId === null) && (selected_collections_id.length > 0)) {
         arr.push(`${selected_collections_id.length} ${lowtrans('?Collection', selected_collections_id.length)}`)
      }
      if (selected_familles_id.length > 0) {
         arr.push(`${selected_familles_id.length} ${lowtrans('?Famille', selected_familles_id.length)}`)
      }
      if (selected_portes_id.length > 0) {
         arr.push(`${selected_portes_id.length} ${lowtrans('?Porté', selected_portes_id.length)}`)
      }
      if (selected_couleurs_id.length > 0) {
         arr.push(`${selected_couleurs_id.length} ${lowtrans('?Couleur', selected_couleurs_id.length)}`)
      }

      if (((minManualPrice !== null) && (minManualPrice > minPrice)) || ((maxManualPrice !== null) && (maxManualPrice < maxPrice))) {
         // if ((minManualPrice !== null) && (maxManualPrice !== null)) {
         arr.push(
            `${lowtrans('?Entre')} ${formatPPUPrice(minManualPrice ?? minPrice)} ${trans('?et')} ${formatPPUPrice(maxManualPrice ?? maxPrice)}`
         )
      }
      setCritSummerize((arr.length > 0) ?
         `(${arr.length} ${lowtrans('?Filtre', arr.length)} : ${arr.join(', ')})` :
         null)

      if (selected_collections_id.length > 1) {
         setSubTitle(`${selected_collections_id.length} ${lowtrans('?Collection', true)}`)
      } else if (selected_collections_id.length === 1) {
         setSubTitle(getCollectionById(selected_collections_id[0]).label)
      } else {
         setSubTitle(trans("?Toutes collections"))
      }
   }, [paramCollectionId, formatPPUPrice, getCollectionById, getSelectedCollectionsIds, getSelectedCouleursIds, getSelectedFamillesIds, getSelectedGammesIds, getSelectedPortesIds, lowtrans, maxManualPrice, maxPrice, minManualPrice, minPrice, trans])

   // const onFilter = useCallback(() => {
   //    console.debug('onFilter')
   // }, [])

   useEffect(() => {
      const start = (curPage - 1) * listPageLength;
      setItems(finalList?.slice(start, start + listPageLength))
   }, [curPage, finalList, listPageLength])

   const onFirstPageChange = useCallback((val) => {
      console.log('onFirstPageChange', val)
      setFirstPage(val)
   }, [setFirstPage])

   const onPageChange = useCallback((val) => {
      console.log('onPageChange', val)
      setCurPage(val)
   }, [setCurPage])

   useEffect(() => {
      setPagesButtons(
         <PagesButtons from={firstPage} max={nbPages} selected={curPage} onFirstPageChange={onFirstPageChange} onPageChange={onPageChange} ></PagesButtons>
      )
   }, [curPage, firstPage, nbPages, onFirstPageChange, onPageChange])

   useEffect(() => {
      setKeepSearchBarAccessible(false)
      setIsDarkLayerVisible(isQtyPickerVisible)
   }, [isQtyPickerVisible, setIsDarkLayerVisible, setKeepSearchBarAccessible])

   useEffect(() => {
      if (!isDarkLayerVisible) {
         setIsQtyPickerVisible(false)
      }
   }, [isDarkLayerVisible])

   const onShowQtyPicker = useCallback((model_id) => {
      console.log(`onShowQtyPicker(${model_id})`)
      setSelectedModelId(model_id)
      setIsQtyPickerVisible(true)
   }, [])

   function doHideInfo() {
      setShowInfoDown(false)
      // On supprime le panneau de la page pour alléger le code
      setTimeout(() => {
         setIsInfoVisible(false)
      }, 300)  // Si changement de 300, changer aussi dans CSS ()
   }

   const onShowDetail = useCallback((model_id) => {
      console.log(`onShowDetail(${model_id})`)
      setSelectedModelId(model_id)
      setSelectedModel(getModelById(model_id))
      setIsInfoVisible(true)
      setSelectedThumb(0)
      setZoomed(false)
      setMinMaxForSelectedModel(getMinMaxPriceForModel(model_id))

      // const ret_colors = getColorsForModel(model_id)
      const ret_colors = getColorsForModel(model_id)?.colors ?? []
      // setStrColorsForSelectedModel(ret_colors?.colors?.join(", "))
      // setArrColorsForSelectedModel(ret_colors?.colors ?? [])
      setArrColorsForSelectedModel(
         ret_colors?.map((color) => {
            return (<span>{color}</span>)
         })
      )

   }, [getColorsForModel, getMinMaxPriceForModel, getModelById])

   useEffect(() => {
      let arr = [];

      if (displayMode === modeList) {
         arr = items?.map((model) => {
            return (<ListItem key={model.id} id={model.id} onShowQtyPicker={onShowQtyPicker} onShowDetail={onShowDetail} />)
         })
      } else {
         arr = items?.map((model) => {
            if (isHJOModel(model.id)) {
               return (
                  <CardModel inactive key={`card-${model.id}`} id={model.id} small={displayMode === modeSmallGrid} ></CardModel>
               )
            } else {
               return (
                  <CardModel key={`card-${model.id}`} id={model.id} onClick={() => { onShowQtyPicker(model.id) }} small={displayMode === modeSmallGrid} ></CardModel>
               )
            }
         })
      }
      setModelItems(arr)
   }, [displayMode, isHJOModel, items, onShowDetail, onShowQtyPicker])

   function goCatalog() {
      navigate('/catalog', { replace: true })
   }

   const hideQtyPicker = useCallback(() => {
      setIsQtyPickerVisible(false)
   }, [])

   // const doSearch = useCallback(() => {
   useEffect(() => {
      let arr

      if (searchText?.length >= minFilterLength) {
         arr = sortList(filterOnText(filteredList, searchText), allMinMax)
      } else {
         arr = sortList(filteredList, allMinMax)
      }
      setFinalList(arr)
   }, [allMinMax, filterOnText, filteredList, minFilterLength, searchText, sortList])

   // const onSearchChange = useCallback((text) => {
   //    setSearchText(text)
   //    // if (text.length === 0 || text.length >= minFilterLength) {
   //    //    setDoFilter(true)
   //    // }
   // }, [setSearchText])

   const onMainSortModeChange = useCallback((val) => {
      // console.log(val)
      // setDoFilter(true)
      setMainSortMode(val)
   }, [setMainSortMode])

   // useEffect(() => {
   //    setSearchBar(
   //       <Searchbar text={searchText} onChange={onSearchChange} onShowTools={onToggleSearchFilters} filters_popup={filterPopup} hasCriteriaEnabled={critSummerize !== null} />
   //    )
   // }, [critSummerize, filterPopup, onSearchChange, onToggleSearchFilters, searchText])

   useEffect(() => {
      let segments = [];

      if (paramCollectionId === null) {
         segments.push(
            ...[
               { value: sortGamme, label: trans('?Par gamme') },
               { value: sortCollection, label: trans('?Par collection') },
               { value: sortFamille, label: trans('?Par famille') }
            ]
         )
         setSortSegItems(
            <SegmentedButtons segments={segments} name="orderby" normal medium_padding opaque default={mainSortMode} onChange={onMainSortModeChange} />
         )
      } else {
         setSortSegItems(null)
      }
   }, [paramCollectionId, mainSortMode, onMainSortModeChange, trans])

   function onDisplayModeWillChange(which) {
      setDisplayMode(which)
   }

   function onSortOrderClick() {
      // console.log('ICI', subSortOrder)
      // setDoFilter(true)
      setSubSortOrder(subSortOrder === sortAsc ? sortDesc : sortAsc)
   }

   function toggleItemsPerPageShowList() {
      setIsItemsPerPageOpen(!isItemsPerPageOpen)
   }

   function onItemsPerPageSelect(id) {
      // console.log('>>>>>>', id)
      setListPageLength(id)
      setIsItemsPerPageOpen(false)
   }

   function onClickBackground() {
      // setIsSubSortModeOpen(false)
   }

   async function addNewOrder() {
      const new_order = makeTemplateOrder(curCustomer)
      const curid = await createOrder(new_order)

      // On a la même chose (avec Toaster dans Customers.jsx)
      toast.success(transWithParams('?Nouvelle commande %1 créée et activée', curid), {
         position: "top-right",
         autoClose: 2500,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         // progress: ,
         theme: "colored",
      });
   }

   const addLines = useCallback((model, multi, variations) => {
      createOrUpdateLinesInOrder(curOrder,
         variations.map((an_item) => {
            const { color, size, qty } = an_item
            const price = getCessPriceForModelColorSize(model, color, size)?.price ?? 0

            return {
               "sku": makeSKU(model, color, size),
               "qty": qty,
               "price": price
            }
         })
      )
   }, [createOrUpdateLinesInOrder, curOrder, getCessPriceForModelColorSize, makeSKU])

   useEffect(() => {
      setCatSearchBar(<CatalogSearchBar onFilter={setDoFilter(true)} />)
   }, [setDoFilter])

   useEffect(() => {
      if (isInfoVisible) {
         blockScroll()
         // document.body.classList.add('noscroll')
         setShowInfoDown(true)
      } else {
         // document.body.classList.remove('noscroll')
         allowScroll()
      }
      return () => {
         allowScroll()
      }
   }, [allowScroll, blockScroll, isInfoVisible])

   // console.log('*-*-*', filteredList, typeof filteredList)
   // console.log("EFFECT*** ICI CHANGED")

   // console.log('CATA_DETAIL', gammes, collections, familles, portes, couleurs)
   // console.log(selectedModel);

   useEffect(() => {
      if (isQtyPickerVisible) {
         setTheQtyPicker(<QtyPicker model={selectedModelId} onHide={hideQtyPicker} onAdd={addLines} />)
      } else {
         setTheQtyPicker(null)
      }
   }, [addLines, hideQtyPicker, isQtyPickerVisible, selectedModelId])

   return (
      <Fragment>
         <Menu page="catalog" />
         <div className={styles.catalog_detail} onClick={onClickBackground}>
            {catSearchBar}
            <div className={styles.content}>
               <header>
                  <ButtonLink lefticon={<FontAwesomeIcon icon={faAngleLeft} />} onClick={goCatalog} >
                     {trans('?Retour au catalogue')}
                     {/* _{minPrice ?? 'null'}_{minManualPrice ?? 'null'}___{maxPrice ?? 'null'}_{maxManualPrice ?? 'null'}_ */}
                  </ButtonLink>
                  {(curCustomer === null) &&
                     <Button disabled lefticon={<FontAwesomeIcon icon={faPlus} />}>
                        {trans('?Aucun client sélectionné')}
                     </Button>
                  }
                  {(curCustomer !== null) &&
                     <Button lefticon={<FontAwesomeIcon icon={faPlus} />} onClick={addNewOrder}>
                        {trans('?Nouvelle commande')}
                     </Button>
                  }
                  <h1>{subTitle}</h1>
                  <div className={styles.sort_mode}>
                     {sortSegItems}
                     <span>{trans('?Prix')} :</span>
                     {subSortOrder === sortAsc && (
                        <SingleRadioButton selected onClick={onSortOrderClick}>
                           <FontAwesomeIcon key={sortAsc} icon={faBarsFilter} />
                        </SingleRadioButton>
                     )}
                     {subSortOrder === sortDesc && (
                        <SingleRadioButton selected onClick={onSortOrderClick}>
                           <FontAwesomeIcon key={sortDesc} icon={faBarsFilter} style={{ transform: 'rotate(180deg)' }} />
                        </SingleRadioButton>
                     )}
                  </div>
                  <span className={styles.filtered_count}>
                     {finalList?.length ?? 0}{' '}
                     {lowtrans('?Modèle', finalList?.length)}
                     {(searchText?.trim().length > 0) ? ` ${trans('?x_sur_y')} ${filteredList.length}` : ''}{' '}
                     {critSummerize}
                  </span>
                  <div className={styles.display_mode}>
                     <RadioButtons selected={(displayMode === modeList) ? modeList : ((displayMode === modeGrid) ? modeGrid : modeSmallGrid)} onWillChange={onDisplayModeWillChange} >
                        <FontAwesomeIcon key={modeList} icon={faBars} />
                        <FontAwesomeIcon key={modeGrid} icon={faGrid2} />
                        <FontAwesomeIcon key={modeSmallGrid} icon={faGrid} />
                     </RadioButtons>
                  </div>
                  <span className={styles.path}>{strPath}</span>
               </header>
               <section className={`${displayMode === modeList ? styles.list : styles.grid} ${displayMode === modeSmallGrid ? styles.small : ''}`}>
                  {modelItems}
               </section>
            </div>
            <div className={styles.footer_bg}>
               <div className={styles.footer}>
                  <span>{trans('?Affichage de')}</span>
                  <DropdownField selectedValue={String(listPageLength)} showAbove height={36} onSelect={onItemsPerPageSelect} isOpen={isItemsPerPageOpen} onToggle={toggleItemsPerPageShowList}>
                     <Fragment key="10">10</Fragment>
                     <Fragment key="20">20</Fragment>
                     <Fragment key="50">50</Fragment>
                     <Fragment key="100">100</Fragment>
                     {/* <Fragment key="999999">{lowtrans("?Tous")}</Fragment> */}
                  </DropdownField>
                  {/* <span>{(listPageLength < 99999) ? trans('?par page') : ''}</span>
                  {(listPageLength < 99999) && pagesButtons} */}
                  <span>{trans('?par page')}</span>
                  {pagesButtons}
               </div>
            </div>
            {isInfoVisible && <div className={`${styles.product_info} ${showInfoDown ? styles.info_down : ''}`}>
               <header>
                  <div>
                     {/* <ButtonLink lefticon={<FontAwesomeIcon icon={faAngleLeft} />} onClick={goCatalog} >
                        {trans('?Retour au catalogue')}
                     </ButtonLink> */}
                     <h2>{subTitle}</h2>
                     <h1>{selectedModel?.label || ''}</h1>
                  </div>
                  <Button onClick={doHideInfo}>
                     {trans('?Fermer')}
                  </Button>
               </header>
               <div className={styles.info_detail}>
                  {!zoomed &&
                     <div className={styles.detail_left}>
                        <div className={styles.info_block}>
                           <h3>{trans('?Détail', true)}</h3>
                           <div className={styles.info_block_detail}>
                              <span>
                                 <span>
                                    <FontAwesomeIcon icon={faRing} />
                                 </span>
                                 {`${formatWeightWithNC(selectedModel.pds)}`}
                              </span>
                              <span>
                                 <span>
                                    <FontAwesomeIcon icon={faGem} />
                                 </span>
                                 {`${formatDiamWeightWithNC(selectedModel.pdsdts)}`}
                              </span>
                              <span>
                                 <span>
                                    <FontAwesomeIcon icon={faCubesStacked} />
                                 </span>
                                 {`${formatNumberWithNC(selectedModel.dts)}`}
                              </span>
                              <span>
                                 <span>
                                    <FontAwesomeIcon icon={faBox} />
                                 </span>
                                 {selectedModel.ecrin}
                              </span>
                           </div>
                        </div>
                        <div className={styles.info_block}>
                           <h3>{trans('?Couleur', true)}</h3>
                           <div className={styles.info_block_colors}>
                              {arrColorsForSelectedModel}
                           </div>
                        </div>
                        <div className={`${styles.info_block} ${styles.description}`}>
                           <h3>{trans('?Description')}</h3>
                           <span>{selectedModel.descr[currentLang ?? 'fr']}</span>
                        </div>
                     </div>
                  }
                  <div className={styles.detail_right}>
                     {!zoomed &&
                        <div className={styles.info_block}>
                           <div className={styles.prix_public}>
                              <div className={styles.prix}>
                                 <span>{trans('?Prix public')}</span>
                                 <span>{formatPPUPrice(minMaxForSelectedModel?.min)}</span>
                              </div>
                              <span className={styles.cycle}>{getCycleById(selectedModel.cycle).label}</span>
                           </div>
                        </div>
                     }
                     <div className={`${styles.info_block} ${styles.info_block_thumbnails} ${zoomed ? styles.zoomed : ''}`}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className={styles.magnify} onClick={() => setZoomed(val => !val)} />
                        {selectedModel &&
                           <>
                              <DynImage className={styles.imgmodel} src={[`catalogue/modeles/${selectedModel.id}@2x.png`, selectedModel.bymessika ? selectedModel.bymessika[selectedThumb] : null]} alt={`Model ${selectedModel.id}`} />
                              {(selectedModel.bymessika?.length > 1) &&
                                 <div className={styles.thumbnails}>
                                    {selectedModel.bymessika.map((an_image, idx) => {
                                       const num = idx
                                       return <img className={`${styles.imgmodel_thumb} ${(num === selectedThumb) ? styles.selected : ''} `} src={`https://www.bymessika.com/media/catalog/product${an_image}`} alt={`Model ${selectedModel.id}`} onClick={() => setSelectedThumb(num)} />
                                    })}
                                 </div>
                              }
                           </>
                        }
                     </div>
                  </div>
               </div>
            </div>
            }
         </div>
         {theQtyPicker}
         <ToastContainer />
      </Fragment>
   )
}

export default CatalogDetail;
